import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import location from 'util/location.js';
import * as S from '../toc-and-privacy.styles';
import { Box } from '@material-ui/core';

const TocModal = ({ open, close }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={open}
      close={close}
      title={t('register.termsAndConditions.label')}
      testId="TocAndPrivacy.TocModal"
    >
      <Box height={['80vh', '70vh']}>
        <S.CustomIframe
          title={t('register.termsAndConditions.label')}
          src={`${location.getLocationOrigin()}/termsofuse_web.html`}
        />
      </Box>
    </CustomDialog>
  );
};

export default TocModal;
