import { createSelector } from 'reselect';
import { defaultLocale } from './i18n.slice';

export const selecti18n = state => state.i18n;

export const selectLocale = createSelector(selecti18n, i18n => i18n.locale);

export const selectBaseLocale = createSelector(selectLocale, locale => {
  let language = locale;
  if (!locale) {
    return defaultLocale;
  }
  if (locale.indexOf('_') !== -1) {
    language = locale.split('_')[0];
  } else if (locale.indexOf('-') !== -1) {
    language = locale.split('-')[0];
  }
  return language.toLowerCase();
});

export const selectBaseLocaleInUpperCase = createSelector(
  selectBaseLocale,
  baseLocale => baseLocale.toUpperCase(),
);
