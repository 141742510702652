import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import MenuAccount from 'components/menu-account/menu-account.component';
import DrawerLoginOptions from 'components/drawer-login-options/drawer-login-options.component';
import DrawerCart from 'components/drawer-cart/drawer-cart.component';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import * as S from './header-togglers.styles';
import ThemeToggle from 'components/theme-toggle/theme-toggle.component';
import EnvConfig from 'components/env-config/env-config.component';

const HeaderTogglers = () => {
  const embedSignUp = useRouteMatch(['/signup']);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (embedSignUp) return null;

  return (
    <S.Container component="ul">
      {process.env.REACT_APP_ENV_NAME !== 'production' && (
        <li>
          <EnvConfig />
        </li>
      )}
      <li>
        <ThemeToggle />
      </li>
      {isLoggedIn ? (
        <li>
          <MenuAccount />
        </li>
      ) : (
        <li>
          <DrawerLoginOptions />
        </li>
      )}
      <li>
        <DrawerCart />
      </li>
    </S.Container>
  );
};

export default HeaderTogglers;
