import { useOrderPaymentOptions } from 'components/payment/payment.new.hooks';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { CHECKOUT_SECTIONS } from 'pages/checkout/checkout.constants';
import CheckoutContext from 'pages/checkout/checkout.context';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'redux/app/app.slice';
import {
  setPaymentInstrumentId,
  setPaymentOption,
} from 'redux/cart/cart.actions';
import {
  selectPaymentInstrumentId,
  selectPaymentOption,
} from 'redux/cart/cart.selectors';
import {
  selectIsLoggedIn,
  selectPaymentInstrumentsCards,
  selectPaymentInstrumentsGiftCards,
} from 'redux/user/user.selectors';
import PaymentSelector from '../payment-selector.component';

const OrderPaymentSelector = () => {
  const dispatch = useDispatch();
  const { scrollToSection } = useContext(CheckoutContext);
  const setPaymentLoading = useCheckoutPaymentLoading(
    state => state.setLoading,
  );
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { selectedPaymentOption, setSelectedPaymentOption, paymentOptions } =
    useOrderPaymentOptions();
  const cartPaymentOption = useSelector(selectPaymentOption);
  const preferredOrderPaymentOption = useSelector(
    selectors.selectUserPreferredOrderPaymentOption,
  );
  const onChangePaymentOption = type => {
    setSelectedPaymentOption(type);
    dispatch(setPaymentOption(type));
    if (isLoggedIn) {
      dispatch(actions.setLoggedInPreferredOrderPaymentOption(type));
    } else {
      dispatch(actions.setPreferredOrderPaymentOption(type));
    }
  };

  useEffect(() => {
    if (!selectedPaymentOption && Object.keys(paymentOptions).length > 0) {
      onChangePaymentOption(
        cartPaymentOption ??
          preferredOrderPaymentOption ??
          Object.keys(paymentOptions).find(
            paymentOptionType => paymentOptions[paymentOptionType],
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cards = useSelector(selectPaymentInstrumentsCards);
  const selectedCard = useSelector(selectPaymentInstrumentId);
  const onChangeCard = paymentInstrumentId =>
    dispatch(setPaymentInstrumentId(paymentInstrumentId));

  const giftCards = useSelector(selectPaymentInstrumentsGiftCards);

  window.Spreedly?.on('errors', function (_errors) {
    setPaymentLoading(false);
    scrollToSection(CHECKOUT_SECTIONS.PAYMENT);
  });

  return (
    <PaymentSelector
      paymentOptions={paymentOptions}
      selectedPaymentOption={selectedPaymentOption}
      onChangePaymentOption={onChangePaymentOption}
      cards={cards}
      selectedCard={selectedCard}
      onChangeCard={onChangeCard}
      giftCards={giftCards}
      selectedGiftCard={selectedCard}
      onChangeGiftCard={onChangeCard}
    />
  );
};

export default OrderPaymentSelector;
