import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from './offers.slice';

export const useOffers = () => {
  const dispatch = useDispatch();

  const allOffers = useSelector(selectors.selectAll);
  const offersTotal = useSelector(selectors.selectTotal);
  const offersRetrievedPages = useSelector(selectors.selectRetrievedPages);
  const offersHasMore = useSelector(selectors.selectHasMore);
  const offersLoading = useSelector(selectors.selectLoading);

  const fetchOffers = useCallback(
    params => dispatch(actions.fetchOffers(params)),
    [dispatch],
  );
  const refreshOffers = useCallback(
    params => dispatch(actions.refreshOffers(params)),
    [dispatch],
  );
  const removeOneOffer = useCallback(
    id => dispatch(actions.removeOne(id)),
    [dispatch],
  );

  const upsertManyOffers = useCallback(
    offers => dispatch(actions.upsertMany(offers)),
    [dispatch],
  );
  const deleteOffer = useCallback(
    id => dispatch(actions.deleteOffer({ id })),
    [dispatch],
  );
  const updateOfferStatus = useCallback(
    (id, status) => dispatch(actions.updateOfferStatus({ id, status })),
    [dispatch],
  );

  return {
    fetchOffers,
    refreshOffers,
    removeOneOffer,
    upsertManyOffers,
    updateOfferStatus,
    deleteOffer,
    allOffers,
    offersTotal,
    offersRetrievedPages,
    offersHasMore,
    offersLoading,
  };
};
