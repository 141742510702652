import { useURLSearchParams } from 'hooks/useURLSearchParams';
import CloverForm from './clover-form/clover-form.component';
import CloverSubmitButton from './clover-submit-button/clover-submit-button.component';
import * as S from './clover.styles';
import Helmet from 'react-helmet';
import CloverFormWrapper from './clover-form/clover-form.wrapper';

const Clover = () => {
  const urlSearchParams = useURLSearchParams();
  const apikey = urlSearchParams.get('apikey');

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <S.Container>
        <CloverFormWrapper>
          <CloverForm apikey={apikey} />
          <CloverSubmitButton />
        </CloverFormWrapper>
      </S.Container>
    </>
  );
};

export default Clover;
