import React, { useState, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderTypeContext } from 'pages/store/new-order-form/order-form-context';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import * as S from './locations.styles';
import LocationsFinder from './locations-finder/locations-finder.component';
import LocationsMap from './locations-map/locations-map.component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { selectIsGoogleMapsLoaded } from '../../redux/config/config.selectors';
import { useLocation } from 'react-router-dom';
import { useLoginLink } from 'hooks/useLoginLink';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';

const LocationsPage = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const isGoogleMapsLoaded = useSelector(selectIsGoogleMapsLoaded);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { state } = useLocation();
  const { redirected } = state || {};
  const formMethods = useForm({ shouldUnregister: true });
  const { fieldNames } = useFieldNames();
  const cateringOrderType = formMethods.watch(fieldNames.cateringOrderType);
  const orderType = formMethods.watch(fieldNames.orderType);
  const orderTypeContextValue = useMemo(
    () => (orderType === 'CATERING' ? cateringOrderType : orderType),
    [cateringOrderType, orderType],
  );
  const handleLoginLinkClick = useLoginLink();

  useEffect(() => {
    if (redirected) {
      handleLoginLinkClick();
      window.history.replaceState({ ...state, redirected: false }, '');
    }
  }, [handleLoginLinkClick, redirected, state]);

  return (
    <S.Container>
      {isGoogleMapsLoaded && (
        <FormProvider {...formMethods}>
          <OrderTypeContext.Provider value={orderTypeContextValue}>
            <LocationsFinder
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              orderType={orderType}
            />
            {matches && <LocationsMap setSelectedStore={setSelectedStore} />}
          </OrderTypeContext.Provider>
          <RHFDevtoolsWrapper control={formMethods.control} />
        </FormProvider>
      )}
    </S.Container>
  );
};

export default LocationsPage;
