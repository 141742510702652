import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';

export const HorizontalLine = styled(Divider)`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled(IncButton)`
  margin-bottom: 0.5em !important;
`;
