import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContentBody,
  ContentContainer,
  ContentHeader,
} from './reset-password.styles';
import Spinner from 'components/spinner/spinner.component';
import ResetPasswordForm from './reset-password-form/reset-password-form.component';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <Container data-testid={'ResetPassword.Container'}>
      {loading && <Spinner />}
      <ContentContainer elevation={2}>
        <ContentHeader
          component="h1"
          variant="h5"
          aria-label={t('resetPassword.header')}
        >
          {t('resetPassword.header')}
        </ContentHeader>
        <ContentBody>
          <ResetPasswordForm setLoading={setLoading} />
        </ContentBody>
      </ContentContainer>
    </Container>
  );
};

export default ResetPassword;
