const CartActionTypes = {
  SET_ORDER_TYPE: 'SET_ORDER_TYPE',
  SET_REQUESTED_ADDRESS: 'SET_REQUESTED_ADDRESS',
  GET_ORDER_ID_START: 'GET_ORDER_ID_START',
  GET_ORDER_BY_ID_START: 'GET_ORDER_BY_ID_START',
  GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILURE: 'GET_ORDER_BY_ID_FAILURE',
  CLEAR_CART: 'CLEAR_CART',
  SET_ORDER_FULFILL_TIME_SUCCESS: 'SET_REQUESTED_FULFILL_TIME_SUCCESS',
  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  MODIFY_ORDER_ITEMS_START: 'MODIFY_ORDER_ITEMS_START',
  MODIFY_ORDER_ITEMS_SUCCESS: 'MODIFY_ORDER_ITEMS_SUCCESS',
  MODIFY_ORDER_ITEMS_FAILURE: 'MODIFY_ORDER_ITEMS_FAILURE',
  SET_DELIVERY_INSTRUCTIONS: 'SET_DELIVERY_INSTRUCTIONS',
  SET_ORDER_OPTION: 'SET_ORDER_OPTION',
  OPEN_CART_DRAWER: 'OPEN_CART_DRAWER',
  CLOSE_CART_DRAWER: 'CLOSE_CART_DRAWER',
  GET_ORDER_OFFERS_START: 'GET_ORDER_OFFERS_START',
  GET_ORDER_OFFERS_SUCCESS: 'GET_ORDER_OFFERS_SUCCESS',
  GET_ORDER_OFFERS_FAILURE: 'GET_ORDER_OFFERS_FAILURE',
  COPY_ORDER_START: 'COPY_ORDER_START',
  COPY_ORDER_SUCCESS: 'COPY_ORDER_SUCCESS',
  COPY_ODER_FAILURE: 'COPY_ODER_FAILURE',
  ADD_ORDER_ITEMS_START: 'ADD_ORDER_ITEMS_START',
  ADD_ORDER_ITEMS_SUCCESS: 'ADD_ORDER_ITEMS_SUCCESS',
  ADD_ORDER_ITEMS_FAILURE: 'ADD_ORDER_ITEMS_FAILURE',
  ADD_ORDER_ITEM_START: 'ADD_ORDER_ITEM_START',
  ADD_ORDER_ITEM_SUCCESS: 'ADD_ORDER_ITEM_SUCCESS',
  ADD_ORDER_ITEM_FAILURE: 'ADD_ORDER_ITEM_FAILURE',
  SET_TIP: 'SET_TIP',
  SET_ORDER_INSTRUCTIONS: 'SET_ORDER_INSTRUCTIONS',
  APPLY_DISCOUNT_START: 'APPLY_DISCOUNT_START',
  APPLY_DISCOUNT_SUCCESS: 'APPLY_DISCOUNT_SUCCESS',
  APPLY_DISCOUNT_FAILURE: 'APPLY_DISCOUNT_FAILURE',
  REMOVE_DISCOUNT_START: 'REMOVE_DISCOUNT_START',
  REMOVE_DISCOUNT_SUCCESS: 'REMOVE_DISCOUNT_SUCCESS',
  REMOVE_DISCOUNT_FAILURE: 'REMOVE_DISCOUNT_FAILURE',
  NEW_ORDER_START: 'NEW_ORDER_START',
  NEW_ORDER_SUCCESS: 'NEW_ORDER_SUCCESS',
  NEW_ORDER_FAILURE: 'NEW_ORDER_FAILURE',
  SET_DEFAULT_CART_ORDER_ITEM_STATE: 'SET_DEFAULT_CART_ORDER_ITEM_STATE',
  UPDATE_ORDER_START: 'UPDATE_ORDER_START',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',
  SET_PAYMENT_INSTRUMENT_ID: 'SET_PAYMENT_INSTRUMENT_ID',
  SET_PAYMENT_OPTION: 'SET_PAYMENT_OPTION',
  SET_LOCATION_DATA: 'SET_LOCATION_DATA',
};

export default CartActionTypes;
