import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAddGiftCardToWallet } from 'redux/config/config.selectors';
import AddExistingGiftCard from '../add-existing-gift-card/add-existing-gift-card.component';
import CardBox from '../card-box/card-box.component';

const AddExistingGiftCardCard = () => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useCustomDialog();
  const addGiftCardToWallet = useSelector(selectAddGiftCardToWallet);

  if (!addGiftCardToWallet) return null;

  return (
    <>
      <CardBox
        onClick={handleOpen}
        text={t('addPurchaseGiftCard.addCardAction')}
        testId="CardBox.IncButtonDark.AddExistingCard"
      />
      <AddExistingGiftCard open={open} handleClose={handleClose} />
    </>
  );
};

export default AddExistingGiftCardCard;
