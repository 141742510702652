import { Box, Grid } from '@material-ui/core';
import Form from 'components/form/form.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';
import GiftCardNumberInput from 'components/gift-card-number-input/gift-card-number-input.component';
import GiftCardSecurityCodeInput from 'components/gift-card-security-code-input/gift-card-security-code-input.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSecurityCodeEnabled } from 'redux/config/config.selectors';

const GuestGiftCardPanel = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const securityCodeEnabled = useSelector(selectSecurityCodeEnabled);

  return (
    <Box data-testid="GuestGiftCardPanel.Box">
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(() => null)}
          id="guest-gift-card-payment-form"
        >
          <MandatoryFieldsInstruction />
          <Grid container spacing={1}>
            <Grid item xs={securityCodeEnabled ? 6 : 12}>
              <GiftCardNumberInput
                name="giftCardNumberInput"
                id="giftCardNumberInput"
                label={t('purchaseWithGiftCardAsGuest.giftCardInput.label')}
              />
            </Grid>
            {securityCodeEnabled && (
              <Grid item xs={6}>
                <GiftCardSecurityCodeInput
                  label={t(
                    'purchaseWithGiftCardAsGuest.securityCodeInput.label',
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </FormProvider>
    </Box>
  );
};

export default GuestGiftCardPanel;
