import IncentivioPaymentsContext from 'components/payment/incentivio/incentivio-payments.context';
import Spinner from 'components/spinner/spinner.component';
import { useContext } from 'react';
import OrderPaymentSelector from '../../order-payment-selector.component';

const IncentivioOrderPaymentSelector = () => {
  const { loading } = useContext(IncentivioPaymentsContext);

  return (
    <>
      {loading && <Spinner />}
      <OrderPaymentSelector />
    </>
  );
};

export default IncentivioOrderPaymentSelector;
