import {
  PaymentGatewayType,
  PaymentType,
} from 'components/payment/payment.constants';
import { NewPaymentContext } from 'components/payment/payment.context';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import { useContext } from 'react';
import { useStatsigGate } from 'util/statsig.hooks';

export const useIsNewSpreedlyCardFlow = () => {
  const { type } = useContext(NewPaymentContext);
  const value = useStatsigGate('spreedly-save-card');
  const paymentGatewayMatches = usePaymentGatewayMatches();

  return (
    paymentGatewayMatches([PaymentGatewayType.SPREEDLY]) &&
    value &&
    type === PaymentType.ORDER
  );
};
