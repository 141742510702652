import CssBaseline from '@material-ui/core/CssBaseline';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { ThemeProvider } from 'styled-components';
import { useTheme } from 'theme.hooks';

const Theme = ({ children }) => {
  const { theme } = useTheme();

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default Theme;
