import EGiftcardForm from 'components/e-giftcard-form/e-giftcard-form.component';
import { useStep } from 'components/flow/step/step.hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from 'redux/cart/cart.actions';
import { selectIsLoggedIn, selectUserData } from 'redux/user/user.selectors';

const GiftcardStep = ({ onSubmit, data }) => {
  const { nextStep, setData } = useStep();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userData = useSelector(selectUserData);

  const handleSubmit = data => {
    onSubmit(data);
    setData(prev => ({ ...prev, giftcardData: data }));
    nextStep();
  };

  useEffect(() => {
    if (isLoggedIn) {
      const { firstName, lastName, phoneNumber, email } = userData;
      dispatch(
        setCustomerInfo({
          firstName,
          lastName,
          phoneNumber,
          email,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EGiftcardForm data={data} onSubmit={handleSubmit} />;
};

export default GiftcardStep;
