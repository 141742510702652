import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useIsURLSearchParamMatch } from 'hooks/useURLSearchParams';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { selectIsWOV2Enabled } from 'redux/config/config.selectors';
import {
  makeSelectIsMenuBrowsingStore,
  selectRequestedStoreDITitle,
} from '../../redux/locations/locations.selectors';
import HorizontalLogo from '../horizontal-logo/horizontal-logo.component';
import WarningDialog from '../warning-dialog/warning-dialog.component';
import HeaderTogglers from './header-togglers/header-togglers.component';
import * as S from './header.styles';
import RescheduleOrderController from './reschedule-order-controller/reschedule-order-controller.component';

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [locationDialog, setLocationDialog] = useState(false);
  const storeTitle = useSelector(selectRequestedStoreDITitle);
  const isWOV2Enabled = useSelector(selectIsWOV2Enabled);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());

  const isMenu = useRouteMatch(['/locations/:storeTitle/menu']);
  const isHideHeader =
    useIsURLSearchParamMatch('embed', 'true') || !isWOV2Enabled;

  const handleLocationChange = () => {
    setLocationDialog(false);
    history.push('/');
  };

  if (isHideHeader) return null;
  const ariaControlsValue = locationDialog ? 'change-location' : '';
  return (
    <S.Container position="fixed" data-testid="Header.Container">
      <S.SkipLink href="#main-content">{t('header.skipToMain')}</S.SkipLink>
      <WarningDialog
        open={locationDialog}
        close={() => setLocationDialog(false)}
        handleAction={handleLocationChange}
        title={t('dialogs.warningLocation.title')}
        subhead={t('dialogs.warningLocation.subhead')}
        warningText={t('dialogs.warningLocation.warning')}
      />
      <S.ToolbarWrapper data-testid="Header.Container.ToolbarWrapper">
        <S.LogoLinkContainer>
          <Link to="/" data-testid="Header.Container.ToolbarWrapper.LogoLink">
            <HorizontalLogo />
          </Link>
        </S.LogoLinkContainer>

        <HeaderTogglers />

        {isMenu && (
          <S.CatalogOptions ismenubrowsingstore={isMenuBrowsingStore}>
            {storeTitle && (
              <S.StoreNameToggler
                ismenubrowsingstore={isMenuBrowsingStore}
                aria-controls={ariaControlsValue}
                aria-haspopup="true"
                handleToggle={() => setLocationDialog(true)}
                toggleTestId="Header.TogglerButton.ChangeLocation"
              >
                <LocationOnIcon />
                <S.CatalogOptionText>{storeTitle}</S.CatalogOptionText>
              </S.StoreNameToggler>
            )}
            <RescheduleOrderController />
          </S.CatalogOptions>
        )}
      </S.ToolbarWrapper>
    </S.Container>
  );
};

export default Header;
