import { Box } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const MessageExpiration = ({ expireDate, redemptionEndDateType }) => {
  const { t } = useTranslation();
  const convertedExpireDate = DateTime.fromISO(expireDate, {
    zone: 'utc',
  });

  const diffDays = convertedExpireDate.diff(DateTime.local(), ['days']).days;

  if (diffDays < 0) return null;

  let text;
  if (diffDays < 1 && ['DYNAMIC', 'STATIC'].includes(redemptionEndDateType)) {
    text = `${t(
      'accountInbox.expired.time.label',
    )} ${convertedExpireDate.toRelative()} `;
  } else {
    text = `${t('accountInbox.expired.date.label')} ${convertedExpireDate
      .toLocal()
      .toFormat('MM/dd/yy')} `;
  }

  return (
    <Box display="flex" alignItems="center">
      <ScheduleIcon fontSize="inherit" /> &nbsp;
      <span aria-label={text}>{text}</span>
    </Box>
  );
};

export default MessageExpiration;
