import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  text-decoration: underline;
  font-size: 0.875rem;
`;

export const TimeSlotList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
