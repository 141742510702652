import { useMemo } from 'react';
import { useOrderDayTimes } from 'pages/store/new-order-form/useOrderDayTimes.hooks';
import { useSelector } from 'react-redux';
import { selectWithinDeliveryDistance } from 'redux/data/orderavailability/orderavailability.selectors';
import { DELIVERY } from 'util/constants';

export const useLocationsCardSelections = (store, orderType) => {
  const { availableDays, availableTimes } = useOrderDayTimes(store, orderType);
  const withinDeliveryDistance = useSelector(state =>
    selectWithinDeliveryDistance(state, store.storeId),
  );

  const dayTimeSkip = useMemo(() => {
    const isAddressValidationsPassed =
      orderType === DELIVERY ? withinDeliveryDistance : true;

    return (
      shouldSkipDayTimeSelection(availableDays, availableTimes) &&
      isAddressValidationsPassed
    );
  }, [orderType, withinDeliveryDistance, availableDays, availableTimes]);

  return { dayTimeSkip, firstAvailableTime: availableTimes[0] };
};

const shouldSkipDayTimeSelection = (availableDays, availableTimes) =>
  availableDays.length === 1 &&
  availableTimes.length === 1 &&
  availableTimes[0].key === 'asap';
