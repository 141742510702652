import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from './root-reducer';

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: window.Cypress
      ? false
      : {
          ignoredActions: ['persist/PERSIST'],
        },
    immutableCheck: window.Cypress ? false : true,
  }),
];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  preloadedState:
    window.Cypress && window.initialState ? window.initialState : undefined,
  middleware,
});

export const persistor = persistStore(store, {
  manualPersist: true,
});
