import { useTheme } from 'theme.hooks';

const scheduleCalendarOverrides = {
  MuiPickersCalendar: {
    week: {
      justifyContent: 'space-between',
    },
  },
  MuiPickersSlideTransition: {
    transitionContainer: {
      '&>*': {
        position: 'relative',
      },
    },
  },
  MuiPickersCalendarHeader: {
    transitionContainer: {
      height: 'auto',
      overflow: 'auto',
    },
    daysHeader: {
      justifyContent: 'space-between',
    },
    dayLabel: {},
    iconButton: {
      '&.Mui-disabled': {},
    },
  },
  MuiPickersBasePicker: {
    container: {
      maxWidth: '360px',
      width: '100%',
    },
    pickerView: {
      flexGrow: 1,
      width: '100%',
      maxWidth: '100%',
      minWidth: '280px',
    },
  },
  MuiPickersDay: {
    day: {},
    current: {},
    dayDisabled: {},
    daySelected: {
      '&:hover': {},
    },
  },
  MuiTypography: {
    body1: { fontSize: '1.1rem' },
    body2: { fontSize: '1rem' },
    caption: { fontSize: '1.1rem' },
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      alignItems: 'center',
      minWidth: '280px',
    },
  },
};

export const useSchedulePickerTheme = () => {
  const { standardTheme, theme } = useTheme();
  const {
    background,
    text: { primary: textPrimary },
    primary: { main, contrastText, dark },
    grey,
  } = theme.palette;

  const disabledColorDarkMode = grey['A700'];
  const disabledColor = grey['400'];

  const { MuiPickersDay, MuiPickersCalendarHeader, MuiPickersStaticWrapper } =
    scheduleCalendarOverrides;

  MuiPickersStaticWrapper.staticWrapperRoot.backgroundColor = background.paper;
  MuiPickersCalendarHeader.dayLabel.color = textPrimary;
  MuiPickersCalendarHeader.iconButton.color = textPrimary;
  MuiPickersCalendarHeader.iconButton.backgroundColor = background.paper;
  MuiPickersDay.day.color = textPrimary;
  MuiPickersDay.current.color = textPrimary;
  MuiPickersDay.daySelected.color = contrastText;
  MuiPickersDay.daySelected.backgroundColor = main;
  MuiPickersDay.daySelected['&:hover'].backgroundColor = dark;

  if (theme.palette.type === 'dark') {
    MuiPickersCalendarHeader.iconButton['&.Mui-disabled'].color =
      disabledColorDarkMode;
    MuiPickersDay.dayDisabled.color = disabledColorDarkMode;
  } else {
    MuiPickersCalendarHeader.iconButton['&.Mui-disabled'].color = disabledColor;
    MuiPickersDay.dayDisabled.color = disabledColor;
  }

  standardTheme.overrides = {
    ...standardTheme.overrides,
    ...scheduleCalendarOverrides,
  };

  return standardTheme;
};
