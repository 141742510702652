import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { setOrderOption } from 'redux/cart/cart.actions';
import { selectOrderType } from 'redux/cart/cart.selectors';
import { useCheckoutSection } from '../checkout-section/checkout-section.hooks';
import { shouldSkipOrderOptions } from 'components/new-order/order-options/useOrderOptions';
import { selectRequestedStore } from 'redux/locations/locations.selectors';
import { orderDetailsSchema } from '../checkout.schema';
import { useGetOrderOptions } from 'pages/store/new-order-form/useGetOrderOptions';
import { selectOrderDetails } from 'redux/common.selectors';
import HiddenMenuErrorContext from 'components/hidden-menu-error-dialog/hidden-menu-error-dialog.context';
import { useSubmitRescheduleOrder } from 'components/header/reschedule-order-controller/reschedule-order-modal/reschedule-order-modal.hooks';

export const useCheckoutOrderDetailsSectionComponent = () => {
  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(orderDetailsSchema),
  });
  const { handleSubmit, getValues } = formMethods;

  useSetSkippedOrderOption();

  const { validate } = useOrderDetailsValidation();
  const { t } = useTranslation();

  const title = useMemo(() => t('checkout.orderDetails.title'), [t]);

  const { setHiddenMenuErrorState, getInvalidItems } = useContext(
    HiddenMenuErrorContext,
  );

  const { onSubmit: onSubmitReschedule } = useSubmitRescheduleOrder();

  const handleOrderSubmit = async data => {
    await onSubmitReschedule(data);
    setEditing(false);
  };

  const handleTimeChange = async () => {
    const { time, orderType } = getValues();
    const invalidItemDetails = getInvalidItems(time, orderType);
    if (invalidItemDetails.length > 0) {
      setEditing(true);
      setHiddenMenuErrorState({
        open: true,
        invalidItemDetails,
        callback: async () => await handleSubmit(handleOrderSubmit)(),
      });
      throw Error();
    }
    await handleSubmit(handleOrderSubmit)();
  };

  const context = useCheckoutSection({
    title,
    onSubmit: handleTimeChange,
    validate,
  });

  const { setEditing } = context;

  return {
    formMethods,
    orderDetailsContext: context,
  };
};

export const useOrderDetailsValidation = () => {
  const store = useStore();

  const validate = useCallback(async () => {
    try {
      await orderDetailsSchema.validate(selectOrderDetails(store.getState()));
    } catch (err) {
      return err;
    }
  }, [store]);

  return { validate };
};

export const useSetSkippedOrderOption = () => {
  const store = useSelector(selectRequestedStore);
  const orderType = useSelector(selectOrderType);
  const dispatch = useDispatch();
  const { orderOptions } = useGetOrderOptions(store, orderType);

  useEffect(() => {
    if (shouldSkipOrderOptions(orderOptions))
      dispatch(setOrderOption(orderOptions[0]));
  }, [dispatch, orderOptions]);
};
