import { DevTool } from '@hookform/devtools';
import React from 'react';

const RHFDevtoolsWrapper = !!window.Cypress
  ? () => null
  : props => {
      return <DevTool {...props} />;
    };

export default RHFDevtoolsWrapper;
