import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

const CardSelect = ({ cards, value, onChange, icon, ...rest }) => {
  return (
    <FormControl fullWidth size="small">
      <Select
        value={value}
        onChange={e => onChange(e.target.value)}
        fullWidth
        variant="outlined"
        data-testid="CardSelect.Select"
        MenuProps={{ 'data-testid': 'CardSelect.Select.Menu' }}
        {...rest}
      >
        {cards?.map(({ paymentInstrumentId, nickname, lastFour }) => (
          <MenuItem key={paymentInstrumentId} value={paymentInstrumentId}>
            <Box display="flex" alignItems="center">
              {!!icon && icon}
              <Box display="flex" flexDirection="column" ml={1}>
                <Typography component="h5">{nickname}</Typography>
                <Typography>****{lastFour}</Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default CardSelect;
