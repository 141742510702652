import DefaultMessageView from 'pages/account-inbox/account-inbox-message/default-message-view/default-message-view.component';
import PurchaseMessage from 'pages/account-inbox/account-inbox-message/purchase-message/purchase-message.component';
import ShareableMessage from 'pages/account-inbox/account-inbox-message/shareable-message/shareable-message.component';
import SurveyMessage from 'pages/account-inbox/account-inbox-message/survey-message/survey-message.component';

const AccountInboxItemContent = ({
  checkoutPromoCode,
  message,
  setModalOpen,
  finalType,
  setIsContentDialogOpen,
  disableOnClick,
}) => {
  const {
    distributedMessageId,
    title,
    mediumImage,
    shortDescription,
    longDescription,
    surveyId,
  } = message;

  let content;
  if (checkoutPromoCode) {
    content = (
      <DefaultMessageView
        title={title}
        shortDescription={shortDescription}
        longDescription={longDescription}
        mediumImage={mediumImage}
      />
    );
  }

  switch (finalType) {
    case 'PURCHASE':
      if (message) {
        content = (
          <PurchaseMessage
            messageData={message}
            checkoutPromoCode={checkoutPromoCode}
          />
        );
      }
      break;

    case 'SHAREABLE':
    case 'REFERRAL':
      if (message) {
        content = (
          <ShareableMessage
            messageData={message}
            handleClose={() => setModalOpen(false)}
            setIsContentDialogOpen={setIsContentDialogOpen}
            disableOnClick={disableOnClick}
          />
        );
      }
      break;

    case 'SURVEY':
      content = (
        <SurveyMessage
          surveyId={surveyId}
          distributedMessageId={distributedMessageId}
          handleClose={() => setModalOpen(false)}
        />
      );
      break;

    default:
      content = (
        <DefaultMessageView
          title={title}
          shortDescription={shortDescription}
          longDescription={longDescription}
          mediumImage={mediumImage}
        />
      );
  }

  return content;
};

export default AccountInboxItemContent;
