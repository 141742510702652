import { Box, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;
`;

export const ContentContainer = styled(Paper)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 460px;
  }
`;

export const ContentBody = styled(Box)`
  padding: 1rem 2rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
