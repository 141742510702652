import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsSmsEnabled } from 'redux/config/config.selectors';
import { updateUserData } from 'redux/user/user.actions';
import { selectUserData } from 'redux/user/user.selectors';

export const usePromptSmsOpt = () => {
  const dispatch = useDispatch();
  const isSmsEnabled = useSelector(selectIsSmsEnabled);
  const userData = useSelector(selectUserData);
  const [loading, setIsLoading] = useState(false);

  const [smsPromptInModalOpen, setSmsPromptInModalOpen] = useState(false);

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (!userData) return;

    if (isSmsEnabled && !userData.extendedAttributes?.SMS_TXN_OPT_IN) {
      setSmsPromptInModalOpen(true);
    }
  }, [isSmsEnabled, userData]);

  const handleClose = (_e, reason) => {
    if (!['escapeKeyDown', 'backdropClick'].includes(reason)) {
      setSmsPromptInModalOpen(false);
    }
  };

  const updateSmsPreference = async preference => {
    setIsLoading(true);
    await dispatch(
      updateUserData({
        ...userData,
        extendedAttributes: {
          ...userData.extendedAttributes,
          SMS_TXN_OPT_IN: preference,
          SMS_MARKETING_OPT_IN: preference,
        },
      }),
    );
    setIsLoading(false);
    handleClose();
  };
  const dontAllow = () => {
    updateSmsPreference('FALSE');
  };

  const onSubmit = () => {
    updateSmsPreference('TRUE');
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    dontAllow,
    smsPromptInModalOpen,
    handleClose,
    loading,
  };
};
