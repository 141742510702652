import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: auto;
    margin-right: 0.5em;
  }
`;
