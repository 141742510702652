import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from './messages.slice';

export const useMessages = () => {
  const dispatch = useDispatch();

  const allMessages = useSelector(selectors.selectAll);
  const messagesTotal = useSelector(selectors.selectTotal);
  const messagesRetrievedPages = useSelector(selectors.selectRetrievedPages);
  const messagesHasMore = useSelector(selectors.selectHasMore);
  const messagesLoading = useSelector(selectors.selectLoading);

  const fetchMessages = useCallback(
    params => dispatch(actions.fetchMessages(params)),
    [dispatch],
  );
  const refreshMessages = useCallback(
    params => dispatch(actions.refreshMessages(params)),
    [dispatch],
  );
  const upsertManyMessages = useCallback(
    offers => dispatch(actions.upsertMany(offers)),
    [dispatch],
  );
  const deleteMessage = useCallback(
    id => dispatch(actions.deleteMessage({ id })),
    [dispatch],
  );
  const updateMessageStatus = useCallback(
    (id, status) => dispatch(actions.updateMessageStatus({ id, status })),
    [dispatch],
  );

  return {
    fetchMessages,
    refreshMessages,
    upsertManyMessages,
    updateMessageStatus,
    deleteMessage,
    allMessages,
    messagesTotal,
    messagesRetrievedPages,
    messagesHasMore,
    messagesLoading,
  };
};
