import { Grid, IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const QtyButton = styled(IconButton)`
  margin: 0 4px;
`;

export const QtyContainer = styled(Grid)`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: center;
    margin-bottom: 1em;
  }
`;
