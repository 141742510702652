import { Typography } from '@material-ui/core';
import { formatPhoneNumber } from 'pages/locations/locations.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledActionLink } from './store-phone.styles';

const StorePhone = ({ phoneNumber }) => {
  const { t } = useTranslation();

  if (!phoneNumber) return null;

  return (
    <StyledActionLink
      data-testid="StorePhone.ActionLink"
      href={`tel:${phoneNumber}`}
      component="a"
      type=""
      aria-label={`${t('locations.card.phone.ariaLabel')} ${phoneNumber}`}
    >
      <Typography variant="body2">{formatPhoneNumber(phoneNumber)}</Typography>
    </StyledActionLink>
  );
};

export default StorePhone;
