import Image from 'components/Image/image.component';
import styled from 'styled-components';

export const OptionItemImage = styled(Image)`
  width: 25px;
  margin-right: 5px;
  vertical-align: middle;
`;

export const Title = styled.span`
  text-decoration: ${({ isItemOutOfStock }) =>
    isItemOutOfStock ? 'line-through' : ''};
`;

export const Price = styled.span`
  margin-left: 5px;
  text-decoration: ${({ isItemOutOfStock }) =>
    isItemOutOfStock ? 'line-through' : ''};
  color: ${({ theme, isItemOutOfStock }) =>
    !isItemOutOfStock ? theme.palette.primary.main : ''};
`;

export const UnAvailableLabel = styled.span`
  margin-left: 5px;
`;
