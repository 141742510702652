import { DateTime } from 'luxon';

export const getNoticePeriodDaysArray = firstTimeString => {
  if (!firstTimeString) return [];

  const noOfDays = Math.round(
    DateTime.fromISO(firstTimeString)
      .set({ hour: 0, minute: 0, second: 0 })
      .diff(DateTime.local().set({ hour: 0, minute: 0, second: 0 }), 'days')
      .days,
  );

  if (noOfDays > 0)
    return Array.from(Array(noOfDays)).map((_x, index) =>
      DateTime.local().plus({ days: index }).toISODate(),
    );

  return [];
};
