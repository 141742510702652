import parse from 'html-react-parser';
import React from 'react';
import ReactTextFormat from 'react-text-format';
import * as S from './linkify-parse.styles';

const LinkifyParse = ({ children }) => {
  if (!children) return null;

  return (
    <S.TextFormat>
      <ReactTextFormat linkTarget="_blank">{parse(children)}</ReactTextFormat>
    </S.TextFormat>
  );
};

export default React.memo(LinkifyParse);
