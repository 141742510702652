import { Box } from '@material-ui/core';
import { CustomQRCode } from 'components/qr-code/qr-code.styles';
import Barcode from 'react-barcode';
import * as S from './render-code.styles';

const RenderCode = ({ codeFormat, code, displayValue = true }) => {
  if (codeFormat === 'BARCODE') {
    return (
      <Box textAlign="center" data-testid="RenderCode.Barcode">
        <Barcode value={code} width={1} displayValue={displayValue} />
      </Box>
    );
  }

  return (
    <Box textAlign="center">
      <CustomQRCode
        size={256}
        value={code}
        data-testid="RenderCode.CustomQRCode"
      />
      {displayValue && <S.Code>{code}</S.Code>}
    </Box>
  );
};

export default RenderCode;
