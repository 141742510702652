import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { InfoBox } from 'components/card-item/card-item.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';

const CardBox = ({ onClick, text, testId }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <InfoBox className="addBox" onClick={onClick}>
        <Box m="auto">
          <IncButtonDark data-testid={testId}>{text}</IncButtonDark>
        </Box>
      </InfoBox>
    </Grid>
  );
};

export default CardBox;
