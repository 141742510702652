import { selectPurchaseWithGiftCard } from 'redux/config/config.selectors';
import { createSelector } from 'reselect';
import { getClientId } from 'util/clientId';

export const selectUser = state => state.user;

export const selectIsLoading = createSelector(
  [selectUser],
  user => user.isLoading,
);

export const selectIsCardLoading = createSelector(
  [selectUser],
  user => user.isCardLoading,
);

export const selectIsInboxItemLoading = createSelector(
  [selectUser],
  user => user.isInboxItemLoading,
);

export const selectUserData = createSelector(
  [selectUser],
  user => user.userData,
);

export const selectPaymentInstruments = createSelector(
  [selectUser],
  user => user.paymentInstruments,
);

export const selectPaymentInstrumentsCards = createSelector(
  [selectPaymentInstruments],
  paymentInstruments =>
    paymentInstruments.filter(({ paymentType }) => paymentType === 'Card'),
);

export const selectPaymentInstrumentsGiftCards = createSelector(
  [selectPaymentInstruments],
  paymentInstruments =>
    paymentInstruments.filter(({ paymentType }) => paymentType === 'Gift Card'),
);

export const selectDefaultCardPaymentInstrument = createSelector(
  selectPaymentInstrumentsCards,
  cards => cards?.find(card => card.isDefault),
);

export const selectDefaultGiftCardPaymentInstrument = createSelector(
  selectPaymentInstrumentsGiftCards,
  giftCards => giftCards?.find(giftCard => giftCard.isDefault),
);

export const selectCheckoutPaymentInstrumentsGiftCards = createSelector(
  selectPurchaseWithGiftCard,
  selectPaymentInstrumentsGiftCards,
  (purchaseWithGiftCard, paymentInstrumentsGiftCards) => {
    if (!purchaseWithGiftCard) return [];
    return paymentInstrumentsGiftCards;
  },
);

export const selectDeliveryAddressList = createSelector(
  [selectUser],
  user => user.deliveryAddressList,
);

export const selectOfferAppliedId = createSelector(
  [selectUser],
  user => user.offerAppliedId,
);

export const selectPaymentInstrumentById = id =>
  createSelector([selectPaymentInstruments], paymentInstruments =>
    paymentInstruments.find(
      ({ paymentInstrumentId }) => paymentInstrumentId === id,
    ),
  );

export const selectDeliveryAddressId = createSelector([selectUser], user =>
  user ? user.deliveryAddressId : null,
);

export const selectRefreshToken = createSelector(
  [selectUser],
  user => user.refreshToken,
);

export const selectRememberMe = createSelector([selectUser], user =>
  user ? user.rememberMe : null,
);

export const selectUserProfileImage = createSelector([selectUserData], data =>
  data?.userProfileImageUrl
    ? data.userProfileImageUrl + '?' + Date.now()
    : null,
);

export const selectAccessToken = createSelector(
  [selectUser],
  user => user.accessToken,
);

export const selectIsLoggedIn = createSelector(
  [selectAccessToken],
  accessToken => !!accessToken,
);

export const selectIsUserDataLoaded = createSelector(
  [selectIsLoggedIn, selectUserData],
  (isLoggedIn, userData) => isLoggedIn && !!userData,
);

export const selectFirstName = createSelector([selectUserData], userData =>
  userData ? userData.firstName : null,
);

export const selectLastName = createSelector([selectUserData], userData =>
  userData ? userData.lastName : null,
);

export const selectPhoneNumber = createSelector([selectUserData], userData =>
  userData ? userData.phoneNumber : null,
);

export const selectIsUserMissingRequiredFields = createSelector(
  selectFirstName,
  selectLastName,
  selectPhoneNumber,
  (firstName, lastName, phoneNumber) =>
    !firstName?.trim() || !lastName?.trim() || !phoneNumber?.trim(),
);

export const selectUserId = createSelector([selectUserData], userData =>
  userData ? userData.userId : null,
);

export const selectClientId = createSelector([selectUserData], userData =>
  userData ? userData.clientId : null,
);

export const selectEmail = createSelector([selectUserData], userData =>
  userData ? userData.email : null,
);

export const selectDateOfBirth = createSelector([selectUserData], userData =>
  userData ? userData.dateOfBirth : null,
);

export const selectGender = createSelector(
  selectUserData,
  userData => userData?.gender,
);

export const selectExtendedAttributes = createSelector(
  selectUserData,
  userData => userData?.extendedAttributes,
);

export const selectUserDietaryPreferences = createSelector(
  selectExtendedAttributes,
  extendedAttributes => extendedAttributes?.DIETARY_PREFERENCES?.toLowerCase(),
);

export const selectUserAllergyPreferences = createSelector(
  selectExtendedAttributes,
  extendedAttributes => extendedAttributes?.ALLERGIES?.toLowerCase(),
);

export const selectLoyaltyData = createSelector(
  selectUser,
  user => user.loyaltyData,
);

export const selectLoyaltyExternalId = createSelector(
  selectLoyaltyData,
  loyaltyData => (loyaltyData ? loyaltyData.externalId : null),
);

export const selectLoyaltyConfirmedPoints = createSelector(
  selectLoyaltyData,
  loyaltyData => (loyaltyData ? loyaltyData.confirmedPoints : null),
);

export const selectLoyaltyHistoryData = createSelector(
  selectUser,
  user => user.loyaltyHistoryData,
);

export const selectLoyaltyHistories = createSelector(
  selectLoyaltyHistoryData,
  loyaltyHistoryData =>
    loyaltyHistoryData ? loyaltyHistoryData.loyaltyHistories : [],
);

export const selectLoyaltyHistoryPaging = createSelector(
  selectLoyaltyHistoryData,
  loyaltyHistoryData => (loyaltyHistoryData ? loyaltyHistoryData.paging : {}),
);

export const selectActivityBasedContent = createSelector(
  selectUser,
  user => user.activityBasedContent,
);

export const selectAllActivityBasedContent = createSelector(
  selectActivityBasedContent,
  activityBasedContent => {
    let contentMessages = [];

    if (activityBasedContent) {
      contentMessages = [
        ...(activityBasedContent?.offerList?.offers ?? []),
        ...(activityBasedContent?.messageList?.messages ?? []),
      ];
    }

    return contentMessages;
  },
);

export const selectActivityBasedContentOffers = createSelector(
  selectActivityBasedContent,
  activityBasedContent => activityBasedContent?.offerList?.offers ?? [],
);

export const selectUserFirstNameFirstLetter = createSelector(
  selectFirstName,
  firstName => firstName?.substring(0, 1),
);

export const selectAuthProvider = createSelector(
  selectUser,
  user => user.authProvider,
);

export const selectStatsigUser = createSelector([selectUserData], userData =>
  userData
    ? {
        userID: userData.userId,
        custom: {
          clientId: userData.clientId,
        },
      }
    : {
        custom: {
          clientId: getClientId(),
        },
      },
);
