import { useTabContext } from '@material-ui/lab';

const OptionGroupTabPanel = ({
  children,
  className,
  style,
  value,
  ...rest
}) => {
  const context = useTabContext();

  return (
    <div
      className={className}
      style={{
        display: value === context.value ? 'block' : 'none',
      }}
      aria-labelledby={`${context.idPrefix}-T-${value}`}
      id={`${context.idPrefix}-P-${value}`}
      role="tabpanel"
      {...rest}
    >
      {children}
    </div>
  );
};

export default OptionGroupTabPanel;
