import { createSelector, createSlice } from '@reduxjs/toolkit';
import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { DEFAULT_THEME } from 'theme.constants';
import headerReducer from './header/header.reducer';
import { PICKUP } from 'util/constants';
import { selectViewOnlyLocationEnabled } from 'redux/config/config.selectors';

const sliceName = 'app';

const appSlice = createSlice({
  name: sliceName,
  initialState: {
    preferredAddress: null,
    loggedInPreferredAddress: null,
    theme: DEFAULT_THEME,
    locationsOrderType: null,
    locationsLocationType: null,
    preferredOrderPaymentOption: null,
    loggedInPreferredOrderPaymentOption: null,
    preferredGiftCardPaymentOption: null,
    loggedInPreferredGiftCardPaymentOption: null,
    searchLocation: null,
  },
  reducers: {
    setPreferredAddress: (state, action) => {
      state.preferredAddress = action.payload;
    },
    setLoggedInPreferredAddress: (state, action) => {
      state.loggedInPreferredAddress = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setLocationsOrderType: (state, action) => {
      state.locationsOrderType = action.payload;
    },
    setLocationsLocationType: (state, action) => {
      state.locationsLocationType = action.payload;
    },
    setPreferredOrderPaymentOption: (state, action) => {
      state.preferredOrderPaymentOption = action.payload;
    },
    setPreferredGiftCardPaymentOption: (state, action) => {
      state.preferredGiftCardPaymentOption = action.payload;
    },
    setLoggedInPreferredOrderPaymentOption: (state, action) => {
      state.loggedInPreferredOrderPaymentOption = action.payload;
    },
    setLoggedInPreferredGiftCardPaymentOption: (state, action) => {
      state.loggedInPreferredGiftCardPaymentOption = action.payload;
    },
    setSearchLocation: (state, action) => {
      state.searchLocation = action.payload;
    },
    setGeoLocation: (state, action) => {
      state.geoLocation = action.payload;
    },
  },
});

// SELECTORS
const selectApp = state => state.app;

const selectPrefferedAddress = createSelector(
  selectApp,
  app => app.preferredAddress,
);

const selectSearchLocation = createSelector(
  selectApp,
  app => app.searchLocation,
);

const selectSearchLocationLatLon = createSelector(
  selectSearchLocation,
  searchLocation => {
    if (!searchLocation) return undefined;

    return {
      latitude: searchLocation.lat,
      longitude: searchLocation.lon,
    };
  },
);

const selectGeoLocation = createSelector(selectApp, app => app.geoLocation);

const selectLoggedInPrefferedAddress = createSelector(
  selectApp,
  app => app.loggedInPreferredAddress,
);

const selectLocationsOrderType = createSelector(
  selectApp,
  app => app.locationsOrderType,
);

const selectUserPrefferedAddress = createSelector(
  selectIsLoggedIn,
  selectPrefferedAddress,
  selectLoggedInPrefferedAddress,
  (isLoggedIn, preferredAddress, loggedInPreferredAddress) =>
    isLoggedIn ? loggedInPreferredAddress : preferredAddress,
);

const selectUserLatLon = createSelector(
  selectUserPrefferedAddress,
  userPrefferedAddress => {
    if (userPrefferedAddress) {
      return {
        latitude: userPrefferedAddress.lat,
        longitude: userPrefferedAddress.lon,
      };
    }
    return {};
  },
);

const selectLocationFetchingLatLon = createSelector(
  selectGeoLocation,
  selectSearchLocationLatLon,
  selectUserPrefferedAddress,
  selectLocationsOrderType,
  selectViewOnlyLocationEnabled,
  (
    geoLocation,
    searchLocationLatLon,
    userPrefferedAddress,
    locationsOrderType,
    viewOnlyLocationEnabled,
  ) => {
    if (
      !!searchLocationLatLon &&
      (locationsOrderType === PICKUP || viewOnlyLocationEnabled)
    )
      return searchLocationLatLon;

    if (
      !!geoLocation &&
      (locationsOrderType === PICKUP || viewOnlyLocationEnabled)
    )
      return geoLocation;

    if (!!userPrefferedAddress)
      return {
        latitude: userPrefferedAddress.lat,
        longitude: userPrefferedAddress.lon,
      };

    return geoLocation ?? {};
  },
);

const selectTheme = createSelector(selectApp, app => app.theme);

const selectLocationsLocationType = createSelector(
  selectApp,
  app => app.locationsLocationType,
);

const selectPreferredOrderPaymentOption = createSelector(
  selectApp,
  app => app.preferredOrderPaymentOption,
);

const selectLoggedInPreferredOrderPaymentOption = createSelector(
  selectApp,
  app => app.loggedInPreferredOrderPaymentOption,
);

const selectUserPreferredOrderPaymentOption = createSelector(
  selectIsLoggedIn,
  selectPreferredOrderPaymentOption,
  selectLoggedInPreferredOrderPaymentOption,
  (loggedIn, pref, loggedInPref) => (loggedIn ? loggedInPref : pref),
);

const selectPreferredGiftCardPaymentOption = createSelector(
  selectApp,
  app => app.preferredGiftCardPaymentOption,
);

const selectLoggedInPreferredGiftCardPaymentOption = createSelector(
  selectApp,
  app => app.loggedInPreferredOrderPaymentOption,
);

const selectUserPreferredGiftCardPaymentOption = createSelector(
  selectIsLoggedIn,
  selectPreferredGiftCardPaymentOption,
  selectLoggedInPreferredGiftCardPaymentOption,
  (loggedIn, pref, loggedInPref) => (loggedIn ? loggedInPref : pref),
);

// EXPORTS
export const actions = {
  ...appSlice.actions,
};

export const selectors = {
  selectApp,
  selectPrefferedAddress,
  selectLoggedInPrefferedAddress,
  selectUserPrefferedAddress,
  selectUserLatLon,
  selectLocationFetchingLatLon,
  selectTheme,
  selectLocationsOrderType,
  selectLocationsLocationType,
  selectPreferredOrderPaymentOption,
  selectLoggedInPreferredOrderPaymentOption,
  selectUserPreferredOrderPaymentOption,
  selectPreferredGiftCardPaymentOption,
  selectLoggedInPreferredGiftCardPaymentOption,
  selectUserPreferredGiftCardPaymentOption,
  selectSearchLocation,
  selectGeoLocation,
};

export default reduceReducers(
  appSlice.reducer,
  combineReducers({
    preferredAddress: (s = null) => s,
    loggedInPreferredAddress: (s = null) => s,
    theme: (s = null) => s,
    locationsOrderType: (s = null) => s,
    locationsLocationType: (s = null) => s,
    header: headerReducer,
    preferredOrderPaymentOption: (s = null) => s,
    loggedInPreferredOrderPaymentOption: (s = null) => s,
    preferredGiftCardPaymentOption: (s = null) => s,
    loggedInPreferredGiftCardPaymentOption: (s = null) => s,
    searchLocation: (s = null) => s,
    geoLocation: (s = null) => s,
  }),
);
