import { Box, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding-top: 1rem;
`;

export const FieldContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FieldName = styled.span`
  font-weight: bold;
`;

export const CardContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const CardBody = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 140px;
  width: 260px;
  border-radius: 4px;
  border: 1px solid black;
`;

export const CardLeft = styled(Box)`
  display: flex;
  flex: 1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const CardRight = styled(Box)`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.headerBgColor};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const CardValue = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
`;

export const LogoImage = styled.img`
  height: 80px;
  width: auto;
`;
