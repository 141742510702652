import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HistoryIcon from '@material-ui/icons/History';
import LocalOffer from '@material-ui/icons/LocalOffer';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RedeemIcon from '@material-ui/icons/Redeem';
import SettingsIcon from '@material-ui/icons/Settings';
import PromoCode from 'components/promo-code/promo-code.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectors } from 'redux/data/appcontent/appcontent.selectors';
import {
  selectInbox,
  selectIsLoyaltyCard,
  selectLoyaltyLabel,
  selectLoyaltyOn,
  selectOrderHistoryTitle,
} from '../../redux/config/config.selectors';
import { signOutStartAsync } from '../../redux/user/user.actions';
import {
  selectUserProfileImage,
  selectUserFirstNameFirstLetter,
} from '../../redux/user/user.selectors';
import AccountLoyaltyCard from '../account-loyalty-card/account-loyalty-card.component';
import AccountLoyalty from '../account-loyalty/account-loyalty.component';
import Menu from '../menu/menu.component';
import TogglerButton from '../toggler-button/toggler-button.component';
import * as S from './menu-account.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { useMemo } from 'react';
import { useIsViewOnlyLocationEnabled } from 'hooks/useIsViewOnlyLocationEnabled';

const MenuAccount = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLoyalty, setOpenLoyalty] = useState(false);
  const dispatch = useDispatch();
  const profileImage = useSelector(selectUserProfileImage);
  const [promoCode, setPromoCode] = useState(false);
  const [loyaltyCard, setLoyaltyCard] = useState(false);
  const isLoyaltyCard = useSelector(selectIsLoyaltyCard);
  const inbox = useSelector(selectInbox);
  const viewOnlyLocationEnabled = useIsViewOnlyLocationEnabled();
  const loyaltyOn = useSelector(selectLoyaltyOn);
  const orderHistoryTitle = useSelector(selectOrderHistoryTitle);
  const loyaltyLabel = useSelector(selectLoyaltyLabel);
  const newMessageCount = useSelector(selectors.selectNewCount);
  const history = useHistory();
  const location = useLocation();
  const pathEnd = useMemo(() => {
    const splitPathname = location?.pathname?.split('/');
    return splitPathname?.[splitPathname?.length - 1];
  }, [location]);

  const firstChar = useSelector(selectUserFirstNameFirstLetter);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = to => {
    handleClose();
    history.push(to);
  };

  const handleLogout = () => {
    dispatch(signOutStartAsync());
  };

  return (
    <>
      <TogglerButton
        aria-controls={anchorEl ? 'account-menu' : undefined}
        aria-haspopup="true"
        handleToggle={handleClick}
        toggleTestId="MenuAccount.TogglerButton"
      >
        <Avatar
          data-testid="avatar"
          aria-hidden="true"
          src={profileImage}
          alt={t('userAvatar.alt')}
        >
          {firstChar}
        </Avatar>
        <Hidden only="xs">{t('menuAccount.title')}</Hidden>
      </TogglerButton>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        handleClose={handleClose}
        testId="MenuAccount.Menu"
      >
        <S.CustomMenuItem
          onClick={() => handleLinkClick('/settings')}
          data-testid="MenuAccount.CustomMenuItem.settings"
          selected={pathEnd === 'settings'}
        >
          <S.MenuItemText primary={t('menuAccount.settings')} />
          <S.MenuItemIcon>
            <SettingsIcon fontSize="small" />
          </S.MenuItemIcon>
        </S.CustomMenuItem>

        {inbox && (
          <S.CustomMenuItem
            data-testid="MenuAccount.CustomMenuItem.inbox"
            onClick={() => handleLinkClick('/inbox')}
            selected={pathEnd === 'inbox'}
          >
            <S.MenuItemText primary={t('menuAccount.inbox')} />
            <S.MenuItemIcon>
              <S.MessageBadge
                overlap="rectangular"
                badgeContent={newMessageCount ? '' : null}
              >
                <MailOutlineIcon fontSize="small" />
              </S.MessageBadge>
            </S.MenuItemIcon>
          </S.CustomMenuItem>
        )}

        {!viewOnlyLocationEnabled && (
          <S.CustomMenuItem
            data-testid="MenuAccount.CustomMenuItem.orderHistory"
            onClick={() => handleLinkClick('/order-history')}
            selected={pathEnd === 'order-history'}
          >
            <S.MenuItemText primary={orderHistoryTitle} />
            <S.MenuItemIcon>
              <HistoryIcon fontSize="small" />
            </S.MenuItemIcon>
          </S.CustomMenuItem>
        )}

        <S.CustomMenuItem
          data-testid="MenuAccount.CustomMenuItem.myCards"
          onClick={() => handleLinkClick('/my-cards')}
          selected={pathEnd === 'my-cards'}
        >
          <S.MenuItemText primary={t('menuAccount.myCards')} />
          <S.MenuItemIcon>
            <CreditCardIcon fontSize="small" />
          </S.MenuItemIcon>
        </S.CustomMenuItem>

        {loyaltyOn && (
          <S.CustomMenuItem
            data-testid="MenuAccount.CustomMenuItem.customLoyalty"
            onClick={() => {
              setOpenLoyalty(true);
              setAnchorEl(null);
            }}
          >
            <S.MenuItemText primary={loyaltyLabel} />
            <S.MenuItemIcon>
              <RedeemIcon fontSize="small" />
            </S.MenuItemIcon>
          </S.CustomMenuItem>
        )}

        <S.CustomMenuItem
          data-testid="MenuAccount.CustomMenuItem.promoCode"
          onClick={() => {
            setPromoCode(true);
            setAnchorEl(null);
          }}
        >
          <S.MenuItemText primary={t('menuAccount.enterCode')} />
          <S.MenuItemIcon>
            <LocalOffer fontSize="small" />
          </S.MenuItemIcon>
        </S.CustomMenuItem>

        {isLoyaltyCard && (
          <S.CustomMenuItem
            data-testid="MenuAccount.CustomMenuItem.loyaltyCard"
            onClick={() => {
              setLoyaltyCard(true);
              setAnchorEl(null);
            }}
          >
            <S.MenuItemText primary={t('menuAccount.loyaltyCard')} />
            <S.MenuItemIcon>
              <CenterFocusWeakIcon fontSize="small" />
            </S.MenuItemIcon>
          </S.CustomMenuItem>
        )}

        <S.CustomMenuItem role="menuitem">
          <IncButton
            data-testid="MenuAccount.LogoutButton"
            onClick={handleLogout}
            fullWidth
          >
            {t('menuAccount.logout')}
          </IncButton>
        </S.CustomMenuItem>
      </Menu>
      {openLoyalty && (
        <AccountLoyalty
          open={openLoyalty}
          handleClose={() => setOpenLoyalty(false)}
        />
      )}
      {loyaltyCard && (
        <AccountLoyaltyCard
          open={loyaltyCard}
          handleClose={() => setLoyaltyCard(false)}
        />
      )}
      <PromoCode open={promoCode} handleClose={() => setPromoCode(false)} />
    </>
  );
};

export default MenuAccount;
