import styled from 'styled-components';

export const CustomForm = styled.form`
  position: relative;
  .input-container {
    margin-bottom: 2em;
  }

  button[type='submit'] {
    margin: 2em 0;
  }

  button[type='submit'].inlineSubmitButton {
    margin: 0;
  }

  > .error-display {
    margin-top: 2em;
  }

  && {
    .MuiFormHelperText-root.Mui-error {
      color: #ea170b;
      font-size: 0.875rem;

      &:before {
        display: inline;
        content: '⚠ ';
      }
    }
  }
`;
