import LocationsActionTypes from './locations.types';
import INC_BASE_API from '../../apis/incentivio-api';

import { setOrderType } from '../cart/cart.actions';
import { selectOrderType } from '../cart/cart.selectors';
import { getResponseErrorMessage } from '../../apis/incentivio-api.util';
import { selectors as appSelectors } from 'redux/app/app.slice';
import {
  selectAvailablePickupStores,
  selectStores,
  selectCateringStores,
  selectIsCatering,
  selectMenuBrowsingStores,
  selectCateringMenuBrowsingStores,
} from './locations.selectors';
import { getLanguageKey } from 'utils';
import { toast } from 'react-toastify';
import { store } from 'redux/store';
import { transformLocationResponse } from './locations.utils';

export const fetchLocationsStart = () => ({
  type: LocationsActionTypes.FETCH_LOCATIONS_START,
});

export const fetchLocationsSuccess = locationsData => ({
  type: LocationsActionTypes.FETCH_LOCATIONS_SUCCESS,
  payload: locationsData,
});

export const fetchLocationsFailure = () => ({
  type: LocationsActionTypes.FETCH_LOCATIONS_FAILURE,
});

export const fetchCateringLocationsStart = () => ({
  type: LocationsActionTypes.FETCH_CATERING_LOCATIONS_START,
});

export const fetchMenuBrowsingLocationsStart = () => ({
  type: LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_START,
});

export const fetchSingleLocationStart = () => ({
  type: LocationsActionTypes.FETCH_SINGLE_LOCATION_START,
});

export const fetchSingleLocationSuccess = (store, isCateringStore) => ({
  type: LocationsActionTypes.FETCH_SINGLE_LOCATION_SUCCESS,
  payload: {
    store,
    isCateringStore,
  },
});

export const fetchSingleLocationFailure = () => ({
  type: LocationsActionTypes.FETCH_SINGLE_LOCATION_FAILURE,
});

export const fetchCateringLocationsSuccess = cateringLocationsData => ({
  type: LocationsActionTypes.FETCH_CATERING_LOCATIONS_SUCCESS,
  payload: cateringLocationsData,
});

export const fetchMenuBrowsingLocationsSuccess = data => ({
  type: LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_SUCCESS,
  payload: data,
});

export const fetchCateringLocationsFailure = () => ({
  type: LocationsActionTypes.FETCH_CATERING_LOCATIONS_FAILURE,
});

export const fetchMenuBrowsingLocationsFailure = () => ({
  type: LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_FAILURE,
});

export const fetchAllLocations = () => {
  return fetchLocationsStartAsync(0, 10001);
};

export const fetchAllCateringLocations = () => {
  return fetchCateringLocationsStartAsync(0, 10001);
};

export const fetchAllMenuBrowsingLocations = () => {
  return fetchMenuBrowsingLocationsStartAsync(0, 10001);
};

export const fetchCateringMenuBrowsingLocationsStart = () => ({
  type: LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_START,
});

export const fetchCateringMenuBrowsingLocationsSuccess = data => ({
  type: LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_SUCCESS,
  payload: data,
});

export const fetchCateringMenuBrowsingLocationsFailure = () => ({
  type: LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_FAILURE,
});

export const fetchAllCateringMenuBrowsingLocations = () => {
  return fetchCateringMenuBrowsingLocationsStartAsync(0, 10001);
};

export const fetchLocationsStartAsync = (page, count) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchLocationsStart());
      const response = await INC_BASE_API.get(
        '/locations',
        getFetchLocationParams({ count, page }),
      );

      const data = getStoreResults(response.data, selectStores, getState);
      dispatch(fetchLocationsSuccess(data));

      const pickupAvailableStores = selectAvailablePickupStores(getState());
      const orderType = selectOrderType(getState());

      if (!orderType) {
        if (pickupAvailableStores.length) {
          dispatch(setOrderType('PICKUP'));
        } else {
          dispatch(setOrderType('DELIVERY'));
        }
      }

      return response.data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error, dispatch));
      dispatch(fetchLocationsFailure());
      return null;
    }
  };
};

export const fetchCateringLocationsStartAsync = (page, count) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchCateringLocationsStart());
      const response = await INC_BASE_API.get(
        '/locations',
        getFetchLocationParams({
          count,
          page,
          iscatering: true,
        }),
      );

      const data = getStoreResults(
        response.data,
        selectCateringStores,
        getState,
      );
      dispatch(fetchCateringLocationsSuccess(data));

      return data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      dispatch(fetchCateringLocationsFailure());
      return null;
    }
  };
};

export const fetchMenuBrowsingLocationsStartAsync = (page, count) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchMenuBrowsingLocationsStart());
      const response = await INC_BASE_API.get(
        '/locations',
        getFetchLocationParams({
          count,
          page,
          ismenubrowsing: true,
        }),
      );

      const data = getStoreResults(
        response.data,
        selectMenuBrowsingStores,
        getState,
      );
      dispatch(fetchMenuBrowsingLocationsSuccess(data));

      return data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      dispatch(fetchMenuBrowsingLocationsFailure());
      return null;
    }
  };
};

export const fetchCateringMenuBrowsingLocationsStartAsync = (page, count) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchCateringMenuBrowsingLocationsStart());
      const response = await INC_BASE_API.get(
        '/locations',
        getFetchLocationParams({
          count,
          page,
          ismenubrowsing: true,
          iscatering: true,
        }),
      );

      const data = getStoreResults(
        response.data,
        selectCateringMenuBrowsingStores,
        getState,
      );
      dispatch(fetchCateringMenuBrowsingLocationsSuccess(data));

      return data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      dispatch(fetchCateringMenuBrowsingLocationsFailure());
      return null;
    }
  };
};

export const fetchSingleLocation = locationId => {
  return async (dispatch, getState) => {
    try {
      const isCatering = selectIsCatering(locationId)(getState());

      dispatch(fetchSingleLocationStart());

      const response = await INC_BASE_API.get(
        `/locationsdetail/${locationId}`,
        {
          params: { langCode: getLanguageKey() },
        },
      );

      dispatch(fetchSingleLocationSuccess(response.data, isCatering));
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      dispatch(fetchSingleLocationFailure());
    }
  };
};

const getFetchLocationParams = ({ page, count, ...rest }) => {
  const { latitude, longitude } = appSelectors.selectLocationFetchingLatLon(
    store.getState(),
  );

  return {
    params: {
      count: count ?? 10000,
      latitude: latitude ?? 0,
      longitude: longitude ?? 0,
      page: page ?? 0,
      radius: 11029160,
      sortby: 'title',
      sortdirection: 'DESC',
      langCode: getLanguageKey(),
      iscatering: false,
      markdeliverablelocations: !!latitude,
      ismenubrowsing: false,
      ...rest,
    },
  };
};

/**
 * This will concatenate the store results from the previous pages
 * @param {object} data Response data from the api
 * @param {Selector} storeSelector Selector to rerieve previously fetched store data
 * @returns {object} data
 */

const getStoreResults = (respData, storeSelector, getState) => {
  const data = transformLocationResponse(respData, getState);
  if (data?.paging?.currentPage === 0) return data;

  const stores = storeSelector(getState());

  return {
    ...data,
    stores: [...stores, ...data.stores],
  };
};
