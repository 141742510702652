import { useCallback, useEffect, useState } from 'react';

export const useFlow = () => {
  const [steps, setSteps] = useState({});
  const [activeStep, setActiveStep] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!activeStep && Object.keys(steps).length) {
      setActiveStep(Object.keys(steps)[0]);
    }
  }, [activeStep, steps]);

  const addStep = useCallback((name, step) => {
    setSteps(prev => ({ ...prev, [name]: step }));
    if (step.initial) {
      setActiveStep(name);
    }
  }, []);

  const removeStep = useCallback(
    name =>
      setSteps(
        Object.keys(steps).reduce((result, key) => {
          if (key !== name) result[key] = steps[key];
          return result;
        }, {}),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    steps,
    addStep,
    removeStep,
    setSteps,
    activeStep,
    setActiveStep,
    data,
    setData,
  };
};
