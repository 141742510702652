import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isValidAddress } from 'pages/locations/locations.utils';
import * as yup from 'yup';
import { store } from 'redux/store';
import {
  selectCheckoutPaymentInstrumentsGiftCards,
  selectPaymentInstrumentsCards,
} from 'redux/user/user.selectors';
import { DateTime } from 'luxon';
import i18n from 'i18n';
import { selectOrderOption } from 'redux/cart/cart.selectors';
import { makeSelectRequestedStoreOrderOptions } from 'redux/common.selectors';
import { PaymentOptionType } from 'components/payment/payment.constants';

yup.addMethod(yup.mixed, 'isValidAddress', function (errorMessage) {
  return this.test(`test-valid-address`, errorMessage, function (value) {
    return isValidAddress(value);
  });
});

yup.addMethod(yup.string, 'isValidPhone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    return !!value && parsePhoneNumberFromString(value, 'US')?.isPossible();
  });
});

yup.addMethod(yup.string, 'isValidPaymentInstrument', function (errorMessage) {
  return this.test(`test-payment-instrument`, errorMessage, function (value) {
    const combinedPI = [
      ...(selectPaymentInstrumentsCards(store.getState()) ?? []),
      ...(selectCheckoutPaymentInstrumentsGiftCards(store.getState()) ?? []),
    ];
    return value && !!combinedPI.some(pi => pi.paymentInstrumentId === value);
  });
});

yup.addMethod(yup.string, 'hasPaymentInstrument', function (errorMessage) {
  return this.test(
    `test-has-payment-instrument`,
    errorMessage,
    function (value) {
      const combinedPI = [
        ...(selectPaymentInstrumentsCards(store.getState()) ?? []),
        ...(selectCheckoutPaymentInstrumentsGiftCards(store.getState()) ?? []),
      ];
      return combinedPI.length > 0;
    },
  );
});

yup.addMethod(yup.string, 'hasCardPaymentInsrument', function (errorMessage) {
  return this.test(
    `test-has-card-payment-instrument`,
    errorMessage,
    function () {
      return selectPaymentInstrumentsCards(store.getState()).length > 0;
    },
  );
});

yup.addMethod(
  yup.string,
  'hasGiftCardPaymentInsrument',
  function (errorMessage) {
    return this.test(
      `test-has-gift-card-payment-instrument`,
      errorMessage,
      function () {
        return (
          selectCheckoutPaymentInstrumentsGiftCards(store.getState()).length > 0
        );
      },
    );
  },
);

yup.addMethod(yup.object, 'isValidFulfillTime', function (errorMessage) {
  return this.test(`test-fulfill-time`, errorMessage, function (value) {
    return value?.key === 'asap' || value?.displayDateTime > DateTime.local();
  });
});

yup.addMethod(yup.object, 'isValidMaxOrderingDate', function (errorMessage) {
  return this.test(`test-fulfill-max-time`, errorMessage, function (value) {
    return (
      !value?.maxOrderingDays ||
      value?.displayDateTime <
        DateTime.local().plus({ days: value?.maxOrderingDays })
    );
  });
});

yup.addMethod(yup.object, 'isValidOrderOption', function (errorMessage) {
  return this.test(
    `test-order-option`,
    errorMessage,
    function (value, context) {
      const selectStoreOrderOptions = makeSelectRequestedStoreOrderOptions(
        context.parent.orderType,
      );
      const orderOptions = selectStoreOrderOptions(store.getState());
      return (
        !orderOptions ||
        orderOptions?.length < 1 ||
        (orderOptions?.length > 0 && !!value?.optionName)
      );
    },
  );
});

export const customerSchema = yup
  .object()
  .nullable()
  .required(() => i18n.t('checkout.customer.errors.null'))
  .shape({
    firstName: yup
      .string()
      .nullable()
      .trim()
      .max(100, ({ max }) =>
        i18n.t('checkout.customer.errors.firstName.max', { max }),
      )
      .required(() => i18n.t('checkout.customer.errors.firstName.required')),
    lastName: yup
      .string()
      .nullable()
      .trim()
      .max(100, ({ max }) =>
        i18n.t('checkout.customer.errors.lastName.max', { max }),
      )
      .required(() => i18n.t('checkout.customer.errors.lastName.required')),
    email: yup
      .string()
      .nullable()
      .trim()
      .email(() => i18n.t('checkout.customer.errors.email.email'))
      .required(() => i18n.t('checkout.customer.errors.email.required'))
      .max(150),
    phoneNumber: yup
      .string()
      .nullable()
      .trim()
      .required(() => i18n.t('checkout.customer.errors.phone_number.required'))
      .isValidPhone(() => i18n.t('forms.errors.phone.invalid')),
  });

export const orderDetailsSchema = yup.object({
  orderType: yup
    .string()
    .nullable()
    .oneOf(['PICKUP', 'DELIVERY'])
    .required(() => i18n.t('checkout.orderDetails.errors.orderType.required')),
  address: yup.mixed().when('orderType', {
    is: 'DELIVERY',
    then: yup
      .mixed()
      .isValidAddress(() =>
        i18n.t('checkout.orderDetails.errors.address.invalid'),
      ),
    otherwise: yup.mixed().optional(),
  }),
  orderOption: yup
    .object()
    .nullable()
    .isValidOrderOption(() =>
      i18n.t('checkout.orderDetails.errors.orderOption.required'),
    ),
  'orderOption-informationField': yup
    .string()
    .nullable()
    .when('orderOption', {
      is: value => !!value?.errorMessageIfInformationFieldIsEmpty,
      then: yup
        .string()
        .nullable()
        .trim()
        .required(
          () =>
            selectOrderOption(store.getState())
              ?.errorMessageIfInformationFieldIsEmpty,
        ),
      otherwise: yup.string().optional(),
    }),
  time: yup
    .object()
    .nullable()
    .required(() => i18n.t('checkout.orderDetails.errors.time.required'))
    .isValidFulfillTime(() => i18n.t('checkout.orderDetails.errors.time.past'))
    .isValidMaxOrderingDate(arg =>
      i18n.t('checkout.orderDetails.errors.time.maxAllowedDate', {
        days: arg?.value?.maxOrderingDays,
      }),
    ),
});

export const paymentSchemaNew = yup.object().shape({
  paymentOption: yup.string().required(),
  paymentInstrument: yup
    .string()
    .nullable()
    .when(
      ['paymentOption', '$isNewSpreedlyCardFlow'],
      (paymentOption, isNewSpreedlyCardFlow, schema) => {
        switch (paymentOption) {
          case PaymentOptionType.CARD:
            if (isNewSpreedlyCardFlow) return schema;
            return schema
              .hasCardPaymentInsrument(() =>
                i18n.t('checkout.payment.errors.noCards'),
              )
              .required(() => i18n.t('checkout.payment.errors.required'))
              .isValidPaymentInstrument(() =>
                i18n.t('checkout.payment.errors.invalid'),
              );

          case PaymentOptionType.GIFT_CARD:
            return schema
              .hasGiftCardPaymentInsrument(() =>
                i18n.t('checkout.payment.errors.noCards'),
              )
              .required(() => i18n.t('checkout.payment.errors.required'))
              .isValidPaymentInstrument(() =>
                i18n.t('checkout.payment.errors.invalid'),
              );

          default:
            return schema.optional();
        }
      },
    ),
});

export const orderInstructionsSchema = yup.object().shape({
  orderInstructions: yup
    .string()
    .nullable()
    .trim()
    .max(800, ({ max }) =>
      i18n.t('checkout.orderInstructions.errors.maxLength', { max }),
    )
    .optional(),
});

export const deliveryInstructionsSchema = yup.object().shape({
  deliveryInstructions: yup
    .string()
    .nullable()
    .trim()
    .max(280, ({ max }) =>
      i18n.t('checkout.deliveryInstructions.errors.maxLength', { max }),
    )
    .optional(),
});
