import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval, useTimeout } from 'usehooks-ts';

// Will get a status update at most every 2 seconds, by default. Will stop
// trying after 2 mins
//
// apiCall should return a truthy value to stop polling
export const usePoller = (
  apiCall,
  statusDelay = 2000,
  statusTimeout = 120000,
) => {
  const [timeout, setTimeout] = useState(null);
  const [delay, setDelay] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [failed, setFailed] = useState(false);
  const [ready, setReady] = useState(false);
  const resolveRef = useRef();

  useTimeout(() => setFailed(true), timeout);
  useInterval(() => setReady(true), delay);

  const start = useCallback(() => {
    setTimeout(statusTimeout);
    setDelay(statusDelay);
    setReady(true);
    return new Promise(resolve => {
      resolveRef.current = resolve;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const poll = async () => {
    setReady(false);
    setInFlight(true);

    try {
      const result = await apiCall();
      if (!!result) {
        setDelay(null);
        setTimeout(null);
        resolveRef.current(result);
      }
    } catch (e) {
      // apiCall should handle api errors
      // If we end up here, something unexpected happened
      setFailed(true);
    } finally {
      setInFlight(false);
    }
  };

  useEffect(() => {
    if (failed) {
      setTimeout(null);
      setDelay(null);
      resolveRef.current(false);
    }
  }, [failed]);

  useEffect(() => {
    ready && !inFlight && poll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, inFlight]);

  return { inFlight, failed, ready, delay, timeout, start };
};
