import styled from 'styled-components';

export const LogoImage = styled.img`
  height: 50px;
  width: auto;
  max-width: 150px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: 64px;
  }
`;
