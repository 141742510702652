import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBaseLocaleInUpperCase } from 'redux/i18n/i18n.selectors';
import { selectOrderTypeLabels } from 'redux/config/config.selectors';
import { selectOrderType } from 'redux/cart/cart.selectors';
import { useTranslation } from 'react-i18next';

export const useOrderTypeLabels = selectedOrderType => {
  const locale = useSelector(selectBaseLocaleInUpperCase);
  const orderTypeLabels = useSelector(selectOrderTypeLabels);
  const reduxOrderType = useSelector(selectOrderType);
  const orderType = selectedOrderType ?? reduxOrderType;
  const { t } = useTranslation();

  const pickupLabel = useMemo(
    () => orderTypeLabels?.pickup?.[locale],
    [locale, orderTypeLabels],
  );

  const deliveryLabel = useMemo(
    () => orderTypeLabels?.delivery?.[locale],
    [locale, orderTypeLabels],
  );

  const cateringLabel = useMemo(
    () => orderTypeLabels?.catering?.[locale],
    [locale, orderTypeLabels],
  );

  const orderTypeLabel = useMemo(
    () => orderTypeLabels?.[orderType?.toLowerCase()]?.[locale],
    [locale, orderType, orderTypeLabels],
  );

  return {
    pickupLabel: pickupLabel ?? t('orderType.PICKUP'),
    deliveryLabel: deliveryLabel ?? t('orderType.DELIVERY'),
    cateringLabel: cateringLabel ?? t('orderType.CATERING'),
    orderTypeLabel:
      orderTypeLabel ?? (orderType ? t(`orderType.${orderType}`) : undefined),
  };
};
