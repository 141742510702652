import { Box } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ScheduleDateController from '../schedule-order/schedule-date-controller/schedule-date-controller.component';
import TimeSelectorWrapper from '../time-selector-wrapper/time-selector-wrapper.component';
import TimeZoneText from '../time-zone-text/time-zone-text.component';
import { tabValues } from './constants';
import { useTodayOrScheduleSelector } from './today-or-schedule-selector.hooks';
import * as S from './today-or-schedule-selector.styles';

const TodayOrScheduleSelector = props => {
  const { t } = useTranslation();
  const { timeZoneName, isTodayOff } = props;

  const {
    tabValue,
    handleTabChange,
    tabs,
    showCalendar,
    ...schedulePropsRest
  } = useTodayOrScheduleSelector(props);

  return (
    <TabContext value={tabValue}>
      <S.StyledTabList
        onChange={handleTabChange}
        aria-label={t('todayOrScheduleSelector.selectorTabs.ariaLabel')}
        data-testid="TodayOrScheduleSelector.TabContext"
        indicatorColor="primary"
        $isTodayOff={isTodayOff}
      >
        {tabs.map(tab => (
          <S.StyledTab {...tab} key={tab.value} $isTodayOff={isTodayOff} />
        ))}
      </S.StyledTabList>
      <S.StyledTabPanel value={tabValues.today}>
        <Box>
          <TimeZoneText timeZoneName={timeZoneName} />
          <TimeSelectorWrapper {...props} />
        </Box>
      </S.StyledTabPanel>
      <S.StyledTabPanel value={tabValues.schedule}>
        <Box data-testid="TodayOrScheduleSelector.Schedule" overflow="auto">
          <TimeZoneText timeZoneName={timeZoneName} />
          <ScheduleDateController
            {...props}
            {...schedulePropsRest}
            showCalendar={showCalendar}
          />
        </Box>
      </S.StyledTabPanel>
    </TabContext>
  );
};

export default TodayOrScheduleSelector;
