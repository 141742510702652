import { Box, Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDisplayTotalWithoutGratuityWithTip } from 'redux/cart/cart.selectors';
import Amount from './amount/amount.component';

const GiftCardData = ({ cardAccountBalance }) => {
  const displayTotalWithTip = useSelector(
    selectDisplayTotalWithoutGratuityWithTip,
  );
  const { t } = useTranslation();

  return (
    <Box mb={1} display="flex" justifyContent="center">
      <Amount
        value={displayTotalWithTip}
        label={t('checkout.payment.giftCard.insufficientFunds.orderTotal')}
      />
      <Divider orientation="vertical" flexItem />
      <Amount
        value={parseFloat(cardAccountBalance).toFixed(2)}
        label={t('checkout.payment.giftCard.insufficientFunds.cardBalance')}
      />
    </Box>
  );
};

export default GiftCardData;
