import React from 'react';
import { PaymentOptionType } from 'components/payment/payment.constants';
import googlePayMark from './google-pay-mark.png';
import PaymentOption from './payment-option.component';
import { ImageAvatar } from './payment-option.styles';

const title = 'Google Pay';

const GooglePayPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  return (
    <PaymentOption
      avatar={<ImageAvatar src={googlePayMark} alt="" />}
      title={title}
      type={PaymentOptionType.GOOGLE_PAY}
      {...rest}
      ref={ref}
    />
  );
});
export default GooglePayPaymentOption;
