import { Box, Link } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  padding: 1em ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Hide = styled(Box)`
  visibility: ${({ hidden }) => (!!hidden ? 'collapse' : 'visible')};
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  .MuiSvgIcon-root {
    margin-right: 6px;
    color: ${({ theme }) => theme.palette?.primary?.main};
  }
`;
