import { useTranslation } from 'react-i18next';
import * as S from './verify-sms.styles';
import Spinner from 'components/spinner/spinner.component';
import { useVerifySmsRequest } from './useVerifySmsRequest.hooks';
import VerifySmsHeader from './verify-sms-header/verify-sms-header.component';
import VerifySmsErrorMessage from './verify-sms-error-message/verify-sms-error-message.component';
import VerifySmsSuccessMessage from './verify-sms-success-message/verify-sms-success-message.component';

const VerifySms = () => {
  const { t } = useTranslation();
  const { loading, error } = useVerifySmsRequest();

  return (
    <S.Container data-testid="VerifySms.Container">
      {loading && <Spinner />}
      <S.ContentContainer elevation={2}>
        {!error && <VerifySmsHeader title={t('verifySms.header')} />}
        <S.ContentBody>
          {error ? <VerifySmsErrorMessage /> : <VerifySmsSuccessMessage />}
        </S.ContentBody>
      </S.ContentContainer>
    </S.Container>
  );
};

export default VerifySms;
