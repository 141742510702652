import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-weight: bold;
  text-transform: capitalize;
`;

export const Container = styled(Box)`
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BoldDescription = styled(Typography)`
  font-size: 1.25rem;
  font-weight: bold;
`;
