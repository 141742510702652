import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { merge } from 'lodash';
import { toast } from 'react-toastify';
import CommonTypes from 'redux/common.types';
import { selectBaseLocaleInUpperCase } from 'redux/i18n/i18n.selectors';
import UserTypes from 'redux/user/user.types';

export const makeEntityAdapter = id =>
  createEntityAdapter({
    selectId: entity => entity[id],
  });

export const createFetchEntities = (
  name,
  path,
  upsertMany,
  totalPages,
  retrievedPages,
  returnValue,
  defaultParams,
  options,
) =>
  createAsyncThunk(
    name,
    async (params = {}, thunkAPI) => {
      const finalParams = merge(defaultParams, params);

      finalParams.languageCode = selectBaseLocaleInUpperCase(
        thunkAPI.getState(),
      );

      try {
        const response = await INC_BASE_API.get(path, {
          params: finalParams,
          authenticated: true,
        });

        thunkAPI.dispatch(upsertMany(response));
        thunkAPI.dispatch(totalPages(response));
        thunkAPI.dispatch(retrievedPages(finalParams.page, thunkAPI));

        return returnValue(response);
      } catch (error) {
        const errorMessage = getResponseErrorMessage(error);
        toast.error(errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
      }
    },
    options,
  );

export const makeFetchNew = (
  name,
  path,
  upsertMany,
  returnValue,
  defaultParams,
  options,
) =>
  createAsyncThunk(
    name,
    async (params = {}, thunkAPI) => {
      const finalParams = merge(defaultParams, params);

      finalParams.languageCode = selectBaseLocaleInUpperCase(
        thunkAPI.getState(),
      );

      try {
        const response = await INC_BASE_API.get(path, {
          params: finalParams,
          authenticated: true,
        });

        thunkAPI.dispatch(upsertMany(response));

        return returnValue(response);
      } catch (error) {
        const errorMessage = getResponseErrorMessage(error);
        toast.error(errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
      }
    },
    options,
  );

export const makeUpdateStatus = (name, path, body, afterApi, options) =>
  createAsyncThunk(
    name,
    async ({ id, status }, thunkAPI) => {
      try {
        const bodyObject = body(id, status);
        await INC_BASE_API.post(path, bodyObject, {
          authenticated: true,
        });

        thunkAPI.dispatch(afterApi(id, status));

        return id;
      } catch (error) {
        const errorMessage = getResponseErrorMessage(error);
        toast.error(errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
      }
    },
    options,
  );

export const makeInitialState = entityAdapter => ({
  ...entityAdapter.getInitialState({
    loading: false,
    currentRequestId: null,
    error: null,
    totalPages: null,
    retrievedPages: 0,
  }),
});

export const makeSlice = (
  name,
  initialState,
  entityAdapter,
  fetchEntities,
  refreshEntities,
) => {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      updateOne: entityAdapter.updateOne,
      removeOne: entityAdapter.removeOne,
      upsertMany: entityAdapter.upsertMany,
      totalPages(state, { payload }) {
        state.totalPages = payload;
      },
      retrievedPages(state, { payload }) {
        state.retrievedPages = payload;
      },
    },
    extraReducers: builder => {
      builder.addCase(fetchEntities.pending, (state, action) => {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
        state.error = null;
      });

      builder.addCase(fetchEntities.fulfilled, (state, action) => {
        if (state.loading && state.currentRequestId === action.meta.requestId) {
          state.loading = false;
          state.currentRequestId = null;
        }
      });

      builder.addCase(fetchEntities.rejected, (state, action) => {
        if (state.loading && state.currentRequestId === action.meta.requestId) {
          state.loading = false;
          state.currentRequestId = null;
          state.error = action.error;
        }
      });

      builder.addCase(refreshEntities.pending, (state, action) => {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
        state.error = null;
      });

      builder.addCase(refreshEntities.fulfilled, (state, action) => {
        if (state.loading && state.currentRequestId === action.meta.requestId) {
          state.loading = false;
          state.currentRequestId = null;
        }
      });

      builder.addCase(refreshEntities.rejected, (state, action) => {
        if (state.loading && state.currentRequestId === action.meta.requestId) {
          state.loading = false;
          state.currentRequestId = null;
          state.error = action.error;
        }
      });

      builder.addCase(CommonTypes.ERROR_HARD_RESET, () => initialState);

      builder.addCase(UserTypes.SIGN_OUT, () => initialState);
    },
  });

  return slice;
};

export const makeSelectors = (name, key, parentSelector, entityAdapter) => {
  const selectBase = createSelector(parentSelector, data => data[key]);

  const selectTotalPages = createSelector(selectBase, base => base.totalPages);

  const selectRetrievedPages = createSelector(
    selectBase,
    base => base.retrievedPages,
  );

  const selectHasMore = createSelector(
    selectTotalPages,
    selectRetrievedPages,
    (totalPages, retrievedPages) =>
      totalPages ? retrievedPages < totalPages : true,
  );

  const selectLoading = createSelector(selectBase, base => base.loading);

  return {
    ...entityAdapter.getSelectors(selectBase),
    [`select${name}`]: selectBase,
    selectTotalPages,
    selectRetrievedPages,
    selectHasMore,
    selectLoading,
  };
};
