import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { errorHardReset } from 'redux/common.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const useEnvForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const customEnv = {
    environmentName: t('envConfig.form.baseUrlSelect.custom.label'),
    baseUrl: '',
    environmentDescription: 'Custom environment setup',
  };
  const [environments, setEnvironments] = useState([customEnv]);
  const [selectedEnv, setSelectedEnv] = useState(customEnv);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          'https://qa.incentivioqa.com/incentivio-mobile-api/dynamicEnvs',
        );
        setEnvironments(pre => [...pre, ...res.data]);
      } catch (error) {
        toast.error(getResponseErrorMessage(error));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async data => {
    let defaultAlias;
    if (!!sessionStorage.getItem('env'))
      defaultAlias = JSON.parse(sessionStorage.getItem('env')).DEFAULT_ALIAS;

    sessionStorage.setItem(
      'env',
      JSON.stringify({
        ...data,
        REACT_APP_MOBILE_API_URL: `${data.REACT_APP_MOBILE_API_URL}/incentivio-mobile-api`,
        DEFAULT_ALIAS: defaultAlias ?? window.location.pathname.split('/c/')[1],
        REACT_APP_ENV_NAME: selectedEnv.environmentName,
        REACT_APP_ENV_DESCRIPTION: selectedEnv.environmentDescription,
        REACT_APP_COMING_SOON: 'false',
      }),
    );

    dispatch(errorHardReset());

    window.location.href = `${window.location.origin}/c/${data.CLIENT_ALIAS}`;
  };

  const resetEnv = () => {
    window.location.href = `${window.location.origin}/c/${
      JSON.parse(sessionStorage.getItem('env')).DEFAULT_ALIAS
    }`;
    sessionStorage.removeItem('env');
    dispatch(errorHardReset());
  };

  return {
    loading,
    environments,
    onSubmit,
    resetEnv,
    selectedEnv,
    setSelectedEnv,
  };
};
