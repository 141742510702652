import { createSelector } from 'reselect';
import { selectors as offersSelectors } from '../offers/offers.slice';
import { selectors as messagesSelectors } from '../messages/messages.slice';
import { DateTime } from 'luxon';

const selectInboxContent = createSelector(
  offersSelectors.selectNonLoyaltyOffers,
  messagesSelectors.selectNonExpiredMessages,
  (offers, messages) =>
    [...offers, ...messages].sort((a, b) => {
      const date1 = DateTime.fromISO(a.distributedDate);
      const date2 = DateTime.fromISO(b.distributedDate);

      return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
    }),
);

const selectTotal = createSelector(
  offersSelectors.selectTotalFiltered,
  messagesSelectors.selectTotal,
  (offersTotal, messagesTotal) => offersTotal + messagesTotal,
);

const selectHasMore = createSelector(
  offersSelectors.selectHasMore,
  messagesSelectors.selectHasMore,
  (offersHasMore, messagesHasMore) => offersHasMore || messagesHasMore,
);

const selectLoading = createSelector(
  offersSelectors.selectLoading,
  messagesSelectors.selectLoading,
  (offersLoading, messagesLoading) => offersLoading || messagesLoading,
);

const selectNewCount = createSelector(
  offersSelectors.selectNonLoyaltyOffers,
  messagesSelectors.selectNonExpiredMessages,
  (allOffers, nonExpiredMessages) =>
    [allOffers, nonExpiredMessages].reduce(
      (result, array) =>
        result +
        array.reduce(
          (result2, entry) => (entry.status === 'NEW' ? result2 + 1 : result2),
          0,
        ),
      0,
    ),
);

export const selectors = {
  selectInboxContent,
  selectTotal,
  selectHasMore,
  selectLoading,
  selectNewCount,
};
