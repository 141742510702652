import { Box } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rover, useRoverState } from 'reakit';
import * as S from './tip-selector.styles';

const TipSelector = ({
  name = 'tip-selector',
  percentages = [0, 10, 15, 20, 30],
  onChange,
  calculateAmount,
  value,
  percentageValue = percentages[0],
  labelledBy,
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(percentageValue?.toString());
  const rover = useRoverState({ currentId: tabValue });

  useEffect(() => {
    if (
      percentageValue > -1 &&
      calculateAmount(percentageValue).toString() !== value.toString()
    ) {
      setTabValue(-1);
    }
  }, [calculateAmount, percentageValue, value]);

  useEffect(() => {
    if (percentages.indexOf(parseInt(percentageValue)) === -1) {
      setTabValue(-1);
    }
  }, [percentages, percentageValue]);

  return (
    <Box data-testid="TipSelector.TipTabList">
      <S.TipGroup aria-labelledby={labelledBy} role="radiogroup" id={name}>
        {percentages
          .map(percentage => (
            <Rover
              {...rover}
              as={S.TipButton}
              key={percentage}
              id={percentage.toString()}
              data-testid="TipSelector.TipTab"
              aria-label={t('tipSelector.tipValueAria', {
                percentage,
                tipValue: calculateAmount(percentage),
              })}
              aria-describedby={labelledBy}
              role="radio"
              onClick={() => {
                setTabValue(percentage.toString());
                onChange(calculateAmount(percentage), percentage.toString());
              }}
              selected={tabValue === percentage.toString()}
              aria-checked={tabValue === percentage.toString()}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box fontSize="12px">{percentage}%</Box>
                <Box fontSize="14px">${calculateAmount(percentage)}</Box>
              </Box>
            </Rover>
          ))
          .concat([
            <Rover
              {...rover}
              as={S.TipButton}
              key="other"
              id="-1"
              data-testid="TipSelector.TipTab"
              aria-label={t('tipSelector.customTipAria')}
              aria-describedby={labelledBy}
              role="radio"
              onClick={() => {
                setTabValue(-1);
              }}
              selected={tabValue === -1}
              aria-checked={tabValue === -1}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box>{t('tipSelector.otherLabel')}</Box>
              </Box>
            </Rover>,
          ])}
      </S.TipGroup>
      {tabValue === -1 && (
        <Box display="flex" justifyContent="flex-end" py={1}>
          <CurrencyTextField
            name="custom_amount"
            inputProps={{
              'data-testid': 'Tip.CurrencyTextField',
              'aria-label': `${t('tipSelector.customTipAria')}`,
            }}
            variant="standard"
            value={value}
            minimumValue="0"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onBlur={(_e, value) => onChange(value, -1)}
          />
        </Box>
      )}
    </Box>
  );
};

export default TipSelector;
