import { Box } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import Spinner from 'components/spinner/spinner.component';
import React, { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetSqaureCard, useSquareCardTokenize } from './square-card.hooks';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { useSelector } from 'react-redux';
import { useSquareContext } from '../square.context';
import { useGetCardNickname } from 'pages/checkout/checkout-payment-section/checkout-payment-section.hooks';

const SquareCard = ({ handleCardInfo }) => {
  const nickname = useGetCardNickname();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const [attaching, setAttaching] = useState(true);
  const { squareCard, squareLoaded, setSquareCard } = useSquareContext();
  const loggedIn = useSelector(selectIsLoggedIn);
  const getCard = useGetSqaureCard();

  const tokenize = useSquareCardTokenize();

  useEffect(() => {
    const execute = async () => {
      setSquareCard(await getCard());
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareLoaded]);

  const onSubmit = useCallback(async () => {
    setButtonDisabled(true);
    const token = await tokenize();
    if (!!token) {
      await handleCardInfo({
        token,
        full_name: nickname,
        month: 0,
        year: 0,
      });
    }
    setButtonDisabled(false);
  }, [tokenize, handleCardInfo, nickname]);

  useEffect(() => {
    if (!squareCard) return;

    const execute = async () => {
      try {
        await squareCard.attach('#card-container');
      } catch (e) {
        toast.error(e.message);
        handleError(e);
      } finally {
        setAttaching(false);
      }
    };
    execute();

    return () => {
      setSquareCard(null);
      squareCard.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareCard]);

  return (
    <Box data-testid="SquareCard.Container">
      {attaching && <Spinner />}
      <form id="payment-form">
        <div id="card-container"></div>
        {loggedIn && !attaching && (
          <IncButton
            onClick={onSubmit}
            data-testid="SquareCard.IncButton.Submit"
            fullWidth
            disabled={buttonDisabled}
          >
            {t('payment.cardModal.submitAction')}
          </IncButton>
        )}
      </form>
    </Box>
  );
};

export default React.memo(SquareCard);
