export const getPreSelectedModifierCount = group => {
  return group.items.filter(item => item.defaultModifier).length;
};

export const hasMandatoryModifiers = optionGroups => {
  const mandatoryGroups = optionGroups.filter(group => {
    if (group.minItemSelections > 0) {
      if (getPreSelectedModifierCount(group) >= group.minItemSelections) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  });
  return mandatoryGroups.length > 0;
};

export const getDefaultModifiers = (optGroups, catalogId) => {
  const optionsArray = [];
  optGroups.forEach(group => {
    group.items.forEach(optionItem => {
      if (optionItem.defaultModifier) {
        const itemReqBody = {
          catalogId,
          groupId: group.groupId,
          itemId: optionItem.itemId,
          quantity: 1,
          itemInstructions: '',
          options: getDefaultModifiers(optionItem.optionGroups, catalogId),
        };
        optionsArray.push(itemReqBody);
      }
    });
  });
  return optionsArray;
};
