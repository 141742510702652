import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './order-item-options.styles';
import { ToggleDetailsButton } from '../../../../components/drawer-cart/drawer-cart-item/drawer-cart-item.styles';
import OrderItemOptionsCollapse from 'components/order-item-options-collapse/order-item-options-collapse.component';

const OrderItemOptions = ({ options }) => {
  const { t } = useTranslation();
  const [detailsShown, setDetailsShown] = useState(false);

  const onToggle = () => {
    setDetailsShown(!detailsShown);
  };
  return (
    <S.Wrapper>
      <ToggleDetailsButton
        onClick={onToggle}
        data-testid="OrderItemOptions.ToggleDetailsButton"
      >
        {detailsShown
          ? t('accountPastOrders.hide')
          : t('accountPastOrders.show')}
      </ToggleDetailsButton>
      <OrderItemOptionsCollapse isOpened={detailsShown} options={options} />
    </S.Wrapper>
  );
};

export default OrderItemOptions;
