import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import IncButton from 'components/inc-button/inc-button.component';
import { convertCentsToDollar } from 'utils';
import { useContext } from 'react';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import { useTranslation } from 'react-i18next';
import * as S from './add-to-cart-button.styles';
import { useSelector } from 'react-redux';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';

const AddToCartButtton = ({ onSubmit, onError, title }) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext();
  const { t } = useTranslation();
  const { editInfo, itemPrice, visibility, itemPriceLoading } =
    useContext(MenuDialogContext);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());

  const totalItemPrice = convertCentsToDollar(itemPrice);

  return (
    <Grid item xs={12} sm>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <IncButton
          type="submit"
          data-testid="CatalogModal.CustomButton.AddToCart"
          fullWidth
          disabled={!visibility.visible || isSubmitting || isMenuBrowsingStore}
        >
          {editInfo
            ? t('catalog.modal.addToCart.actionEditCart')
            : t('catalog.modal.addToCart.actionAddToCart')}
          <S.CartPrice
            aria-live="polite"
            aria-label={t('catalog.modal.addToCart.button.ariaLabel', {
              title,
              totalItemPrice,
            })}
          >
            {itemPriceLoading ? (
              <S.CartButtonSpinner size={20} />
            ) : (
              totalItemPrice
            )}
          </S.CartPrice>
        </IncButton>
      </form>
    </Grid>
  );
};

export default AddToCartButtton;
