import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useEffect } from 'react';

export const useUnregisterAddressForPickup = (orderType, unregister) => {
  const {
    fieldNames: { address },
  } = useFieldNames();

  useEffect(() => {
    if (orderType === 'PICKUP') unregister(address);
  }, [address, orderType, unregister]);
};

export default useUnregisterAddressForPickup;
