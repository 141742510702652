import i18n from 'i18next';
import { getOrderByIdStartAsync } from '../redux/cart/cart.actions';
import LogRocket from 'util/getLogRocket';

export const getResponseErrorMessage = (data, dispatch = () => {}) => {
  if (data?.response?.status === 401) return;

  const incentivioMessage = getResponseIncErrorMessage(data);

  if (data?.code === 'ECONNABORTED') {
    LogRocket.captureException(data);
    return i18n.t('errors.genericError');
  }

  switch (getResponseErrorCode(data)) {
    case 'INTERNAL_SERVER_ERROR':
      return i18n.t('errors.genericError');

    case 'PRICE_CALCULATION_FAILED':
      dispatch(getOrderByIdStartAsync());
      return incentivioMessage;

    case 'ERROR_DUE_TO_INVALID_CARD':
      return i18n.t('errors.invalidCard');

    case 'USER_OFFER_NOT_USABLE':
      return i18n.t('errors.offerNotUsable');

    case 'OFFER_PURCHASETYPE_NOT_INTEGRATED':
      return i18n.t('errors.offerNotIntegrated');

    case 'LOYALTY_ACCOUNT_HISTORIES_NOT_FOUND':
      return null;

    case 'RECAPTCHA_TOKEN_NOT_FOUND':
      return i18n.t('errors.refreshRequired');

    default:
      return incentivioMessage || i18n.t('errors.genericError');
  }
};

export const getResponseErrorCode = data => {
  const code = data?.response?.headers?.['incentivio-code'];
  return !!code ? code : undefined;
};

export const getResponseIncErrorMessage = data => {
  const message = data?.response?.headers?.['incentivio-message'];
  return !!message ? message : undefined;
};
