import ErrorBoundary from 'components/error-boundary/error-boundary.component';

const AddCardErrorBoundary = ({ children, name }) => {
  return (
    <ErrorBoundary FallbackComponent={() => null} name={name}>
      {children}
    </ErrorBoundary>
  );
};

export default AddCardErrorBoundary;
