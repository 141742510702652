import { forwardRef } from 'react';
import { Group, Rover, useRoverState } from 'reakit';

const OrderTypeSelectorButtons = forwardRef((props, ref) => {
  const { types, roverAs, ...propRest } = props;
  const rover = useRoverState({ orientation: 'horizontal' });

  return (
    <Group {...propRest} ref={ref}>
      {types.map(({ label, type, ...rest }) => {
        return (
          <Rover {...rover} {...rest} as={roverAs} key={type} value={type}>
            {label}
          </Rover>
        );
      })}
    </Group>
  );
});

export default OrderTypeSelectorButtons;
