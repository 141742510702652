import { Box } from '@material-ui/core';
import GiftCardPaymentSelector from 'components/payment/payment-selector/gift-card-payment-selector/gift-card-payment-selector.component';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import WalletButton from 'components/payment/wallet-button/wallet-button.component';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentInstrumentById } from 'redux/user/user.selectors';
import { useGiftCardPaymentStep } from '../gift-card-payment-step.hooks';
import GiftCardPurchaseButton from './gift-card-purchase-button/gift-card-purchase-button.component';

const GiftCardPaymentStepNew = ({
  cards,
  selection,
  handleSelection,
  showWallet,
  trigger,
  purchaseLoading,
  isLoading,
  fallbackPaymentToken,
}) => {
  const paymentInstrumentSelector = useCallback(
    state => selectPaymentInstrumentById(selection)(state),
    [selection],
  );
  const paymentInstrument = useSelector(paymentInstrumentSelector);
  const paymentToken = paymentInstrument?.paymentToken ?? fallbackPaymentToken;
  const { processOrder, loading } = useGiftCardPaymentStep({
    purchaseLoading,
    isLoading,
    trigger,
    paymentToken,
  });
  const { selectedPaymentOption } = usePaymentOptions();
  const disabled =
    (selectedPaymentOption === PaymentOptionType.CARD && !selection) || loading;

  return (
    <>
      <GiftCardPaymentSelector
        cards={cards}
        selectedCard={selection}
        onChangeCard={handleSelection}
      />

      {showWallet && <WalletButton />}

      {!showWallet && (
        <Box mt={1}>
          <GiftCardPurchaseButton
            processOrder={processOrder}
            disabled={disabled}
          />
        </Box>
      )}
    </>
  );
};

export default GiftCardPaymentStepNew;
