import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PasswordVisibility = ({
  setShowPassword,
  showPassword,
  iconButtonProps,
}) => {
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        onClick={handleShowPassword}
        onMouseDown={handleMouseDownPassword}
        {...iconButtonProps}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibility;
