import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import CustomDialog from '../custom-dialog/custom-dialog.component';
import * as S from './warning-dialog.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';

const WarningDialog = ({
  close,
  open,
  handleAction,
  title,
  subhead,
  warningText,
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={open}
      close={close}
      title={title}
      testId="WarningDialog.CustomDialog"
      paperId="change-location"
    >
      <S.Question>{subhead}</S.Question>
      <S.WarningBox>
        <WarningIcon />
        <Typography>{warningText}</Typography>
      </S.WarningBox>

      <Grid container spacing={1} justify="center">
        <Grid item xs={6}>
          <IncButtonDark
            onClick={close}
            fullWidth
            data-testid="WarningDialog.CustomButton.Cancel"
          >
            {t('dialogs.cancelAction')}
          </IncButtonDark>
        </Grid>
        <Grid item xs={6}>
          <IncButton
            onClick={handleAction}
            fullWidth
            data-testid="WarningDialog.CustomButton.Continue"
          >
            {t('dialogs.continueAction')}
          </IncButton>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default WarningDialog;
