import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';

export const CustomMenuItem = styled(MenuItem)`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0;
  }
`;

export const MenuItemIcon = styled(ListItemIcon)`
  min-width: unset;
`;

export const MenuItemText = styled(ListItemText)`
  min-width: 200px;
  text-transform: capitalize;
`;

export const MessageBadge = styled(Badge)`
  .MuiBadge-badge {
    height: 10px;
    min-width: 10px;
    top: 4px;
    right: 3px;
    padding: 0;
    background-color: ${({ theme }) => theme.palette.messageCountColor};
  }
`;
