import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { applyDiscount } from 'redux/cart/cart.actions';
import {
  selectApplicableOffers,
  selectOrderAndItemDiscounts,
} from 'redux/cart/cart.selectors';
import OffersModal from '../checkout-offers-section/offers-modal/offers-modal.component';
import CheckoutContext from '../checkout.context';

const CheckoutOffersModal = () => {
  const dispatch = useDispatch();
  const applicableOffers = useSelector(selectApplicableOffers);
  const discounts = useSelector(selectOrderAndItemDiscounts);
  const [pointsToSpend, setPointsToSpend] = useState();
  const { offersModalOpen, closeOffersModal } = useContext(CheckoutContext);

  const onSubmit = async data => {
    if (!data.offer) return;

    const requestedOffer = applicableOffers.find(
      applicableOffer => applicableOffer.distributedOfferId === data.offer,
    );

    const res = await dispatch(
      applyDiscount(
        requestedOffer.distributedOfferId,
        requestedOffer.offerId,
        pointsToSpend,
        requestedOffer.title,
      ),
    );
    if (!res.data) {
      toast.error(res);
    }
  };

  return offersModalOpen ? (
    <OffersModal
      applicableOffers={applicableOffers}
      defaultValue={discounts?.[0]?.distributedOfferId}
      pointsToSpend={pointsToSpend}
      setPointsToSpend={setPointsToSpend}
      open={offersModalOpen}
      handleClose={closeOffersModal}
      onSubmit={onSubmit}
    />
  ) : null;
};

export default CheckoutOffersModal;
