import React from 'react';
import CardItem from '../../../components/card-item/card-item.component';

const AccountMyCardsDisplay = ({ myCards, testId }) =>
  myCards
    .sort(({ isDefault }) => (isDefault ? -1 : 1))
    .map(card => (
      <CardItem
        key={`myCards${card.paymentInstrumentId}`}
        card={card}
        testId={testId}
      />
    ));

export default AccountMyCardsDisplay;
