import Slider from '@material-ui/core/Slider';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPreDiscountSubtotal } from 'redux/cart/cart.selectors';
import { universalMaxPoints } from 'redux/cart/cart.utils';
import { convertCentsToDollar } from 'utils';
import * as S from './offer-item-slider.styles';
import { useTranslation } from 'react-i18next';

const OfferItemSlider = ({
  discountAmount,
  pointsCost,
  availablePoints,
  setPointsToSpend,
  pointsToSpend,
}) => {
  const { t } = useTranslation();
  const handleSliderChange = (_e, newValue) => {
    setPointsToSpend(newValue);
  };

  const preDiscountSubtotal = useSelector(selectPreDiscountSubtotal);

  const maxPoints = useMemo(
    () =>
      universalMaxPoints(
        availablePoints,
        discountAmount,
        pointsCost,
        preDiscountSubtotal,
      ),
    [availablePoints, discountAmount, pointsCost, preDiscountSubtotal],
  );

  const marks = [{ value: maxPoints, label: maxPoints }];
  if (pointsCost !== maxPoints) {
    marks.unshift({ value: pointsCost, label: pointsCost });
  }
  const min = pointsCost === maxPoints ? 0 : pointsCost;
  const step = pointsCost === maxPoints ? null : pointsCost;

  const redeemedPoints = (pointsToSpend / pointsCost) * discountAmount;

  const savedAmount = convertCentsToDollar(
    redeemedPoints > preDiscountSubtotal ? preDiscountSubtotal : redeemedPoints,
  );

  return (
    <S.SliderWrapper>
      <Slider
        data-testid="OfferItem.Slider"
        defaultValue={pointsCost}
        value={pointsToSpend}
        onChange={handleSliderChange}
        marks={marks}
        step={step}
        min={min}
        max={maxPoints}
        valueLabelDisplay="auto"
        aria-label={t(
          'checkout.payment.offerSelection.offerList.offer.slider.ariaLabel',
        )}
      />
      <S.SavedText
        aria-label={t(
          'checkout.payment.offerSelection.offerList.offer.slider.pointsSaved.ariaLabel',
          { savedAmount },
        )}
        data-testid="OfferItemSlider.SavedText"
      >
        {t(
          'checkout.payment.offerSelection.offerList.offer.slider.pointsSaved.label',
          { savedAmount },
        )}
      </S.SavedText>
    </S.SliderWrapper>
  );
};

export default OfferItemSlider;
