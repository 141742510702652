import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import CustomInput from 'components/custom-input/custom-input.component';
import { InputLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';

const AddressAlias = ({ aliasName, defaultValue }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <CustomInput>
      <InputLabel htmlFor={aliasName}>{t('address.alias.label')}*</InputLabel>
      <TrimTextField
        id={aliasName}
        {...register(aliasName, {
          required: t('address.alias.errors.required'),
          'aria-required': true,
          validate: value =>
            !value.trim() ? t('address.alias.errors.required') : true,
        })}
        fullWidth
        defaultValue={defaultValue}
        placeholder={t('address.alias.placeholder')}
        inputProps={{
          'data-testid': 'AddressFields.TextField.Alias',
        }}
      />
      <ErrorMessage
        errors={errors}
        name={aliasName}
        render={({ message }) => <FormError errorMessage={message} />}
      />
    </CustomInput>
  );
};

export default AddressAlias;
