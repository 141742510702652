import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

export const BottomContainer = styled.div`
  height: 100vh;
`;

export const FadeRevealTrigger = styled(Fade)`
  position: relative;
  top: 100px;
`;

export const FadeReveal = styled(Fade)`
  position: fixed;
  bottom: 2em;
  left: 2em;
`;
