import Tab from '@material-ui/core/Tab';
import { TabContext } from '@material-ui/lab';
import Spinner from 'components/spinner/spinner.component';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import OptionGroup from 'pages/menu/option-group/option-group.component';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './option-group-tabs.styles';

const OptionGroupTabs = ({
  selectionGroups,
  isCustomizations,
  tabVal,
  handleTabChange,
  tabValues,
  filteredEditOptionsByGroupId,
  editInfo,
  customizationGroups,
}) => {
  const { t } = useTranslation();
  const { isItemModifierDetailsFetching } = useContext(MenuDialogContext);

  const tabs = useMemo(() => {
    const tabs = [];
    if (selectionGroups.length > 0) {
      tabs.push({
        value: tabValues.selectOptions,
        label: t('catalog.modal.tabs.selectOptions'),
        'data-testid': 'CatalogModal.CustomTabs.SelectOptions',
      });
    }
    if (isCustomizations) {
      tabs.push({
        value: tabValues.customizations,
        label: t('catalog.modal.tabs.customizations'),
        'data-testid': 'CatalogModal.CustomTabs.Customizations',
      });
    }
    return tabs;
  }, [
    isCustomizations,
    selectionGroups.length,
    t,
    tabValues.customizations,
    tabValues.selectOptions,
  ]);

  if (isItemModifierDetailsFetching) return <Spinner />;

  return tabs.length > 0 ? (
    <TabContext value={tabVal}>
      <S.CustomTabs
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label={t('catalog.modal.tabs.ariaLabel')}
        data-testid="CatalogModal.CustomTabs"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            data-testid={tab['data-testid']}
          />
        ))}
      </S.CustomTabs>

      <S.CustomTabPanel value={tabValues.selectOptions}>
        {selectionGroups.map((group, i) => (
          <OptionGroup
            key={group.groupId}
            group={group}
            editOptions={filteredEditOptionsByGroupId(group)}
            formKey={`g${i}`}
            isParentAndEditFlow={editInfo?.options}
          />
        ))}
      </S.CustomTabPanel>

      <S.CustomTabPanel value={tabValues.customizations}>
        {customizationGroups.map((group, i) => (
          <OptionGroup
            key={group.groupId}
            group={group}
            editOptions={filteredEditOptionsByGroupId(group)}
            formKey={`g${i + selectionGroups.length}`}
            isParentAndEditFlow={editInfo?.options}
          />
        ))}
      </S.CustomTabPanel>
    </TabContext>
  ) : null;
};

export default OptionGroupTabs;
