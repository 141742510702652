import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import { selectOrderItems, selectOrderType } from '../cart/cart.selectors';
import { MULTIPLE_CATALOG, SINGLE_CATALOG } from './catalog.constants';
import {
  countCatalogItems,
  countGroupItems,
  isCatalogVisible,
  isGroupVisible,
  collectInheritedOptionGroups,
  mergeItemDetails,
  findItemDetailsInGroups,
  itemDetailsComparator,
  findGroup,
  getAllSubGroupIds,
} from './catalogs.utils';
import { selectRecommendationDetails } from 'redux/recommendations/recommendations.selectors';
import { selectRequestedOrFirstAvailabilityObject } from 'redux/data/orderavailability/orderavailability.selectors';

export const selectCatalogs = state => state.catalogs;

export const selectCatalogsIsFetching = createSelector(
  [selectCatalogs],
  catalogs => catalogs.isFetching,
);

export const selectChecksum = createSelector(
  selectCatalogs,
  catalogs => catalogs?.checksum,
);

// Looks for the checksum argument (a string) and compares that to determine if
// we have a new catalogArray. Always returns 'true' for the catalogs argument
// because it is not a string.
//
// The end result of this is the catalogsArray selector (and all derivative
// selectors) will only be recomputed when the checksum changes. If we didn't do
// this custom equality check, then components depending upon the catalogs array
// would re-render every time the fetch catalogs api call is successful, even if
// the checksum didn't change.
const createCatalogArraySelector = createSelectorCreator(
  defaultMemoize,
  (prev, curr) => (typeof curr === 'string' ? prev === curr : true),
);

export const selectCatalogsArray = createCatalogArraySelector(
  selectChecksum,
  selectCatalogs,
  (_checksum, catalogs) => {
    return catalogs && catalogs.catalogsArray ? catalogs.catalogsArray : [];
  },
);

export const makeSelectIsLargeMenuMode = catalogId =>
  createSelector(
    selectCatalogsArray,
    catalogs =>
      !!catalogs?.find(catalog => catalog.catalogId === catalogId)
        ?.largeMenuMode,
  );

export const selectVisibleCatalogs = createSelector(
  selectCatalogsArray,
  selectRequestedOrFirstAvailabilityObject,
  selectOrderType,
  (catalogsArray, requestedTime, orderType) => {
    return catalogsArray.filter(catalog =>
      isCatalogVisible(catalog, orderType, requestedTime?.storeDateTime),
    );
  },
);

export const selectMenuCatalogs = createSelector(
  selectVisibleCatalogs,
  selectRequestedOrFirstAvailabilityObject,
  selectOrderType,
  (visibleCatalogs, requestedTime, orderType) =>
    visibleCatalogs.filter(
      catalog =>
        countCatalogItems(catalog, orderType, requestedTime?.storeDateTime) > 0,
    ),
);

export const makeSelectMenuSubgroups = group =>
  createSelector(
    selectRequestedOrFirstAvailabilityObject,
    selectOrderType,
    (requestedTime, orderType) =>
      group && group.subGroups
        ? group.subGroups.filter(
            subGroup =>
              isGroupVisible(
                subGroup,
                orderType,
                requestedTime?.storeDateTime,
              ) &&
              countGroupItems(
                subGroup,
                orderType,
                requestedTime?.storeDateTime,
              ),
          )
        : [],
  );

export const makeSelectMenuGroups = catalog =>
  createSelector(
    selectRequestedOrFirstAvailabilityObject,
    selectOrderType,
    (requestedTime, orderType) => {
      let filteredCatalogGroups =
        catalog && catalog.groups
          ? catalog.groups.filter(
              group =>
                isGroupVisible(
                  group,
                  orderType,
                  requestedTime?.storeDateTime,
                ) &&
                countGroupItems(group, orderType, requestedTime?.storeDateTime),
            )
          : [];

      while (
        filteredCatalogGroups.length &&
        filteredCatalogGroups.every(
          fcg => fcg.items?.length < 1 && fcg.subGroups?.length > 0,
        )
      ) {
        filteredCatalogGroups = filteredCatalogGroups.reduce(
          (result, fcg) => [...result, ...fcg.subGroups],
          [],
        );
      }

      return filteredCatalogGroups;
    },
  );

export const selectOverrideCatalogItemDetails = createSelector(
  selectCatalogsArray,
  selectOrderItems,
  (catalogsArray, orderItems) => {
    const detailsArray = [];
    let orderItemsFound = null;
    catalogsArray.forEach(catalog => {
      orderItemsFound = orderItems.filter(
        orderItem => catalog.catalogId === orderItem.catalogId,
      );
      if (!!orderItemsFound.length > 0) {
        orderItemsFound.forEach(item => {
          detailsArray.push({
            catalogDetails: catalog,
            orderItemDetails: item,
          });
        });
      }
    });
    return detailsArray;
  },
);

export const selectAllOverrideApplicableMainGroups = createSelector(
  selectCatalogsArray,
  catalogsArray => {
    const overrideAppliedMainGroups = [];
    catalogsArray.forEach(catalog => {
      overrideAppliedMainGroups.push(
        ...catalog.groups.filter(group => group.groupOverrideApplied),
      );
    });
    return overrideAppliedMainGroups;
  },
);

export const selectOverriddeGroupItemDetails = createSelector(
  selectAllOverrideApplicableMainGroups,
  selectOrderItems,
  (allOverrideApplicableMainGroups, orderItems) => {
    const overrideAppliedGroupDetails = [];
    allOverrideApplicableMainGroups.forEach(group => {
      const groupIds = [group.groupId, ...getAllSubGroupIds(group)];
      orderItems.forEach(orderItem => {
        if (groupIds.includes(orderItem.group.groupId)) {
          overrideAppliedGroupDetails.push({
            groupDetails: group,
            orderItemDetails: orderItem,
          });
        }
      });
    });
    return overrideAppliedGroupDetails;
  },
);

export const selectOutOfStockItemIds = createSelector(
  selectCatalogs,
  catalogs => catalogs?.outOfStockItemIds || [],
);

export const makeSelectIsItemOutOfStock = itemId =>
  createSelector(
    selectOutOfStockItemIds,
    outOfStockItemIds => !!outOfStockItemIds.find(id => id === itemId),
  );

export const makeSelectAvailableItems = items =>
  createSelector(selectOutOfStockItemIds, outOfStockItemIds => {
    if (Array.isArray(items)) {
      return [...items].filter(
        item => !outOfStockItemIds.find(id => item.itemId === id),
      );
    }
    return [];
  });

export const makeSelectUnAvailableItems = items =>
  createSelector(selectOutOfStockItemIds, outOfStockItemIds => {
    if (Array.isArray(items)) {
      return [...items].filter(
        item => !!outOfStockItemIds.find(id => item.itemId === id),
      );
    }
    return [];
  });

export const makeSelectItemsSortedByAvailability = items =>
  createSelector(
    makeSelectAvailableItems(items),
    makeSelectUnAvailableItems(items),
    (availableItems, unAvailableItems) => [
      ...availableItems,
      ...unAvailableItems,
    ],
  );

export const selectMenuType = createSelector(
  selectMenuCatalogs,
  catalogsArray => {
    if (catalogsArray.length === 1) return SINGLE_CATALOG;
    else if (catalogsArray.length > 1) return MULTIPLE_CATALOG;
    else return null;
  },
);

export const selectAvailableRecommendationDetails = createSelector(
  selectRecommendationDetails,
  selectOutOfStockItemIds,
  (recommendationDetails, outOfStockItemIds) =>
    recommendationDetails.filter(
      detail => !outOfStockItemIds.find(id => id === detail.objId),
    ),
);

export const makeSelectGroup = (catalogId, groupIds) =>
  createSelector(selectMenuCatalogs, catalogsArray => {
    const catalog = catalogsArray.find(
      catalog => catalog.catalogId === catalogId,
    );
    return findGroup(catalog?.groups, groupIds);
  });

export const selectAllItemDetails = createSelector(
  selectCatalogsArray,
  selectOrderType,
  selectRequestedOrFirstAvailabilityObject,
  (catalogs, orderType, requestedTime) => {
    let itemDetails = {};

    catalogs.forEach(catalog => {
      const path = [catalog.catalogId];
      const catalogVisible = isCatalogVisible(
        catalog,
        orderType,
        requestedTime?.storeDateTime,
      );
      const visibility = {
        catalog: catalogVisible,
        catalogOverride: !catalogVisible && catalog.catalogOverride,
      };
      itemDetails = mergeItemDetails(
        itemDetails,
        findItemDetailsInGroups(
          catalog.groups,
          orderType,
          requestedTime,
          path,
          visibility,
        ),
      );
    });
    return itemDetails;
  },
);

export const makeSelectCatalogItem = (catalogId, groupIds, itemId) =>
  createSelector(
    selectCatalogsArray,
    selectAllItemDetails,
    (catalogsArray, allItemDetails) => {
      const itemDetailsCollection = allItemDetails[itemId];
      const itemDetails = itemDetailsCollection?.find(
        itemDetailsObject =>
          itemDetailsObject.path.includes(catalogId) &&
          groupIds.every(groupId => itemDetailsObject.path.includes(groupId)),
      );
      if (!!itemDetails)
        itemDetails.inheritedOptionGroups = collectInheritedOptionGroups(
          catalogsArray,
          itemDetails.path,
          itemDetails.item,
        );

      return itemDetails ?? {};
    },
  );

export const selectRecommendedItems = createSelector(
  selectCatalogsArray,
  selectAllItemDetails,
  selectAvailableRecommendationDetails,
  (catalogsArray, allItemDetails, availableRecommendationDetails) =>
    availableRecommendationDetails.reduce((result, recommendationDetail) => {
      const itemDetailsCollection = allItemDetails[recommendationDetail.objId];
      if (itemDetailsCollection?.length) {
        const filteredCollection = itemDetailsCollection.filter(
          itemDetails =>
            itemDetails.visibility.catalog && itemDetails.visibility.group,
        );
        if (filteredCollection.length) {
          const comparator = itemDetailsComparator(
            recommendationDetail.catalogIds,
            recommendationDetail.groupIds,
          );
          const matchedItemDetails = filteredCollection.sort(comparator)[0];
          matchedItemDetails.inheritedOptionGroups =
            collectInheritedOptionGroups(
              catalogsArray,
              matchedItemDetails.path,
              matchedItemDetails.item,
            );
          result.push(matchedItemDetails);
        }
      }
      return result;
    }, []),
);

export const selectAllItemDetailsCsv = createSelector(
  selectAllItemDetails,
  allItemDetails =>
    ['Item Name,Group Name,Path']
      .concat(
        Object.values(allItemDetails)
          .flatMap(itemDetailsArray =>
            itemDetailsArray.map(itemDetails =>
              !!itemDetails.item
                ? `${itemDetails.item.displayInfo[0].title.replace(/,/g, '')},${
                    itemDetails.group?.displayInfo?.[0]?.title.replace(
                      /,/g,
                      '',
                    ) ?? ''
                  },${itemDetails.path.join('.')}.${itemDetails.item.itemId}`
                : null,
            ),
          )
          .filter(row => row !== null),
      )
      .join('\n'),
);

export const selectAllSingleItemDetails = createSelector(
  selectCatalogs,
  catalogs => catalogs?.allSingleItemDetails,
);

export const makeSelectItemDetailsById = itemId =>
  createSelector(
    selectAllSingleItemDetails,
    allSingleItemDetails => allSingleItemDetails?.[itemId],
  );
