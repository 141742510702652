import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

export const OrderButton = styled(IncButton)`
  margin: 0 0.5em;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 100px;
  }
`;
