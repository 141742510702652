import styled from 'styled-components';

export const Container = styled.div`
  .spf-button {
    display: none;
  }

  .spf-field-submit {
    padding: 0;
  }
`;
