export const configureTestMaps = gApi => {
  if (window.Cypress) {
    if (gApi === '') {
      window.google = {
        ...window.google,
        maps: {
          ...window.google.maps,
          places: {
            ...window.google.maps.places,
            ...window.incTest.google.maps.places,
          },
          Geocoder: window.incTest.google.maps.Geocoder,
        },
      };
    } else if (gApi) {
      window.alert('You should not use a real google maps api key for testing');
    }
  }
};
