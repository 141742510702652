import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAddGiftCardToWallet } from 'redux/config/config.selectors';
import * as S from './add-to-account.styles';

const AddToAccount = ({ addToWallet, firstName, lastName }) => {
  const { t } = useTranslation();

  const addGiftCardToWallet = useSelector(selectAddGiftCardToWallet);

  if (!addGiftCardToWallet) return null;

  return (
    <>
      <S.AddToAccountBodyMessage component="p" gutterBottom>
        {t('eGiftCard.view.notInAccount', { firstName, lastName })}
      </S.AddToAccountBodyMessage>
      <IncButton
        onClick={addToWallet}
        data-testid="LoggedInBody.CustomButton.AddToAccount"
        fullWidth
      >
        {t('eGiftCard.view.addToAccountButton')}
      </IncButton>
    </>
  );
};

export default AddToAccount;
