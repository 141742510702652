import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HiddenTypography from 'components/hidden-typography/hidden-typography.component';
import { useTranslation } from 'react-i18next';

const SummaryItemsQtyButtons = ({
  quantity,
  hideQuantityPicker,
  handleDecrement,
  handleIncrement,
  modifyQtyType,
  modifyingQtyItemIds,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={hideQuantityPicker ? 'center' : 'space-between'}
      alignItems="center"
      height="80px"
      pl="6px"
    >
      <HiddenTypography component="p" id="qty-hidden-instruction">
        {t('checkout.items.quantity.hiddenTypographyText')}
      </HiddenTypography>
      {!hideQuantityPicker && (
        <IconButton
          size="small"
          color="primary"
          onClick={handleIncrement}
          disabled={modifyQtyType === '+' && modifyingQtyItemIds.length > 0}
          data-testid="SummaryItem.IconButton.Increase"
          aria-label={t('checkout.items.quantity.increment', {
            title,
            quantity,
          })}
          aria-describedby="qty-hidden-instruction"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      )}
      <Box data-testid="SummaryItem.Box.Quantity">{quantity}</Box>
      {!hideQuantityPicker && (
        <IconButton
          size="small"
          color="primary"
          onClick={handleDecrement}
          disabled={modifyQtyType === '-' && modifyingQtyItemIds.length > 0}
          data-testid="SummaryItem.IconButton.Decrease"
          aria-label={t('checkout.items.quantity.decrement', {
            title,
            quantity,
          })}
          aria-describedby="qty-hidden-instruction"
        >
          <RemoveIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default SummaryItemsQtyButtons;
