import GiftCardPaymentSelector from '../gift-card-payment-selector/gift-card-payment-selector.component';

const PaymentSelectorWrapper = ({ cards, selection, handleSelection }) => {
  return (
    <GiftCardPaymentSelector
      cards={cards}
      selectedCard={selection}
      onChangeCard={handleSelection}
    />
  );
};
export default PaymentSelectorWrapper;
