import { PICKUP } from 'util/constants';
import { useGetOrderType } from 'pages/store/new-order-form/useGetOrderType';
import DeliveryOrderHours from './delivery-order-hours/delivery-order-hours.component';
import PickupOrderHours from './pickup-order-hours/pickup-order-hours.component';

const NonCateringOrderingHours = ({ store }) => {
  const orderType = useGetOrderType();

  if (orderType === PICKUP) return <PickupOrderHours store={store} />;

  return <DeliveryOrderHours store={store} />;
};

export default NonCateringOrderingHours;
