import { TabList } from '@material-ui/lab';
import styled from 'styled-components';

export const CustomTabList = styled(TabList)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  position: sticky;
  top: ${({ theme }) => theme.navHeight};
  z-index: 100;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 4px;
  }

  & .MuiTabScrollButton-root:last-of-type {
    padding-right: 20px;
    width: 60px;
  }

  & .MuiTabs-scroller {
    flex-grow: 0;
  }
`;
