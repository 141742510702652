import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';

const CustomPagination = styled(Pagination)`
  margin: 0;
  .MuiPagination-ul {
    justify-content: center;
  }
`;

export default CustomPagination;
