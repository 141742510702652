import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectItemsSortedByAvailability } from 'redux/catalogs/catalogs.selectors';
import { helperText } from './option-group.utils';

export const useOptionGroup = (group, editOptions) => {
  const {
    displayInfo,
    items,
    groupId,
    selectionMandatory,
    maxItemSelections,
    minItemSelections,
  } = group;

  const selectItemsSortedByAvailability = useCallback(
    state => makeSelectItemsSortedByAvailability(items)(state),
    [items],
  );
  const itemsSortedByAvailability = useSelector(
    selectItemsSortedByAvailability,
  );

  const helperTextKey = helperText(group);

  const editingOptionItem = useCallback(
    itemId => editOptions?.find(item => item.itemId === itemId),
    [editOptions],
  );

  return {
    displayInfo,
    selectionMandatory,
    helperTextKey,
    itemsSortedByAvailability,
    maxItemSelections,
    minItemSelections,
    groupId,
    editingOptionItem,
  };
};
