import { useCallback, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCustomerInfo } from 'redux/cart/cart.selectors';
import { selectUserData, selectIsLoggedIn } from 'redux/user/user.selectors';
import { setCustomerInfo } from 'redux/cart/cart.actions';
import { useStore } from 'react-redux';
import { customerSchema } from '../checkout.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { updateUserData } from 'redux/user/user.actions';
import { getSectionId } from '../checkout-section/checkout-section.hooks';
import CheckoutContext from '../checkout.context';

export const useCustomerSectionComponent = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const customerInfo = useSelector(selectCustomerInfo);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const { validateSection } = useContext(CheckoutContext);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(customerSchema),
  });

  const { handleSubmit } = methods;

  const submitCustomerInfo = () =>
    handleSubmit(
      async data => {
        await dispatch(setCustomerInfo(data));
        isLoggedIn &&
          (await dispatch(updateUserData({ ...userData, ...data })));
      },
      err => {
        throw err;
      },
    )();

  useEffect(() => {
    if (!customerInfo && isLoggedIn && userData) {
      dispatch(
        setCustomerInfo({
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          email: userData.email,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo, dispatch, isLoggedIn, userData]);

  useEffect(() => {
    !!customerInfo &&
      validateSection(getSectionId(t('checkout.customer.title')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  return { customerInfo, submitCustomerInfo, formMethods: methods };
};

export const useCustomerValidation = () => {
  const store = useStore();

  const validate = useCallback(async () => {
    try {
      await customerSchema.validate(selectCustomerInfo(store.getState()));
    } catch (err) {
      return err;
    }
  }, [store]);

  return { validate };
};
