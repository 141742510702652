import NotFound from 'components/not-found/not-found.component';
import React, { useEffect } from 'react';

const SocialShareContainer = () => {
  useEffect(() => {
    if (!!window.opener) {
      window.opener.postMessage('fbShareSuccess');
      window.close();
    }
  }, []);

  return !window.opener ? <NotFound /> : null;
};

export default SocialShareContainer;
