import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import {
  useMakePayment,
  useMakePaymentBody,
  usePaymentGiftCardAsAGuest,
  usePaymentMode,
  usePurchaseEmailGiftCardBody,
} from '../payment.new.hooks';
import INC_BASE_API from 'apis/incentivio-api';
import { IncentivioApiError } from 'util/errors';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { selectPaymentOption } from 'redux/cart/cart.selectors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PaymentOptionType } from '../payment.constants';
import { useSquareCardTokenize } from './square-card/square-card.hooks';
import { useErrorHandler } from 'react-error-boundary';
import getSquare from 'util/getSquare';

export const usePlaceOrderWithCard = () => useMakePayment(useMakePaymentBody());

export const useSquarePayments = () => {
  const [squareLoaded, setSquareLoaded] = useState({});
  const [squareCard, setSquareCard] = useState(null);

  const handleError = useErrorHandler();

  useEffect(() => {
    const execute = async () => {
      try {
        await new Promise(async (resolve, reject) => {
          if (!!getSquare()) {
            setSquareLoaded(true);
            resolve();
            return;
          }
          setTimeout(async () => {
            // wait 1 second to see if Square is defined on the window. I
            // think this is only an issue when testing
            if (!getSquare()) {
              reject(new Error('window.Square is not defined'));
            }
            setSquareLoaded(true);
            resolve();
          }, 1000);
        });
      } catch (err) {
        handleError(err);
      }
    };
    execute();
  }, [handleError]);

  return {
    squareLoaded,
    setSquareCard,
    squareCard,
  };
};

export const useSquarePaymentsPlaceOrder = () => {
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const paymentMode = usePaymentMode();
  const paymentOption = useSelector(selectPaymentOption);
  const history = useHistory();

  const { trigger: cardTrigger } = usePlaceOrderWithCard();
  const { trigger: lookupGiftCardTrigger } = usePaymentGiftCardAsAGuest();
  const tokenize = useSquareCardTokenize();

  let response;

  const trigger = async () => {
    try {
      if (paymentMode === 'NO_PAYMENT') {
        response = await addPromise(cardTrigger());
      } else {
        // eslint-disable-next-line default-case
        switch (paymentOption) {
          case PaymentOptionType.CARD:
          case PaymentOptionType.PAY_LATER:
          case PaymentOptionType.GIFT_CARD:
            response = await addPromise(cardTrigger());
            break;

          case PaymentOptionType.GUEST_CARD:
            const paymentToken = await tokenize();
            if (!paymentToken) return;
            response = await addPromise(
              cardTrigger({
                paymentToken,
                isOneTimeTransaction: true,
                month: 0,
                year: 0,
              }),
            );
            break;

          case PaymentOptionType.GUEST_GIFT_CARD:
            const { lookupToken, giftCardNumber } = await addPromise(
              lookupGiftCardTrigger(),
            );
            if (lookupToken) {
              response = await cardTrigger({
                paymentToken: giftCardNumber,
                isOneTimeTransaction: true,
              });
            }
            break;
        }
      }
      history.push('/checkout/thankyou', response.data);
    } catch (e) {
      // handle errors in caller
      throw e;
    }
  };

  return { trigger };
};

export const useSquareEmailGiftCardPayment = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const body = usePurchaseEmailGiftCardBody();

  const tokenize = useSquareCardTokenize();

  const trigger = async paymentToken => {
    try {
      setLoading(true);

      if (!isLoggedIn) {
        const token = await tokenize();
        if (!token) {
          setLoading(false);
          return;
        }
        body.paymentToken = token;
      } else {
        body.paymentToken = paymentToken;
      }

      const response = await INC_BASE_API.post(`/giftcard/purchase`, body, {
        authenticated: isLoggedIn,
      });

      return response;
    } catch (e) {
      throw new IncentivioApiError('useSquareEmailGiftCardPayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const useSquarePurchaseEmailGiftCard = () => {
  const {
    loading: emailGiftCardLoading,
    trigger: triggerEmailGiftCardPayment,
  } = useSquareEmailGiftCardPayment();

  const loading = useMemo(() => emailGiftCardLoading, [emailGiftCardLoading]);

  const trigger = async paymentToken => {
    const resp = await triggerEmailGiftCardPayment(paymentToken);

    return resp;
  };

  return { loading, trigger };
};
