import * as S from './logged-in-body.styles';
import Spinner from 'components/spinner/spinner.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserData } from 'redux/user/user.selectors';
import { useViewGiftCard } from './logged-in-body.hooks';
import AddToAccount from './add-to-account/add-to-account.component';
import { Link as RouterLink } from 'react-router-dom';

const LoggedInBody = ({ giftcard }) => {
  const { t } = useTranslation();
  const { inWallet, isLoading, addToWallet } = useViewGiftCard(giftcard);

  const { firstName, lastName } = useSelector(selectUserData) || {};

  if (isLoading) return <Spinner />;

  if (inWallet)
    return (
      <S.LoggedInBodyMessage
        component="p"
        gutterBottom
        data-testid="GiftcardViewPage.LoggedInBody"
      >
        {t('eGiftCard.view.inAccount')}{' '}
        <S.AddToAccountLink
          component={RouterLink}
          to="../../my-cards"
          data-testid="GiftcardViewPage.NavigateToMyCards"
        >
          {t('eGiftCard.view.inWalletLink')}
        </S.AddToAccountLink>{' '}
        {t('eGiftCard.view.inAccountLinkCont')}
      </S.LoggedInBodyMessage>
    );

  if (!inWallet && firstName)
    return (
      <AddToAccount
        firstName={firstName}
        lastName={lastName}
        addToWallet={addToWallet}
      />
    );

  return null;
};

export default LoggedInBody;
