import AppleIcon from '@material-ui/icons/Apple';
import { PaymentOptionType } from 'components/payment/payment.constants';
import React from 'react';
import PaymentOption from './payment-option.component';

const title = 'Apple Pay';

const ApplePayPaymentOption = React.forwardRef(({ selected, ...rest }, ref) => {
  return (
    <PaymentOption
      icon={<AppleIcon />}
      title={title}
      type={PaymentOptionType.APPLE_PAY}
      selected={selected}
      {...rest}
      ref={ref}
    />
  );
});
export default ApplePayPaymentOption;
