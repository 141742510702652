import { useMemo } from 'react';

export const useAccountOrderHistoryItem = orderDiscounts => {
  const isOrderDiscountApplied = useMemo(
    () => !!orderDiscounts && orderDiscounts?.length > 0,
    [orderDiscounts],
  );

  return { isOrderDiscountApplied };
};
