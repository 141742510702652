import React from 'react';

import * as S from './breadcrumbs.styles';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ children, isdark, maxWidth }) => {
  const { t } = useTranslation();

  return (
    <Hidden only={['xs', 'sm']}>
      <S.Wrapper>
        <Container maxWidth={maxWidth} data-testid="Breadcrumbs.Container">
          <S.Container isdark={isdark} aria-label={t('breadcrumbs.container')}>
            {children}
          </S.Container>
        </Container>
      </S.Wrapper>
    </Hidden>
  );
};

export default Breadcrumbs;
