import React from 'react';
import * as S from './account-container.styles';
import Spinner from '../spinner/spinner.component';
import { selectIsLoading } from '../../redux/user/user.selectors';
import { useSelector } from 'react-redux';

const AccountContainer = ({
  children,
  testId = 'AccountContainer',
  showLoading = false,
}) => {
  const isLoading = useSelector(selectIsLoading);

  return (
    <S.CustomContainer
      maxWidth="md"
      style={isLoading ? { minHeight: '100vh' } : null}
      data-testid={testId}
    >
      {(isLoading || showLoading) && <Spinner />}
      {children}
    </S.CustomContainer>
  );
};

export default AccountContainer;
