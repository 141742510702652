import { Link, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CheckoutH2 = styled(Typography)`
  font-size: 1.15rem;
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1.2rem;
  }
`;
CheckoutH2.defaultProps = { component: 'h2' };

export const CheckoutH3 = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1.1rem;
  }
`;
CheckoutH3.defaultProps = { component: 'h3' };

export const MapsLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: underline;
`;

export const ItemTotal = styled(Typography)`
  font-size: 0.8rem;
  font-weight: bold;
`;
