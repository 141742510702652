import React from 'react';
import * as S from './menu-card-new-image.styles';

const MenuCardNewImage = ({ smallImage }) => {
  return (
    smallImage?.length > 0 && (
      <S.MobileImage src={smallImage[0]} data-testid="MenuCard.Image" alt="" />
    )
  );
};

export default MenuCardNewImage;
