import { useOnLogout } from 'hooks/auth';
import { useFetchLocationsByProximity } from 'pages/locations/locations-list/locations-list.hooks';
import { useInitOrderAvailability } from 'pages/store/new-order-form/useOrderAvailability.hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setDefaultCartOrderItemState } from 'redux/cart/cart.actions';
import { selectOrderItemIdMismatch } from 'redux/cart/cart.selectors';
import { fetchCatalogsStartAsync } from 'redux/catalogs/catalogs.actions';
import { fetchConfigStartAsync } from 'redux/config/config.actions';
import { useAppcontent } from 'redux/data/appcontent/appcontent.hooks';
import {
  fetchAddressesStartAsync,
  fetchUserDataStartAsync,
} from 'redux/user/user.actions';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { useInitSegment } from 'util/segment.hooks';

export const useAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [userLoading, setUserLoading] = useState(true);
  const { fetchAppcontent } = useAppcontent();

  useOnLogout(() => {
    toast.info(t('logout'));
  });

  useEffect(() => {
    const loadUser = async () => {
      if (isLoggedIn) {
        if (await dispatch(fetchUserDataStartAsync())) {
          await Promise.all([
            dispatch(fetchAddressesStartAsync()),
            fetchAppcontent(),
          ]);
          setUserLoading(false);
        } else {
          setUserLoading(false);
        }
      } else {
        setUserLoading(false);
      }
    };
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn]);

  return { userLoading };
};

export const usePreload = () => {
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);
  const orderItemIdMismatch = useSelector(selectOrderItemIdMismatch);

  useInitSegment();
  useEffect(() => {
    Promise.all([
      dispatch(fetchConfigStartAsync()),
      dispatch(fetchCatalogsStartAsync()),
    ]).then(() => {
      setLoadingData(false);
    });
  }, [dispatch]);

  useEffect(() => {
    !loadingData &&
      !!orderItemIdMismatch &&
      dispatch(setDefaultCartOrderItemState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadingData]);

  useInitOrderAvailability();
  useFetchLocationsByProximity();

  return {
    loadingData,
  };
};
