import i18next from 'i18next';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { selectors } from 'redux/app/app.slice';
import { selectIsTipDisplay } from 'redux/common.selectors';
import { selectRecommendationsEnabled } from 'redux/config/config.selectors';
import {
  selectAvailableDays,
  selectAvailableTimesTZ,
  selectDefaultAvailabilityObject,
  selectOrderAvailabilityError,
  selectWithinDeliveryDistance,
} from 'redux/data/orderavailability/orderavailability.selectors';
import { actions as orderAvailabilityActions } from 'redux/data/orderavailability/orderavailability.slice';
import { selectBaseLocale } from 'redux/i18n/i18n.selectors';
import {
  removeRecommendationAddedOrderItemId,
  resetRecommendations,
  setRecommendationAddedOrderItemIds,
} from 'redux/recommendations/recommendations.actions';
import {
  selectAllRecommendedItemIds,
  selectIsUserSeenRecommendationItems,
  selectRecommendationAccepted,
  selectRecommendationAddedOrderItemIds,
} from 'redux/recommendations/recommendations.selectors';
import { getAnalytics } from 'util/analytics';
import INC_BASE_API from '../../apis/incentivio-api';
import {
  getResponseErrorCode,
  getResponseErrorMessage,
} from '../../apis/incentivio-api.util';
import { getLanguageKey, toISOStringLocal } from '../../utils';
import { selectors as appSelectors } from '../app/app.slice';
import { fetchOutOfStockItems } from '../catalogs/catalogs.actions';
import { selectors as clientSelectors } from '../client/client.slice';
import {
  makeSelectStoreBySlug,
  selectRequestedStoreDITitle,
  selectStoreById,
} from '../locations/locations.selectors';
import { selectIsLoggedIn } from '../user/user.selectors';
import {
  selectLocationId,
  selectOrder,
  selectOrderAndItemDiscounts,
  selectOrderId,
  selectOrderItemIdMismatch,
  selectOrderItems,
  selectOrderOption,
  selectOrderType,
} from './cart.selectors';
import CartActionTypes from './cart.types';
import {
  addGroupIdToOptions,
  assignGroupIds,
  getOrderAvailabilityError,
  getRecommendationStatus,
} from './cart.utils';

export const setOrderType = orderType => ({
  type: CartActionTypes.SET_ORDER_TYPE,
  payload: orderType,
});

export const getOrderIdStart = () => ({
  type: CartActionTypes.GET_ORDER_ID_START,
});

export const setLocationData = payload => ({
  type: CartActionTypes.SET_LOCATION_DATA,
  payload,
});

export const newOrderStart = () => ({
  type: CartActionTypes.NEW_ORDER_START,
});

export const newOrderSuccess = payload => ({
  type: CartActionTypes.NEW_ORDER_SUCCESS,
  payload,
});

export const newOrderFailure = errorMessage => ({
  type: CartActionTypes.NEW_ORDER_FAILURE,
  payload: errorMessage,
});

export const updateOrderStart = () => ({
  type: CartActionTypes.UPDATE_ORDER_START,
});

export const updateOrderSuccess = payload => ({
  type: CartActionTypes.UPDATE_ORDER_SUCCESS,
  payload,
});

export const updateOrderFailure = errorMessage => ({
  type: CartActionTypes.UPDATE_ORDER_FAILURE,
  payload: errorMessage,
});

export const getOrderByIdStart = () => ({
  type: CartActionTypes.GET_ORDER_BY_ID_START,
});

export const setCustomerInfo = customerInfo => ({
  type: CartActionTypes.SET_CUSTOMER_INFO,
  payload: customerInfo,
});

export const getOrderByIdSuccess = order => ({
  type: CartActionTypes.GET_ORDER_BY_ID_SUCCESS,
  payload: order,
});

export const getOrderByIdFailure = errorMessage => ({
  type: CartActionTypes.GET_ORDER_BY_ID_FAILURE,
  payload: errorMessage,
});

export const setOrderFulfillTimeSuccess = fulfillTime => ({
  type: CartActionTypes.SET_ORDER_FULFILL_TIME_SUCCESS,
  payload: fulfillTime,
});

export const modifyOrderItemsStart = itemId => ({
  type: CartActionTypes.MODIFY_ORDER_ITEMS_START,
  payload: itemId,
});

export const modifyOrderItemsSuccess = (data, orderItemId) => ({
  type: CartActionTypes.MODIFY_ORDER_ITEMS_SUCCESS,
  payload: { data, orderItemId },
});

export const modifyOrderItemsFailure = itemId => ({
  type: CartActionTypes.MODIFY_ORDER_ITEMS_FAILURE,
  payload: itemId,
});

export const addOrderItemStart = itemId => ({
  type: CartActionTypes.ADD_ORDER_ITEM_START,
  payload: itemId,
});

export const addOrderItemSuccess = (partialOrder, path) => ({
  type: CartActionTypes.ADD_ORDER_ITEM_SUCCESS,
  payload: { partialOrder, path },
});

export const addOrderItemFailure = itemId => ({
  type: CartActionTypes.ADD_ORDER_ITEM_FAILURE,
  payload: itemId,
});

export const setTipAction = (amount, percentage) => ({
  type: CartActionTypes.SET_TIP,
  payload: { amount, percentage },
});

export const setRequestedAddress = requestedAddress => ({
  type: CartActionTypes.SET_REQUESTED_ADDRESS,
  payload: requestedAddress,
});

export const setOrderInstructions = orderInstructions => ({
  type: CartActionTypes.SET_ORDER_INSTRUCTIONS,
  payload: orderInstructions,
});

export const setDeliveryInstructions = deliveryInstructions => ({
  type: CartActionTypes.SET_DELIVERY_INSTRUCTIONS,
  payload: deliveryInstructions,
});

export const setOrderOption = orderOption => ({
  type: CartActionTypes.SET_ORDER_OPTION,
  payload: orderOption,
});

export const clearCart = () => ({
  type: CartActionTypes.CLEAR_CART,
});

export const openCartDrawer = () => ({
  type: CartActionTypes.OPEN_CART_DRAWER,
});

export const closeCartDrawer = () => ({
  type: CartActionTypes.CLOSE_CART_DRAWER,
});

export const getOrderOffersStart = () => ({
  type: CartActionTypes.GET_ORDER_OFFERS_START,
});

export const getOrderOffersSuccess = payload => ({
  type: CartActionTypes.GET_ORDER_OFFERS_SUCCESS,
  payload,
});

export const getOrderOffersFailure = errorMessage => ({
  type: CartActionTypes.GET_ORDER_OFFERS_FAILURE,
  payload: errorMessage,
});

export const copyOrderStart = () => ({
  type: CartActionTypes.COPY_ORDER_START,
});

export const copyOrderSuccess = () => ({
  type: CartActionTypes.COPY_ORDER_SUCCESS,
});

export const copyOrderFailure = errorMessage => ({
  type: CartActionTypes.COPY_ODER_FAILURE,
  payload: errorMessage,
});

export const addOrderItemsStart = () => ({
  type: CartActionTypes.ADD_ORDER_ITEMS_START,
});

export const addOrderItemsSuccess = order => ({
  type: CartActionTypes.ADD_ORDER_ITEMS_SUCCESS,
  payload: order,
});

export const addOrderItemsFailure = errorMessage => ({
  type: CartActionTypes.ADD_ORDER_ITEMS_FAILURE,
  payload: errorMessage,
});

export const applyDiscountStart = () => ({
  type: CartActionTypes.APPLY_DISCOUNT_START,
});

export const applyDiscountSuccess = payload => ({
  type: CartActionTypes.APPLY_DISCOUNT_SUCCESS,
  payload,
});

export const applyDiscountFailure = () => ({
  type: CartActionTypes.APPLY_DISCOUNT_FAILURE,
});

export const removeDiscountStart = () => ({
  type: CartActionTypes.REMOVE_DISCOUNT_START,
});

export const removeDiscountSuccess = () => ({
  type: CartActionTypes.REMOVE_DISCOUNT_SUCCESS,
});

export const removeDiscountFailure = errorMessage => ({
  type: CartActionTypes.REMOVE_DISCOUNT_FAILURE,
  payload: errorMessage,
});

export const setDefaultCartOrderItemState = () => ({
  type: CartActionTypes.SET_DEFAULT_CART_ORDER_ITEM_STATE,
});

export const setTip = (amount, percentage) => (dispatch, getState) => {
  const isTipDisplay = selectIsTipDisplay(getState());
  if (isTipDisplay) {
    getAnalytics().trackTipApplied({ tip: amount });
  }
  dispatch(setTipAction(amount, percentage));
};

export const newOrder = data => {
  return async (dispatch, getState) => {
    try {
      const { location, orderType, address, time } = data;
      const locale = selectBaseLocale(getState());
      dispatch(newOrderStart());

      const delivery = {};

      if (orderType === 'DELIVERY') {
        delivery.deliveryAddress = {
          postalCode: address.postalCode,
          city: address.city,
          street1: address.street1,
          street2: address.street2,
          state: address.state,
          country: address.country,
          lat: address.lat,
          lon: address.lon,
          aptSuite: address.aptSuite,
        };
      }

      const response = await INC_BASE_API.put(`/orders`, {
        clientId: clientSelectors.selectClientId(getState()),
        locationId: location.locationId,
        orderSource: 'WEB',
        orderType,
        requestedFulfillTime: time.dateString,
        delivery,
        languageCode: locale.toUpperCase(),
      });

      dispatch(
        newOrderSuccess({
          orderId: response.data.orderId,
          requestedFulfillTime: time.dateString || time.key,
          ...{ ...data, dayTimeSkip: undefined, time: null }, // prevent non-serializable state
        }),
      );

      dispatch(resetRecommendations());

      return response.data;
    } catch (error) {
      dispatch(newOrderFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const rescheduleOrder = (formData, body) => {
  return async (dispatch, getState) => {
    try {
      const { time } = formData;
      const orderId = selectOrderId(getState());
      dispatch(updateOrderStart());

      const response = await INC_BASE_API.patch(`/orders/${orderId}`, body);

      await dispatch(getOrderByIdStartAsync());

      dispatch(
        updateOrderSuccess({
          requestedFulfillTime: time.dateString || time.key,
          ...{ ...formData, time: null }, // prevent non-serializable state
        }),
      );

      return response.data;
    } catch (error) {
      const errorMessage = getResponseErrorMessage(error, dispatch);
      toast.error(errorMessage);
      dispatch(updateOrderFailure(errorMessage));
      return null;
    }
  };
};

export const addOrderItemStartAsync = (
  catalogId,
  groupId,
  { itemId, title },
  itemInstructions,
  options,
  quantity,
  recommendationView,
  path,
) => {
  return async (dispatch, getState) => {
    try {
      const orderId = selectOrderId(getState());
      const clientId = clientSelectors.selectClientId(getState());
      const recommendationsEnabled = selectRecommendationsEnabled(getState());
      const isUserSeenRecommendationItems = selectIsUserSeenRecommendationItems(
        getState(),
      );
      const allRecommendedItemIds = selectAllRecommendedItemIds(getState());
      const isItemRecommended = allRecommendedItemIds.includes(itemId);

      dispatch(addOrderItemStart(itemId));

      const response = await INC_BASE_API.put(
        `/orders/${orderId}/orderitems`,
        {
          catalogId,
          groupId,
          itemId,
          itemInstructions,
          options,
          quantity,
          additionalAttributes: {
            recommendation_status: getRecommendationStatus(
              recommendationsEnabled,
              isUserSeenRecommendationItems,
              recommendationView,
              isItemRecommended,
            ),
          },
        },
        {
          params: {
            languageCode: getLanguageKey().toUpperCase(),
          },
          headers: {
            CLIENTID: clientId,
          },
        },
      );
      dispatch(addOrderItemSuccess(response.data, path));
      const orderItem = response.data.orderItems.find(
        item => item.itemId === itemId,
      );

      if (recommendationView) {
        getAnalytics().trackRecommendationAdded(orderItem);
        dispatch(setRecommendationAddedOrderItemIds(itemId));
        toast.success(i18next.t('recommendations.toastSuccess'));
      }
      getAnalytics().trackProductAdded(orderItem);

      return response;
    } catch (error) {
      if (getResponseErrorCode(error) === 'NOT_FOUND') {
        toast.error(i18next.t('errors.itemNotFound', { title }));
      } else {
        toast.error(getResponseErrorMessage(error, dispatch));
      }

      dispatch(addOrderItemFailure(itemId));
      handleOutOfStockRequest(error, dispatch);

      return getResponseErrorCode(error);
    }
  };
};

export const updateOrderItemStartAsync = orderItem => {
  return async (dispatch, getState) => {
    const orderId = selectOrderId(getState());
    try {
      dispatch(modifyOrderItemsStart(orderItem.orderItemId));
      const response = await INC_BASE_API.put(
        `/orders/${orderId}/updateorderitems`,
        {
          orderItems: [orderItem],
        },
      );

      dispatch(modifyOrderItemsSuccess(response.data, orderItem.orderItemId));
      getAnalytics().trackProductUpdated(orderItem);
      return response;
    } catch (error) {
      const errorCode = getResponseErrorCode(error, dispatch);
      if (errorCode === 'NOT_FOUND') {
        await dispatch(
          removeOrderItemStartAsync(
            orderId,
            orderItem.orderItemId,
            orderItem.itemId,
          ),
        );
        const { title } = orderItem?.displayInfo;
        toast.error(i18next.t('errors.itemNotFoundRemoved', { title }));
      } else {
        toast.error(getResponseErrorMessage(error, dispatch));
        dispatch(modifyOrderItemsFailure(orderItem.orderItemId));
        handleOutOfStockRequest(error, dispatch);
      }
    }
  };
};

export const getOrderByIdStartAsync = () => {
  return (dispatch, getState) => {
    const orderId = selectOrderId(getState());
    dispatch(getOrderByIdStart());
    return INC_BASE_API.get(`/orders/${orderId}`)
      .then(response => {
        dispatch(getOrderByIdSuccess(response.data));
        if (selectOrderItemIdMismatch(getState())) {
          dispatch(setDefaultCartOrderItemState());
        }
        return response.data;
      })
      .catch(error => {
        const errorMessage = getResponseErrorMessage(error, dispatch);
        toast.error(errorMessage);
        dispatch(getOrderByIdFailure(errorMessage));
        return null;
      });
  };
};

export const resetCart = () => {
  return dispatch => {
    dispatch(clearCart());
  };
};

export const removeOrderItemStartAsync = (orderId, orderItemId, itemId) => {
  return async (dispatch, getState) => {
    try {
      const recommendationAccepted = selectRecommendationAccepted(getState());
      const recommendationAddedOrderItemIds =
        selectRecommendationAddedOrderItemIds(getState());

      dispatch(modifyOrderItemsStart(orderItemId));
      const orderItems = selectOrderItems(getState());
      const orderItem = orderItems.find(item => item.itemId === itemId);

      const response = await INC_BASE_API.delete(
        `/orders/${orderId}/orderitems/${orderItemId}`,
      );
      dispatch(modifyOrderItemsSuccess(response.data, orderItemId));

      if (recommendationAccepted) {
        const index = recommendationAddedOrderItemIds.indexOf(itemId);
        if (index > -1) dispatch(removeRecommendationAddedOrderItemId(index));
      }

      if (recommendationAddedOrderItemIds.includes(itemId)) {
        getAnalytics().trackRecommendationRemoved(orderItem);
      }
      getAnalytics().trackProductDeleted(orderItem);

      return response.data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error, dispatch));
      dispatch(modifyOrderItemsFailure(orderItemId));
    }
  };
};

export const getOrderOffers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(getOrderOffersStart());
      const orderId = selectOrderId(getState());
      const orderItems = selectOrderItems(getState());
      const orderOption = selectOrderOption(getState());
      const locationId = selectLocationId(getState());
      const formattedOrderItems = orderItems.map(item => ({
        ...item,
        groupId: item.group.groupId,
        options: addGroupIdToOptions(item.options),
      }));
      const { latitude, longitude } = appSelectors.selectUserLatLon(getState());

      const response = await INC_BASE_API.post(
        `/orderoffers`,
        {
          orderId,
          orderOptionType: orderOption?.optionName,
          languageCode: getLanguageKey().toUpperCase(),
          latitude: latitude ?? 0,
          localDateTime: toISOStringLocal(new Date()),
          longitude: longitude ?? 0,
          orderItems: formattedOrderItems,
          locationId,
        },
        { authenticated: true },
      );

      dispatch(getOrderOffersSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getOrderOffersFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const copyOrder = (orderId, setSelectedLocation) => {
  return async (dispatch, getState) => {
    try {
      dispatch(copyOrderStart());
      const response = await INC_BASE_API.post(
        `/orders/${orderId}/copyorder`,
        {},
        { authenticated: true },
      );

      const { locationId } = response.data;

      const location = selectStoreById(locationId)(getState());

      if (!location) {
        dispatch(copyOrderFailure(i18next.t('errors.locationNotAvailable')));
        return null;
      }

      dispatch(resetCart());
      setSelectedLocation(location);
      dispatch(setOrderType(response.data.orderType));
      dispatch(copyOrderSuccess());
      return response.data;
    } catch (error) {
      dispatch(copyOrderFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const addOrderItemsForReorder = requestedOrderItems => {
  return async (dispatch, getState) => {
    try {
      const orderId = selectOrderId(getState());
      const isRecommendationsEnabled = selectRecommendationsEnabled(getState());
      dispatch(addOrderItemsStart());
      let orderItems = assignGroupIds(requestedOrderItems);
      orderItems = orderItems.map(item => ({
        ...item,
        additionalAttributes: {
          recommendation_status: isRecommendationsEnabled ? 'PRE' : undefined,
        },
      }));

      const res = await INC_BASE_API.put(
        `/orders/${orderId}/addorderitems?languageCode=${getLanguageKey().toUpperCase()}`,
        { orderItems },
        { authenticated: true },
      );
      dispatch(addOrderItemsSuccess(res.data));
      getAnalytics().trackReorder(
        selectOrder(getState()),
        selectRequestedStoreDITitle(getState()),
      );
      return res.data;
    } catch (error) {
      dispatch(addOrderItemsFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const applyDiscount = (
  distributedOfferId,
  offerId,
  pointsToSpend,
  title,
) => {
  return async (dispatch, getState) => {
    const orderId = selectOrderId(getState());
    const orderOption = selectOrderOption(getState());
    const isLoggedIn = selectIsLoggedIn(getState());

    try {
      await dispatch(removeAllDiscounts());
      dispatch(applyDiscountStart());

      const storeTimeZone = selectAvailableTimesTZ(getState());
      const localDateTime = DateTime.local({ zone: storeTimeZone }).toFormat(
        "yyyy-MM-dd'T'HH:mm:ss",
      );
      const { latitude, longitude } = appSelectors.selectUserLatLon(getState());

      const data = {
        distributedOfferId,
        offerId,
        pointsToSpend: pointsToSpend ? pointsToSpend : undefined,
        languageCode: getLanguageKey().toUpperCase(),
        localDateTime,
        latitude: latitude ?? 0,
        longitude: longitude ?? 0,
        orderOptionType: orderOption?.optionName,
      };

      const response = await INC_BASE_API.post(
        `/orders/${orderId}/applydiscount`,
        data,
        { authenticated: isLoggedIn },
      );
      dispatch(applyDiscountSuccess(response.data));

      getAnalytics().trackCouponApplied({
        orderId,
        offerId,
        discount: response.data.orderTotal.totalDiscountApplied,
        title,
      });

      return response;
    } catch (error) {
      const errorMessage = getResponseErrorMessage(error, dispatch);
      dispatch(applyDiscountFailure());
      getAnalytics().trackCouponDenied({
        orderId,
        offerId,
        title,
        reason: errorMessage,
      });

      return errorMessage;
    }
  };
};

export const removeAllDiscounts = () => {
  return async (dispatch, getState) => {
    const discounts = selectOrderAndItemDiscounts(getState());

    if (discounts?.length < 1) return;

    return Promise.all(
      discounts.map(discount =>
        dispatch(
          removeDiscount(
            discount.discountId,
            discount.description,
            discount.appliedDiscount,
          ),
        ),
      ),
    );
  };
};

export const removeDiscount = (offerId, title, discount) => {
  return async (dispatch, getState) => {
    const isLoggedIn = selectIsLoggedIn(getState());

    if (!offerId) return null;

    dispatch(removeDiscountStart());
    try {
      const orderId = selectOrderId(getState());
      const response = await INC_BASE_API.post(
        `/orders/${orderId}/removediscount`,
        { offerId },
        { authenticated: isLoggedIn },
      );
      await dispatch(getOrderByIdStartAsync());

      dispatch(removeDiscountSuccess());
      getAnalytics().trackCouponRemoved({
        orderId,
        offerId,
        title,
        discount: parseFloat(discount),
      });

      return response.data;
    } catch (error) {
      const errorMessage = getResponseErrorMessage(error, dispatch);
      toast.error(errorMessage);
      dispatch(removeDiscountFailure(errorMessage));
      return null;
    }
  };
};

const handleOutOfStockRequest = (error, dispatch) => {
  if (
    ['ITEM_OUT_OF_STOCK', 'MODIFIER_OUT_OF_STOCK'].includes(
      getResponseErrorCode(error),
    )
  ) {
    dispatch(fetchOutOfStockItems());
  }
};

export const makeDefaultOrder = (urlOrderType, urlStoreSlug) => {
  return async (dispatch, getState) => {
    const store = makeSelectStoreBySlug(urlStoreSlug)(getState());

    if (
      !store ||
      (!store.pickupOrdersAccepted && !store.deliveryOrdersAccepted) ||
      store.externalOrderingEnabled
    ) {
      throw new Error();
    }

    const address = selectors.selectUserPrefferedAddress(getState());
    const reduxOrderType = selectOrderType(getState());
    let orderType = urlOrderType ?? reduxOrderType ?? 'PICKUP';

    await dispatch(
      orderAvailabilityActions.fetchOrderAvailability({
        storeId: store?.storeId,
        address,
        requestedOrderType: orderType,
      }),
    );

    const withinDeliveryDistance = selectWithinDeliveryDistance(
      getState(),
      store.storeId,
    );
    const reduxOrderAvailabilityError = selectOrderAvailabilityError(
      getState(),
    );
    const pickupAvailableDays = selectAvailableDays(
      getState(),
      store.storeId,
      'PICKUP',
    );
    const deliveryAvailableDays = selectAvailableDays(
      getState(),
      store.storeId,
      'DELIVERY',
    );

    const pickupAvailabilityError = getOrderAvailabilityError(
      'PICKUP',
      address,
      withinDeliveryDistance,
      reduxOrderAvailabilityError,
      pickupAvailableDays,
    );
    const deliveryAvailabilityError = getOrderAvailabilityError(
      'DELIVERY',
      address,
      withinDeliveryDistance,
      reduxOrderAvailabilityError,
      deliveryAvailableDays,
    );

    if (orderType === 'PICKUP' && pickupAvailabilityError) {
      orderType = 'DELIVERY';
    }
    if (orderType === 'DELIVERY' && deliveryAvailabilityError) {
      throw new Error();
    }

    const time = selectDefaultAvailabilityObject(
      getState(),
      store?.storeId,
      orderType,
    );

    const newOrderResult = await dispatch(
      newOrder({
        orderType,
        time,
        location: { locationId: store.storeId, slug: urlStoreSlug },
        address,
      }),
    );

    if (!newOrderResult) throw new Error();
  };
};

export const setPaymentInstrumentId = paymentInstrumentId => ({
  type: CartActionTypes.SET_PAYMENT_INSTRUMENT_ID,
  payload: paymentInstrumentId,
});

export const getOrderItemPrice = orderItem => {
  return async (_, getState) => {
    try {
      const orderId = selectOrderId(getState());
      const response = await INC_BASE_API.post(
        `/orders/${orderId}/orderitemprice`,
        orderItem,
      );

      return response?.data?.subtotal;
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
    }
  };
};

export const setPaymentOption = paymentOption => ({
  type: CartActionTypes.SET_PAYMENT_OPTION,
  payload: paymentOption,
});
