import { DateTime } from 'luxon';
import { store } from '../redux/store';
import LogRocket from './getLogRocket';
import location from './location';
import { segment } from './segment';
import { selectors as clientSelectors } from 'redux/client/client.slice';
import { analyticsEnabledByEnv } from './analyticsEnabledByEnv';
import { heap } from './heap';

/**
 * This is a wrapper around our chosen analytics platform in case we want to
 * swap it out for something else later on. It also allows for a much cleaner
 * impelementation in the areas of the code where we actually trigger these
 * events.
 */
export const incAnalytics = {
  identify: async userData => {
    const data = identifyTransform(userData);
    segment.identify(userData?.userId, data);
    LogRocket.identify(userData?.userId, data);
    await heap.identify(userData?.userId);
    heap.addUserProperties(data);
  },
  identifyGuest: async traits => {
    segment.identify(traits);
    LogRocket.identify(traits?.email, traits);
    await heap.identify(traits?.email);
    heap.addUserProperties(traits);
  },
  page: (...args) => segment.page(...args),

  clientGroup: (clientId, clientAlias) =>
    segment.group(clientId, {
      name: clientAlias,
      description: 'Incentivio Client Mapping',
    }),

  webGroup: () =>
    segment.group('WEB', {
      name: 'Web Group',
      description: 'Users that have visited the web ordering app',
    }),

  trackSignIn: properties => {
    segment.track('Signed In', properties, userContext());
    heap.track('Signed In', properties, userContext());
  },
  trackSignOut: properties => {
    segment.track('Signed Out', properties, userContext());
    heap.track('Signed Out', properties, userContext());
  },
  trackSignUp: properties => {
    segment.track('Signed Up', properties, userContext());
    heap.track('Signed Up', properties, userContext());
  },

  trackOrderCompleted: (order, storeTitle) => {
    const data = {
      order_id: order?.orderId,
      affiliation: storeTitle,
      subtotal: order?.orderTotal.subtotal / 1000,
      total: order?.orderTotal.total / 1000,
      revenue: order?.orderTotal.subtotal / 1000,
      shipping: order?.orderTotal.chargeSummary.totalCharges / 1000,
      tax: order?.orderTotal.taxSummary.totalTaxes / 1000,
      discount: order?.orderTotal.totalDiscountApplied / 1000,
      coupon: order?.orderDiscounts?.[0]?.description,
      currency: 'USD',
      products: order?.orderItems?.map(orderItem => itemToProduct(orderItem)),
    };
    segment.track('Order Completed', data);
    heap.track('Order Completed', data);
  },

  trackProductAdded: orderItem => {
    segment.track('Product Added', itemToProduct(orderItem));
    heap.track('Product Added', itemToProduct(orderItem));
  },

  trackProductUpdated: orderItem => {
    segment.track('Product Updated', itemToProduct(orderItem));
    heap.track('Product Updated', itemToProduct(orderItem));
  },

  trackProductDeleted: orderItem => {
    segment.track('Product Removed', itemToProduct(orderItem));
    heap.track('Product Removed', itemToProduct(orderItem));
  },

  trackProductViewed: item => {
    const data = {
      price: item?.price / 1000,
      url: location.getLocationHref(),
    };
    segment.track('Product Viewed', itemToProduct(item, data));
    heap.track('Product Viewed', itemToProduct(item, data));
  },

  trackRecommendationAdded: orderItem => {
    segment.track('Recommendation Added', itemToProduct(orderItem));
    heap.track('Recommendation Added', itemToProduct(orderItem));
  },

  trackRecommendationRemoved: orderItem => {
    heap.track('Recommendation Removed', itemToProduct(orderItem));
    segment.track('Recommendation Removed', itemToProduct(orderItem));
  },

  trackPaymentInfoEntered: payInfo => {
    const data = {
      order_id: payInfo?.orderId,
      step: undefined,
      shipping_method: undefined,
      payment_method: undefined,
    };
    segment.track('Payment Info Entered', data);
    heap.track('Payment Info Entered', data);
  },

  trackCartViewed: orderItems => {
    const data = {
      products: orderItems?.map(orderItem => itemToProduct(orderItem)),
    };
    segment.track('Cart Viewed', data);
    heap.track('Cart Viewed', data);
  },

  trackRecommendationsViewed: orderItems => {
    const data = {
      products: orderItems?.map(orderItem =>
        itemToProduct(orderItem, { price: orderItem?.price / 1000 }),
      ),
    };
    segment.track('Recommendations Viewed', data);
    heap.track('Recommendations Viewed', data);
  },

  trackCouponEntered: (data = {}) => {
    const couponData = {
      order_id: data.orderId,
      coupon_id: data.promoCode,
    };
    segment.track('Coupon Entered', couponData);
    heap.track('Coupon Entered', couponData);
  },

  trackCouponApplied: (data = {}) => {
    const couponData = {
      order_id: data.orderId,
      coupon_id: data.offerId,
      coupon_name: data.title,
      discount: data.discount / 1000,
    };
    segment.track('Coupon Applied', couponData);
    heap.track('Coupon Applied', couponData);
  },

  trackCouponDenied: (data = {}) => {
    const couponData = {
      order_id: data.orderId,
      coupon_id: data.offerId,
      coupon_name: data.title,
      reason: data.reason,
    };
    segment.track('Coupon Denied', couponData);
    heap.track('Coupon Denied', couponData);
  },

  trackCouponRemoved: (data = {}) => {
    const couponData = {
      order_id: data.orderId,
      coupon_id: data.offerId,
      coupon_name: data.title,
      discount: data.discount,
    };
    segment.track('Coupon Removed', couponData);
    heap.track('Coupon Removed', couponData);
  },

  trackTipApplied: (data = {}) => {
    const couponData = {
      tip: data.tip,
      currency: 'USD',
    };
    segment.track('Tip Applied', couponData);
    heap.track('Tip Applied', couponData);
  },

  trackCheckoutStarted: (order, storeTitle) => {
    const data = {
      order_id: order?.orderId,
      affiliation: storeTitle,
      subtotal: order?.orderTotal?.subtotal / 1000,
      total: order?.orderTotal?.total / 1000,
      revenue: order?.orderTotal?.subtotal / 1000,
      shipping: order?.orderTotal?.chargeSummary?.totalCharges / 1000,
      tax: order?.orderTotal?.taxSummary?.totalTaxes / 1000,
      discount: order?.orderTotal?.totalDiscountApplied / 1000,
      coupon: order?.orderDiscounts?.[0]?.description,
      currency: 'USD',
      products: order?.orderItems?.map(orderItem => itemToProduct(orderItem)),
    };
    segment.track('Checkout Started', data);
    heap.track('Checkout Started', data);
  },

  trackReorder: (order, storeTitle) => {
    const data = {
      order_id: order?.orderId,
      affiliation: storeTitle,
      subtotal: order?.orderTotal?.subtotal / 1000,
      total: order?.orderTotal?.total / 1000,
      revenue: order?.orderTotal?.subtotal / 1000,
      shipping: order?.orderTotal?.chargeSummary?.totalCharges / 1000,
      tax: order?.orderTotal?.taxSummary?.totalTaxes / 1000,
      discount: order?.orderTotal?.totalDiscountApplied / 1000,
      coupon: order?.orderDiscounts?.[0]?.description,
      currency: 'USD',
      products: order?.orderItems?.map(orderItem => itemToProduct(orderItem)),
    };
    segment.track('Reorder', data);
    heap.track('Reorder', data);
  },
};

export const identifyTransform = (userData = {}) => ({
  address: userData.address,
  birthday: DateTime.fromISO(userData.dateOfBirth).toISO(),
  email: userData.email,
  firstName: userData.firstName,
  id: userData.userId,
  lastName: userData.lastName,
  phone: userData.phoneNumber,
  gender: userData.gender,
  avatar: userData.userProfileImageUrl,
  clientId: userData.clientId,
  externalId: userData.externalId,
  howAcquired: userData.howAcquired,
});

const itemToProduct = (item = {}, overrides = {}) => {
  const displayInfo = getDisplayInfo(item);
  const coupon = getItemDiscount(item);

  return {
    product_id: item?.itemId,
    category: item?.group?.groupDisplayName ?? item?.group?.groupName,
    name:
      displayInfo.title ??
      item?.displayInfoTitle ??
      item?.itemName ??
      item?.title,
    price: getItemPrice(item),
    quantity: item.quantity || 1,
    image_url: displayInfo.mediumImage?.[0],
    coupon,
    ...overrides,
  };
};

const getItemPrice = item =>
  item?.itemTotal?.total / 1000 || item?.unitPrice / 1000 || 0;

export const getItemDiscount = item => {
  if (item.itemDiscounts?.length) {
    return item.itemDiscounts[0]?.description;
  } else if (!!item.options?.length) {
    for (let option of item.options) {
      const discount = getItemDiscount(option);
      if (!!discount) {
        return discount;
      }
    }
  }
};

const getDisplayInfo = (item = {}) => {
  if (!!item.displayInfo && item.displayInfo.title) return item.displayInfo;

  if (
    !item.displayInfo ||
    !Array.isArray(item.displayInfo) ||
    item.displayInfo.length < 1
  ) {
    return {};
  }

  // The ?? {} might be redundant with the previous conditional, but I'm not
  // sure. So, I'm keeping it.
  return item?.displayInfo?.[0] ?? {};
};

const userContext = () => ({
  context: {
    groupId: clientSelectors.selectClientId(store.getState()),
  },
});

const fakeAnalytics = {
  ...Object.keys(incAnalytics).reduce((result, key) => {
    result[key] = () => null;
    return result;
  }, {}),
  // We still want logrocket events tracked
  identify: userData => {
    LogRocket.identify(userData?.userId, identifyTransform(userData));
  },
  identifyGuest: traits => {
    LogRocket.identify(traits?.email, traits);
  },
};

const makeWrapper = wrappee => {
  return (...args) => {
    try {
      return wrappee(...args);
    } catch (error) {
      LogRocket.captureException(error);
    }
  };
};

// This prevents any of the analytics methods from throwing an error that will
// crash the app. We will report this to logrocket if it happens.
const analyticsProxy = new Proxy(incAnalytics, {
  get: function (obj, prop) {
    const result = obj[prop];

    if (result instanceof Function) {
      return makeWrapper(result);
    } else {
      // Method does not exist on incAnalytics. Return a method that logs the
      // issue with LogRocket
      return () => {
        LogRocket.captureException(
          new Error(`Method ${prop} does not exist on incAnalytics`),
        );
      };
    }
  },
});

export const getAnalytics = () =>
  analyticsEnabledByEnv() ? analyticsProxy : fakeAnalytics;
