import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ItemName = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 0.5em;
`;

export const ItemDescription = styled(Typography)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text.primaryLight};
  margin-bottom: 2em;
`;
