import React from 'react';
import { BrowserRouter, useRouteMatch } from 'react-router-dom';
import PreClient from './pre-client.component';

const StaticClientContainer = () => {
  const testMatch = useRouteMatch('/test');

  return (
    <BrowserRouter basename={!!testMatch ? '/test' : null}>
      <PreClient />
    </BrowserRouter>
  );
};

export default StaticClientContainer;
