import OrderPaymentSelectorWrapper from 'components/payment/payment-selector/order-payment-selector/order-payment-selector-wrapper/order-payment-selector-wrapper.component';
import CheckoutSectionBase from '../checkout-section/checkout-section-base.component';
import { useCheckoutPaymentSectionNewComponent } from './checkout-payment-section-new.hooks';

const CheckoutPaymentSectionNew = ({ ...rest }) => {
  const { checkoutSectionContext } = useCheckoutPaymentSectionNewComponent();

  return (
    <CheckoutSectionBase
      context={checkoutSectionContext}
      data-testid="CheckoutPaymentSection.CheckoutSectionBase"
      {...rest}
    >
      <OrderPaymentSelectorWrapper />
    </CheckoutSectionBase>
  );
};
export default CheckoutPaymentSectionNew;
