import { createSelector } from 'reselect';

export const selectRecommendations = state => state.recommendations;

export const selectRecommendationData = createSelector(
  selectRecommendations,
  recommendations => recommendations.recommendationData,
);

export const selectRecommendationCode = createSelector(
  selectRecommendationData,
  recommendationData => recommendationData?.code ?? [],
);

export const selectRecommendationDetails = createSelector(
  selectRecommendationData,
  recommendationData => recommendationData?.recommendationDetails ?? [],
);

export const selectAllRecommendedItemIds = createSelector(
  selectRecommendationDetails,
  recommendationDetails => recommendationDetails.map(detail => detail.objId),
);

export const selectRecommendationAddedOrderItemIds = createSelector(
  selectRecommendations,
  recommendations => recommendations.recommendationAddedOrderItemIds ?? [],
);

export const selectRecommendationAccepted = createSelector(
  selectRecommendationAddedOrderItemIds,
  recommendationAddedOrderItemIds => recommendationAddedOrderItemIds.length > 0,
);

export const selectIsUserSeenRecommendationItems = createSelector(
  selectAllRecommendedItemIds,
  recommendationItemIds => recommendationItemIds.length > 0,
);
