import { selectData } from '../data.selectors';
import { createCachedSelector } from 're-reselect';
import { selectRequestedFulfillTime } from 'redux/cart/cart.selectors';
import { makeAvailabilityObject } from 'redux/cart/cart.utils';
import { createSelector } from 'reselect';
import { selectIsFutureDates } from 'redux/config/config.selectors';
import { DateTime } from 'luxon';
import { keySelectorCombiner } from 'redux/redux.utils';
import i18n from 'i18n';
import { PICKUP } from 'util/constants';
import { selectDisplayTimeZone } from 'redux/locations/locations.selectors';
import {
  orderTypePropSelector,
  storeIdPropSelector,
} from 'redux/propSelectors/propSelectors';

export const selectOrderAvailability = createSelector(
  selectData,
  data => data.orderavailability,
);

export const selectIsOrderAvailabilityLoading = createSelector(
  selectOrderAvailability,
  orderAvailability => orderAvailability.loading,
);

export const selectOrderAvailabilityError = createSelector(
  selectOrderAvailability,
  orderAvailability => orderAvailability.error?.errorMessage,
);

const dayPropSelector = (_, _storeId, _orderType, day) => day;

export const selectStoreAvailability = createCachedSelector(
  selectOrderAvailability,
  storeIdPropSelector,
  (orderAvailability, storeId) => orderAvailability?.entities?.[storeId] ?? {},
)({ keySelectorCreator: keySelectorCombiner });

export const selectDelayedMessage = createCachedSelector(
  selectStoreAvailability,
  orderTypePropSelector,
  (storeAvailability, orderType) => {
    if (orderType === 'PICKUP') return storeAvailability?.pickupDelayedMessage;
    return storeAvailability?.deliveryDelayedMessage;
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsDeliveryAvailable = createCachedSelector(
  selectStoreAvailability,
  storeAvailability =>
    storeAvailability.deliveryAvailable
      ? storeAvailability.deliveryAvailable
      : null,
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsPickupAvailable = createCachedSelector(
  selectStoreAvailability,
  storeAvailability =>
    storeAvailability.pickupAvailable
      ? storeAvailability.pickupAvailable
      : null,
)({ keySelectorCreator: keySelectorCombiner });

export const selectPickupAvailableTimes = createCachedSelector(
  selectStoreAvailability,
  availability => availability.pickupTimeSlots ?? [],
)({ keySelectorCreator: keySelectorCombiner });

export const selectDeliveryAvailableTimes = createCachedSelector(
  selectStoreAvailability,
  availability => availability.deliveryTimeSlots ?? [],
)({ keySelectorCreator: keySelectorCombiner });

export const selectPickupOffDays = createCachedSelector(
  selectStoreAvailability,
  availability => availability.pickupOffDays ?? [],
)({ keySelectorCreator: keySelectorCombiner });

export const selectDeliveryOffDays = createCachedSelector(
  selectStoreAvailability,
  availability => availability.deliveryOffDays ?? [],
)({ keySelectorCreator: keySelectorCombiner });

export const selectAvailableTimesByType = createCachedSelector(
  selectPickupAvailableTimes,
  selectDeliveryAvailableTimes,
  orderTypePropSelector,
  (pickupTimeSlots, deliveryTimeSlots, orderType) => {
    if (orderType === 'PICKUP') return pickupTimeSlots;
    return deliveryTimeSlots;
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectOrderingOffDays = createCachedSelector(
  selectPickupOffDays,
  selectDeliveryOffDays,
  orderTypePropSelector,
  (pickupOffDays, deliveryOffDays, orderType) => {
    if (orderType === PICKUP) return pickupOffDays;
    return deliveryOffDays;
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectAvailableTimesTZ = createCachedSelector(
  selectStoreAvailability,
  availability => availability?.timeZone,
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsAsapEnabled = createCachedSelector(
  selectStoreAvailability,
  orderTypePropSelector,
  (storeAvailability, orderType) =>
    orderType === 'PICKUP'
      ? storeAvailability.pickupAsapAvailable
      : storeAvailability.deliveryAsapAvailable,
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsOrderTypeAvailable = createCachedSelector(
  selectIsPickupAvailable,
  selectIsDeliveryAvailable,
  orderTypePropSelector,
  (isPickupAvailable, isDeliveryAvailable, orderType) =>
    orderType === 'PICKUP' ? isPickupAvailable : isDeliveryAvailable,
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsAsapOnly = createCachedSelector(
  selectIsAsapEnabled,
  selectAvailableTimesByType,
  selectIsOrderTypeAvailable,
  (isAsapEnabled, availableTimes, isOrderTypeAvailable) =>
    (!availableTimes || Object.keys(availableTimes).length === 0) &&
    isAsapEnabled &&
    isOrderTypeAvailable,
)({ keySelectorCreator: keySelectorCombiner });

export const selectPickupAsapDuration = createCachedSelector(
  selectStoreAvailability,
  storeAvailability => storeAvailability?.pickupAsapDuration,
)({ keySelectorCreator: keySelectorCombiner });

export const selectDeliveryAsapDuration = createCachedSelector(
  selectStoreAvailability,
  storeAvailability => storeAvailability?.deliveryAsapDuration,
)({ keySelectorCreator: keySelectorCombiner });

export const selectAsapDuration = createCachedSelector(
  selectPickupAsapDuration,
  selectDeliveryAsapDuration,
  orderTypePropSelector,
  (pickupAsapDuration, deliveryAsapDuration, orderType) =>
    orderType === 'PICKUP' ? pickupAsapDuration : deliveryAsapDuration,
)({ keySelectorCreator: keySelectorCombiner });

export const selectFlatAvailableTimes = createCachedSelector(
  selectAvailableTimesByType,
  selectAvailableTimesTZ,
  selectDisplayTimeZone,
  (availableTimes, storeZone, displayZone) =>
    Object.entries(availableTimes).reduce(
      (result, [dayString, times]) =>
        result.concat(
          times.map(({ time, valid }) =>
            makeAvailabilityObject(
              `${dayString}T${time}`,
              storeZone,
              displayZone,
              undefined,
              undefined,
              valid,
            ),
          ),
        ),
      [],
    ),
)({ keySelectorCreator: keySelectorCombiner });

export const selectGroupedAvailabilityObjects = createCachedSelector(
  selectFlatAvailableTimes,
  availableTimes => {
    return availableTimes.reduce((result, availabilityObject) => {
      const dayLabel = availabilityObject.dayLabel;
      const isoDateString = availabilityObject.displayDateTime.toISODate();
      const existingObject = result.find(
        groupObject => groupObject.isoDateString === isoDateString,
      );

      if (existingObject) {
        existingObject.availabilityObjects.push(availabilityObject);
      } else {
        result.push({
          dayLabel,
          isoDateString,
          availabilityObjects: [availabilityObject],
        });
      }

      return result;
    }, []);
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectAvailableDays = createCachedSelector(
  selectGroupedAvailabilityObjects,
  selectIsFutureDates,
  selectIsAsapOnly,
  selectDisplayTimeZone,
  (groupedAvailabilityObjects, isFutureDates, isAsapOnly, displayTimeZone) => {
    const groupedAvailabilityObjectsWithValidTimes =
      groupedAvailabilityObjects.filter(ao =>
        ao.availabilityObjects.some(timeslotAo => !!timeslotAo.valid),
      );
    const todayIsoDate = DateTime.local({
      zone: displayTimeZone,
    }).toISODate();

    if (isAsapOnly) return [todayIsoDate];
    else if (!groupedAvailabilityObjects) return [];
    else if (isFutureDates)
      return groupedAvailabilityObjectsWithValidTimes.map(
        ao => ao.isoDateString,
      );
    else if (
      groupedAvailabilityObjectsWithValidTimes?.[0]?.isoDateString ===
      todayIsoDate
    ) {
      return [todayIsoDate];
    }
    return [];
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectFirstAvailableTimeString = createCachedSelector(
  selectStoreAvailability,
  orderTypePropSelector,
  (availability, orderType) => {
    if (orderType === 'PICKUP') return availability.firstAvailablePickupTime;
    return availability.firstAvailableDeliveryTime;
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectFirstAvailableAvailabilityObject = createCachedSelector(
  selectStoreAvailability,
  selectAvailableTimesTZ,
  selectDisplayTimeZone,
  orderTypePropSelector,
  (availability, storeZone, displayZone, orderType) =>
    orderType === 'PICKUP'
      ? makeAvailabilityObject(
          availability?.firstAvailablePickupTime,
          storeZone,
          displayZone,
        )
      : makeAvailabilityObject(
          availability?.firstAvailableDeliveryTime,
          storeZone,
          displayZone,
        ),
)({ keySelectorCreator: keySelectorCombiner });

export const selectIsAsapPossible = createCachedSelector(
  selectFirstAvailableAvailabilityObject,
  selectIsAsapEnabled,
  selectDisplayTimeZone,
  selectIsAsapOnly,
  (firstAvailable, isAsapEnabled, displayZone, isAsapOnly) => {
    return (
      (isAsapEnabled &&
        firstAvailable?.displayDateTime.day ===
          DateTime.local({ zone: displayZone }).day) ||
      isAsapOnly
    );
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectAvailableTimes = createCachedSelector(
  selectIsAsapPossible,
  selectGroupedAvailabilityObjects,
  selectAvailableTimesTZ,
  selectDisplayTimeZone,
  selectIsAsapOnly,
  dayPropSelector,
  selectAsapDuration,
  selectFirstAvailableTimeString,
  (
    isAsapPossible,
    availabilityObjects,
    storeZone,
    displayZone,
    isAsapOnly,
    isoDateString,
    asapDuration,
    firstAvailableTimeString,
  ) => {
    let aoGroup = availabilityObjects.find(
      ag => ag.isoDateString === isoDateString,
    );
    let dayAvailabilityObjects = aoGroup ? aoGroup.availabilityObjects : [];
    if (
      isAsapOnly ||
      (aoGroup?.dayLabel === i18n.t('locations.card.today') && isAsapPossible)
    ) {
      dayAvailabilityObjects = [
        makeAvailabilityObject(
          'asap',
          storeZone,
          displayZone,
          asapDuration,
          firstAvailableTimeString,
        ),
        ...dayAvailabilityObjects,
      ];
    }

    return dayAvailabilityObjects;
  },
)({ keySelectorCreator: keySelectorCombiner });

export const selectWithinDeliveryDistance = createCachedSelector(
  selectStoreAvailability,
  storeAvailability => storeAvailability.withinDeliveryDistance,
)({ keySelectorCreator: keySelectorCombiner });

export const selectStoreAvailabilityExists = createCachedSelector(
  selectOrderAvailability,
  storeIdPropSelector,
  (orderAvailability, storeId) => !!orderAvailability?.entities?.[storeId],
)((_state, storeId) => storeId ?? '');

export const selectRequestedFulfillTimeAvailabilityObject =
  createCachedSelector(
    selectAvailableTimesTZ,
    selectDisplayTimeZone,
    selectRequestedFulfillTime,
    selectAsapDuration,
    selectFirstAvailableTimeString,
    (storeZone, displayZone, rft, asapDuration, firstAvailableTimeString) =>
      rft &&
      storeZone &&
      displayZone &&
      makeAvailabilityObject(
        rft,
        storeZone,
        displayZone,
        asapDuration,
        firstAvailableTimeString,
      ),
  )({ keySelectorCreator: keySelectorCombiner });

export const selectAsapAvailabilityObject = createCachedSelector(
  selectAvailableTimesTZ,
  selectDisplayTimeZone,
  (storeZone, displayZone) =>
    makeAvailabilityObject('asap', storeZone, displayZone),
)({ keySelectorCreator: keySelectorCombiner });

export const selectDefaultAvailabilityObject = createCachedSelector(
  selectFlatAvailableTimes,
  selectIsAsapPossible,
  selectIsAsapOnly,
  selectAsapAvailabilityObject,
  (flatAvailableTimes, isAsapPossible, isAsapOnly, asapAo) =>
    isAsapOnly || isAsapPossible ? asapAo : flatAvailableTimes[0],
)({ keySelectorCreator: keySelectorCombiner });

export const selectRequestedOrFirstAvailabilityObject = createSelector(
  selectRequestedFulfillTimeAvailabilityObject,
  selectFirstAvailableAvailabilityObject,
  (requestedFulfillTime, firstAvailable) => {
    if (!requestedFulfillTime) return null;
    if (requestedFulfillTime.key === 'asap') return firstAvailable;
    return requestedFulfillTime;
  },
);
