import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import FirstName from 'components/form/form-fields/first-name.component';
import LastName from 'components/form/form-fields/last-name.component';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import { updateUserData, uploadProfilePic } from 'redux/user/user.actions';
import {
  selectUserData,
  selectUserProfileImage,
  selectGender,
  selectUserFirstNameFirstLetter,
  selectAuthProvider,
} from 'redux/user/user.selectors';
import InputPhone from 'components/input-phone/input-phone.component';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import * as S from './account-settings-form.styles';
import { useForm, FormProvider } from 'react-hook-form';
import {
  selectIsSmsEnabledMarketing,
  selectIsSmsEnabledOrdeirng,
} from 'redux/config/config.selectors';
import { useTranslation } from 'react-i18next';
import Addresses from './addresses/addresses.component';
import PasswordDialog from '../password-dialog/password-dialog.component';
import AccountContainer from 'components/account-container/account-container.component';
import TrimEmailField from 'components/form/form-fields/trim-email-field/trim-email-field.component';
import Allergy from 'components/dietary-preferences/allergy/allergy.component';
import Dietary from 'components/dietary-preferences/dietary/dietary.component';
import CustomSwitch from 'components/custom-switch/custom-switch.component';
import DeleteAccountDialog from '../delete-account-dialog/delete-account-dialog.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import { FormHelperText } from '@material-ui/core';
import BirthMonthDaySelect from 'components/form/form-fields/birth-month-day-select/birth-month-day-select.component';
import { getDateOfBirth } from 'utils';
import TocAndPrivacy from 'components/toc-and-privacy/toc-and-privacy.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const AccountSettingsForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const genderInit = useSelector(selectGender);
  const isSmsEnabledOrdeirng = useSelector(selectIsSmsEnabledOrdeirng);
  const isSmsEnabledMarketing = useSelector(selectIsSmsEnabledMarketing);
  const profileImage = useSelector(selectUserProfileImage);
  const userData = useSelector(selectUserData) ?? {};
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    extendedAttributes,
    dateOfBirth,
  } = userData;
  const authProvider = useSelector(selectAuthProvider);
  const isLoggedInUsingSocial =
    authProvider === 'GOOGLE' || authProvider === 'APPLE';

  const [gender, setGender] = useState(genderInit ?? '');

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const {
    open: openDeleteAccount,
    handleClose: handleCloseDeleteAccount,
    handleOpen: handleOpenDeleteAccount,
  } = useCustomDialog();

  const firstChar = useSelector(selectUserFirstNameFirstLetter);

  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      birthMonth: dateOfBirth?.split?.('-')?.[1] ?? '',
      birthDay: dateOfBirth?.split?.('-')?.[2] ?? '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async formData => {
    const { birthMonth, birthDay, ...data } = formData;
    const newUserData = {
      ...userData,
      ...data,
      dateOfBirth: getDateOfBirth(birthMonth, birthDay),
      email: isLoggedInUsingSocial ? email : data.email,
      gender: gender || null,
      extendedAttributes: {
        ...userData.extendedAttributes,
        EMAIL_OPT_IN: data.EMAIL_OPT_IN?.toString().toUpperCase(),
        DIETARY_PREFERENCES: data.DIETARY_PREFERENCES?.toString().toUpperCase(),
        ALLERGIES: data.ALLERGIES?.toString().toUpperCase(),
        SMS_TXN_OPT_IN: data.SMS_TXN_OPT_IN?.toString().toUpperCase(),
        SMS_MARKETING_OPT_IN:
          data.SMS_MARKETING_OPT_IN?.toString().toUpperCase(),
      },
    };

    const res = await dispatch(updateUserData(newUserData));

    if (res) toast.success(t('accountSettings.form.saveSuccess'));
  };

  const handleAddProfilePic = e => {
    if (e.target && e.target.files) {
      dispatch(uploadProfilePic(e.target.files[0]));
    }
  };

  const handleGenderUpdate = e => {
    if (e.target && e.target.value) {
      setGender(e.target.value);
    }
  };

  return (
    <AccountContainer testId="AccountSettings.AccountContainer">
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h1>{t('accountSettings.title')}</h1>
          <MandatoryFieldsInstruction />
          <S.ProfilePic
            data-testid="AccountSettingsForm.ProfilePic"
            src={profileImage}
            alt={firstName}
            onClick={handleClick}
          >
            {firstChar}
          </S.ProfilePic>

          <CustomInput>
            <S.ProfilePicUpload
              type="file"
              accept="image/*"
              onChange={handleAddProfilePic}
              name="profile-pic"
              ref={hiddenFileInput}
            />
          </CustomInput>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FirstName
                register={register}
                errors={errors}
                defaultValue={firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LastName
                register={register}
                errors={errors}
                defaultValue={lastName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomInput>
                <InputLabel htmlFor="email">
                  {t('register.label.email')}*
                </InputLabel>
                <TrimEmailField
                  defaultValue={email}
                  fullWidth
                  id="email"
                  placeholder={t('register.placeholder.email')}
                  error={!!errors.email}
                  inputProps={{ autocomplete: 'email', 'aria-required': true }}
                  {...register('email', {
                    disabled: isLoggedInUsingSocial,
                    required: t('register.errors.requiredField'),
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: t('forms.errors.email.invalid'),
                    },
                  })}
                />
                {isLoggedInUsingSocial && (
                  <FormHelperText data-testid="AccountSettings.Email.HelperText">
                    {t('forms.errors.email.socialLogin', { authProvider })}
                  </FormHelperText>
                )}
                {errors.email && (
                  <FormError errorMessage={errors.email.message} />
                )}
              </CustomInput>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputPhone defaultValue={phoneNumber} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <BirthMonthDaySelect />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomInput>
                <InputLabel htmlFor="gender">
                  {t('accountSettings.form.gender.label')}
                </InputLabel>
                <Select
                  fullWidth
                  value={gender}
                  onChange={handleGenderUpdate}
                  inputProps={{
                    id: 'gender',
                    autocomplete: 'sex',
                  }}
                  SelectDisplayProps={{ 'data-testid': 'Select.Gender' }}
                  MenuProps={{
                    MenuListProps: { 'data-testid': 'Select.Gender.Menu' },
                  }}
                >
                  <MenuItem value="MALE">
                    {t('accountSettings.form.gender.option.male')}
                  </MenuItem>
                  <MenuItem value="FEMALE">
                    {t('accountSettings.form.gender.option.female')}
                  </MenuItem>
                </Select>
              </CustomInput>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Allergy />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dietary />
            </Grid>
          </Grid>

          <Addresses />

          {!isLoggedInUsingSocial && (
            <S.SectionContainer data-testid="AccountSettings.SectionLinkText.ChangePasswordContainer">
              <S.SectionTitle>{t('accountSettings.password')}</S.SectionTitle>
              <S.SectionLinkText
                component="button"
                type="button"
                onClick={() => setShowPasswordDialog(true)}
                data-testid="AccountSettings.SectionLinkText.ChangePassword"
              >
                {t('accountSettings.changePasswordAction')}
              </S.SectionLinkText>
            </S.SectionContainer>
          )}

          <S.SectionContainer>
            <S.SectionTitle>
              {t('accountSettings.deleteAccount.title')}
            </S.SectionTitle>
            <S.SectionLinkText
              component="button"
              type="button"
              onClick={handleOpenDeleteAccount}
              data-testid="AccountSettings.SectionLinkText.Delete"
            >
              {t('accountSettings.deleteAccount.linkText')}
            </S.SectionLinkText>
          </S.SectionContainer>

          {isSmsEnabledOrdeirng && (
            <Grid>
              <CustomSwitch
                defaultChecked={extendedAttributes?.SMS_TXN_OPT_IN === 'TRUE'}
                label={t('userSettings.smsOrderNotifications')}
                dataTestId="AccountSettings.CustomSwitch.smsTxnOptIn"
                {...register('SMS_TXN_OPT_IN')}
              />
            </Grid>
          )}

          {isSmsEnabledMarketing && (
            <Grid>
              <CustomSwitch
                defaultChecked={
                  extendedAttributes?.SMS_MARKETING_OPT_IN === 'TRUE'
                }
                name="SMS_MARKETING_OPT_IN"
                label={t('userSettings.smsMarketingNotifications')}
                dataTestId="AccountSettings.CustomSwitch.smsMarketingOptIn"
                {...register('SMS_MARKETING_OPT_IN')}
              />
            </Grid>
          )}

          <Grid>
            <CustomSwitch
              defaultChecked={extendedAttributes?.EMAIL_OPT_IN === 'TRUE'}
              name="EMAIL_OPT_IN"
              label={t('userSettings.emailMarketingNotifications')}
              dataTestId="AccountSettings.CustomCheckbox.EmailOptIn"
              {...register('EMAIL_OPT_IN')}
            />
          </Grid>

          <TocAndPrivacy isSettingsPage />

          <IncButton
            type="submit"
            data-testid="AccountSettings.CustomButton.Submit"
            fullWidth
          >
            {t('accountSettings.saveAction')}
          </IncButton>
        </Form>

        <PasswordDialog
          open={showPasswordDialog}
          close={() => setShowPasswordDialog(false)}
        />

        <DeleteAccountDialog
          open={openDeleteAccount}
          close={handleCloseDeleteAccount}
        />
      </FormProvider>
    </AccountContainer>
  );
};

export default AccountSettingsForm;
