import { PaymentOptionType } from 'components/payment/payment.constants';
import { useSpreedlyPaymentsPlaceOrder } from 'components/payment/spreedly/spreedly-payments.hooks';
import WalletButton from 'components/payment/wallet-button/wallet-button.component';
import PlaceOrder from 'pages/checkout/checkout-buttons/place-order/place-order.component';
import { useNewCheckoutButtons } from '../../checkout-buttons-new.hooks';

const SpreedlyCheckoutButtons = ({
  insufficientModalSetOpen,
  scriptLoading,
}) => {
  const { trigger } = useSpreedlyPaymentsPlaceOrder();
  const { handlePlaceOrder, loading, validate, paymentOption } =
    useNewCheckoutButtons(trigger, insufficientModalSetOpen);

  switch (paymentOption) {
    case PaymentOptionType.APPLE_PAY:
    case PaymentOptionType.GOOGLE_PAY:
      return <WalletButton validate={validate} />;
    default:
      return (
        <PlaceOrder
          handlePlaceOrder={handlePlaceOrder}
          disabled={loading || scriptLoading}
        />
      );
  }
};

export default SpreedlyCheckoutButtons;
