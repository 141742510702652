import { useGoogleLogin } from '@react-oauth/google';
import i18n from 'i18next';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getGoogleJwt,
  signInUserFailure,
  socialSignInStartAsync,
} from 'redux/user/user.actions';
import googleLogo from './g-logo.png';
import * as S from './google-signup.styles';

const GoogleSignUp = ({ btnText, send, setError }) => {
  const dispatch = useDispatch();

  const handleError = () => {
    toast.error(i18n.t('errors.genericError'));
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async codeResponse => {
      let jwt, id_token;

      try {
        const response = await dispatch(getGoogleJwt(codeResponse.code));
        id_token = response.data.id_token;
        jwt = jwt_decode(id_token);
        await dispatch(
          socialSignInStartAsync({
            email: jwt.email,
            authProvider: 'GOOGLE',
            jwt: id_token,
          }),
        );
      } catch (e) {
        if (e?.response?.data?.error === 'invalid_grant' && !!jwt) {
          // take user to sign up
          send('REGISTER', {
            registerData: {
              userData: {
                email: jwt.email,
                firstName: jwt.given_name,
                lastName: jwt.family_name,
              },
              jwt: id_token,
              authProvider: 'GOOGLE',
            },
          });
        } else if (e?.response?.headers?.['incentivio-message']) {
          setError(e?.response?.headers?.['incentivio-message']);
        } else {
          setError(e.message);
        }
        dispatch(signInUserFailure());
      }
    },
    onError: handleError,
  });

  return (
    <S.SignupButton
      onClick={googleLogin}
      fullWidth
      data-testid="CreateAccountStep.CustomButton.Google"
      startIcon={<S.GoogleLogoAvatar src={googleLogo} alt="" />}
    >
      {btnText}
    </S.SignupButton>
  );
};

export default GoogleSignUp;
