import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryInstructions } from 'redux/cart/cart.actions';
import { selectDeliveryInstructions } from 'redux/cart/cart.selectors';
import CheckoutSection from '../checkout-section/checkout-section.component';
import { deliveryInstructionsSchema } from '../checkout.schema';
import * as S from './checkout-delivery-instructions-section.styles';
import { useDeliveryInstructionsValidation } from './checkout-delivery-instructions-section.hooks';
import CheckoutContext from '../checkout.context';

const CheckoutDeliveryInstructionsSection = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(deliveryInstructionsSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = methods;
  const dispatch = useDispatch();
  const defaultValue = useSelector(selectDeliveryInstructions);
  const { validateCheckoutSections } = useContext(CheckoutContext);

  const submit = useCallback(() => {
    handleSubmit(
      () => {
        dispatch(setDeliveryInstructions(getValues('deliveryInstructions')));
        validateCheckoutSections();
      },
      err => {
        throw err;
      },
    )();
  }, [dispatch, getValues, handleSubmit, validateCheckoutSections]);

  const { validate } = useDeliveryInstructionsValidation(errors);

  return (
    <CheckoutSection
      title={t('checkout.deliveryInstructions.title')}
      headerProps={{ id: 'delivery-instructions-header' }}
      validate={validate}
      data-testid="CheckoutDeliveryInstructionsSection.CheckoutSection"
    >
      <FormProvider {...methods}>
        <S.CustomForm>
          <CustomInput>
            <TrimTextField
              fullWidth
              multiline
              maxRows={4}
              id="deliveryInstructions"
              placeholder={t('checkout.deliveryInstructions.placeholder')}
              {...register('deliveryInstructions')}
              aria-labelledby="delivery-instructions-header"
              onBlur={submit}
              defaultValue={defaultValue}
              error={!!errors.deliveryInstructions}
            />
            <ErrorMessage
              name="deliveryInstructions"
              errors={errors}
              render={({ message }) => <FormError errorMessage={message} />}
            />
          </CustomInput>
        </S.CustomForm>
      </FormProvider>
    </CheckoutSection>
  );
};

export default CheckoutDeliveryInstructionsSection;
