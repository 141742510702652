import INC_BASE_API from 'apis/incentivio-api';
import { usePoller } from 'hooks/usePoller';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectOrder } from 'redux/cart/cart.selectors';
import { selectRequestedStoreDITitle } from 'redux/locations/locations.selectors';
import { updateRecommendationsOrder } from 'redux/recommendations/recommendations.actions';
import { getAnalytics } from 'util/analytics';

export const useOrderStatus = (statusDelay = 2000, statusTimeout = 120000) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState('pending');
  const order = useSelector(selectOrder);
  const storeTitle = useSelector(selectRequestedStoreDITitle);

  const callGetStatus = async () => {
    try {
      const response = await INC_BASE_API.get(
        `/orders/${order.orderId}/paymentstatus`,
      );

      setStatus(response?.data?.orderStatus);

      if (response?.data?.orderStatus === 'ORDER_SUCCESS') {
        dispatch(updateRecommendationsOrder());
        getAnalytics().trackOrderCompleted(order, storeTitle);
        history.push('/checkout/thankyou', response.data);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      toast.error(e?.response?.headers?.['incentivio-message']);
      history.push('/checkout');
      return true;
    }
  };

  const { failed, start } = usePoller(
    callGetStatus,
    statusDelay,
    statusTimeout,
  );

  useEffect(() => {
    const execute = async () => {
      const result = await start();
      if (!result) {
        toast.error(i18n.t('payment.status.timeout'));
        history.push('/checkout');
      }
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status, failed };
};
