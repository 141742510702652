import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
`;

export const Content = styled(Box)`
  flex-grow: 1;
`;

export const CartTogglerWrapper = styled.div`
  position: relative;
`;

export const CustomBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: white;
    color: black;
  }
`;
