import React from 'react';
import { convertCentsToDollar } from 'utils';
import { useTranslation } from 'react-i18next';
import * as S from './option-item-label.styles';

const OptionItemLabel = ({ isItemOutOfStock, title, price, smallImage }) => {
  const { t } = useTranslation();
  return (
    <>
      {smallImage?.[0] && (
        <S.OptionItemImage
          src={smallImage[0]}
          alt=""
          data-testid={'OptionItem.OptionItemImage'}
        />
      )}
      <S.Title isItemOutOfStock={isItemOutOfStock}>{title}</S.Title>
      {price !== 0 && (
        <S.Price isItemOutOfStock={isItemOutOfStock}>
          {convertCentsToDollar(price)}
        </S.Price>
      )}
      {isItemOutOfStock && (
        <S.UnAvailableLabel>{t('catalog.itemNotAvailable')}</S.UnAvailableLabel>
      )}
    </>
  );
};

export default OptionItemLabel;
