import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { DateTime } from 'luxon';
import { useOrderDayTimes } from 'pages/store/new-order-form/useOrderDayTimes.hooks';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDelayedMessage,
  selectFirstAvailableTimeString,
  selectOrderingOffDays,
} from 'redux/data/orderavailability/orderavailability.selectors';
import { getNoticePeriodDaysArray } from 'redux/data/orderavailability/orderavailability.utils';
import { PICKUP } from 'util/constants';

export const useScheduleOrder = props => {
  let isTodayOff;
  const { store, orderType } = props;
  const {
    pickupSevenPlusDaysOrdering,
    deliverySevenPlusDaysOrdering,
    storeId,
  } = store;
  const { timeZoneName, availableTimes, availableDays, day } = useOrderDayTimes(
    store,
    orderType,
  );
  const todayDateTime = useRef(DateTime.local());
  const { orderTypeLabel } = useOrderTypeLabels(orderType);
  const delayedMessage = useSelector(state =>
    selectDelayedMessage(state, storeId, orderType),
  );
  const orderingOffDays = useSelector(state =>
    selectOrderingOffDays(state, storeId, orderType),
  );
  const firstAvailableTimeString = useSelector(state =>
    selectFirstAvailableTimeString(state, storeId, orderType),
  );

  const noticePeriodDays = getNoticePeriodDaysArray(firstAvailableTimeString);

  const offDays = [...noticePeriodDays, ...orderingOffDays];

  const isSevenPlusDayOrderingEnabled =
    orderType === PICKUP
      ? pickupSevenPlusDaysOrdering
      : deliverySevenPlusDaysOrdering;

  if (isSevenPlusDayOrderingEnabled) {
    isTodayOff = offDays.includes(todayDateTime.current.toISODate());
  } else {
    isTodayOff = !availableDays.includes(todayDateTime.current.toISODate());
  }

  return {
    orderTypeLabel,
    delayedMessage,
    timeZoneName,
    availableTimes,
    availableDays,
    day,
    offDays,
    isTodayOff,
    todayDateTime,
    isSevenPlusDayOrderingEnabled,
  };
};
