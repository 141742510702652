import { useTranslation } from 'react-i18next';
import MinMaxMessage from '../min-max-message.component';
import { convertCentsToDollar } from 'utils';

const MinMaxCateringMessage = ({ translationString, min, max, ...rest }) => {
  const { t } = useTranslation();

  if (isNaN(min) || isNaN(max)) return null;

  return (
    <MinMaxMessage
      message={t(translationString, {
        min: convertCentsToDollar(min),
        max: convertCentsToDollar(max),
      })}
      {...rest}
    />
  );
};

export default MinMaxCateringMessage;
