import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSelector } from 'react-redux';
import {
  selectSocialSignInEnabled,
  selectSocialSignInGoogleClientId,
} from 'redux/config/config.selectors';

const GoogleSocialSignIn = ({ children }) => {
  const socialSignInEnabled = useSelector(selectSocialSignInEnabled);
  const googleSignInClientId = useSelector(selectSocialSignInGoogleClientId);

  if (!socialSignInEnabled) return children;

  return (
    <GoogleOAuthProvider clientId={googleSignInClientId}>
      {children}
    </GoogleOAuthProvider>
  );
};
export default GoogleSocialSignIn;
