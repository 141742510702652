import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import Image from 'components/Image/image.component';

export const CustomSlider = styled(AwesomeSlider)`
  --slider-transition-duration: 200ms;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-color: white;
  --control-bullet-color: ${({ theme }) => theme.palette.primary.main};
  --control-bullet-active-color: ${({ theme }) => theme.palette.primary.main};
  --loader-bar-color: ${({ theme }) => theme.palette.primary.main};
  --loader-bar-height: 3px;

  .awssld__bullets {
    z-index: 2;
    bottom: 10px;

    button {
      width: 10px;
      height: 10px;
    }
  }
`;

export const OriginalImg = styled(Image)`
  width: 100%;
  height: auto;
`;
