import { useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useClearEditOptions,
  useIsMaxItemSelectionsReached,
  useOptionItemRegister,
  useUnregisterOnRemove,
} from '../option-item.hooks';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';

export const useOptionItemCheckbox = props => {
  const {
    item,
    data,
    minItemSelections,
    editOptions,
    isParentEdited,
    maxItemSelections,
    name,
    isItemOutOfStock,
    itemDetails,
    isParentAndEditFlow,
    group: { items },
  } = props;

  const { itemId, defaultModifier, formKey } = item;
  const {
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();

  useOptionItemRegister({ minItemSelections, name });

  const isMaxSelectionsReached = useIsMaxItemSelectionsReached(
    name,
    maxItemSelections,
    item.minQuantity > 0 ? item.minQuantity : 1,
  );

  const { unRegisterOnRemove } = useUnregisterOnRemove();

  const { handleSetItemPrice } = useContext(MenuDialogContext);

  const clearEditOptions = useClearEditOptions();

  const checked = !!data.find(item => item.itemId === itemId);

  const getUniqueValuesByItemId = arr => [
    ...new Map(arr.map(item => [item['itemId'], item])).values(),
  ];

  const removeItemFromGroup = () => {
    setValue(
      name,
      data.filter(item => item.itemId !== itemId),
      { shouldDirty: true },
    );
    unRegisterOnRemove(itemDetails.identifier);
    handleSetItemPrice();
  };

  const clearAndSetItemInGroup = () => {
    setValue(name, [itemDetails], { shouldDirty: true, shouldValidate: true });
    unRegisterOnRemove(itemDetails.groupFormKey);
    handleSetItemPrice();
  };

  const isDisableModifierGroup = useMemo(() => {
    const lockedModifierCount = items.filter(
      ({ lockedModifier }) => lockedModifier,
    ).length;
    return lockedModifierCount > 0 && lockedModifierCount === maxItemSelections;
  }, [items, maxItemSelections]);

  const addItemToGroup = (opt = {}) => {
    const currentData = getValues(name) ?? [];
    setValue(name, getUniqueValuesByItemId([...currentData, itemDetails]), {
      shouldDirty: true,
    });
    errors.options?.[formKey] && trigger(name);
    if (!opt.edit) handleSetItemPrice();
  };

  useEffect(() => {
    editOptions?.forEach(option => {
      if (option.itemId === itemId) addItemToGroup({ edit: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      editOptions ||
      isItemOutOfStock ||
      isParentEdited ||
      isParentAndEditFlow
    )
      return;
    if (defaultModifier) addItemToGroup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemOutOfStock]);

  useEffect(() => {
    if (isItemOutOfStock && checked) removeItemFromGroup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemOutOfStock]);

  const handleMultipleSelections = () => {
    if (isMaxSelectionsReached && !checked) return;

    if (isMaxSelectionsReached || checked) {
      removeItemFromGroup();
      return;
    }

    addItemToGroup();
  };

  const handleCheckboxChange = () => {
    clearEditOptions();
    if (checked && maxItemSelections === 1 && minItemSelections === 1) return;

    if (checked && maxItemSelections === 1 && minItemSelections === 0) {
      removeItemFromGroup();
      return;
    }

    if (maxItemSelections > 1 || maxItemSelections === 0) {
      handleMultipleSelections();
      return;
    }

    clearAndSetItemInGroup();
  };

  return { checked, handleCheckboxChange, isDisableModifierGroup };
};
