/**
 * This file should contain selectors that have cross-cutting concerns and
 * require them to use selectors from multiple other state slices. We are
 * putting these in a 'common' file for two reasons:
 * 1. It avoids circular dependency issues when testing
 * 2. Sometimes these selectors just don't fit anywhere else
 */

import {
  selectOrderOption,
  selectOrderType,
  selectRequestedAddress,
  selectTipPercentage,
} from './cart/cart.selectors';
import {
  selectConfig,
  selectConfirmPickup,
  selectCustomizedConfirmation,
  selectConfirmPickupNoNum,
  selectConfirmDelivery,
  selectConfirmDeliveryNoNum,
  selectShowOrderNumber,
  selectTipPercentages,
  selectClientName,
} from './config/config.selectors';
import { getFormattedThankYouMessage, getLanguageKey } from '../utils';
import {
  selectDisplayTimeZone,
  selectMaxDaysOrderingDays,
  selectRequestedStore,
  selectRequestedStoreAddress,
  selectRequestedStoreDITitle,
} from './locations/locations.selectors';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import { DEFAULT_TIP_PERCENTAGE } from 'pages/checkout/checkout.constants';
import { getDisplayStoreAddress } from 'pages/locations/locations.utils';
import queryString from 'query-string';
import {
  selectAvailableTimesTZ,
  selectRequestedFulfillTimeAvailabilityObject,
} from './data/orderavailability/orderavailability.selectors';

export const selectConfirmThankYouMessage = (orderNumber, orderDate) =>
  createSelector(
    [
      selectCustomizedConfirmation,
      selectOrderType,
      selectConfirmPickup,
      selectConfirmPickupNoNum,
      selectConfirmDelivery,
      selectConfirmDeliveryNoNum,
      selectRequestedStore,
      selectShowOrderNumber,
    ],
    (
      customizedConfirmation,
      orderType,
      pickup,
      pickupNoNum,
      delivery,
      deliveryNoNum,
      requestedStore,
      showOrderNumber,
    ) => {
      if (customizedConfirmation && requestedStore) {
        if (orderType === 'PICKUP') {
          if (orderNumber && showOrderNumber && pickup) {
            return getFormattedThankYouMessage(
              pickup[getLanguageKey()],
              orderDate,
              orderNumber,
              requestedStore.title,
            );
          }
          if (pickupNoNum) {
            return getFormattedThankYouMessage(
              pickupNoNum[getLanguageKey()],
              orderDate,
              orderNumber,
              requestedStore.title,
            );
          }
        }

        if (orderType === 'DELIVERY') {
          if (orderNumber && showOrderNumber && delivery) {
            return getFormattedThankYouMessage(
              delivery[getLanguageKey()],
              orderDate,
              orderNumber,
              requestedStore.title,
            );
          }
          if (deliveryNoNum) {
            return getFormattedThankYouMessage(
              deliveryNoNum[getLanguageKey()],
              orderDate,
              orderNumber,
              requestedStore.title,
            );
          }
        }
      }

      return null;
    },
  );

export const makeSelectThankYouFulfillTime = dateTimeString =>
  createSelector(
    selectDisplayTimeZone,
    selectAvailableTimesTZ,
    (displayTimeZone, storeTimeZone) =>
      DateTime.fromISO(dateTimeString, { zone: storeTimeZone }).setZone(
        displayTimeZone,
      ),
  );

export const selectOrderTypeTipConfig = createSelector(
  selectTipPercentages,
  selectOrderType,
  (tipPercentages, orderType) => tipPercentages?.[orderType?.toLowerCase()],
);

export const selectInitialTip = createSelector(
  selectTipPercentage,
  selectOrderType,
  selectOrderTypeTipConfig,
  (cartTip, orderType, tipConfig) =>
    cartTip || (tipConfig?.defaultTip ?? DEFAULT_TIP_PERCENTAGE[orderType]),
);

export const selectIsTipDisplay = createSelector(
  selectConfig,
  selectOrderTypeTipConfig,
  (config, tipConfig) => {
    if (
      config.isTipDisplay &&
      Array.isArray(tipConfig?.values) &&
      tipConfig.values.every(percentage => percentage === 0)
    ) {
      return false;
    }
    return config.isTipDisplay;
  },
);

export const selectRequestedStoreGoogleMapsQueryUrl = createSelector(
  selectClientName,
  selectRequestedStoreDITitle,
  selectRequestedStoreAddress,
  (clientName, storeTitle, storeAddress) => {
    const query = queryString.stringify({
      query: `${clientName} ${storeTitle}, ${getDisplayStoreAddress(
        storeAddress,
      )}`,
    });
    return `https://www.google.com/maps/search/?api=1&${query}`;
  },
);

export const makeSelectRequestedStoreOrderOptions = orderType =>
  createSelector(selectRequestedStore, store =>
    orderType === 'DELIVERY'
      ? store?.deliveryOrderOptions
      : store?.pickupOrderOptions,
  );

export const selectOrderDetails = createSelector(
  selectOrderType,
  selectRequestedAddress,
  selectOrderOption,
  selectRequestedFulfillTimeAvailabilityObject,
  selectMaxDaysOrderingDays,
  (orderType, address, orderOption, time, maxOrderingDays) => ({
    orderType,
    address,
    orderOption,
    'orderOption-informationField': orderOption?.informationFieldInput,
    time: !!time
      ? {
          ...time,
          maxOrderingDays,
        }
      : undefined,
  }),
);
