import { CustomCheckbox } from 'components/custom-checkbox/custom-checkbox.styles';
import * as S from './spreedly-save-card-checkbox.styles';

const SpreedlySaveCardCheckbox = ({ label, ...rest }) => {
  return (
    <S.CheckboxWrapper
      data-testid="SpreedlySaveCardCheckbox.CheckboxWrapper"
      control={<CustomCheckbox {...rest} />}
      label={label}
    />
  );
};

export default SpreedlySaveCardCheckbox;
