import { useValidateSpreedly } from 'components/payment/spreedly/spreedly-payments.hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PurchaseButton from '../purchase-button/purchase-button.component';

const GiftCardSpreedlyPurchaseButton = ({
  processOrder,
  disabled,
  cardInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const validate = useValidateSpreedly();
  const { t } = useTranslation();

  window.Spreedly?.on('errors', function (_errors) {
    setLoading(false);
  });

  const onClick = () => {
    if (validate()) {
      setLoading(true);
      document.getElementById('payment-form').requestSubmit();
    } else {
      document.getElementById('errors').innerHTML = t(
        'checkout.payment.errors.requiredFields',
      );
    }
  };

  useEffect(() => {
    if (!cardInfo) return;
    processOrder(cardInfo.token);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo]);

  return <PurchaseButton disabled={loading || disabled} onClick={onClick} />;
};

export default GiftCardSpreedlyPurchaseButton;
