/**
 * Ok well I couldn't figure out how to do this with stubs, so we have all of
 * this garbage. Sorry.
 */

if (window.Cypress) {
  window.testGrecaptcha = {
    enterprise: {
      execute: (_key, { action }) => `test token ${action}`,
      ready: fn => fn(),
    },
  };
}

export const grecaptcha = () =>
  window.Cypress ? window.testGrecaptcha : window.grecaptcha;
