import { useCloverPurchaseEmailGiftCard } from 'components/payment/clover/clover-payments.hooks';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import GiftCardPaymentStepNew from '../gift-card-payment-step-new/gift-card-payment-step-new.component';

const CloverGiftCardPaymentStep = props => {
  const { trigger, loading: purchaseLoading } =
    useCloverPurchaseEmailGiftCard();

  const { selectedPaymentOption } = usePaymentOptions();

  return (
    <GiftCardPaymentStepNew
      trigger={trigger}
      purchaseLoading={purchaseLoading}
      selectedPaymentOption={selectedPaymentOption}
      {...props}
    />
  );
};

export default CloverGiftCardPaymentStep;
