import React from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import { InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from './trim-text-field/trim-text-field.component';
import { ErrorMessage } from '@hookform/error-message';
import { useResetDefaultValue } from './useResetDefaultValue';

const LastName = ({ errors, register, defaultValue, disabled }) => {
  const { t } = useTranslation();

  useResetDefaultValue('lastName', defaultValue);

  return (
    <CustomInput>
      <InputLabel htmlFor="lastName">{t('forms.label.lastName')}*</InputLabel>
      <TrimTextField
        id="lastName"
        fullWidth
        placeholder={t('forms.placeholder.lastName')}
        error={!!errors.lastName}
        inputProps={{ autocomplete: 'family-name', 'aria-required': true }}
        {...register('lastName', {
          required: t('register.errors.requiredField'),
          'aria-required': true,
          validate: value => !!value.trim(),
        })}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <ErrorMessage
        errors={errors}
        name="lastName"
        render={({ message }) => (
          <FormError errorMessage={message} data-testid="LastName.FormError" />
        )}
      />
    </CustomInput>
  );
};

export default LastName;
