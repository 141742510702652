import React from 'react';
import AddressControlsField from '../address-controls-field/address-controls-field.component';

const LoggedOutAddressControls = ({ ...rest }) => {
  return (
    <AddressControlsField
      dataTestId="LoggedOutAddressControls.Label"
      {...rest}
    />
  );
};

export default LoggedOutAddressControls;
