import { Box } from '@material-ui/core';
import CreditCardPanelController from './credit-card-panel-controller/credit-card-panel-controller.component';

const CreditCardPanel = props => {
  return (
    <Box data-testid="CreditCardPanel.Box">
      <CreditCardPanelController {...props} />
    </Box>
  );
};

export default CreditCardPanel;
