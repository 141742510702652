import { useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorHardReset } from 'redux/common.actions';
import { persistor } from 'redux/store';
import { defaultErrorHandler } from './error-boundary.utils';
import { useErrorHandler } from 'react-error-boundary';

export const useDefaultOnReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handler = useCallback(() => {
    dispatch(errorHardReset());
    persistor.flush().then(() => {
      history.go(0);
    });
  }, [dispatch, history]);

  return handler;
};

// This reset handler will re-mount components below it in the tree, so this
// should be a parent of any components that might resolve the error when they
// are re-mounted.
export const useRetryOnReset = () => {
  const [errorState, setErrorState] = useState(false);
  const onReset = useCallback(() => setErrorState(prev => !prev), []);

  return {
    onReset,
    resetKeys: [errorState],
  };
};

export const useIncErrorHandler = () => {
  const handleError = useErrorHandler();

  const handleIncError = (error, errorBoundaryName) => {
    defaultErrorHandler(errorBoundaryName)(error);
    handleError(error);
  };

  return handleIncError;
};
