import i18next from 'i18next';
import { isValidAddress } from 'pages/locations/locations.utils';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as appActions,
  selectors as appSelectors,
} from 'redux/app/app.slice';
import {
  selectDeliveryAddressList,
  selectIsLoggedIn,
} from 'redux/user/user.selectors';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';

export const useAddressControls = (name, defaultValue, orderType) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { fieldNames } = useFieldNames();

  const dispatch = useDispatch();
  const { watch, setValue, setError, register, trigger, formState } =
    useFormContext();

  const [showSpinner, setShowSpinner] = useState(false);

  const preferredAddress = useSelector(
    isLoggedIn
      ? appSelectors.selectLoggedInPrefferedAddress
      : appSelectors.selectPrefferedAddress,
  );

  const [unsavedAddresses, setUnsavedAddresses] = useState(
    preferredAddress ? [preferredAddress] : [],
  );
  const savedAddresses = useSelector(selectDeliveryAddressList);
  const addresses = useMemo(
    () => (isLoggedIn ? savedAddresses : unsavedAddresses ?? []),
    [isLoggedIn, savedAddresses, unsavedAddresses],
  );

  const selectName = useMemo(() => `${name}-select`, [name]);
  const newName = useMemo(() => `${name}-new`, [name]);
  const cateringOrderType = watch(fieldNames.cateringOrderType);

  const defaultSelectValue = useMemo(() => {
    if (
      !!defaultValue &&
      addresses.find(address => address.addressId === defaultValue.addressId)
    ) {
      return defaultValue.addressId;
    } else if (
      !!preferredAddress &&
      addresses.find(
        address => address.addressId === preferredAddress.addressId,
      )
    ) {
      return preferredAddress.addressId;
    } else {
      return addresses?.[0]?.addressId ?? '';
    }
  }, [defaultValue, preferredAddress, addresses]);
  const selectValue = watch(selectName);
  const error = useMemo(() => !!formState.errors[name], [formState, name]);

  useEffect(() => {
    if (!isLoggedIn && !!preferredAddress?.addressId)
      setValue(selectName, preferredAddress.addressId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleCancel = () => {
    setValue(selectName, defaultSelectValue);
  };

  useEffect(() => {
    if (selectValue === 'new') {
      setValue(name, 'new');
    } else if (!!selectValue) {
      const address = addresses?.find(
        address => address.addressId === selectValue,
      );
      setValue(name, address);
      !!address &&
        dispatch(
          isLoggedIn
            ? appActions.setLoggedInPreferredAddress(address)
            : appActions.setPreferredAddress(address),
        );
      trigger(name);
    } else {
      setValue(name, null);
    }
  }, [dispatch, isLoggedIn, name, selectValue, setValue, addresses, trigger]);

  useEffect(() => {
    if (
      (isLoggedIn && savedAddresses?.length < 1) ||
      (!isLoggedIn && unsavedAddresses?.length < 1)
    ) {
      setValue(selectName, 'new');
    }
  }, [unsavedAddresses, isLoggedIn, savedAddresses, selectName, setValue]);

  useEffect(() => {
    register(name, {
      validate: {
        required: value => {
          if (
            orderType === 'DELIVERY' ||
            (orderType === 'CATERING' && cateringOrderType === 'DELIVERY')
          ) {
            return (
              isValidAddress(value) ||
              i18next.t('address.select.errors.required')
            );
          }
          return true;
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, orderType, register, cateringOrderType]);

  return {
    setValue,
    setError,
    errors: formState.errors,
    trigger,
    showSpinner,
    setShowSpinner,
    addresses,
    setAddresses: setUnsavedAddresses,
    selectName,
    newName,
    defaultSelectValue,
    selectValue,
    handleCancel,
    error,
  };
};

export const useGetAddressLabel = isCatering => {
  return useMemo(() => {
    return isCatering
      ? i18next.t('locations.cateringInput.label')
      : i18next.t('locations.deliveryInput.label');
  }, [isCatering]);
};
