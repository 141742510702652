import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import AccountInboxMessage from 'pages/account-inbox/account-inbox-message/account-inbox-message.component';
import { selectIsLoading } from 'redux/user/user.selectors';
import Spinner from 'components/spinner/spinner.component';
import { EmptyContent } from 'components/promo-code/promo-code.styles';
import { Box } from '@material-ui/core';

const MessagesDialog = ({
  open,
  checkoutPromoCode,
  setOpen,
  messages,
  testId,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const { t } = useTranslation();
  return (
    <CustomDialog
      open={open}
      title={checkoutPromoCode ? 'Offers' : 'Content'}
      testId={testId}
      close={() => setOpen(false)}
    >
      {isLoading && <Spinner />}
      {messages.length ? (
        messages.map(message => (
          <Box pb={2}>
            <AccountInboxMessage
              message={message}
              handleClose={() => setOpen(false)}
              checkoutPromoCode={checkoutPromoCode}
              key={
                message.distributedMessageId
                  ? message.distributedMessageId
                  : message.distributedOfferId
              }
            />
          </Box>
        ))
      ) : (
        <EmptyContent>{t('payment.promoCode.emptyContent')}</EmptyContent>
      )}
    </CustomDialog>
  );
};

export default MessagesDialog;
