import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectTotal } from 'redux/cart/cart.selectors';
import {
  selectRequestedStoreMaxValue,
  selectRequestedStoreMinValue,
  selectRequestedStoreOrderValueLongMessage,
} from 'redux/locations/locations.selectors';

export const useValideMinMax = () => {
  const storeValMessage = useSelector(
    selectRequestedStoreOrderValueLongMessage,
  );
  const storeMaxVal = useSelector(selectRequestedStoreMaxValue);
  const storeMinVal = useSelector(selectRequestedStoreMinValue);
  const total = useSelector(selectTotal);

  const isMinMaxError = useCallback(() => {
    if (!!storeMinVal && total < storeMinVal) {
      toast.error(storeValMessage);
      return true;
    }

    if (!!storeMaxVal && total > storeMaxVal) {
      toast.error(storeValMessage);
      return true;
    }

    return false;
  }, [storeMaxVal, storeMinVal, storeValMessage, total]);

  return { isMinMaxError };
};
