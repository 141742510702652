import { getEnv } from 'util/getEnv';
import location from 'util/location';
import LogRocket from 'util/getLogRocket';

const setupLogrocket = () => {
  if (
    !(
      getEnv('REACT_APP_LOGROCKET_DISABLE_LOCALHOST') === 'true' &&
      location.getLocationHref().indexOf('localhost') !== -1
    ) &&
    getEnv('REACT_APP_LOGROCKET')
  ) {
    const clients = getEnv('REACT_APP_LOGROCKET_CLIENTS')
      ? getEnv('REACT_APP_LOGROCKET_CLIENTS').split(',')
      : [];

    if (
      clients &&
      clients.length &&
      (clients[0] === '*' ||
        clients.some(
          client => location.getLocationHref().indexOf(client) !== -1,
        ))
    ) {
      LogRocket.init(getEnv('REACT_APP_LOGROCKET'), {
        mergeIframes: true,
        network: {
          requestSanitizer: requestSanitizerFunction,
          responseSanitizer: responseSanitizerFunction,
        },
      });
    }
  }
};

export default setupLogrocket;

export const requestSanitizerFunction = request => {
  if (!request.body) return request;

  if (isUrlMatch(request, '/oauth/token', 'POST')) {
    return {
      ...request,
      body: request.body.replace(/&password=[^&]*&/, '&password=*****&'),
    };
  }

  if (isUrlMatch(request, '/useraccounts/updatepassword', 'POST')) {
    const secrets = [
      { key: 'oldPassword', value: '*****' },
      { key: 'newPassword', value: '*****' },
    ];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/useraccounts', 'POST')) {
    const secrets = [{ key: 'password', value: '*****' }];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/makepayment', 'POST')) {
    const secrets = [{ key: 'paymentToken', value: '*****' }];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/purchase/ecard', 'POST')) {
    const secrets = [{ key: 'paymentToken', value: '*****' }];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/paymentinstruments', 'POST')) {
    const secrets = [
      { key: 'paymentToken', value: '*****' },
      { key: 'expirationMonth', value: '**' },
      { key: 'expirationYear', value: '****' },
    ];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/giftcard/add-value', 'POST')) {
    const secrets = [{ key: 'paymentToken', value: '*****' }];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }

  if (isUrlMatch(request, '/resetpassword', 'POST')) {
    const secrets = [
      { key: 'newPassword', value: '*****' },
      { key: 'newPassword2', value: '*****' },
    ];

    return {
      ...request,
      body: JSONSanitizer(JSON.parse(request.body), secrets),
    };
  }
  return request;
};

export const responseSanitizerFunction = response => {
  if (!response.body) return response;

  if (isUrlMatch(response, '/paymentinstruments', 'GET')) {
    const sanitizedBody = JSON.parse(response.body)?.paymentInstruments.map(
      instrument =>
        JSONSanitizer(instrument, [
          { key: 'paymentToken', value: '*****' },
          { key: 'expirationMonth', value: '**' },
          { key: 'expirationYear', value: '****' },
        ]),
    );

    return { ...response, body: sanitizedBody };
  }
  return response;
};

export const JSONSanitizer = (body, secretsObjectArray) => {
  secretsObjectArray.forEach(({ key, value }) => {
    body[key] = value;
  });
  return body;
};

export const isUrlMatch = (request, urlPath, method) => {
  return request.url.includes(urlPath) && request.method === method;
};
