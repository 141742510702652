import INC_BASE_API from 'apis/incentivio-api';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { cloverSubmitButtonClick } from 'pages/clover/clover.utils';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectPaymentOption } from 'redux/cart/cart.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { IncentivioApiError } from 'util/errors';
import { PaymentOptionType } from '../payment.constants';
import {
  useMakePayment,
  useMakePaymentBody,
  usePaymentGiftCardAsAGuest,
  usePaymentMode,
  usePurchaseEmailGiftCardBody,
} from '../payment.new.hooks';

export const usePlaceOrderWithCard = () => useMakePayment(useMakePaymentBody());

export const useCloverPaymentsPlaceOrder = () => {
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const paymentMode = usePaymentMode();
  const paymentOption = useSelector(selectPaymentOption);
  const history = useHistory();

  const { trigger: cardTrigger } = usePlaceOrderWithCard();
  const { trigger: lookupGiftCardTrigger } = usePaymentGiftCardAsAGuest();
  let response;

  const trigger = async () => {
    try {
      if (paymentMode === 'NO_PAYMENT') {
        response = await addPromise(cardTrigger());
      } else {
        // eslint-disable-next-line default-case
        switch (paymentOption) {
          case PaymentOptionType.CARD:
          case PaymentOptionType.PAY_LATER:
          case PaymentOptionType.GIFT_CARD:
            response = await addPromise(cardTrigger());
            break;

          case PaymentOptionType.GUEST_CARD:
            const result = await cloverSubmitButtonClick();
            response = await addPromise(
              cardTrigger({
                paymentToken: result.token,
                isOneTimeTransaction: true,
              }),
            );
            break;

          case PaymentOptionType.GUEST_GIFT_CARD:
            const { lookupToken, giftCardNumber } = await addPromise(
              lookupGiftCardTrigger(),
            );
            if (lookupToken) {
              response = await cardTrigger({
                paymentToken: giftCardNumber,
                isOneTimeTransaction: true,
              });
            }
            break;
        }
      }
      history.push('/checkout/thankyou', response.data);
    } catch (e) {
      // handle errors in caller
      throw e;
    }
  };

  return { trigger };
};

export const useCloverEmailGiftCardPayment = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const body = usePurchaseEmailGiftCardBody();

  const trigger = async paymentToken => {
    try {
      setLoading(true);

      if (!isLoggedIn) {
        const resp = await cloverSubmitButtonClick();
        if (!resp?.token) return;
        body.paymentToken = resp.token;
      } else {
        body.paymentToken = paymentToken;
      }

      const response = await INC_BASE_API.post(`/giftcard/purchase`, body, {
        authenticated: isLoggedIn,
      });

      return response;
    } catch (e) {
      throw new IncentivioApiError('useCloverEmailGiftCardPayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const useCloverPurchaseEmailGiftCard = () => {
  const {
    loading: emailGiftCardLoading,
    trigger: triggerEmailGiftCardPayment,
  } = useCloverEmailGiftCardPayment();

  const loading = useMemo(() => emailGiftCardLoading, [emailGiftCardLoading]);

  const trigger = async paymentToken => {
    const resp = await triggerEmailGiftCardPayment(paymentToken);

    return resp;
  };

  return { loading, trigger };
};
