import React from 'react';
import AccountLoyaltyCustomTwoPunchImage from './account-loyalty-custom-two-punch-img/account-loyalty-custom-two-punch-img.component';

const AccountLoyaltyCustomTwoPunch = ({ points, loyaltyPointImgs }) => {
  const filledImgs = points <= 15 ? points : 15;
  const unFilledImgs = 15 - filledImgs;

  let filledImgsArray = [];
  let unFilledImgsArray = [];

  if (filledImgs > 0 && loyaltyPointImgs[0]) {
    filledImgsArray = [...Array(filledImgs)];
  }

  if (unFilledImgs > 0 && loyaltyPointImgs[1]) {
    unFilledImgsArray = [...Array(unFilledImgs)];
  }

  return [
    filledImgsArray.map((_val, i) => (
      <AccountLoyaltyCustomTwoPunchImage
        img={loyaltyPointImgs[0]}
        index={i}
        testId="AccountLoyaltyCustomTwoPunch.AccountLoyaltyCustomTwoPunchImage.filledImage"
      />
    )),
    unFilledImgsArray.map((_val, i) => (
      <AccountLoyaltyCustomTwoPunchImage
        img={loyaltyPointImgs[1]}
        index={i}
        testId="AccountLoyaltyCustomTwoPunch.AccountLoyaltyCustomTwoPunchImage.unFilledImage"
      />
    )),
  ];
};

export default AccountLoyaltyCustomTwoPunch;
