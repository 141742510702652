import { Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as S from './back-to-rewards.styles';
import { useSelector } from 'react-redux';
import { selectLoyaltyLabel } from 'redux/config/config.selectors';

const BackToRewards = ({ handleRewardsClick, hidden }) => {
  const loyaltyLabel = useSelector(selectLoyaltyLabel);
  if (hidden) return null;

  return (
    <Box mb={1}>
      <S.RewardsLink
        data-testid="BackToRewards.RewardsLink"
        onClick={handleRewardsClick}
      >
        <ArrowBackIcon />
        {loyaltyLabel}
      </S.RewardsLink>
    </Box>
  );
};

export default BackToRewards;
