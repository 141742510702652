import { createSelector } from 'reselect';
import { findAllItemDiscounts } from './cart.utils';
import { PaymentOptionType } from 'components/payment/payment.constants';

export const selectCart = state => state.cart;

export const selectOrder = createSelector([selectCart], cart =>
  cart ? cart.order : null,
);

export const selectOrderType = createSelector(
  [selectCart],
  cart => cart.orderType,
);

export const selectLocation = createSelector(
  [selectCart],
  cart => cart.location,
);

export const selectTip = createSelector(
  selectCart,
  cart => cart.tip?.amount ?? 0,
);

export const selectTipInt = createSelector(selectCart, cart =>
  parseInt(parseFloat(cart.tip?.amount ?? 0) * 1000),
);

export const selectLocationId = createSelector([selectLocation], location =>
  location ? location.locationId : null,
);

export const selectOrderLocationId = createSelector(selectOrder, order =>
  order ? order.locationId : null,
);

export const selectOrderMerchantId = createSelector(selectOrder, order =>
  order ? order.merchantId : null,
);

export const selectSlug = createSelector([selectLocation], location =>
  location ? location.slug : null,
);

export const selectCustomerInfo = createSelector(
  [selectCart],
  cart => cart.customerInfo,
);

export const selectOrderOption = createSelector(
  [selectCart],
  cart => cart.orderOption,
);

export const selectDeliveryInstructions = createSelector(selectCart, cart =>
  cart.orderType === 'DELIVERY' ? cart.deliveryInstructions : undefined,
);

export const selectRequestedAddress = createSelector(
  [selectCart],
  cart => cart.requestedAddress || {},
);

export const selectRequestedAddressIsValid = createSelector(
  [selectRequestedAddress],
  requestedAddress =>
    !!requestedAddress &&
    !!requestedAddress.lat &&
    !!requestedAddress.lon &&
    !!requestedAddress.postalCode &&
    !!requestedAddress.street1 &&
    !!requestedAddress.country &&
    !!requestedAddress.city,
);

export const selectIsDrawerOpen = createSelector(
  [selectCart],
  cart => cart.isDrawerOpen,
);

export const selectOrderInstructions = createSelector([selectCart], cart =>
  cart.orderInstructions ? cart.orderInstructions : undefined,
);

export const selectOrderOptionInfo = createSelector(
  selectOrderOption,
  orderOption => {
    if (orderOption) {
      const orderOptionInfo = {};
      orderOptionInfo.optionName = orderOption.optionName || undefined;
      orderOptionInfo.information =
        orderOption.informationFieldInput || undefined;
      return orderOptionInfo;
    }
    return undefined;
  },
);

export const selectIsFetching = createSelector(
  [selectCart],
  cart => cart.isFetching,
);

export const selectRequestedFulfillTime = createSelector(
  [selectCart],
  cart => cart.requestedFulfillTime || null,
);

export const selectOrderItems = createSelector(
  selectOrder,
  order => order?.orderItems ?? [],
);

export const selectOrderItemExternalIdObjectArray = createSelector(
  selectOrderItems,
  orderItems => {
    const externalIdArray = [];
    orderItems.forEach(({ externalId, itemId }) => {
      externalIdArray.push({
        externalId,
        itemId,
      });
    });
    return externalIdArray;
  },
);

export const makeSelectOrderItemByOrderItemId = orderItemId =>
  createSelector([selectOrderItems], orderItems =>
    orderItemId && orderItems
      ? orderItems.find(item => item.orderItemId === orderItemId)
      : null,
  );

export const selectOrderTotal = createSelector([selectOrder], order =>
  order ? order.orderTotal : null,
);

export const selectSubtotal = createSelector(
  [selectOrderTotal],
  orderTotal => orderTotal?.subtotal ?? 0,
);

export const selectTotal = createSelector(
  selectOrderTotal,
  orderTotal => orderTotal?.total ?? 0,
);

export const selectGratuity = createSelector(
  selectOrderTotal,
  orderTotal => orderTotal?.gratuity ?? 0,
);

export const selectTotalWithoutGratuity = createSelector(
  selectTotal,
  selectGratuity,
  (total, gratuity) => total - gratuity,
);

export const selectDisplayTotalWithoutGratuity = createSelector(
  selectTotalWithoutGratuity,
  total => (total / 1000).toFixed(2),
);

/**
 * Gratuity is on the order object itself and is not very useful to us. "Tip" is
 * the tip stored in app state and sometimes we need this along with the total
 */
export const selectTotalWithoutGratuityWithTip = createSelector(
  selectTotalWithoutGratuity,
  selectTipInt,
  (total, tip) => total + tip,
);

export const selectDisplayTotalWithoutGratuityWithTip = createSelector(
  selectTotalWithoutGratuityWithTip,
  total => (total / 1000).toFixed(2),
);

export const selectPreDiscountSubtotal = createSelector(
  [selectOrderTotal],
  orderTotal => (orderTotal ? orderTotal.preDiscountSubtotal : null),
);

export const selectItemsCountDetails = createSelector([selectOrder], order =>
  order ? order.itemsCountDetails : null,
);

export const selectOrderDiscounts = createSelector(
  [selectOrder],
  order => order?.orderDiscounts ?? [],
);

export const selectIsAutoDiscountApplied = createSelector(
  selectOrderDiscounts,
  orderDiscounts =>
    orderDiscounts.some(
      ({ extendedAttributes }) =>
        extendedAttributes?.AUTO_DISCOUNT_APPLIED === 'true',
    ),
);

export const selectOrderItemDiscounts = createSelector(
  selectOrderItems,
  orderItems => findAllItemDiscounts(orderItems),
);

export const selectOrderAndItemDiscounts = createSelector(
  selectOrderDiscounts,
  selectOrderItemDiscounts,
  (orderDiscounts = [], orderItemDiscounts = []) =>
    orderDiscounts.concat(orderItemDiscounts),
);

export const selectSelectedDiscount = createSelector(
  selectOrderDiscounts,
  orderDiscounts =>
    orderDiscounts && orderDiscounts.length ? orderDiscounts[0] : null,
);

export const selectItemsCount = createSelector(
  [selectItemsCountDetails],
  itemsCountDetails =>
    itemsCountDetails ? itemsCountDetails.totalItemsCount : 0,
);

export const selectDisplayOrderSubTotal = createSelector(
  [selectOrderTotal],
  orderTotal => (orderTotal ? orderTotal.displaySubtotal : 0.0),
);

export const selectTaxSummary = createSelector([selectOrderTotal], orderTotal =>
  orderTotal ? orderTotal.taxSummary : null,
);

export const selectTotalTaxes = createSelector(
  selectTaxSummary,
  taxSummary => taxSummary?.totalTaxes ?? 0,
);

export const selectTipCalculateAmount = createSelector(
  selectSubtotal,
  selectTotalTaxes,
  (subtotal, totalTaxes) => (subtotal + totalTaxes) / 1000,
);

export const selectChargeSummary = createSelector(
  [selectOrderTotal],
  orderTotal => (orderTotal ? orderTotal.chargeSummary : null),
);

export const selectCharges = createSelector(
  [selectChargeSummary],
  chargeSummary => (chargeSummary ? chargeSummary.charges : null),
);

export const selectTaxes = createSelector([selectTaxSummary], taxSummary =>
  taxSummary ? taxSummary.taxes : null,
);

export const selectIsFreeOrder = createSelector(
  selectTotalWithoutGratuity,
  selectTip,
  (total, tip) => total + parseFloat(tip) === 0,
);

export const selectOrderId = createSelector([selectOrder], order =>
  order ? order.orderId : null,
);

export const selectIsModifyingQtyItemId = createSelector(
  selectOrder,
  order => order.modifyQtyItemIds || [],
);

export const makeIsItemQtyBeingModified = itemId =>
  createSelector(selectIsModifyingQtyItemId, removingItemId =>
    removingItemId.filter(id => id === itemId),
  );

export const selectOffers = createSelector(selectCart, cart => cart.offers);

export const selectApplicableOffers = createSelector(selectOffers, offers =>
  offers ? offers.applicableOffers : null,
);

export const selectOrderItemIds = createSelector(
  selectCart,
  cart => cart.orderItemIds,
);

export const selectOrderItemPaths = createSelector(
  selectCart,
  cart => cart.orderItemPaths,
);

export const selectOrderItemIdMismatch = createSelector(
  selectOrderItemIds,
  selectOrderItems,
  (orderItemIds, orderItems) => {
    return orderItemIds?.length !== (orderItems?.length ?? 0);
  },
);

export const makeSelectOrderItemPath = orderItemId =>
  createSelector(
    selectOrderItemPaths,
    orderItemPaths => orderItemPaths[orderItemId],
  );

export const selectPaymentInstrumentId = createSelector(
  selectCart,
  cart => cart.paymentInstrumentId,
);

export const selectTipPercentage = createSelector(
  selectCart,
  cart => cart.tip?.percentage,
);

export const selectValidOfferByPromo = distributedOfferId =>
  createSelector(selectApplicableOffers, applicableOffers =>
    applicableOffers?.find(
      applicable => applicable.distributedOfferId === distributedOfferId,
    ),
  );

export const selectPaymentOption = createSelector(
  selectCart,
  cart => cart.paymentOption ?? PaymentOptionType.CARD,
);
