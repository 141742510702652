export const useMobileAppListeners = () => {
  const fireListeners = payload => {
    // Android event listener
    if (window.appInterface !== undefined) {
      window.appInterface.postMessage(JSON.stringify(payload));
      return;
    }

    // IOS event listener
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.iosListener.postMessage(
        JSON.stringify(payload),
      );
      return;
    }
  };

  return fireListeners;
};
