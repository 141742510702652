import React from 'react';
import * as S from '../account-inbox-message.styles';
import Grid from '@material-ui/core/Grid';
import { getActivityBasedContent } from '../../../../redux/user/user.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { INCENTIVIO_URL } from '../../account-inbox.constants';
import TwitterShare from '../../../../components/twitter-share/twitter-share.component';
import FacebookShare from '../../../../components/facebook-share/facebook-share.component';
import { useOffers } from 'redux/data/offers/offers.hooks';
import { useMessages } from 'redux/data/messages/messages.hooks';
import MessageShortDescription from '../message-short-description/message-short-description.component';
import MessageLongDescription from '../message-long-description/message-long-description.component';
import MessageTitle from '../message-title/message-title.component';

const ShareableMessage = ({
  messageData,
  handleClose,
  setIsContentDialogOpen,
  disableOnClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    title,
    shareTitle,
    shareSentence,
    shareURL,
    shortDescription,
    longDescription,
    messageId,
    shareShortDesc,
  } = messageData;

  const shareableUrl = shareURL || INCENTIVIO_URL;
  const shareableTitle = shareTitle || title;
  const { upsertManyOffers } = useOffers();
  const { upsertManyMessages } = useMessages();

  const handleShareSuccess = socialMedia => {
    dispatch(getActivityBasedContent(messageId, socialMedia)).then(data => {
      if (
        data?.offerList?.offers?.length ||
        data?.messageList?.messages?.length
      ) {
        setIsContentDialogOpen(true);
      }

      data?.offerList?.offers?.length &&
        upsertManyOffers(data.offerList.offers);
      data?.messageList?.messages?.length &&
        upsertManyMessages(data.messageList.messages);
    });
  };

  const handleFacebookShareSuccess = e => {
    if (e.data === 'fbShareSuccess') {
      handleShareSuccess('fb', messageId);
      handleClose();
    }
  };

  const handleTwitterShareSuccess = () => {
    handleShareSuccess('tw', messageId);
    handleClose();
  };

  const getShareableDescription = () => {
    if (!!shareSentence && !!shareShortDesc) {
      return `\n\n${shareSentence}\n\n${shareShortDesc}`;
    }
    if (!!shareSentence) {
      return `\n\n${shareSentence}`;
    }
    if (!!shareShortDesc) {
      return `\n\n${shareShortDesc}`;
    }
    if (!!shortDescription) {
      return `\n\n${shortDescription}`;
    }
    return '';
  };

  const shareableData = encodeURIComponent(
    `${shareableTitle}${getShareableDescription()}`,
  );
  return (
    <>
      <MessageTitle title={title} />
      <MessageShortDescription description={shortDescription} />
      <MessageLongDescription description={longDescription} />
      <S.ShareLabel>{t('accountInbox.shareLabel')}</S.ShareLabel>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <TwitterShare
            shareableData={shareableData}
            shareableUrl={shareableUrl}
            onShare={handleTwitterShareSuccess}
            disableOnClick={disableOnClick}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FacebookShare
            shareableData={shareableData}
            shareableUrl={shareableUrl}
            onShare={handleFacebookShareSuccess}
            disableOnClick={disableOnClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ShareableMessage;
