import Spinner from 'components/spinner/spinner.component';
import Cookies from 'js-cookie';
import Clover from 'pages/clover/clover.component';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getAnalytics } from 'util/analytics';
import { actions, selectors } from '../../redux/client/client.slice';
import location from '../../util/location';
import DynamicClientContainer from './dynamic-client.container';
import StaticClientContainer from './static-client.container';
import NotFound from 'components/not-found/not-found.component';

const ClientContainer = () => {
  const dispatch = useDispatch();
  const staticClient = useMemo(() => Cookies.get('INC_STATIC_CLIENT'), []);
  const matchDynamic = useRouteMatch({
    path: ['/c/:clientAlias', '/test/c/:clientAlias'],
  });
  const matchTest = useRouteMatch('/test');
  const history = useHistory();
  const clientAlias = useMemo(
    () => staticClient ?? matchDynamic?.params?.clientAlias,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const clientId = useSelector(selectors.selectClientId);
  const clientError = useSelector(selectors.selectError);
  const [awaitRedirect, setAwaitRedirect] = useState(false);

  useEffect(() => {
    if (clientAlias) {
      if (staticClient) setAwaitRedirect(true);
      dispatch(actions.fetchClientId(clientAlias)).then(data => {
        getAnalytics().clientGroup(data?.payload?.clientId, staticClient);
        getAnalytics().webGroup();
        if (staticClient && !!matchDynamic) {
          if (!!matchTest) history.replace('/test');
          else history.replace('/');
        }
        setAwaitRedirect(false);
      });
    } else {
      location.setLocationHref('https://incentivio.com');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (clientError) return <NotFound />;
  else if (!clientId || awaitRedirect) return <Spinner />;

  return staticClient ? (
    <Switch>
      <Route path={['/', '/test']} component={StaticClientContainer} />
    </Switch>
  ) : (
    <Switch>
      <Route path="/c/:clientAlias/payments/clover" component={Clover} />
      <Route
        path={['/c/:clientAlias', '/test/c/:clientAlias']}
        component={DynamicClientContainer}
      />
    </Switch>
  );
};

export default ClientContainer;
