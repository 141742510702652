import { Box } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ActionLink from 'components/action-link/action-link.component';
import CheckoutContext from 'pages/checkout/checkout.context';
import React, { useContext } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { useDispatch } from 'react-redux';
import { removeDiscount } from 'redux/cart/cart.actions';
import * as S from './applied-offer.styles';

const AppliedOffer = ({ discount }) => {
  const dispatch = useDispatch();
  const { openOffersModal } = useContext(CheckoutContext);
  const isAutoDiscountApplied =
    discount.extendedAttributes?.AUTO_DISCOUNT_APPLIED === 'true';

  const handleClose = () => {
    dispatch(
      removeDiscount(
        discount.discountId,
        discount.description,
        discount.displayAppliedDiscount,
      ),
    );
  };

  return (
    <Zoom duration={600} triggerOnce>
      <S.CustomAlert
        severity="success"
        icon={<LocalOfferIcon />}
        onClose={isAutoDiscountApplied ? undefined : handleClose}
        data-testid="AppliedOffer.CustomAlert"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flex={1}
          alignItems="center"
        >
          <Box data-testid="AppliedOffer.Box.Description">
            {isAutoDiscountApplied ? (
              discount.description
            ) : (
              <ActionLink onClick={openOffersModal}>
                {discount.description}
              </ActionLink>
            )}
          </Box>
          <Box whiteSpace="nowrap" data-testid="AppliedOffer.Box.Amount">
            -(${discount.displayAppliedDiscount})
          </Box>
        </Box>
      </S.CustomAlert>
    </Zoom>
  );
};

export default AppliedOffer;
