import {
  getResponseErrorMessage,
  getResponseIncErrorMessage,
} from 'apis/incentivio-api.util';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { useValidateRecommendationStatus } from 'components/payment/payment.new.hooks';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  selectLocationId,
  selectPaymentOption,
} from 'redux/cart/cart.selectors';
import { fetchOutOfStockItems } from 'redux/catalogs/catalogs.actions';
import { selectReloadGiftCard } from 'redux/config/config.selectors';
import { fetchSingleLocation } from 'redux/locations/locations.actions';
import { IncentivioApiError, StripeError } from 'util/errors';
import {
  CHECKOUT_SECTIONS,
  ORDER_DETAILS_SECTION_ERROR_CODES,
  SELECTED_DATE_AFTER_MAX_DAYS_OUT,
} from '../checkout.constants';
import CheckoutContext from '../checkout.context';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

export const useHandleOrderStatus = insufficientModalSetOpen => {
  const history = useHistory();
  const { setSectionValidationState, scrollToSection, setSectionEditingState } =
    useContext(CheckoutContext);
  const reloadGiftCard = useSelector(selectReloadGiftCard);
  const locationId = useSelector(selectLocationId);
  const loggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  return error => {
    if (error instanceof IncentivioApiError) {
      if (ORDER_DETAILS_SECTION_ERROR_CODES.includes(error.incentivioCode)) {
        setSectionValidationState('Order Details-section', {
          message: getResponseIncErrorMessage(error.cause),
        });
        scrollToSection('Order Details-section');
        setSectionEditingState(CHECKOUT_SECTIONS.ORDER_DETAILS, true);
        if (error.incentivioCode === SELECTED_DATE_AFTER_MAX_DAYS_OUT) {
          dispatch(fetchSingleLocation(locationId));
        }
      } else if (error.incentivioMessage === 'Order is already closed') {
        toast.error(error.incentivioMessage);
        history.push('/');
      } else if (
        error.incentivioCode === 'INSUFFICIENT_GIFT_CARD_FUNDS' &&
        reloadGiftCard &&
        loggedIn
      ) {
        toast.error(getResponseErrorMessage(error.cause));
        insufficientModalSetOpen?.(true);
      } else if (
        ['ITEM_OUT_OF_STOCK', 'MODIFIER_OUT_OF_STOCK'].includes(
          error.incentivioCode,
        )
      ) {
        toast.error(getResponseErrorMessage(error.cause));
        dispatch(fetchOutOfStockItems());
      } else {
        toast.error(getResponseErrorMessage(error.cause));
      }
    } else if (error instanceof StripeError) {
      setSectionValidationState('Payment-section', {
        message: error.cause.message,
      });
      scrollToSection('Payment-section');
    } else if (error.message === 'Validation') {
      return;
    }
  };
};

export const useNewCheckoutButtons = (trigger, insufficientModalSetOpen) => {
  const { validateCheckoutSections } = useContext(CheckoutContext);

  const handleOrderStatus = useHandleOrderStatus(insufficientModalSetOpen);

  const [loading, setLoading] = useState(false);

  const paymentOption = useSelector(selectPaymentOption);

  const { validate: validateRecommendationStatus } =
    useValidateRecommendationStatus();

  const handlePlaceOrder = async () => {
    try {
      setLoading(true);
      await validate();
      await validateRecommendationStatus();
      await trigger();
    } catch (error) {
      handleOrderStatus(error);
    } finally {
      setLoading(false);
    }
  };

  const validate = async () => {
    if (!(await validateCheckoutSections())) throw new Error('Validation');
  };

  return {
    loading,
    showPlaceOrder: ![
      PaymentOptionType.GOOGLE_PAY,
      PaymentOptionType.APPLE_PAY,
    ].includes(paymentOption),
    handlePlaceOrder,
    validate,
    paymentOption,
  };
};
