import CheckoutSectionEdit from 'pages/checkout/checkout-section/checkout-section-edit/checkout-section-edit.component';
import RescheduleOrder from 'components/reschedule-order/reschedule-order.component';

const CheckoutOrderDetailsEdit = () => {
  return (
    <CheckoutSectionEdit>
      <RescheduleOrder />
    </CheckoutSectionEdit>
  );
};

export default CheckoutOrderDetailsEdit;
