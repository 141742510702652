import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import { useRescheduleOrderModal } from 'components/header/reschedule-order-controller/reschedule-order-modal/reschedule-order-modal.hooks';
import RescheduleOrder from 'components/reschedule-order/reschedule-order.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RescheduleOrderModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const formMethods = useForm({ shouldUnregister: true });
  const { handleSubmit } = formMethods;
  const { handleTimeChange } = useRescheduleOrderModal(
    handleClose,
    handleSubmit,
  );

  return (
    <CustomDialog
      open={open}
      close={handleClose}
      testId="RescheduleOrderModal.CustomDialog"
      title={t('dialogs.dayTime.title')}
      paperId="reschedule-order"
    >
      <FormProvider {...formMethods}>
        <RescheduleOrder handleTimeChange={handleTimeChange} />
      </FormProvider>
    </CustomDialog>
  );
};

export default RescheduleOrderModal;
