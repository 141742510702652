import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import INC_BASE_API from '../../apis/incentivio-api';
import { getResponseErrorMessage } from '../../apis/incentivio-api.util';
import { setClientId } from '../../util/clientId';

const sliceName = 'client';

// THUNKS
const fetchClientId = createAsyncThunk(
  `${sliceName}/fetchClientId`,
  async (clientAlias, thunkAPI) => {
    try {
      const response = await INC_BASE_API.get(`/clientalias/${clientAlias}`);
      setClientId(response.data.clientId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(getResponseErrorMessage(error));
    }
  },
);

// SLICE
const clientSlice = createSlice({
  name: sliceName,
  initialState: {
    id: null,
    isFetching: false,
    error: null,
  },
  extraReducers: builder => {
    builder.addCase(fetchClientId.pending, (state, action) => {
      state.isFetching = true;
    });

    builder.addCase(fetchClientId.fulfilled, (state, action) => {
      state.isFetching = false;
      state.id = action.payload.clientId;
      state.shortDescription = action.payload.shortDescription;
    });

    builder.addCase(fetchClientId.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.error;
    });
  },
});

// SELECTORS
const selectClient = state => state.client;

const selectClientId = createSelector(selectClient, client => client.id);

const selectShortDescription = createSelector(
  selectClient,
  client => client.shortDescription,
);

const selectIsFetching = createSelector(
  selectClient,
  client => client.isFetching,
);

const selectError = createSelector(selectClient, client => client.error);

// EXPORTS
export const actions = {
  ...clientSlice.actions,
  fetchClientId,
};

export const selectors = {
  selectClient,
  selectClientId,
  selectIsFetching,
  selectShortDescription,
  selectError,
};

export default clientSlice.reducer;
