import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBaseLocale } from 'redux/i18n/i18n.selectors';
import { getDisplayInfoForLocale } from 'util/displayInfo';

export const useGetDisplayInfo = () => {
  const locale = useSelector(selectBaseLocale);

  const getDisplayInfo = useCallback(
    displayInfo => {
      if (
        !Array.isArray(displayInfo) &&
        typeof displayInfo === 'object' &&
        displayInfo !== null
      ) {
        return displayInfo;
      }

      if (!displayInfo || displayInfo.length < 1) return {};

      return getDisplayInfoForLocale(displayInfo, locale);
    },
    [locale],
  );

  return getDisplayInfo;
};

export const useDisplayInfo = di => {
  const getDisplayInfo = useGetDisplayInfo();

  const displayInfo = useMemo(() => getDisplayInfo(di), [di, getDisplayInfo]);
  const langCode = useMemo(() => displayInfo.langCode, [displayInfo.langCode]);
  const title = useMemo(() => displayInfo.title, [displayInfo.title]);
  const shortDescription = useMemo(
    () => displayInfo.shortDescription,
    [displayInfo.shortDescription],
  );
  const longDescription = useMemo(
    () => displayInfo.longDescription,
    [displayInfo.longDescription],
  );
  const smallImage = useMemo(
    () => displayInfo.smallImage,
    [displayInfo.smallImage],
  );
  const mediumImage = useMemo(
    () => displayInfo.mediumImage,
    [displayInfo.mediumImage],
  );

  return {
    displayInfo,
    langCode,
    title,
    shortDescription,
    longDescription,
    smallImage,
    mediumImage,
  };
};
