import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Wrapper = styled.div`
  margin-left: 2.8em;
  margin-bottom: 0.5em;
  margin-top: -1em;

  .ReactCollapse--collapse {
    width: 100%;
    transition: height 500ms;
  }
`;

export const OptionItem = styled(Typography)`
  font-size: 0.75rem;
`;
