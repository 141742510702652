import i18n from 'i18n';

export const getMonths = () => [
  {
    label: 'January',
    id: '01',
    days: 31,
  },
  {
    label: 'February',
    id: '02',
    days: 28,
  },
  {
    label: 'March',
    id: '03',
    days: 31,
  },
  {
    label: 'April',
    id: '04',
    days: 30,
  },
  {
    label: 'May',
    id: '05',
    days: 31,
  },
  {
    label: 'June',
    id: '06',
    days: 30,
  },
  {
    label: 'July',
    id: '07',
    days: 31,
  },
  {
    label: 'August',
    id: '08',
    days: 31,
  },
  {
    label: 'September',
    id: '09',
    days: 30,
  },
  {
    label: 'October',
    id: '10',
    days: 31,
  },
  {
    label: 'November',
    id: '11',
    days: 30,
  },
  {
    label: 'December',
    id: '12',
    days: 31,
  },
];

export const getMonthsWithNoneOption = () => [
  {
    label: <em>{i18n.t('monthSelect.options.none')}</em>,
    id: '',
  },
  ...getMonths(),
];
