import React, { useState } from 'react';
import CustomDialog from '../../../components/custom-dialog/custom-dialog.component';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../../components/form/form.component';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import CustomInput from '../../../components/custom-input/custom-input.component';
import InputLabel from '@material-ui/core/InputLabel';
import FormError from '../../../components/form-error/form-error.component';
import { updatePassword } from '../../../redux/user/user.actions';
import { toast } from 'react-toastify';
import IncButton from 'components/inc-button/inc-button.component';
import Password from 'components/form/form-fields/password/password.component';
import ConfirmPassword from 'components/form/form-fields/confirm-password/confirm-password.component';
import PasswordVisibility from 'components/form/form-fields/password-visibility/password-visibility.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const PasswordDialog = ({ open, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const passwordSubmit = ({ oldPassword, password: newPassword }) => {
    dispatch(updatePassword({ oldPassword, newPassword })).then(res => {
      if (res) {
        toast.success('Password Updated');
        close();
      }
    });
  };

  const { ref, ...passwordRegisterProps } = register('oldPassword', {
    required: true,
  });

  return (
    <CustomDialog
      open={open}
      close={close}
      title={t('dialogs.updatePassword.title')}
      testId="AccountSettings.PasswordDialog"
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(passwordSubmit)}>
          <MandatoryFieldsInstruction />
          <CustomInput>
            <InputLabel htmlFor="oldPassword">
              {t('dialogs.updatePassword.currentPassword')}*
            </InputLabel>
            <Input
              id="oldPassword"
              fullWidth
              type={showOldPassword ? 'text' : 'password'}
              error={!!errors.oldPassword}
              inputProps={{
                autocomplete: 'current-password',
                'aria-required': true,
              }}
              {...passwordRegisterProps}
              inputRef={ref}
              placeholder={t('forms.changePassword.current.label')}
              endAdornment={
                <PasswordVisibility
                  showPassword={showOldPassword}
                  setShowPassword={setShowOldPassword}
                  iconButtonProps={{
                    'data-testid': 'OldPasswordVisibility.IconButton',
                    'aria-label': t(
                      'forms.changePassword.current.ariaLabel.toggleVisibility',
                    ),
                  }}
                />
              }
            />
            {errors.oldPassword && (
              <FormError errorMessage={t('errors.requiredField')} />
            )}
          </CustomInput>

          <Password label={t('forms.changePassword.new.label')} />

          <ConfirmPassword />

          <IncButton
            type="submit"
            data-testid="PasswordDialog.CustomButton.Save"
            fullWidth
          >
            {t('dialogs.updatePassword.updateAction')}
          </IncButton>
        </Form>
      </FormProvider>
    </CustomDialog>
  );
};

export default PasswordDialog;
