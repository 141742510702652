import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import {
  fetchAllCateringLocations,
  fetchAllLocations,
  fetchAllMenuBrowsingLocations,
  fetchAllCateringMenuBrowsingLocations,
} from 'redux/locations/locations.actions';
import {
  makeSelectStoreBySlug,
  selectAreAllLocationsTypesLoadedOnce,
  selectHasMoreCateringLocations,
  selectHasMoreCateringMenuBrowsingLocations,
  selectHasMoreLocations,
  selectHasMoreMenuBrowsingLocations,
  selectIsLocationsLoading,
} from 'redux/locations/locations.selectors';

/**
 *
 * We can get rid of this mess if we just load the specific location with the
 * /locationsdetail endpoint, but this will require a lot of app changes that I
 * don't want to take on right now.
 *
 * What the below is doing is make sure we have loaded all of the client's
 * locations with the list locations endpoint
 */
export const useStorePage = () => {
  const { path } = useRouteMatch();
  const { storeTitle } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(
    // eslint-disable-next-line
    useCallback(makeSelectStoreBySlug(storeTitle), [storeTitle]),
  );
  const hasMoreLocations = useSelector(selectHasMoreLocations);
  const hasMoreCateringLocations = useSelector(selectHasMoreCateringLocations);
  const hasMoreMenuBrowsingLocations = useSelector(
    selectHasMoreMenuBrowsingLocations,
  );
  const hasMoreCateringMenuBrowsingLocations = useSelector(
    selectHasMoreCateringMenuBrowsingLocations,
  );
  const locationsLoading = useSelector(selectIsLocationsLoading);
  const areAllLocationsTypesLoadedOnce = useSelector(
    selectAreAllLocationsTypesLoadedOnce,
  );

  useEffect(() => {
    if (!store && areAllLocationsTypesLoadedOnce) {
      hasMoreLocations && dispatch(fetchAllLocations());
      hasMoreCateringLocations && dispatch(fetchAllCateringLocations());
      hasMoreMenuBrowsingLocations && dispatch(fetchAllMenuBrowsingLocations());
      hasMoreCateringMenuBrowsingLocations &&
        dispatch(fetchAllCateringMenuBrowsingLocations());

      if (
        !hasMoreLocations &&
        !hasMoreCateringLocations &&
        !hasMoreMenuBrowsingLocations &&
        !hasMoreCateringMenuBrowsingLocations
      )
        history.push('/');
    }
  }, [
    areAllLocationsTypesLoadedOnce,
    dispatch,
    hasMoreCateringLocations,
    hasMoreCateringMenuBrowsingLocations,
    hasMoreLocations,
    hasMoreMenuBrowsingLocations,
    history,
    store,
  ]);

  return { path, loading: locationsLoading, store };
};
