import { useCallback, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { selectOrderInstructions } from 'redux/cart/cart.selectors';
import { selectShowOrderInstructions } from 'redux/config/config.selectors';
import { orderInstructionsSchema } from '../checkout.schema';

export const useOrderInstructionsValidation = errors => {
  const store = useStore();

  const validate = useCallback(async () => {
    await orderInstructionsSchema.validate({
      orderInstructions: selectOrderInstructions(store.getState()),
    });
    return errors['orderInstructions'];
  }, [errors, store]);

  return { validate };
};

export const useCheckoutOrderInstructionsSection = () => {
  const showOrderInstructions = useSelector(selectShowOrderInstructions);
  const show = useMemo(() => showOrderInstructions, [showOrderInstructions]);

  return { show };
};
