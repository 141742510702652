import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoyaltyLabel } from 'redux/config/config.selectors';
import * as S from './loyalty-subtitles.styles';

const LoyaltySubTitles = ({ handleHistoryClick, hidden }) => {
  const loyaltyLabel = useSelector(selectLoyaltyLabel);
  const { t } = useTranslation();

  if (hidden) return null;

  return (
    <Box
      justifyContent="space-between"
      display="flex"
      alignItems="center"
      marginBottom="1em"
    >
      <S.Subtitle>{loyaltyLabel}</S.Subtitle>
      <S.HistoryLink
        component="button"
        variant="body2"
        onClick={handleHistoryClick}
        data-testid="LoyaltySubTitles.Link"
      >
        {t('accountLoyalty.historyLink')}
      </S.HistoryLink>
    </Box>
  );
};

export default LoyaltySubTitles;
