import Spinner from 'components/spinner/spinner.component';
import MenuPage from 'pages/menu/menu.component';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import StoreLanding from './store-landing/store-landing.component';
import { useStorePage } from './useStorePage.hooks';

const StorePage = () => {
  const { path, loading, store } = useStorePage();

  if (!store && loading) return <Spinner />;

  return (
    <Switch>
      <Route exact path={`${path}`} component={StoreLanding} />
      <Route path={`${path}/menu`} component={MenuPage} />

      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default StorePage;
