import * as S from './info-view.styles';

const InfoView = ({ title, description }) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {description}
    </S.Container>
  );
};

export default InfoView;
