import { DateTime } from 'luxon';

export const useScheduleDateController = props => {
  const today = DateTime.local();
  const { defaultDay, offDays } = props;

  const nextFocusedDate = (days = 0) => {
    const initFocusDate = today.plus({ days: days + 1 });

    if (!offDays.includes(initFocusDate.toISODate())) return initFocusDate;

    return nextFocusedDate(days + 1);
  };

  const getInitialFocusedDate = () => {
    if (
      defaultDay &&
      defaultDay !== today.toISODate() &&
      !offDays.includes(defaultDay)
    )
      return DateTime.fromISO(defaultDay);

    return nextFocusedDate();
  };

  return { getInitialFocusedDate };
};
