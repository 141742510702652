import { useState, useContext, useMemo } from 'react';
import { OrderFormContext } from './order-form-context';

export const useOrderForm = (opts = {}) => {
  const parentContext = useContext(OrderFormContext);

  const [options, setOptions] = useState(
    opts.options ?? parentContext?.options ?? {},
  );

  const [orderItems, setOrderItems] = useState(
    opts.orderItems ?? parentContext?.orderItems ?? [],
  );

  const context = useMemo(
    () => ({ options, setOptions, orderItems, setOrderItems }),
    [options, orderItems],
  );

  return context;
};
