import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useContext, useMemo } from 'react';
import { OrderTypeContext } from './order-form-context';
import { useFormContext } from 'react-hook-form';

export const useGetOrderType = () => {
  const { fieldNames } = useFieldNames();
  const { watch } = useFormContext();
  const orderType = watch(fieldNames.orderType);
  const contextOrderType = useContext(OrderTypeContext);

  return useMemo(
    () => contextOrderType ?? orderType,
    [contextOrderType, orderType],
  );
};
