import React from 'react';
import * as S from './menu.styles';

const Menu = ({
  children,
  anchorEl,
  open,
  handleClose,
  testId = 'CustomMenu',
}) => {
  return (
    <>
      <S.CustomMenu
        anchorEl={anchorEl}
        open={open !== undefined ? open : Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ 'data-testid': testId }}
      >
        {children}
      </S.CustomMenu>
    </>
  );
};

export default Menu;
