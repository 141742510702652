import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectClientName } from 'redux/config/config.selectors';
import { signInStartAsync } from 'redux/user/user.actions';

export const useGetUserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = useURLSearchParams().get('token');

  const getUserDetails = async () => {
    setLoading(true);

    try {
      const response = await INC_BASE_API.get(
        `/useraccounts/phoneonly/token/${token}`,
      );
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      const errorDescription = error?.response?.data['error_description'];
      setError(errorDescription ?? getResponseErrorMessage(error));
      toast.error(errorDescription ?? getResponseErrorMessage(error));
    }
  };

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, data, error };
};

export const useMobileSignup = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useURLSearchParams().get('token');
  const clientName = useSelector(selectClientName);

  const mobileSignup = async formData => {
    try {
      const { status } = await INC_BASE_API.put(
        `/useraccounts/phoneonly/token/${token}`,
        {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          phoneNumber: formData?.phoneNumber,
          password: formData?.password,
        },
      );

      if (status === 200) {
        await dispatch(
          signInStartAsync({
            email: formData?.email,
            password: formData?.password,
            remember_me: false,
          }),
        );

        history.push('/');
        toast.success(t('signUp.successMessage', { clientName }));
      }
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
    }
  };

  return { mobileSignup };
};
