import { Box, Hidden } from '@material-ui/core';
import React from 'react';
import * as S from './message-card.styles';
import MessageDate from './message-date/message-date.component';
import MessageStatus from './message-status/message-status.component';
import MessageExpiration from './message-expiration/message-expiration.component';

const MessageCard = ({
  title,
  image,
  description,
  date,
  status,
  onClick,
  disabled,
  expireDate,
  redemptionEndDateType,
  distributedMessageId,
}) => {
  const isMessage = !!distributedMessageId;

  return (
    <S.MessageCard
      disabled={disabled}
      data-testid="AccountInboxMessage.MessageContainer"
    >
      <S.ActionArea onClick={onClick}>
        <Box
          height={[S.xsHeight, S.height]}
          display="flex"
          flex={1}
          justifyContent="flex-start"
        >
          <S.MessageImage image={image} title={title} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={[S.xsHeight, S.height]}
            width={1}
          >
            <S.Content>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flex={1}
              >
                <S.Header component="h2" variant="h5">
                  {title}
                </S.Header>
              </Box>
              <S.SubHeader>
                <MessageDate date={date} />
              </S.SubHeader>
              <Hidden xsDown>
                <S.Description variant="body2">{description}</S.Description>
              </Hidden>
            </S.Content>
            <S.Bottom>
              {!!expireDate && !isMessage && (
                <Box
                  marginRight="10px"
                  data-testid="AccountInboxMessage.ExpirationDateTime"
                >
                  <MessageExpiration
                    expireDate={expireDate}
                    redemptionEndDateType={redemptionEndDateType}
                  />
                </Box>
              )}
              <MessageStatus status={status} disabled={disabled} />
            </S.Bottom>
          </Box>
        </Box>
      </S.ActionArea>
    </S.MessageCard>
  );
};

export default MessageCard;
