import RegisterFields from 'components/register-fields/register-fields.component';
import { useTranslation } from 'react-i18next';
import * as S from './signup-form.styles';

const SignupForm = ({ onSuccess }) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Title variant="h5" component="h1">
        {t('signUp.title')}
      </S.Title>
      <RegisterFields onSuccess={onSuccess} />
    </>
  );
};

export default SignupForm;
