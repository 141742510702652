import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { defaultMessages } from '../view-messages.utils';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useViewMessageInbox = () => {
  const { t } = useTranslation();
  const query = useURLSearchParams();

  const message = useMemo(
    () => ({
      distributedMessageId: '4d71df61-9fc8-405a-a5d6-f2baea9c9330',
      merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
      messageId: '6c124971-c7d6-42da-b1a2-fe98cfcf4317',
      languageCode: 'EN',
      title: query.get('title') ?? t('inboxMessage.title'),
      shortDescription:
        query.get('shortDescription') ?? t('inboxMessage.shortDescription'),
      longDescription:
        query.get('longDescription') ?? t('inboxMessage.longDescription'),
      smallImage: query.get('smallImage') ?? '',
      mediumImage: query.get('mediumImage') ?? '',
      status: 'NEW',
      distributedDate: '2024-09-09T04:56:22',
      updatedDate: '2024-09-09T04:56:22',
      messageType: query.get('messageType') ?? 'PRIVATE',
      distributedEndDate: '2024-09-22T18:29:59',
    }),
    [query, t],
  );

  defaultMessages.push(message);

  return {
    finalContent: defaultMessages,
    containerAriaAttributes: {},
    finalTotal: 3,
    message,
  };
};
