import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

export const BackToLogin = styled(Box)`
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 0.5em;
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Title = styled(Typography)`
  margin: 1em 0;
  font-weight: 600;
`;
