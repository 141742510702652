import { combineReducers } from 'redux';
import offersReducer from './offers/offers.slice';
import messagesReducer from './messages/messages.slice';
import orderAvailabilityReducer from './orderavailability/orderavailability.slice';

export default combineReducers({
  offers: offersReducer,
  messages: messagesReducer,
  orderavailability: orderAvailabilityReducer,
});
