import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { Grid, Typography } from '@material-ui/core';

export const GroupTitle = styled(Typography)`
  font-size: 2rem;
`;

export const GroupsContainer = styled(Container)`
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  .back-button {
    margin: 0 0 1.5em !important;
  }
`;

export const ShortDescription = styled(Typography)`
  font-size: 1rem;
  margin: 0 0 1em;
  padding-bottom: 1rem;
`;

export const GroupGrid = styled(Grid)`
  padding: 0;
  list-style: none;
`;
