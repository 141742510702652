import debounce from 'lodash.debounce';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useQueryParamDialog } from './dialog.hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  makeSelectCatalogItem,
  makeSelectGroup,
  makeSelectIsLargeMenuMode,
  makeSelectItemDetailsById,
} from 'redux/catalogs/catalogs.selectors';
import { menuItemKeyParam } from './menu-dialog.constants';
import { getOrderItemPrice } from 'redux/cart/cart.actions';
import { getOptions, mapOptionsToRequest } from 'redux/catalogs/catalogs.utils';
import { getStaticItemPrice } from './menu-dialog.utils';
import { getOrderItemDetailsStartAsync } from 'redux/catalogs/catalogs.actions';

export const useMenuItemDialog = formMethods => {
  const {
    open,
    close: handleClose,
    path,
    catalogId,
    groupIds,
    groupId,
    itemId,
  } = useQueryParamDialog();
  const { state: { editInfo: editInfoState, recommendationView } = {} } =
    useLocation();

  const [editInfo, setEditInfo] = useState(editInfoState);

  useEffect(() => {
    setEditInfo(editInfoState);
  }, [editInfoState]);

  const {
    item,
    visibility,
    globalOptionGroups,
    isItemModifierDetailsFetching,
  } = useGetItemDetails({
    catalogId,
    groupIds,
    groupId,
    itemId,
    open,
  });

  const notFoundDialogOpen = useMemo(() => open && !item, [item, open]);
  const menuItemDialogOpen = useMemo(() => open && !!item, [item, open]);

  const groupSelector = useMemo(
    () => makeSelectGroup(catalogId, groupIds),
    [catalogId, groupIds],
  );

  const group = useSelector(groupSelector);

  const { itemPrice, setItemPrice } = useHandleItemPrice(
    editInfo?.total,
    item?.price,
  );

  const { itemQuantity, setItemQuantity } = useHandleItemQuantity(
    editInfo?.quantity,
  );

  useResetItemPriceAndQuantity(
    setItemPrice,
    setItemQuantity,
    menuItemDialogOpen,
  );

  const { handleSetItemPrice, itemPriceLoading } = useSetItemPrice({
    item,
    catalogId,
    groupId,
    setItemPrice,
    itemQuantity,
    ...formMethods,
  });

  return {
    menuItemDialogOpen,
    notFoundDialogOpen,
    handleClose,
    isItemModifierDetailsFetching,
    item,
    visibility,
    globalOptionGroups,
    catalogId,
    groupId,
    editInfo,
    recommendationView,
    path,
    group,
    itemPrice,
    setItemPrice,
    itemQuantity,
    setItemQuantity,
    itemPriceLoading,
    setEditInfo,
    handleSetItemPrice,
  };
};

export const useGetItemDetailsFromRetrievedItemsDetails = itemId => {
  const selectedItemId = useMemo(
    () => makeSelectItemDetailsById(itemId),
    [itemId],
  );

  return useSelector(selectedItemId);
};

export const useGetItemDetails = ({
  catalogId,
  groupIds,
  groupId,
  itemId,
  open,
}) => {
  const [item, setTtem] = useState();
  const [isItemModifierDetailsFetching, setIsItemModifierDetailsFetching] =
    useState(false);
  const isLargeMenuMode = useIsLargeMenuModeOn(catalogId);

  const dispatch = useDispatch();
  const selectCatalogItem = useMemo(
    () => makeSelectCatalogItem(catalogId, groupIds, itemId),
    [catalogId, groupIds, itemId],
  );

  const {
    item: baseItem,
    visibility,
    inheritedOptionGroups: globalOptionGroups,
  } = useSelector(selectCatalogItem);

  const retrievedItemDetail =
    useGetItemDetailsFromRetrievedItemsDetails(itemId);

  useEffect(() => {
    setTtem(!!retrievedItemDetail ? retrievedItemDetail : baseItem);
  }, [baseItem, retrievedItemDetail]);

  useEffect(() => {
    if (!isLargeMenuMode || !open || !!retrievedItemDetail) return;

    const fetchItemDetails = async () => {
      setIsItemModifierDetailsFetching(true);
      await dispatch(
        getOrderItemDetailsStartAsync({ catalogId, groupId, itemId }),
      );
      setIsItemModifierDetailsFetching(false);
    };
    fetchItemDetails();
  }, [
    dispatch,
    isLargeMenuMode,
    open,
    baseItem,
    retrievedItemDetail,
    catalogId,
    groupId,
    itemId,
  ]);

  return {
    item,
    visibility,
    globalOptionGroups,
    isItemModifierDetailsFetching,
  };
};

export const useOpenMenuItemDialogPath = (path, state = {}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useURLSearchParams();

  return useCallback(() => {
    searchParams.append(menuItemKeyParam, path);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
      state,
    });
  }, [history, location.pathname, path, searchParams, state]);
};

export const useHandleItemPrice = (editTotal, initialItemPrice = 0) => {
  const [itemPrice, setItemPrice] = useState(0);

  useEffect(() => {
    setItemPrice(editTotal ?? initialItemPrice);
  }, [editTotal, initialItemPrice]);

  return { itemPrice, setItemPrice };
};

export const useHandleItemQuantity = editQuantity => {
  const [itemQuantity, setItemQuantity] = useState(1);

  useEffect(() => {
    setItemQuantity(editQuantity ?? 1);
  }, [editQuantity]);

  return { itemQuantity, setItemQuantity };
};

export const useResetItemPriceAndQuantity = (
  setItemPrice,
  setItemQuantity,
  menuItemDialogOpen,
) => {
  useEffect(() => {
    if (!menuItemDialogOpen) {
      setItemPrice(0);
      setItemQuantity(1);
    }
  }, [menuItemDialogOpen, setItemPrice, setItemQuantity]);
};

const useSetItemPrice = args => {
  const {
    item: mainItem = {},
    catalogId,
    groupId,
    setItemPrice,
    itemQuantity,
    getValues,
    trigger,
  } = args;
  const dispatch = useDispatch();
  const [itemPriceLoading, setItemPriceLoading] = useState(false);

  const handleDynamicPriceCalculation = useMemo(
    () =>
      debounce(async item => {
        if (!(await trigger())) {
          setItemPrice(mainItem.price);
          setItemPriceLoading(false);
          return;
        }
        setItemPriceLoading(true);
        const price = await dispatch(
          getOrderItemPrice({
            ...item,
            options: mapOptionsToRequest(item.options),
          }),
        );
        setItemPrice(price ?? 0);
        setItemPriceLoading(false);
      }, 1000),
    [dispatch, mainItem.price, setItemPrice, setItemPriceLoading, trigger],
  );

  const handleSetItemPrice = useCallback(
    async qty => {
      const item = {
        catalogId,
        groupId,
        itemId: mainItem.itemId,
        quantity: qty ?? itemQuantity,
        options: getOptions(getValues()?.options ?? []),
      };

      if (mainItem.extendedAttributes?.PRICE_CALCULATION_MODE === 'dynamic') {
        if (!(await trigger())) {
          setItemPrice(mainItem.price);
          return;
        }
        setItemPriceLoading(true);
        handleDynamicPriceCalculation(item);
      } else {
        setItemPrice(getStaticItemPrice({ ...item, price: mainItem.price }));
      }
    },
    [
      catalogId,
      groupId,
      mainItem.itemId,
      mainItem.extendedAttributes?.PRICE_CALCULATION_MODE,
      mainItem.price,
      itemQuantity,
      getValues,
      trigger,
      setItemPriceLoading,
      handleDynamicPriceCalculation,
      setItemPrice,
    ],
  );

  return { handleSetItemPrice, itemPriceLoading };
};

export const useIsLargeMenuModeOn = catalogId => {
  const selectIsLargeMenuMode = useMemo(
    () => makeSelectIsLargeMenuMode(catalogId),
    [catalogId],
  );

  return useSelector(selectIsLargeMenuMode);
};
