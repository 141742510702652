import i18next from 'i18next';

export const hasError = (errors, groupId) =>
  errors && errors.options && errors.options[groupId];

export const getError = (errors, groupId) => errors.options[groupId].message;

export const helperText = optionGroup => {
  const { minItemSelections, maxItemSelections } = optionGroup;
  let helperText = '';

  if (minItemSelections && maxItemSelections) {
    if (minItemSelections === maxItemSelections) {
      if (minItemSelections !== 0) {
        helperText = i18next.t(
          'catalog.modal.optionGroup.helperText.minAndMaxEq',
          {
            min: minItemSelections,
            max: maxItemSelections,
          },
        );
      }
    } else {
      helperText = i18next.t('catalog.modal.optionGroup.helperText.minAndMax', {
        min: minItemSelections,
        max: maxItemSelections,
      });
    }
  } else if (minItemSelections && !maxItemSelections) {
    helperText = i18next.t('catalog.modal.optionGroup.helperText.min', {
      min: minItemSelections,
      max: maxItemSelections,
    });
  } else if (!minItemSelections && maxItemSelections) {
    helperText = i18next.t('catalog.modal.optionGroup.helperText.max', {
      min: minItemSelections,
      max: maxItemSelections,
    });
  }

  return helperText;
};
