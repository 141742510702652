import { Box } from '@material-ui/core';
import ItemCarousel from 'components/item-carousel/item-carousel.component';
import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { CheckoutH2 } from '../checkout.styles';
import {
  useCheckoutRecommendationsSectionComponent,
  useViewRecommendationAnalytics,
} from './checkout-recommendations-section.hooks';
import RecommendationItem from './recommendation-item/recommendation-item.component';

const CheckoutRecommendationsSection = ({ ...rest }) => {
  const { t } = useTranslation();
  const { show, recommendationItemDetails } =
    useCheckoutRecommendationsSectionComponent();

  useViewRecommendationAnalytics(recommendationItemDetails);

  if (!show) return null;

  return (
    <Zoom duration={600} triggerOnce>
      <Box data-testid="CheckoutRecommendationsSection.Box" {...rest}>
        <CheckoutH2 data-testid="CheckoutRecommendationsSection.CheckoutH2.Title">
          {t('recommendations.title')}
        </CheckoutH2>

        {recommendationItemDetails.length > 1 ? (
          <ItemCarousel
            items={recommendationItemDetails}
            renderItem={item => <RecommendationItem item={item} />}
          />
        ) : (
          <RecommendationItem item={recommendationItemDetails?.[0]} />
        )}
      </Box>
    </Zoom>
  );
};

export default CheckoutRecommendationsSection;
