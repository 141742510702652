import React, { useContext } from 'react';
import AccountLoyaltyPointsBox from '../account-loyalty-points-box/account-loyalty-points-box.component';
import AccountLoyaltyActivity from '../account-loyalty-activity/account-loyalty-activity.component';
import Messages from 'pages/account-inbox/messages/messages.component';
import LoyaltySubTitles from '../loyalty-subtitles/loyalty-subtitles.component';
import { useHistoryView } from 'components/account-loyalty/account-loyalty.hooks';
import BackToRewards from '../back-to-rewards/back-to-rewards.component';
import { LoyaltyContext } from 'components/account-loyalty/account-loyalty.context';

const AccountLoyaltyDefault = ({ points, pointName }) => {
  const loyaltyContent = useContext(LoyaltyContext);

  const { handleHistoryClick, handleRewardsClick, showHistory } =
    useHistoryView();

  return (
    <>
      <BackToRewards
        handleRewardsClick={handleRewardsClick}
        hidden={!showHistory}
      />
      <AccountLoyaltyPointsBox points={points} pointName={pointName} />
      <LoyaltySubTitles
        handleHistoryClick={handleHistoryClick}
        hidden={showHistory}
      />
      {!showHistory ? (
        <Messages {...loyaltyContent} />
      ) : (
        <AccountLoyaltyActivity />
      )}
    </>
  );
};

export default AccountLoyaltyDefault;
