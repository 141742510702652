import PaymentErrorBoundary from 'components/payment/payment-error-boundary/payment-error-boundary.component';
import ThreeDS2 from './threeds2/threeds2.component';

const PaymentsContainer = () => {
  return (
    <PaymentErrorBoundary name="payment-container">
      <ThreeDS2 />
    </PaymentErrorBoundary>
  );
};

export default PaymentsContainer;
