import React, { useContext } from 'react';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import OrderTypeSelector from 'components/new-order/order-type-selector/order-type-selector.component';
import { useOrderTypeSelectors } from 'pages/store/new-order-form/useOrderTypeSelectors.hooks';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import Form from 'components/form/form.component';
import { Box } from '@material-ui/core';
import AddressControls from 'components/address/address-controls/address-controls.component';
import { useTranslation } from 'react-i18next';
import {
  OrderFormContext,
  OrderTypeContext,
} from 'pages/store/new-order-form/order-form-context';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import OrderHistorySelections from './order-history-selections/order-history-selections.component';
import { useOrderForm } from 'pages/store/new-order-form/useOrderFormContext.hooks';
import HiddenMenuErrorContext from 'components/hidden-menu-error-dialog/hidden-menu-error-dialog.context';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from 'redux/cart/cart.actions';
import { selectIsCatering } from 'redux/locations/locations.selectors';
import { useGetAddressLabel } from 'components/address/address-controls/address-controls.hooks';

const AccountOrderHistoryDialog = ({
  setReorderOpen,
  selectedStore,
  orderHistoryData: { orderType: previousOrderType, orderItems },
}) => {
  const { fieldNames } = useFieldNames();
  const formMethods = useForm({ shouldUnregister: true });
  const { watch, control } = formMethods;
  const { orderTypes } = useOrderTypeSelectors(selectedStore);
  const defaultOrderType =
    orderTypes.find(orderType => orderType?.type === previousOrderType)?.type ??
    orderTypes?.[0]?.type;

  const orderType = watch(fieldNames.orderType, defaultOrderType);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCatering = useSelector(selectIsCatering(selectedStore.storeId));
  const label = useGetAddressLabel(isCatering);

  const { hiddenMenuErrorState } = useContext(HiddenMenuErrorContext);

  const handleClose = () => {
    if (hiddenMenuErrorState.invalidItemDetails?.length > 0)
      dispatch(clearCart());

    setReorderOpen(false);
  };

  const orderContext = useOrderForm({ orderItems });

  return (
    <CustomDialog
      title={t('accountPastOrders.modal.title')}
      open
      testId="AccountOrderHistoryDialog.CustomDialog"
      close={handleClose}
      paperId="locationsListContainer"
    >
      <FormProvider {...formMethods}>
        <Form>
          <OrderTypeContext.Provider value={orderType}>
            <OrderFormContext.Provider value={orderContext}>
              <OrderTypeSelector
                types={orderTypes}
                defaultValue={defaultOrderType}
                name={fieldNames.orderType}
              />
              {orderType === 'DELIVERY' && (
                <Box mb={2}>
                  <AddressControls orderType={orderType} label={label} />
                </Box>
              )}

              <OrderHistorySelections
                store={selectedStore}
                orderType={orderType}
                closeReorderDialog={handleClose}
              />
            </OrderFormContext.Provider>
          </OrderTypeContext.Provider>
        </Form>
        <RHFDevtoolsWrapper control={control} />
      </FormProvider>
    </CustomDialog>
  );
};

export default React.memo(AccountOrderHistoryDialog);
