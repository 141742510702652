import SpreedlyCard from '../spreedly-card/spreedly-card.component';
import * as S from './spreedly-card-guest.styles';

const SpreedlyCardGuest = () => {
  return (
    <S.Container>
      <SpreedlyCard />
    </S.Container>
  );
};

export default SpreedlyCardGuest;
