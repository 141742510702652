import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  margin: 1rem;
  height: 3rem;
  width: 3rem;
`;

export const TextContainer = styled(Typography)`
  font-size: 2rem;
`;
