import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { defaultOffers } from '../view-offers.utils';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useViewOfferInbox = () => {
  const { t } = useTranslation();
  const query = useURLSearchParams();

  const offer = useMemo(
    () => ({
      distributedOfferId: '7cd24c75-395f-4433-afc3-ce6b3940aeee',
      merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
      messageId: '7b4dcb56-4dc0-44dd-af69-d61d07fedf14',
      offerId: '7b4dcb56-4dc0-44dd-af69-d61d07fedf15',
      clientId: '1b63163d-1aba-4944-bbc1-ebc495d9dc5c',
      offerCode: 'palmlanedev.1b91394b-9b52-40b0-a5e3-e8a00a381cc1',
      codeFormat: 'QRCODE',
      redemptionEndDate: '2023-02-02T04:59:59',
      redeemType: 'PURCHASE',
      purchaseTypes: ['INTEGRATED'],
      isLoyaltyOffer: false,
      eligible: false,
      universalOffer: false,
      redemptionEndDateType: 'STATIC',
      languageCode: 'EN',
      title: query.get('title') ?? t('inboxMessage.title'),
      shortDescription:
        query.get('shortDescription') ?? t('inboxMessage.shortDescription'),
      longDescription:
        query.get('longDescription') ?? t('inboxMessage.longDescription'),
      smallImage: query.get('smallImage') ?? '',
      mediumImage: query.get('mediumImage') ?? '',
      status: 'READ',
      distributedDate: '2023-11-17T10:53:37',
      updatedDate: '2023-11-17T10:53:37',
    }),
    [query, t],
  );

  defaultOffers.push(offer);

  return {
    finalContent: defaultOffers,
    containerAriaAttributes: {},
    finalTotal: 3,
    offer,
  };
};
