import BackAction from 'components/back-action/back-action.component';
import { menuItemKeySplitChar } from 'hooks/dialog/menu-dialog.constants';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import {
  makeSelectAvailableItems,
  makeSelectMenuSubgroups,
  makeSelectUnAvailableItems,
} from 'redux/catalogs/catalogs.selectors';
import MenuCardNewGroup from '../menu-card-new/menu-card-new-group/menu-card-new-group.component';
import MenuCardNewItem from '../menu-card-new/menu-card-new-item/menu-card-new-item.component';
import { useGroupTabs } from '../single-catalog-menu/single-catalog-menu.hooks';
import * as S from './menu-group.styles';

const MenuGroup = ({
  catalogId,
  group,
  parentGroup,
  parentGroupTitle,
  selectedGroup,
  setSelectedGroup,
  subgroup,
  path = `${catalogId}${menuItemKeySplitChar}${group.groupId}`,
}) => {
  // eslint-disable-next-line
  const selectSubgroups = useCallback(makeSelectMenuSubgroups(group), [group]);
  const { spyOffset } = useGroupTabs();
  const { t } = useTranslation();

  const { title: groupTitle, shortDescription: groupShortDescription } =
    useDisplayInfo(group?.displayInfo);

  useEffect(() => {
    if (subgroup && selectedGroup?.groupId === group.groupId) {
      scroller.scrollTo(`group-${group.groupId}`, {
        offset: spyOffset - 200,
      });
    }
  }, [group.groupId, selectedGroup?.groupId, spyOffset, subgroup]);

  // eslint-disable-next-line
  const selectAvailabileItems = useCallback(
    makeSelectAvailableItems(group.items),
    [group],
  );

  // eslint-disable-next-line
  const selectUnAvailabileItems = useCallback(
    makeSelectUnAvailableItems(group.items),
    [group],
  );

  const subGroups = useSelector(selectSubgroups);

  const availableItems = useSelector(selectAvailabileItems);
  const unAvailableItems = useSelector(selectUnAvailabileItems);

  if (selectedGroup?.groupId === group.groupId) {
    return (
      <>
        <S.GroupTitle component="h2" id={`group-${group.groupId}`}>
          {groupTitle}
        </S.GroupTitle>

        <S.ShortDescription id={groupShortDescription}>
          {groupShortDescription}
        </S.ShortDescription>

        {!!parentGroup && (
          <BackAction
            label={parentGroupTitle}
            handleBack={() => setSelectedGroup(parentGroup)}
            aria-label={t('catalog.group.backButton.ariaLabel', {
              groupName: parentGroupTitle,
            })}
          />
        )}

        <S.GroupGrid
          container
          spacing={3}
          component="ul"
          aria-labelledby={`group-${group.groupId}`}
        >
          {availableItems.map(item => (
            <MenuCardNewItem
              key={item.itemId}
              item={item}
              path={`${path}${menuItemKeySplitChar}${item.itemId}`}
            />
          ))}

          {subGroups.map(subGroup => (
            <MenuCardNewGroup
              key={subGroup.groupId}
              group={subGroup}
              handleSetGroup={setSelectedGroup}
              tabIndex="0"
              groupTitle={parentGroupTitle}
              subgroup
            />
          ))}

          {unAvailableItems.map(item => (
            <MenuCardNewItem
              key={item.itemId}
              item={item}
              path={`${path}${menuItemKeySplitChar}${item.itemId}`}
            />
          ))}
        </S.GroupGrid>
      </>
    );
  } else {
    return subGroups.map(subGroup => (
      <MenuGroup
        catalogId={catalogId}
        group={subGroup}
        parentGroup={group}
        parentGroupTitle={groupTitle}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        subgroup
        path={`${path}${menuItemKeySplitChar}${subGroup.groupId}`}
      />
    ));
  }
};

export default MenuGroup;
