import React from 'react';
import { Autoplay, FreeMode } from 'swiper';
import 'swiper/modules/autoplay/autoplay.min.css';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';
import * as S from './item-carousel.styles';

const ItemCarousel = ({ items, renderItem }) => {
  return (
    <S.ItemSwiper
      modules={[Autoplay, FreeMode]}
      slidesPerView="auto"
      spaceBetween={15}
      loop={true}
      loopedSlides={items.length}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
      }}
      freeMode={{
        enabled: true,
        momentumBounce: false,
        momentumRatio: 0.5,
        momentumVelocityRatio: 0.5,
      }}
    >
      {items.map(item => (
        <SwiperSlide key={item.item.itemId}>{renderItem(item)}</SwiperSlide>
      ))}
    </S.ItemSwiper>
  );
};

export default ItemCarousel;
