import { Typography } from '@material-ui/core';
import PickupLocationsSearch from 'components/address/pickup-locations-search/pickup-locations-search';
import CustomInput from 'components/custom-input/custom-input.component';
import React from 'react';
import { Container } from '../locations-finder/locations-address-controls/locations-address-controls.styles';
import { useTranslation } from 'react-i18next';

const LocationsSearch = () => {
  const { t } = useTranslation();

  return (
    <Container data-testid="LocationsSearch.Container">
      <CustomInput>
        <Typography component="h1" variant="h6" id="address-control-label">
          {t('locations.pickupInput.locationSearch.label')}
        </Typography>
        <PickupLocationsSearch />
      </CustomInput>
    </Container>
  );
};

export default LocationsSearch;
