import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  menuItemKeyParam,
  menuItemKeySplitChar,
} from './menu-dialog.constants';

export const useQueryParamDialog = () => {
  const searchParams = useURLSearchParams();

  const value = useMemo(
    () => searchParams.get(menuItemKeyParam),
    [searchParams],
  );
  const open = useMemo(() => !!value, [value]);
  const close = useCloseQueryParamDialog(menuItemKeyParam);

  const keyArray = useMemo(
    () => value?.split(menuItemKeySplitChar) ?? [],
    [value],
  );

  useEffect(() => {
    searchParams.has(menuItemKeyParam) &&
      !searchParams.get(menuItemKeyParam) &&
      close();
  }, [close, searchParams]);

  return {
    open,
    close,
    path: value,
    catalogId: keyArray[0],
    groupIds: keyArray.slice(1, -1),
    itemId: keyArray[keyArray.length - 1],
    groupId: keyArray[keyArray.length - 2],
  };
};

export const useCloseQueryParamDialog = key => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useURLSearchParams();

  return useCallback(() => {
    searchParams.delete(key);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [history, key, location.pathname, searchParams]);
};
