import { Chip } from '@material-ui/core';
import styledBy from 'styled-by';
import styled from 'styled-components';

export const StatusChip = styled(Chip)`
  ${styledBy('status', {
    NEW: ({ theme }) =>
      `
        background-color: ${theme.palette.info.main}; 
        color: ${theme.palette.info.contrastText};
      `,
  })};
`;
