import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const HistoryLink = styled(Button)`
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`;

export const Subtitle = styled(Typography)`
  font-weight: bold;
`;
