import CommonTypes from '../common.types';
import CatalogActionTypes from './catalogs.types';

export const INITIAL_STATE = {
  catalogsArray: null,
  isFetching: false,
  outOfStockItemIds: [],
  checksum: '1',
  allSingleItemDetails: {},
};

const catalogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CatalogActionTypes.FETCH_CATALOGS_START:
      return {
        ...state,
        isFetching: true,
      };

    case CatalogActionTypes.FETCH_CATALOGS_SUCCESS:
      const newState = {
        ...state,
        isFetching: false,
        checksum: action.payload.checksum,
      };

      if (!!action.payload.checksum) {
        if (action.payload.catalogsArray.length) {
          newState.catalogsArray = action.payload.catalogsArray;
        }
      } else {
        newState.catalogsArray = [];
        newState.checksum = '1';
      }

      return newState;

    case CatalogActionTypes.FETCH_OUT_OF_ITEMS_SUCCESS:
      return {
        ...state,
        outOfStockItemIds: action.payload,
      };

    case CatalogActionTypes.FETCH_OUT_OF_ITEMS_FAILURE:
      return {
        ...state,
        outOfStockItemIds: [],
      };

    case CatalogActionTypes.FETCH_CATALOGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CatalogActionTypes.GET_SINGLE_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        allSingleItemDetails: {
          ...state.allSingleItemDetails,
          [action.payload.itemDetails.itemId]: action.payload.itemDetails,
        },
      };

    case CommonTypes.ERROR_HARD_RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default catalogsReducer;
