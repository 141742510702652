import { OrderTypeContext } from 'pages/store/new-order-form/order-form-context';
import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as appSelectors } from 'redux/app/app.slice';
import {
  selectCustomCatalogIsActive,
  selectIsCateringAvailable,
} from 'redux/config/config.selectors';
import {
  fetchLocationsStartAsync,
  fetchCateringLocationsStartAsync,
  fetchMenuBrowsingLocationsStartAsync,
  fetchCateringMenuBrowsingLocationsStartAsync,
} from 'redux/locations/locations.actions';
import { selectIsAllDeliveryLocationsOutOfRange } from 'redux/locations/locations.selectors';
import { CATERING, DELIVERY } from 'util/constants';

export const useFetchLocationsByProximity = () => {
  const dispatch = useDispatch();
  const isCateringAvailable = useSelector(selectIsCateringAvailable);
  const isMenuBrowsingAvailable = useSelector(selectCustomCatalogIsActive);
  const { latitude, longitude } = useSelector(
    appSelectors.selectLocationFetchingLatLon,
  );

  useEffect(() => {
    dispatch(fetchLocationsStartAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude]);

  useEffect(() => {
    isCateringAvailable && dispatch(fetchCateringLocationsStartAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, isCateringAvailable]);

  useEffect(() => {
    if (isMenuBrowsingAvailable) {
      dispatch(fetchMenuBrowsingLocationsStartAsync());
      dispatch(fetchCateringMenuBrowsingLocationsStartAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, isMenuBrowsingAvailable]);
};

export const useShowAllLocationsOutOfRangeError = () => {
  const isAllDeliveryLocationsOutOfRange = useSelector(
    selectIsAllDeliveryLocationsOutOfRange,
  );
  const orderType = useContext(OrderTypeContext);
  const locationType = useSelector(appSelectors.selectLocationsLocationType);

  return (
    locationType !== CATERING &&
    isAllDeliveryLocationsOutOfRange &&
    orderType === DELIVERY
  );
};
