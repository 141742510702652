import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTip } from 'redux/cart/cart.actions';
import {
  selectTip,
  selectTipCalculateAmount,
  selectTipPercentage,
} from 'redux/cart/cart.selectors';
import {
  selectInitialTip,
  selectIsTipDisplay,
  selectOrderTypeTipConfig,
} from 'redux/common.selectors';
import { selectDisableTipping } from 'redux/locations/locations.selectors';

export const useCheckoutTipComponent = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const percentages = useSelector(selectOrderTypeTipConfig)?.values;
  const value = useSelector(selectTip);
  const percentage = useSelector(selectTipPercentage);
  const initialPercentage = useSelector(selectInitialTip);
  const basis = useSelector(selectTipCalculateAmount);
  const calculateAmount = useCallback(
    percentage => parseFloat(basis * (percentage / 100)).toFixed(2),
    [basis],
  );
  const onChange = (amount, percentage) => dispatch(setTip(amount, percentage));

  useEffect(() => {
    percentage === null &&
      dispatch(setTip(calculateAmount(initialPercentage), initialPercentage));
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value,
    percentages,
    calculateAmount,
    onChange,
    percentageValue: percentage,
    ready,
  };
};

export const useCheckoutTip = () => {
  const dispatch = useDispatch();
  const isTipDisplay = useSelector(selectIsTipDisplay);
  const tip = parseFloat(useSelector(selectTip));
  const disableTipping = useSelector(selectDisableTipping);

  const show = useMemo(
    () => !!isTipDisplay && !disableTipping,
    [isTipDisplay, disableTipping],
  );

  useEffect(() => {
    if (!isTipDisplay || !!disableTipping) {
      dispatch(setTip(0, null));
    }
  }, [dispatch, isTipDisplay, disableTipping]);

  return { show, tip };
};
