import React from 'react';

const EmailGiftcardPurchaseContext = React.createContext();

const { Provider, Consumer } = EmailGiftcardPurchaseContext;
export {
  Provider as EmailGiftcardPurchaseProvider,
  Consumer as EmailGiftcardPurchaseConsumer,
};
export default EmailGiftcardPurchaseContext;
