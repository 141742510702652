import { OrderTypeContext } from 'pages/store/new-order-form/order-form-context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectRequestedAddressIsValid } from 'redux/cart/cart.selectors';
import { CATERING, DELIVERY } from 'util/constants';
import { selectors } from 'redux/app/app.slice';

export const useIsStoreNotWithinDeliveryDistance = ({
  withInDeliveryRadius,
  deliveryOrdersAccepted,
}) => {
  const isRequestedAddressIsValid = useSelector(selectRequestedAddressIsValid);
  const orderType = useContext(OrderTypeContext);
  const locationType = useSelector(selectors.selectLocationsLocationType);

  if (!isRequestedAddressIsValid || !deliveryOrdersAccepted) return false;

  if (locationType === CATERING && withInDeliveryRadius === false) return true;

  if (orderType === DELIVERY && withInDeliveryRadius === false) return true;
};
