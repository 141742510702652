import React from 'react';
import * as S from './horizontal-logo.styles';
import {
  selectClientName,
  selectLogo,
} from '../../redux/config/config.selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HorizontalLogo = () => {
  const logo = useSelector(selectLogo);
  const clientName = useSelector(selectClientName);
  const { t } = useTranslation();

  return (
    <S.LogoImage
      src={logo}
      alt={t('header.logo.link.ariaLabel', { clientName })}
    />
  );
};

export default HorizontalLogo;
