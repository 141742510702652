import i18n from 'i18n';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDisplayTimeZone } from 'redux/locations/locations.selectors';

const makeDayObject = (dateTime, today, availableDays) => ({
  dateTime,
  isoDateString: dateTime.toISODate(),
  dayString: today
    ? i18n.t('locations.card.today')
    : dateTime.toLocaleString({ weekday: 'short' }),
  dayOfMonth: dateTime.day,
  enabled: availableDays.some(ad => ad === dateTime.toISODate()),
});

export const useDayTabsComponent = (availableDays, name, defaultVal) => {
  const displayTimeZone = useSelector(selectDisplayTimeZone);

  const days = useMemo(() => {
    const today = DateTime.local({ zone: displayTimeZone });
    return [
      makeDayObject(today, true, availableDays),
      ...[1, 2, 3, 4, 5, 6].map(i =>
        makeDayObject(today.plus({ days: i }), false, availableDays),
      ),
    ];
  }, [availableDays, displayTimeZone]);

  const firstDay = useMemo(() => days.find(day => !!day.enabled), [days]);
  const defaultValue = defaultVal ?? firstDay?.isoDateString;
  const defaultDay = useMemo(
    () => days.find(day => day.isoDateString === defaultValue),
    [days, defaultValue],
  );

  const {
    field: { onChange, value },
  } = useController({ name, defaultValue });

  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  useEffect(() => {
    // This does 2 things
    // 1. Sets the initial form value, which for some reason isn't triggered by
    //    simply setting the defaultValue
    // 2. For the store page, will change the day, if necessary, when switching
    //    between pickup and delivery
    if (days.some(day => day.isoDateString === value && day.enabled))
      onChange(value);
    else if (defaultDay?.enabled) onChange(defaultValue);
    else onChange(firstDay?.isoDateString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDays]);

  return { days, value, handleChange };
};
