import CardPaymentModal from 'components/payment/card-payment-modal/card-payment-modal.component';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPaymentInstrument } from 'redux/user/user.actions';

const AddCreditCardModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleAddCreditCard = async cardInfo => {
    setLoading(true);
    const res = await dispatch(addPaymentInstrument(cardInfo, true));
    if (!!res) handleClose();
    setLoading(false);
  };

  return (
    <CardPaymentModal
      open
      handleClose={handleClose}
      handleCardInfo={handleAddCreditCard}
      loading={loading}
    />
  );
};

export default AddCreditCardModal;
