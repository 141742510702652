import { menuItemKeySplitChar } from 'hooks/dialog/menu-dialog.constants';
import {
  useGetItemDetailsFromRetrievedItemsDetails,
  useIsLargeMenuModeOn,
  useOpenMenuItemDialogPath,
} from 'hooks/dialog/menu-dialog.hooks';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderItemStartAsync } from 'redux/cart/cart.actions';
import {
  selectMenuCatalogs,
  selectRecommendedItems,
} from 'redux/catalogs/catalogs.selectors';
import { selectGroup } from 'redux/catalogs/catalogs.utils';
import { retrieveRecommendations } from 'redux/recommendations/recommendations.actions';
import { selectRecommendationCode } from 'redux/recommendations/recommendations.selectors';
import { getAnalytics } from 'util/analytics';
import {
  getDefaultModifiers,
  hasMandatoryModifiers,
} from './checkout-recommendations-section.utils';

export const useRecommendationsComponent = itemDetails => {
  const { item, path, inheritedOptionGroups } = itemDetails;
  const catalogId = path[0];
  const groupId = path[path.length - 1];
  const { itemId, optionGroups: itemOptionGroups = [] } = item;
  const optionGroups = useMemo(
    () => [...itemOptionGroups, ...inheritedOptionGroups],
    [inheritedOptionGroups, itemOptionGroups],
  );
  const pathString = useMemo(
    () => `${path.join(menuItemKeySplitChar)}${menuItemKeySplitChar}${itemId}`,
    [itemId, path],
  );
  const openModal = useOpenMenuItemDialogPath(pathString, {
    recommendationView: true,
  });
  const dispatch = useDispatch();
  const displayInfo = useDisplayInfo(item.displayInfo);
  const isLargeMenuMode = useIsLargeMenuModeOn(catalogId);
  const retrievedItemDetail =
    useGetItemDetailsFromRetrievedItemsDetails(itemId);

  const handleItemClicked = openModal;

  const quickAddToCart = useCallback(async () => {
    if (
      hasMandatoryModifiers(optionGroups) ||
      (isLargeMenuMode && !retrievedItemDetail)
    ) {
      handleItemClicked();
      return;
    }

    const preSelectedOptions = getDefaultModifiers(optionGroups, catalogId);

    await dispatch(
      addOrderItemStartAsync(
        catalogId,
        groupId,
        { itemId, itemName: displayInfo.title },
        '',
        preSelectedOptions,
        1,
        true,
        pathString,
      ),
    );
  }, [
    catalogId,
    dispatch,
    displayInfo.title,
    groupId,
    handleItemClicked,
    isLargeMenuMode,
    itemId,
    optionGroups,
    pathString,
    retrievedItemDetail,
  ]);

  return {
    handleItemClicked,
    quickAddToCart,
    displayInfo,
  };
};

export const useViewRecommendationAnalytics = recommendationItemDetails => {
  const catalogs = useSelector(selectMenuCatalogs);

  useEffect(() => {
    recommendationItemDetails?.length > 0 &&
      getAnalytics().trackRecommendationsViewed(
        recommendationItemDetails.map(itemDetail => {
          const { item, path } = itemDetail;
          const catalogId = path[0];
          const groupIds = path.slice(1);
          return {
            ...item,
            group: {
              groupName: selectGroup(catalogs, catalogId, groupIds)?.title,
            },
          };
        }),
      );
  }, [catalogs, recommendationItemDetails]);
};

export const useCheckoutRecommendationsSectionComponent = () => {
  const dispatch = useDispatch();
  const recommendationItemDetails = useSelector(selectRecommendedItems);
  const recommendationStatus = useSelector(selectRecommendationCode);

  useEffect(() => {
    dispatch(retrieveRecommendations());
  }, [dispatch]);

  const show = useMemo(
    () => recommendationStatus >= 0 && recommendationItemDetails?.length,
    [recommendationItemDetails?.length, recommendationStatus],
  );

  return { show, recommendationItemDetails };
};
