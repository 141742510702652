import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

export const useTestError = name => {
  const [error, setError] = useState(null);
  useErrorHandler(error);

  if (window.Cypress) {
    window.testError = {
      ...window.testError,
      [name]: setError,
    };
  }
};
