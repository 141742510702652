import { CreditCard } from '@material-ui/icons';
import { PaymentOptionType } from 'components/payment/payment.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentOption from './payment-option.component';

const GuestCardPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  const { t } = useTranslation();
  const title = t('paymentSelector.paymentOption.creditCard.title');

  return (
    <PaymentOption
      icon={<CreditCard />}
      title={title}
      type={PaymentOptionType.GUEST_CARD}
      {...rest}
      ref={ref}
    />
  );
});
export default GuestCardPaymentOption;
