import React from 'react';
import { Link, scroller } from 'react-scroll';
import ImageTab from '../image-tab.component';

const ScrollImageTab = React.forwardRef(
  (
    {
      value,
      spy = true,
      smooth = true,
      offset,
      onSetActive,
      imageSrc,
      label,
      wrapped = true,
      selected,
      groupId,
      'data-testid': testId,
      'aria-controls': _,
      ...rest
    },
    ref,
  ) => {
    return (
      <ImageTab
        // Here we have dynamic scrolling content and aria controls are not properly set. Setting the aria-controls manually
        aria-controls={groupId}
        key={`tab-${value}`}
        value={value}
        imageSrc={imageSrc}
        label={label}
        wrapped={wrapped}
        selected={selected}
        ref={ref}
        component={Link}
        to={value}
        spy={spy}
        smooth={smooth}
        offset={offset}
        onSetActive={onSetActive}
        onKeyUp={event => {
          if (event.key === 'Enter') {
            scroller.scrollTo(value, { smooth: true, offset });
          }
        }}
        data-testid={testId}
        {...rest}
      />
    );
  },
);

export default ScrollImageTab;
