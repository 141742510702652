import { memo } from 'react';
import { useGetOrderTypeIcon } from './order-type-icon.hooks';

const OrderTypeIcon = () => {
  const OrderTypeIcon = useGetOrderTypeIcon();

  return <OrderTypeIcon />;
};

export default memo(OrderTypeIcon);
