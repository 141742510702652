import i18n from 'i18next';
import { createSelector } from 'reselect';
import { analyticsEnabledByEnv } from 'util/analyticsEnabledByEnv';
import { FB_APP_ID } from '../../pages/account-inbox/account-inbox.constants';
import { getEnv } from '../../util/getEnv';
import location from '../../util/location';
import { getTranslationFromArray, getTranslationFromObject } from '../../utils';
import { selectBaseLocale } from '../i18n/i18n.selectors';

export const selectConfig = state => state.config;

export const selectIsConfigLoaded = state => !!state.config;

export const selectLanguagesSupported = createSelector(
  [selectConfig],
  config => config.languagesSupported,
);

export const selectFavicon = createSelector(
  selectConfig,
  config => config.images?.favicon,
);

export const selectLocaleIndex = createSelector(
  selectLanguagesSupported,
  selectBaseLocale,
  (languagesSupported, locale) => {
    if (!languagesSupported || languagesSupported.length < 1 || !locale)
      return 0;
    const index = languagesSupported.indexOf(locale);
    return index > -1 ? index : 0;
  },
);

export const selectImages = createSelector(
  [selectConfig],
  config => config.images || {},
);

export const selectIsGoogleMapsLoaded = createSelector(
  [selectConfig],
  config => config.isGoogleMapsLoaded,
);

export const selectLogo = createSelector([selectImages], images => images.logo);

export const selectMapPin = createSelector(
  selectImages,
  images => images.mapPin,
);

export const selectColors = createSelector(
  [selectConfig],
  config => config.colors,
);

export const selectFonts = createSelector(
  [selectConfig],
  config => config.fonts,
);

export const selectIsGuestCheckoutEnabled = createSelector(
  [selectConfig],
  config => !!config.isGuestCheckoutEnabled,
);

export const selectIsAptSuiteMandatory = createSelector(
  [selectConfig],
  config => config.aptSuiteMand,
);

export const selectIsCateringAvailable = createSelector(
  [selectConfig],
  config => config.catering,
);

export const selectCustomCatalog = createSelector(
  [selectConfig],
  config => config.customCatalog ?? {},
);

export const selectCustomCatalogIsActive = createSelector(
  selectCustomCatalog,
  customCatalog => true,
);

export const selectShowOrderNumber = createSelector(
  [selectConfig],
  config => config.showOrderNumber,
);

export const selectMessageCountColor = createSelector(
  [selectConfig],
  config => config.messageCountColor,
);

export const selectInbox = createSelector(
  [selectConfig],
  config => config.inbox,
);

export const selectIsLoyaltyCard = createSelector(
  [selectConfig],
  config => config.isLoyaltyCard,
);

export const selectConfirmPickup = createSelector([selectConfig], config =>
  config.confirmPickUp ? JSON.parse(config.confirmPickUp[0]) : null,
);

export const selectConfirmPickupNoNum = createSelector([selectConfig], config =>
  config.confirmPickUpNoNum ? JSON.parse(config.confirmPickUpNoNum[0]) : null,
);

export const selectConfirmDelivery = createSelector([selectConfig], config =>
  config.confirmDelivery ? JSON.parse(config.confirmDelivery[0]) : null,
);

export const selectConfirmDeliveryNoNum = createSelector(
  [selectConfig],
  config =>
    config.confirmDeliveryNoNum
      ? JSON.parse(config.confirmDeliveryNoNum[0])
      : null,
);

export const selectCustomizedConfirmation = createSelector(
  [selectConfig],
  config => config.customizedConfirmation,
);

export const selectLoyalty = createSelector(
  [selectConfig],
  config => config.loyalty || {},
);

export const selectShowItemInstructions = createSelector(
  [selectConfig],
  config => config.showItemInstructions,
);

export const selectgAPI = createSelector([selectConfig], config => config.gAPI);

export const selectClientName = createSelector(
  selectConfig,
  config => config.clientName ?? 'Incentivio',
);

export const selectItemInstructionsAdditionalText1 = createSelector(
  selectConfig,
  selectLocaleIndex,
  (config, localeIndex) =>
    getTranslationFromArray(
      config.itemInstructionsAdditionalText1,
      localeIndex,
    ),
);

export const selectItemInstructionsLabel = createSelector(
  selectConfig,
  selectLocaleIndex,
  (config, localeIndex) =>
    getTranslationFromArray(
      config.itemInstructionsLabel,
      localeIndex,
      i18n.t('catalog.modal.itemInstructions.label'),
    ),
);

export const selectItemInstructionsWatermarkText = createSelector(
  selectConfig,
  selectLocaleIndex,
  (config, localeIndex) =>
    getTranslationFromArray(
      config.itemInstructionsWatermarkText,
      localeIndex,
      i18n.t('catalog.modal.itemInstructions.placeholder'),
    ),
);

export const selectLoyaltyCardTitle = createSelector(
  selectConfig,
  selectLocaleIndex,
  (config, localeIndex) =>
    getTranslationFromArray(config.loyaltyCardTitle, localeIndex),
);

export const selectLoyaltyCardCodeType = createSelector(
  [selectConfig],
  config =>
    config.loyaltyCardCodeType ? config.loyaltyCardCodeType.toUpperCase() : '',
);

export const selectLoyaltyOn = createSelector(
  [selectConfig],
  config => config.loyaltyOn,
);

export const selectDeliveryInstructions = createSelector(
  [selectConfig],
  config => config.deliveryInstructions,
);

export const selectGiftCard = createSelector(
  [selectConfig],
  config => config.giftCard || {},
);

export const selectLoyaltyLabel = createSelector(
  selectConfig,
  selectLocaleIndex,
  (config, localeIndex) =>
    getTranslationFromArray(
      config.loyaltyLabel,
      localeIndex,
      i18n.t('menuAccount.loyalty'),
    ),
);

export const selectOrderHistoryTitle = createSelector(
  selectConfig,
  selectBaseLocale,
  (config, locale) =>
    getTranslationFromObject(
      config.orderHistoryTitle,
      locale,
      i18n.t('menuAccount.orderHistory'),
    )?.toLowerCase(),
);

export const selectCustomizationChars = createSelector(
  [selectConfig],
  config => config.customizationChars,
);

export const selectHideQtyPicker = createSelector(
  [selectConfig],
  config => config.hideQtyPicker,
);

export const selectIsFutureDates = createSelector(
  [selectConfig],
  config => config.isFutureDates,
);

export const selectShowOrderInstructions = createSelector(
  [selectConfig],
  config => config.showOrderInstructions,
);

export const selectIsActiveGiftCard = createSelector(
  [selectGiftCard],
  giftCard => (giftCard ? giftCard.isActive : null),
);

export const selectSecurityCodeEnabled = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.securityCodeEnabled,
);

export const selectPurchaseNewGiftCard = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.purchaseNewGiftCard,
);

export const selectAddGiftCardToWallet = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.addGiftCardToWallet,
);

export const selectReloadGiftCard = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.reloadGiftCard,
);

export const selectGiftCardLookUp = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.giftCardLookUp,
);

export const selectPurchaseWithGiftCard = createSelector(
  selectGiftCard,
  giftCard => giftCard.isActive && giftCard.purchaseWithGiftCard,
);

export const selectGuestGiftCardOrderPaymentsEnabled = createSelector(
  selectGiftCard,
  giftCard => !!giftCard.isActive && !!giftCard.guestAddGiftCardEnabled,
);

export const selectGiftCardTitle = createSelector(
  selectGiftCard,
  selectLocaleIndex,
  (giftCard, localeIndex) =>
    getTranslationFromArray(giftCard.giftCardTitle, localeIndex, 'Gift Card'),
);

export const selectGiftCardHeader = createSelector(
  selectGiftCard,
  selectLocaleIndex,
  (giftCard, localeIndex) =>
    getTranslationFromArray(
      giftCard.giftCardHeader,
      localeIndex,
      i18n.t('cardItem.cardTitle'),
    ),
);

export const selectGiftCardDefaultLocation = createSelector(
  [selectGiftCard],
  giftCard =>
    giftCard && giftCard.defaultLocation
      ? giftCard.defaultLocation
      : 'POOL_ACCOUNT',
);

export const selectGiftCardDefaultMerchant = createSelector(
  [selectGiftCard],
  giftCard =>
    giftCard && giftCard.defaultMerchant
      ? giftCard.defaultMerchant
      : 'POOL_ACCOUNT_MERCHANT',
);

export const selectMinAmountGiftCard = createSelector(
  [selectGiftCard],
  giftCard => giftCard.minAmount || 25,
);

export const selectMaxAmountGiftCard = createSelector(
  [selectGiftCard],
  giftCard => giftCard.maxAmount || 10000,
);

export const selectGiftCardSelectableAmounts = createSelector(
  selectGiftCard,
  selectMinAmountGiftCard,
  selectMaxAmountGiftCard,
  (giftCard, configMin, configMax) => {
    if (giftCard && giftCard?.selectableAmount?.length) {
      return giftCard.selectableAmount.map(amount => parseFloat(amount));
    } else {
      const increment = 25;
      const min = parseFloat(configMin) || 25;
      const max = parseFloat(configMax) || 1000;
      const selectableAmount = [Math.floor(min)];
      let prev = min;
      while (selectableAmount.length < 4 && prev + increment <= max) {
        prev += increment;
        selectableAmount.push(Math.floor(prev));
      }
      return selectableAmount;
    }
  },
);

export const selectLoyaltyText = createSelector(
  selectLoyalty,
  selectLocaleIndex,
  (loyalty, localeIndex) =>
    getTranslationFromArray(loyalty.loyaltyText, localeIndex, ''),
);

export const selectLoyaltyType = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyType,
);

export const selectLoyaltyPointImgs = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyPointImgs || [],
);

export const selectLoyaltyPointDivisor = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyPointsDivisor,
);

export const selectLoyaltyPointName = points =>
  createSelector(selectLoyalty, selectLocaleIndex, (loyalty, localeIndex) => {
    if (points === 1) {
      return getTranslationFromArray(
        loyalty.loyaltyPointTermSingular,
        localeIndex,
        i18n.t('accountLoyalty.pointNameSingular'),
      );
    }
    return getTranslationFromArray(
      loyalty.loyaltyPointTermPlural,
      localeIndex,
      i18n.t('accountLoyalty.pointNamePlural'),
    );
  });

export const selectLoyaltyLogo = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyLogo,
);

export const selectLoyaltyButtons = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyButtons || {},
);

export const selectLoyaltyHeader = createSelector(
  selectLoyalty,
  selectLocaleIndex,
  (loyalty, localeIndex) =>
    getTranslationFromArray(
      loyalty.loyaltyHeader,
      localeIndex,
      i18n.t('accountLoyalty.loyaltyHeader'),
    ),
);

export const selectLoyaltyCardButtonName = createSelector(
  selectLoyaltyButtons,
  selectLocaleIndex,
  (loyaltyButtons, localeIndex) =>
    getTranslationFromArray(
      loyaltyButtons.button2,
      localeIndex,
      i18n.t('accountLoyalty.loyaltyCardAction'),
    ),
);

export const selectFbAppId = createSelector(
  [selectConfig],
  config => config.fbAppId || FB_APP_ID,
);

export const selectLoyaltyHeaderRectangle1 = createSelector(
  [selectLoyalty],
  loyalty => loyalty.loyaltyHeaderRectangle1,
);

export const selectWov2 = createSelector(selectConfig, config => config.wov2);

export const selectIsWOV2Enabled = createSelector(
  selectWov2,
  wov2 =>
    !getEnv('REACT_APP_COMING_SOON') ||
    getEnv('REACT_APP_COMING_SOON') === 'false' ||
    (wov2 && wov2.enabled) ||
    location.getSearchParam('demo') === 'true',
);

export const selectIsEmailGiftcardEnabled = createSelector(
  selectGiftCard,
  giftCard => (giftCard?.isActive ? giftCard?.sendByEmailIsActive : false),
);

export const selectRecommendationsEnabled = createSelector(
  selectConfig,
  config => config.recommendationsEnabled,
);

export const selectIsDateOfBirthMandatory = createSelector(
  selectConfig,
  config => !!config.signUp?.dateOfBirth?.required,
);

export const selectIsDateOfBirthEnabled = createSelector(
  selectConfig,
  config => !!config.signUp?.dateOfBirth?.enabled,
);

export const selectOrderTypeLabels = createSelector(
  selectConfig,
  config => config.orderTypeLabels,
);

export const selectSegment = createSelector(selectConfig, config =>
  analyticsEnabledByEnv() ? config.segment : undefined,
);

export const selectSegmentId = createSelector(
  selectSegment,
  segment => segment?.workspaceId,
);

export const selectTipPercentages = createSelector(
  selectConfig,
  config => config.tipPercentages,
);

export const selectSignUpPreferencesInfoDisabled = createSelector(
  selectConfig,
  config => config.signUpPreferenceDisabled,
);

export const selectSignUpPreferencesInfo = createSelector(
  selectConfig,
  config => config.signUpPreferenceInfo,
);

export const selectAllergyPreferences = createSelector(
  selectSignUpPreferencesInfo,
  signUpPreferencesInfo => signUpPreferencesInfo?.ALLERGIES,
);

export const selectDietaryPreferences = createSelector(
  selectSignUpPreferencesInfo,
  signUpPreferencesInfo => signUpPreferencesInfo?.DIETARY,
);

export const selectAllergyDisplayInfo = createSelector(
  selectAllergyPreferences,
  selectBaseLocale,
  (allergyPreferences, locale) => ({
    label: getTranslationFromObject(
      allergyPreferences?.[0].preferenceName,
      locale,
      i18n.t('dietaryPreferences.allergies.label'),
    ),
    values: getTranslationFromObject(
      allergyPreferences?.[0].values,
      locale,
      i18n.t('dietaryPreferences.allergies.values')?.split(','),
    ),
    infoText: getTranslationFromObject(
      allergyPreferences?.[0].infoText,
      locale,
      i18n.t('dietaryPreferences.allergies.infoText'),
    ),
    multiSelect: allergyPreferences?.[0].multiSelectOption,
  }),
);

export const selectDietaryDisplayInfo = createSelector(
  selectDietaryPreferences,
  selectBaseLocale,
  (dietaryPreferences, locale) => ({
    label: getTranslationFromObject(
      dietaryPreferences?.[0].preferenceName,
      locale,
      i18n.t('dietaryPreferences.dietary.label'),
    ),
    values: getTranslationFromObject(
      dietaryPreferences?.[0].values,
      locale,
      i18n.t('dietaryPreferences.dietary.values')?.split(','),
    ),
    infoText: getTranslationFromObject(
      dietaryPreferences?.[0].infoText,
      locale,
      i18n.t('dietaryPreferences.dietary.infoText'),
    ),
    multiSelect: dietaryPreferences?.[0].multiSelectOption,
  }),
);

export const selectClientConfig = createSelector(
  selectConfig,
  config => config.clientConfig,
);

export const selectViewOnlyLocationEnabled = createSelector(
  selectClientConfig,
  clientConfig => clientConfig?.viewOnlyLocationEnabled,
);

export const selectSms = createSelector(
  selectClientConfig,
  clientConfig => clientConfig?.sms,
);

export const selectIsSmsEnabled = createSelector(
  selectSms,
  sms => sms?.enabled,
);

export const selectIsSmsEnabledOrdeirng = createSelector(
  selectSms,
  sms => sms?.enabledOrdering,
);
export const selectIsSmsEnabledMarketing = createSelector(
  selectSms,
  sms => sms?.enabledMarketing,
);

export const selectIsHideQrCode = createSelector(
  selectGiftCard,
  giftCard => giftCard?.hideQrCode,
);

export const selectRecaptcha = createSelector(
  selectConfig,
  config => config.recaptcha ?? {},
);

export const selectRecaptchaEnabled = createSelector(
  selectRecaptcha,
  recaptcha =>
    !!recaptcha?.isActive &&
    !!recaptcha?.keyId &&
    Object.values(recaptcha?.enableFor ?? {}).some(action => !!action),
);
export const selectShouldLoadRecaptcha = createSelector(
  selectRecaptchaEnabled,
  selectIsEmailGiftcardEnabled,
  selectGiftCardLookUp,
  (recaptchaEnabled, eGiftcardEnabled, giftCardLookUpEnabled) =>
    (!window.Cypress || window.recaptchaOverride) &&
    getEnv('NODE_ENV') !== 'test' &&
    (recaptchaEnabled || eGiftcardEnabled || giftCardLookUpEnabled),
);

export const selectRecaptchaEnableFor = createSelector(
  selectRecaptcha,
  recaptcha => recaptcha.enableFor ?? {},
);

export const selectRecaptchaCreateAccount = createSelector(
  selectRecaptchaEnableFor,
  selectRecaptchaEnabled,
  (enableFor, recaptchaEnabled) =>
    !!recaptchaEnabled && !!enableFor.createAccount,
);

export const selectRecaptchaPayment = createSelector(
  selectRecaptchaEnableFor,
  selectRecaptchaEnabled,
  (enableFor, recaptchaEnabled) => !!recaptchaEnabled && !!enableFor.payment,
);

export const selectRecaptchaAddGiftCard = createSelector(
  selectRecaptchaEnableFor,
  selectRecaptchaEnabled,
  (enableFor, recaptchaEnabled) =>
    !!recaptchaEnabled && !!enableFor.addGiftCard,
);

export const selectRecaptchaLogin = createSelector(
  selectRecaptchaEnableFor,
  selectRecaptchaEnabled,
  (enableFor, recaptchaEnabled) => !!recaptchaEnabled && !!enableFor.login,
);

export const selectUserAcccountVerification = createSelector(
  selectClientConfig,
  clientConfig => clientConfig?.userAccountVerification,
);

export const selectUserAccountVerificationMethod = createSelector(
  selectUserAcccountVerification,
  userAccountVerification => userAccountVerification?.method,
);

export const selectSocialSignIn = createSelector(
  selectClientConfig,
  config => config?.socialSignIn,
);

export const selectSocialSignInEnabled = createSelector(
  selectSocialSignIn,
  socialSignIn => socialSignIn?.enabled ?? false,
);

export const selectSocialSignInGoogleClientId = createSelector(
  selectSocialSignIn,
  socialSignIn => socialSignIn?.googleClientId,
);

export const selectSocialSignInAppleClientId = createSelector(
  selectSocialSignIn,
  socialSignIn => socialSignIn?.appleClientId,
);

export const selectApplePayId = createSelector(
  selectClientConfig,
  config => config?.toastApplePay?.id,
);

export const selectHideLoyaltyScanCode = createSelector(
  selectConfig,
  config => config?.hideLoyaltyScanCode,
);

export const selectHideCateringMinMaxShortMessage = createSelector(
  selectConfig,
  config => !!config?.hideCateringMinMaxShortMessage,
);

export const selectForceUserToSelectOrderType = createSelector(
  selectConfig,
  config => !!config?.forceUserToSelectOrderType,
);

export const selectIsGuestPurchaseEnabled = createSelector(
  selectGiftCard,
  selectIsGuestCheckoutEnabled,
  (giftCard, isGuestCheckoutEnabled) =>
    giftCard.guestPurchaseEnabled === undefined ||
    giftCard.guestPurchaseEnabled === null
      ? !!isGuestCheckoutEnabled
      : !!giftCard.guestPurchaseEnabled,
);

export const selectDefaultOrderType = createSelector(
  selectConfig,
  config => config?.defaultOrderType,
);

export const selectOrderTypeFromDefaultOrderType = createSelector(
  selectDefaultOrderType,
  defaultOrderType => defaultOrderType?.active && defaultOrderType?.orderType,
);

export const makeSelectDefaultOrderType = orderTypes =>
  createSelector(
    selectOrderTypeFromDefaultOrderType,
    defaultOrderType =>
      orderTypes.find(({ type }) => type === defaultOrderType)?.type,
  );
