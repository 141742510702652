import { Typography } from '@material-ui/core';
import AddressControls from 'components/address/address-controls/address-controls.component';
import React from 'react';
import * as S from './locations-address-controls.styles';
import { useLocationsAddressControls } from './locations-address-controls.hooks';
import { useGetAddressLabel } from 'components/address/address-controls/address-controls.hooks';

const LocationsAddressControls = ({ orderType, headerRef }) => {
  const hideLocationsAddressControls = useLocationsAddressControls();
  const label = useGetAddressLabel(orderType === 'CATERING');

  if (hideLocationsAddressControls) return null;

  return (
    <S.Container ref={headerRef} id="LocationsAddressControls.Container">
      <AddressControls
        orderType={orderType}
        label={
          <Typography component="h1" variant="h6" id="address-control-label">
            {label}
          </Typography>
        }
      />
    </S.Container>
  );
};

export default LocationsAddressControls;
