import { useTranslation } from 'react-i18next';
import { getLocationOrderingInfo } from 'pages/locations/locations.utils';
import { Box } from '@material-ui/core';
import OrderingTimeSlots from '../ordering-time-slots/ordering-time-slots.component';

const CateringOrderingHours = ({ store }) => {
  const {
    pickupOrderHours,
    deliveryOrderHours,
    timeZone,
    pickupOrdersAccepted,
    deliveryOrdersAccepted,
    pickupOffDays,
    deliveryOffDays,
  } = store;
  const { t } = useTranslation();
  const { durations: pickupDurations } = getLocationOrderingInfo(
    pickupOrderHours,
    timeZone,
    pickupOffDays,
  );
  const { durations: deliveryDurations } = getLocationOrderingInfo(
    deliveryOrderHours,
    timeZone,
    deliveryOffDays,
  );

  return (
    <Box display="flex" justifyContent="space-between">
      {pickupOrdersAccepted && pickupDurations.length > 0 && (
        <OrderingTimeSlots
          title={t('locations.card.todayOrderingHours.pickup')}
          durations={pickupDurations}
          titleTestId="CateringOrderingHours.OrderingTimeSlots.Pickup.Title"
        />
      )}
      {deliveryOrdersAccepted && deliveryDurations.length > 0 && (
        <OrderingTimeSlots
          title={t('locations.card.todayOrderingHours.delivery')}
          durations={deliveryDurations}
          titleTestId="CateringOrderingHours.OrderingTimeSlots.Delivery.Title"
        />
      )}
    </Box>
  );
};

export default CateringOrderingHours;
