import { DateTime } from 'luxon';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';
import { store } from 'redux/store';
import { summation } from 'utils';

export const mapOptionsToRequest = options =>
  options?.map(opt => ({
    ...opt,
    options: mapOptionsToRequest(opt.options),
    price: undefined,
    parentName: undefined,
    groupFormKey: undefined,
    identifier: undefined,
    parentIds: undefined,
  })) ?? [];

export const getInvalidItemsForHiddenMenus = (
  catalogOverrideItemDetails,
  groupOverrideItemDetails,
  orderType,
  fulfillTime,
) => {
  const time = fulfillTime?.storeDateTime ?? fulfillTime?.firstAvailableTime;
  return [
    ...getCatalogOverrideInvalidItems(
      catalogOverrideItemDetails,
      orderType,
      time,
    ),
    ...getGroupOverrideInvalidItems(groupOverrideItemDetails, orderType, time),
  ];
};

const getCatalogOverrideInvalidItems = (
  catalogOverrideItemDetails,
  orderType,
  requestedTime,
) => {
  return catalogOverrideItemDetails
    .filter(
      details =>
        !isCatalogVisible(details.catalogDetails, orderType, requestedTime),
    )
    .map(details => details.orderItemDetails);
};

const getGroupOverrideInvalidItems = (
  groupOverrideItemDetails,
  orderType,
  requestedTime,
) => {
  return groupOverrideItemDetails
    .filter(
      details =>
        !isGroupVisible(details.groupDetails, orderType, requestedTime),
    )
    .map(details => details.orderItemDetails);
};

export const isCatalogVisible = (catalog, orderType, requestedTime) =>
  isVisible(
    catalog.catalogOverrideApplied,
    catalog.catalogOverride,
    orderType,
    requestedTime,
  );

export const isGroupVisible = (group, orderType, requestedTime) =>
  isVisible(
    group.groupOverrideApplied,
    group.groupOverride,
    orderType,
    requestedTime,
  );

export const isVisible = (
  overrideApplied,
  override,
  orderType,
  requestedTime,
) => {
  const isMenuBrowsingStore = makeSelectIsMenuBrowsingStore()(store.getState());
  if (isMenuBrowsingStore) return true;

  if (!requestedTime) return false;

  if (overrideApplied) {
    const currentDay = requestedTime.toFormat('cccc').toUpperCase();
    const todayOverrides = findTodayOverride(override, orderType, currentDay);

    if (todayOverrides) {
      return !hasRequestedTimeOverride(todayOverrides, requestedTime);
    }
  }

  return true;
};

const findTodayOverride = (catalogOverride, orderType, currentDay) => {
  const override = catalogOverride.find(
    override =>
      override.applicableOrderTypes.some(
        applicableOrderType => applicableOrderType === orderType,
      ) && Object.keys(override.override).some(dayKey => dayKey === currentDay),
  );
  return override ? override.override[currentDay] : null;
};

const hasRequestedTimeOverride = (todayOverrides, requestedTime) => {
  const nowOverride = todayOverrides.find(todayOverride => {
    const startTime = DateTime.fromFormat(todayOverride.startTime, 'HHmm', {
      zone: requestedTime.zone,
    }).set({
      day: requestedTime.day,
      month: requestedTime.month,
      year: requestedTime.year,
    });
    const endTime = DateTime.fromFormat(todayOverride.endTime, 'HHmm', {
      zone: requestedTime.zone,
    }).set({
      day: requestedTime.day,
      month: requestedTime.month,
      year: requestedTime.year,
    });
    return requestedTime >= startTime && requestedTime <= endTime;
  });

  return !!nowOverride;
};

export const countCatalogItems = (catalog, orderType, requestedTime) =>
  catalog && catalog.groups
    ? catalog.groups.reduce(
        (result, group) =>
          result + countGroupItems(group, orderType, requestedTime),
        0,
      )
    : 0;

export const countGroupItems = (group, orderType, requestedTime) => {
  let count = 0;
  if (isGroupVisible(group, orderType, requestedTime)) {
    if (group.items) count += group.items.length;
    if (group.subGroups)
      count += group.subGroups.reduce(
        (result, subGroup) =>
          result + countGroupItems(subGroup, orderType, requestedTime),
        0,
      );
  }

  return count;
};

export const mergeItemDetails = (items1, ...rest) => {
  const result = { ...items1 };
  rest.forEach(catalogItems => {
    Object.entries(catalogItems).forEach(([key, value]) => {
      if (!!result[key]) result[key] = [...result[key], ...value];
      else result[key] = value;
    });
  });

  return result;
};

export const findItemDetailsInGroups = (
  groups = [],
  orderType,
  requestedTime,
  parentPath,
  parentVisibility,
) => {
  let itemDetails = {};
  groups.forEach(group => {
    const path = [...parentPath, group.groupId];
    const groupVisible = isGroupVisible(
      group,
      orderType,
      requestedTime?.storeDateTime,
    );
    const visibility = {
      ...parentVisibility,
      group: groupVisible,
      groupOverride: !groupVisible && group.groupOverride,
      visible: parentVisibility.catalog && groupVisible,
    };
    const groupItemDetails = group.items?.reduce((result, item) => {
      result[item.itemId] = [{ path, visibility, item, group }];
      return result;
    }, {});
    itemDetails = mergeItemDetails(
      itemDetails,
      groupItemDetails,
      findItemDetailsInSubGroups(group.subGroups, path, visibility),
    );
  });
  return itemDetails;
};

export const findItemDetailsInSubGroups = (
  groups = [],
  parentPath,
  visibility,
) => {
  let itemDetails = {};
  groups.forEach(group => {
    const path = [...parentPath, group.groupId];
    const groupItemDetails = group.items?.reduce((result, item) => {
      result[item.itemId] = [{ path, visibility, item, group }];
      return result;
    }, {});
    itemDetails = mergeItemDetails(
      itemDetails,
      groupItemDetails,
      findItemDetailsInSubGroups(group.subGroups, path, visibility),
    );
  });
  return itemDetails;
};

export const collectInheritedOptionGroups = (catalogsArray, path, item) => {
  let inheritedOptionGroups = [];

  if (!item.inheritParentOptions) return inheritedOptionGroups;

  let parentGroup = catalogsArray
    .find(catalog => catalog.catalogId === path[0])
    ?.groups?.find(group => group.groupId === path[1]);
  let index = 2;

  while (!!parentGroup && index < path.length) {
    const subGroup = parentGroup.subGroups?.find(
      // eslint-disable-next-line no-loop-func
      subGroup => subGroup.groupId === path[index],
    );

    if (!!subGroup?.inheritParentOptions) {
      inheritedOptionGroups = [
        ...inheritedOptionGroups,
        ...parentGroup.optionGroups,
      ];
    } else {
      inheritedOptionGroups = [];
    }

    ++index;
    parentGroup = subGroup;
  }

  inheritedOptionGroups = [
    ...inheritedOptionGroups,
    ...(parentGroup?.optionGroups ?? {}),
  ];

  return inheritedOptionGroups;
};

export const itemDetailsMatchScore = (catalogIds, groupIds, itemDetails) => {
  let score = 0;

  if (catalogIds?.includes(itemDetails.path[0])) {
    score += summation(itemDetails.path.length - 1) + 1;
  }

  score += itemDetails.path.slice(1).reduce((result, groupId, index) => {
    if (groupIds?.includes(groupId)) result += index + 1;
    return result;
  }, 0);

  return score;
};

export const itemDetailsComparator =
  (catalogIds, groupIds) => (first, second) =>
    itemDetailsMatchScore(catalogIds, groupIds, second) -
    itemDetailsMatchScore(catalogIds, groupIds, first);

export const findGroup = (groups, groupIds, pIndex = 0) => {
  if (!groupIds?.length || !groups?.length) return;

  let index = pIndex;
  const group = groups?.find(grp => grp.groupId === groupIds[index]);

  if (groupIds.length - 1 > index) {
    //has sub groups
    return findGroup(group?.subGroups, groupIds, index + 1);
  }

  return group;
};

export const selectGroup = (catalogsArray, catalogId, groupIds) => {
  const catalog = catalogsArray.find(
    catalog => catalog.catalogId === catalogId,
  );
  return findGroup(catalog?.groups, groupIds);
};

export const getAllSubGroupIds = group => {
  const subGroupIds = [...(group.subGroupIds ?? [])];
  if (group.subGroups?.length > 0) {
    group.subGroups.forEach(grp => {
      subGroupIds.push(...getAllSubGroupIds(grp));
    });
  }

  return subGroupIds;
};

const mergeOptionGroups = (mainOptions, subOptions) => {
  return mainOptions.reduce((result, mainOption) => {
    const subOpt = subOptions.filter(subOption => {
      return mainOption.identifier === subOption.parentName;
    });
    mainOption.options = subOpt;
    return [...result, mainOption];
  }, []);
};

export const getOptions = values => {
  if (!values) return;
  // Cleanup undefined values
  const options = Object.values(values)
    .flat()
    .filter(x => !!x);

  return allOptionsSortedWithHierachy(options).reduce(
    (result, cur) => mergeOptionGroups(cur, result),
    [],
  );
};

const allOptionsSortedWithHierachy = options => {
  const mainOptions = options.filter(opt => !opt.parentIds);
  const subOptions = options.filter(opt => opt.parentIds?.length > 0);
  return [...subOptionsSortedWIthHierachy(subOptions), mainOptions];
};

const subOptionsSortedWIthHierachy = subOptions => {
  // get the deepest child
  let deepestLevel = 0;
  subOptions.forEach(opt => {
    if (opt.parentIds.length > deepestLevel) {
      deepestLevel = opt.parentIds.length;
    }
  });
  // push them to an array with deepest first
  const array = [];
  for (let i = deepestLevel; i > 0; i--) {
    array.push(subOptions.filter(opt => opt.parentIds.length === i));
  }

  return array;
};
