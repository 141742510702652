import React from 'react';
import { useCheckoutTipComponent } from './checkout-tip.hooks';
import TipSelector from 'components/tip-selector/tip-selector.component';

const CheckoutTip = () => {
  const {
    value,
    percentages,
    calculateAmount,
    onChange,
    percentageValue,
    ready,
  } = useCheckoutTipComponent();

  return ready ? (
    <>
      <TipSelector
        value={value}
        percentages={percentages}
        calculateAmount={calculateAmount}
        onChange={onChange}
        percentageValue={percentageValue}
        labelledBy={'tip-label'}
      />
    </>
  ) : null;
};

export default CheckoutTip;
