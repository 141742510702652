import ErrorBoundary from 'components/error-boundary/error-boundary.component';
import { useRetryOnReset } from 'components/error-boundary/error-boundary.hooks';
import React from 'react';
import PaymentErrorFallback from './payment-error-fallback/payment-error-fallback.component';

const PaymentErrorBoundary = ({ name, children }) => {
  const retryProps = useRetryOnReset();

  return (
    <ErrorBoundary
      name={name}
      FallbackComponent={PaymentErrorFallback}
      {...retryProps}
    >
      {children}
    </ErrorBoundary>
  );
};

export default PaymentErrorBoundary;
