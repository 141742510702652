import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rescheduleOrder } from 'redux/cart/cart.actions';
import HiddenMenuErrorContext from 'components/hidden-menu-error-dialog/hidden-menu-error-dialog.context';
import {
  arrangeOrderOptions,
  getFormattedDeliveryAddressObject,
} from 'redux/cart/cart.utils';
import { selectOrderType } from 'redux/cart/cart.selectors';

export const useRescheduleOrderModal = (handleClose, rhfHandleSubmit) => {
  const { getInvalidItems, setHiddenMenuErrorState } = useContext(
    HiddenMenuErrorContext,
  );

  const { onSubmit } = useSubmitRescheduleOrder();

  const handleSumbit = data => {
    onSubmit(data);
    handleClose();
  };

  const handleTimeChange = async ({ time }) => {
    await rhfHandleSubmit(data => {
      const invalidItemDetails = getInvalidItems(time, data.orderType);
      if (invalidItemDetails.length > 0) {
        setHiddenMenuErrorState({
          open: true,
          invalidItemDetails,
          callback: () => handleSumbit({ ...data, time }),
        });
        return;
      }
      handleSumbit({ ...data, time });
    })();
  };

  return { handleTimeChange };
};

export const useSubmitRescheduleOrder = () => {
  const dispatch = useDispatch();
  const { fieldNames } = useFieldNames();
  const prevOrderType = useSelector(selectOrderType);

  const onSubmit = useCallback(
    async formData => {
      const { orderType, address, time } = formData;
      const body = [
        {
          op: 'REPLACE',
          path: 'fulfillTime',
          value: time.dateString,
        },
      ];

      if (prevOrderType !== orderType) {
        body.push({
          op: 'REPLACE',
          path: 'orderType',
          value: orderType,
        });
      }

      if (orderType === 'DELIVERY') {
        body.push({
          op: 'REPLACE',
          path: 'deliveryInfo',
          value: JSON.stringify({
            deliveryAddress: getFormattedDeliveryAddressObject(address),
          }),
        });
      }

      await dispatch(
        rescheduleOrder(arrangeOrderOptions(formData, fieldNames), body),
      );
    },
    [dispatch, fieldNames, prevOrderType],
  );

  return { onSubmit };
};
