import React from 'react';
import { BrowserRouter, useParams, useRouteMatch } from 'react-router-dom';
import PreClient from './pre-client.component';

const DynamicClientContainer = () => {
  const { clientAlias } = useParams();
  const testMatch = useRouteMatch('/test');

  return (
    <BrowserRouter
      basename={!!testMatch ? `/test/c/${clientAlias}` : `/c/${clientAlias}`}
    >
      <PreClient />
    </BrowserRouter>
  );
};

export default DynamicClientContainer;
