import { Box, Fade } from '@material-ui/core';
import MenuGroups from 'pages/menu/menu-group/menu-groups.component';
import React from 'react';

const SingleCatalogGroups = ({ groups, catalog }) => {
  return (
    <Fade in={true} timeout={{ appear: 1500, enter: 1000 }}>
      <Box data-testid="CatalogBody.Container">
        <MenuGroups groups={groups} catalogId={catalog.catalogId} />
      </Box>
    </Fade>
  );
};

export default SingleCatalogGroups;
