import * as S from './detail-view.styles';

const DetailView = ({ label, detail, testId, noBreak }) => {
  return (
    <div>
      <S.Label>{label}</S.Label>
      <S.Detail
        variant="h6"
        component="div"
        data-testid={testId}
        noBreak={noBreak}
      >
        {detail}
      </S.Detail>
    </div>
  );
};

export default DetailView;
