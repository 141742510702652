import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import catalogsReducer from './catalogs/catalogs.reducer';
import locationsReducer from './locations/locations.reducer';
import cartReducer from './cart/cart.reducer';
import configReducer from './config/config.reducer';
import userReducer from './user/user.reducer';
import clientReducer from './client/client.slice';
import storage from '../util/clientLocalForage';
import i18nSlice from './i18n/i18n.slice';
import appReducer from './app/app.slice';
import dataReducer from './data/data.reducer';
import recommendationsReducer from './recommendations/recommendations.reducer';

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['refreshToken', 'rememberMe', 'accessToken'],
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  blacklist: ['isDrawerOpen', 'isFetching'],
};

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: [
    'loggedInPreferredAddress',
    'preferredAddress',
    'searchLocation',
    'theme',
  ],
};

const recommendationsPersistConfig = {
  key: 'recommendations',
  storage,
};

if (window.Cypress) {
  const timeout = window.Cypress.env('RP_TIMEOUT') || 1;
  userPersistConfig.timeout = timeout;
  cartPersistConfig.timeout = timeout;
}

const rootReducer = combineReducers({
  locations: locationsReducer,
  catalogs: catalogsReducer,
  cart: persistReducer(cartPersistConfig, cartReducer),
  config: configReducer,
  user: persistReducer(userPersistConfig, userReducer),
  client: clientReducer,
  i18n: i18nSlice.reducer,
  app: persistReducer(appPersistConfig, appReducer),
  data: dataReducer,
  recommendations: persistReducer(
    recommendationsPersistConfig,
    recommendationsReducer,
  ),
});

export default rootReducer;
