import { Box } from '@material-ui/core';
import React from 'react';
import Theme from 'Theme';
import { useViewMessageInbox } from './view-message-inbox.hooks';
import AccountInboxItem from 'pages/account-inbox/account-inbox-item/account-inbox-item.component';

const ViewMessageInbox = () => {
  const { message } = useViewMessageInbox();
  const dummyFunc = () => null;

  return (
    <Theme>
      <Box data-testid="ViewMessage.Inbox" p={2}>
        <AccountInboxItem
          setModalOpen={dummyFunc}
          handleDelete={dummyFunc}
          handleClose={dummyFunc}
          message={message}
          finalType={message.messageType}
          disableOnClick
        />
      </Box>
    </Theme>
  );
};

export default ViewMessageInbox;
