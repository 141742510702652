import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { pathname } = useLocation();

  const RedirectComp = () => (
    <Redirect
      to={{
        pathname: '/',
        state: { redirected: true, component: pathname },
      }}
    />
  );

  return isLoggedIn ? children : <RedirectComp />;
};

export default ProtectedRoute;
