import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 575px;
  }
`;

export const Content = styled(DialogContent)`
  padding: 2em;
  overflow: unset;
`;

export const Title = styled(Typography)`
  margin-bottom: 0.5em;
`;

export const OriginalImg = styled.img`
  width: 100%;
  height: auto;
`;

export const Close = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  padding: 0;
  top: 1em;
  right: 1em;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
