import { useSpreedlyPurchaseEmailGiftCard } from 'components/payment/spreedly/spreedly-payments.hooks';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import GiftCardPaymentStepNew from '../gift-card-payment-step-new/gift-card-payment-step-new.component';

const SpreedlyGiftCardPaymentStep = props => {
  const { trigger, loading: purchaseLoading } =
    useSpreedlyPurchaseEmailGiftCard();

  const { selectedPaymentOption } = usePaymentOptions();

  return (
    <GiftCardPaymentStepNew
      trigger={trigger}
      purchaseLoading={purchaseLoading}
      selectedPaymentOption={selectedPaymentOption}
      {...props}
    />
  );
};

export default SpreedlyGiftCardPaymentStep;
