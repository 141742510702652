import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';

const TrackOrder = ({ trackingUrl }) => {
  const { t } = useTranslation();

  if (!trackingUrl) return null;

  return (
    <IncButton
      fullWidth
      href={trackingUrl}
      target="_blank"
      data-testid="TrackOrder"
    >
      {t('trackOrder.label')}
    </IncButton>
  );
};

export default TrackOrder;
