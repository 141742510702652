import { IncButtonLight } from 'components/inc-button/inc-button.styles';
import { ImageAvatar } from 'components/payment/payment-selector/payment-options/payment-option/payment-option.styles';
import styled from 'styled-components';

export const SignupButton = styled(IncButtonLight)`
  border-color: #000;

  && {
    margin: 0;
  }
`;

export const GoogleLogoAvatar = styled(ImageAvatar)`
  width: 25px;
  height: 25px;
`;
