import { Box, darken, Tab } from '@material-ui/core';
import styled from 'styled-components';
import mediaHelper from 'styled-media-helper';

const width = 160;
const smallWidth = 100;

const smallBreakpoints = {};
let i = 1.5;
while (smallWidth * i < 600) {
  smallBreakpoints[i] = smallWidth * i;
  i += 1;
}
smallBreakpoints['last'] = 600;

const media = mediaHelper(smallBreakpoints);

export const CustomTab = styled(Tab)`
  &.MuiTab-root {
    align-items: flex-start;
    width: ${width}px;

    ${({ scroll }) => scroll && `height: 100%;`}

    ${Object.entries(smallBreakpoints)
      .map(([key, value]) =>
        key !== 'last'
          ? `
            ${media.up(key)} {
              width: calc(100vw / ${key});
            }
          `
          : `
            ${media.up(key)} {
              width: ${width}px;
            }
          `,
      )
      .join('\n')}
  }

  &.MuiTab-textColorInherit {
    opacity: 1;
    /* color: #ddd; */
  }

  &.Mui-selected {
    font-weight: bold;
    color: inherit;
  }

  &.Mui-selected .MuiTab-wrapper {
    padding: 0px;

    > div:last-child {
      opacity: 1;
    }
  }

  & .MuiTab-wrapper {
    padding: 0px 0.26px;
    height: 100%;
    justify-content: space-between;

    > div:last-child {
      opacity: 0.7;
    }
  }

  &:hover .MuiTab-wrapper > *:first-child {
    border-color: ${darken('#ddd', 0.2)};
  }

  &:hover.Mui-selected .MuiTab-wrapper > *:first-child {
    border-color: ${({ theme }) => theme.palette.primary.light};
  }

  &.MuiTab-labelIcon.Mui-selected .MuiTab-wrapper > *:first-child {
    margin: 0px 0px 6px 0px;
  }

  &.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin: 2px 2px 8px 2px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiTab-labelIcon.Mui-selected .MuiTab-wrapper > *:first-child {
      margin: 0px 0px 6px 0px;
    }

    &.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
      margin: 1px 1px 7px 1px;
    }

    &.MuiTab-root {
      padding: 6px;
    }
  }
`;

export const LabelContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  transition: flex 400ms;
  word-break: break-word;
`;
