import React from 'react';
import * as S from './toggler-button.styles';

const TogglerButton = ({
  children,
  handleToggle,
  buttonRef,
  toggleTestId = 'testTogglerButton',
  ...rest
}) => (
  <S.TogglerButton
    ref={buttonRef}
    data-testid={toggleTestId}
    onClick={handleToggle}
    {...rest}
  >
    {children}
  </S.TogglerButton>
);

export default TogglerButton;
