import { MenuItem } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AddressMenuItem from './address-menu-item/address-menu-item.component';
import * as S from './address-select.styles';

const AddressSelect = ({
  name,
  id = name,
  defaultValue = '',
  addresses,
  addressesGroupTitle,
  addMenuItem,
  disabled,
  hidden = false,
  error,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      id={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <S.CustomSelect
          value={value}
          onChange={event => {
            onChange(event.target.value);
          }}
          disabled={disabled}
          hidden={hidden}
          fullWidth
          variant="outlined"
          error={!!error}
          data-testid="AddressSelect.CustomSelect"
          MenuProps={{ 'data-testid': 'AddressSelect.CustomSelect.Menu' }}
        >
          {!!addMenuItem && (
            <MenuItem key="new" value="new">
              {addMenuItem}
            </MenuItem>
          )}
          {!!addresses?.length && !!addressesGroupTitle && (
            <S.Subheader disableSticky={true}>
              {addressesGroupTitle}
            </S.Subheader>
          )}
          {addresses.map(address => (
            <MenuItem key={address.addressId} value={address.addressId}>
              <AddressMenuItem title={address.addressAlias} address={address} />
            </MenuItem>
          ))}
        </S.CustomSelect>
      )}
    />
  );
};

export default React.memo(AddressSelect);
