import { Box } from '@material-ui/core';
import React from 'react';
import * as S from './address-menu-item.styles';
import { getFormattedAddress } from 'utils';

const AddressMenuItem = ({ Icon, title, address }) => {
  return (
    <S.Container>
      {!!Icon && (
        <Box display="flex" alignSelf="flex-start">
          {Icon}
        </Box>
      )}
      <Box ml={!!Icon ? '1rem' : undefined}>
        {title && <Box fontWeight="bold">{title}</Box>}
        {address && (
          <Box fontSize="0.75rem">{getFormattedAddress(address)}</Box>
        )}
      </Box>
    </S.Container>
  );
};

export default AddressMenuItem;
