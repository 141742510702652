import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardIdentifier = styled(Typography)`
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
`;

export const YourBalance = styled(Typography)`
  margin-top: 1em;
  margin-bottom: 2em;
  text-align: center;
  span {
    display: block;
  }

  b {
    font-size: 1.5rem;
  }
`;
