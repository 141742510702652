import React from 'react';
import { useMemo } from 'react';
import * as S from './image-tab.styles';
import TabImage from './tab-image/tab-image.component';

const ImageTab = ({
  imageSrc,
  selected,
  label,
  'data-testid': testId = 'ImageTab',
  ...rest
}) => {
  const icon = useMemo(
    () =>
      imageSrc ? <TabImage src={imageSrc} selected={selected} alt="" /> : null,
    [imageSrc, selected],
  );

  return (
    <S.CustomTab
      label={<S.LabelContainer>{label}</S.LabelContainer>}
      selected={selected}
      icon={icon}
      data-testid={testId}
      {...rest}
    />
  );
};

export default ImageTab;
