import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Label = styled(Typography)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 3vw;
  }
`;

export const Detail = styled(Typography)`
  word-break: ${({ noBreak }) => (!!noBreak ? 'normal' : 'break-word')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 4vw;
  }
`;
