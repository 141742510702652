import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSegmentId } from 'redux/config/config.selectors';
import { getAnalytics } from './analytics';
import { analyticsEnabledByEnv } from './analyticsEnabledByEnv';

// Assign this to window so we can override in Cypress tests. We don't want to
// load the actual code snippet in tests.
/* istanbul ignore next */
window.incSegmentSnippet =
  window.incSegmentSnippet ??
  (segmentId => {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            key +
            '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          n?.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.13.2';
        analytics._writeKey = segmentId;
        analytics.load(segmentId);
      }
  });

export const useInitSegment = () => {
  const segmentId = useSelector(selectSegmentId);

  useEffect(() => {
    if (analyticsEnabledByEnv() && !!segmentId) {
      window.incSegmentSnippet(segmentId);
      getAnalytics().page();
    }
  }, [segmentId]);
};
