import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StoreAddress = styled(Typography)`
  font-size: 1.25rem;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`;
