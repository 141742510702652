import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ErrorBox = styled(Box)`
  max-width: 300px;

  p {
    margin-bottom: 1rem;
  }
`;
