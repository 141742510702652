// Hook
import { useEffect, useRef } from 'react';
import i18next from 'i18next';

const useEventListener = (eventName, handler) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = event => savedHandler.current(event);
    i18next.on(eventName, eventListener);

    return () => {
      i18next.off(eventName);
    };
  }, [eventName]);
};

export default useEventListener;
