import { useStep } from 'components/flow/step/step.hooks';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useGiftCardPaymentStep = ({
  purchaseLoading,
  isLoading,
  trigger,
  paymentToken,
}) => {
  const history = useHistory();

  const {
    data: { giftcardData },
  } = useStep();

  const loading = useMemo(
    () => isLoading || purchaseLoading,
    [isLoading, purchaseLoading],
  );

  const processOrder = async spreedlyToken => {
    try {
      const res = await trigger(paymentToken ?? spreedlyToken);
      if (!!res?.data)
        history.push('/giftcard/thankyou', { ...giftcardData, ...res.data });
    } catch (error) {
      toast.error(error?.cause?.message ?? error?.message);
    }
  };
  return { processOrder, loading };
};
