import { useSelector } from 'react-redux';
import { selectViewOnlyLocationEnabled } from 'redux/config/config.selectors';

export const useIsViewOnlyLocationEnabled = store => {
  const webAppConfigViewOnlyLocationEnabled = useSelector(
    selectViewOnlyLocationEnabled,
  );

  return webAppConfigViewOnlyLocationEnabled || store?.viewOnlyLocationEnabled;
};
