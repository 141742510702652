import React from 'react';
import * as S from './account-loyalty-custom-two-punch-img.styles';

const AccountLoyaltyCustomTwoPunchImage = ({ img, index, testId }) => {
  return (
    <S.LoyaltyCustomTwoGrid key={`punch-img-${index}`}>
      <S.LoyaltyCustomTwoPunchImg src={img} data-testid={testId} alt="" />
    </S.LoyaltyCustomTwoGrid>
  );
};

export default AccountLoyaltyCustomTwoPunchImage;
