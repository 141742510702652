import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';

export const LocationCard = styled(Card)`
  margin-bottom: 1em;
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .back-button {
    margin-top: 1em;
    margin-bottom: 0;
  }

  &.active {
    border: 4px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.accentColor};
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Content = styled(CardContent)`
  padding-bottom: 0;
`;

export const LocationCardDisabled = styled(Card)`
  margin-bottom: 1em;
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid gray;
  opacity: 0.5;
  position: relative;
`;

export const OutOfRangeBadge = styled.div`
  position: absolute;
  transform: rotate(-45deg);
  z-index: 1;
  background-color: grey;
  padding: 5px;
  bottom: 30px;
  right: -40px;
  width: 159px;
  text-align: center;
`;
