import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setRequestedAddress } from 'redux/cart/cart.actions';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';

export const useAddress = () => {
  const { watch } = useFormContext();
  const {
    fieldNames: { address: name },
  } = useFieldNames();
  const address = watch(name, 'default');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!address) return;
    if (address === 'new') dispatch(setRequestedAddress(null));
    else if (address !== 'default') dispatch(setRequestedAddress(address));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, dispatch, name]);
};
