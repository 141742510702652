import { useFormContext } from 'react-hook-form';

export const useTrimFields = name => {
  const { setValue, trigger } = useFormContext();

  const handleTrim = e => {
    setValue(name, e.target.value.trim());
    // trigger is needed for correct email trim validation
    trigger(name);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') handleTrim(e);
  };

  return { handleKeyPress, handleTrim };
};
