import { Grid, IconButton, Tooltip } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { getAddressFromPlaceSuggestion } from 'pages/locations/locations.utils';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  actions as appActions,
  selectors as appSelectors,
} from 'redux/app/app.slice';
import usePlacesAutocomplete from 'use-places-autocomplete';
import AddressAutoCompleteInput from '../address-autocomplete/address-autocomplete-input/address-autocomplete-input.component';
import AddressAutocompleteOption from '../address-autocomplete/address-autocomplete-option/address-autocomplete-option.component';
import { CustomAutocomplete } from '../address-autocomplete/address-autocomplete.styles';
import { getOptionLabel } from '../address-autocomplete/address-autocomplete.utils';

const PickupLocationsSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control } = useFormContext();
  const [searchByGeoLocation, setSearchByGeoLocation] = useState(false);
  const searchLocation = useSelector(appSelectors.selectSearchLocation);

  const {
    suggestions: { data },
    setValue: setPlacesValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleChange = async (newValue, onChange) => {
    if (!newValue) return onChange(null);
    const address = await getAddressFromPlaceSuggestion(newValue);
    const value = {
      ...address,
      description: newValue.description,
      main_text: newValue.structured_formatting?.main_text,
    };
    onChange(value);
    setSearchByGeoLocation(false);
    dispatch(appActions.setSearchLocation(value));
  };

  const handleInputChange = useCallback(
    (_e, newValue) => setPlacesValue(newValue),
    [setPlacesValue],
  );

  const successCallback = position => {
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    setSearchByGeoLocation(true);
    dispatch(appActions.setGeoLocation(location));
    dispatch(appActions.setSearchLocation(undefined));
  };

  const errorCallback = error => {
    if (error.code === 1)
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.permission'),
      );
    else
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.failure'),
      );
    dispatch(appActions.setGeoLocation(undefined));
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  return (
    <Controller
      name="pickupSearch"
      id="pickupSearch"
      control={control}
      defaultValue={searchLocation}
      render={({ field: { value, onChange, ...rest } }) => (
        <Grid container>
          <Grid item xs={11}>
            <CustomAutocomplete
              {...rest}
              fullWidth
              data-testid="AddressSelect.CustomSelect"
              ListboxProps={{
                'data-testid': 'AddressSelect.CustomSelect.Menu',
              }}
              getOptionLabel={getOptionLabel}
              options={data}
              includeInputInList
              getOptionSelected={(option, value) =>
                option.place_id === value.placeId
              }
              value={value}
              onChange={async (_e, newValue) =>
                await handleChange(newValue, onChange)
              }
              onInputChange={handleInputChange}
              renderInput={params => (
                <AddressAutoCompleteInput
                  {...params}
                  name={'newAddress-address'}
                  fullWidth
                  placeholder={t(
                    'locations.pickupInput.locationSearch.placeholder',
                  )}
                  inputProps={{
                    'aria-labelledby': 'address-control-label',
                    'aria-required': 'true',
                    ...params.inputProps,
                  }}
                />
              )}
              renderOption={option => (
                <AddressAutocompleteOption option={option} />
              )}
              forcePopupIcon={false}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={t('locations.pickupInput.label')}>
              <IconButton
                disabled={searchByGeoLocation}
                onClick={() => {
                  getCurrentLocation();
                  handleChange('', onChange);
                }}
                data-testid="PickupLocationSearch.CurrentLocation"
              >
                <MyLocationIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default PickupLocationsSearch;
