import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const ContentHeader = styled(Typography)`
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;
