import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors,
  actions,
} from 'redux/app/header/loginDrawer/loginDrawer.slice';

export const useDrawerLoginOptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const drawerState = useSelector(selectors.selectIsOpen);
  const initialMachineState = useSelector(selectors.selectInititalMachineState);
  const setDrawerState = useCallback(
    value => dispatch(actions.setOpen(value)),
    [dispatch],
  );
  const setInitialMachineState = useCallback(
    value => dispatch(actions.setInititalMachineState(value)),
    [dispatch],
  );
  const [isLight, setIsLight] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState(
    t('drawer.login.loginOrRegister'),
  );

  const handleDrawerToggle = useCallback(
    isOpen => () => {
      setDrawerState(isOpen);
      if (isOpen) {
        setIsLight(false);
      } else {
        setInitialMachineState('initial');
      }
    },
    [setDrawerState, setInitialMachineState],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setDrawerState(false), []);

  return {
    drawerState,
    setDrawerState,
    initialMachineState,
    setInitialMachineState,
    isLight,
    setIsLight,
    drawerTitle,
    setDrawerTitle,
    handleDrawerToggle,
  };
};
