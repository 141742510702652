import { PaymentGatewayType } from 'components/payment/payment.constants';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import CloverCheckoutButtons from './clover-checkout-buttons/clover-checkout-buttons.component';
import IncentivioCheckoutButtons from './incentivio-checkout-buttons/incentivio-checkout-buttons.component';
import SpreedlyCheckoutButtonsContainer from './spreedly-checkout-buttons/spreedly-checkout-buttons-container/spreedly-checkout-buttons-container.component';
import SquareCheckoutButtons from './square-checkout-buttons/square-checkout-buttons.component';

const CheckoutButtonsWrapper = props => {
  const matchesPaymentGateway = usePaymentGatewayMatches();

  if (matchesPaymentGateway([PaymentGatewayType.SPREEDLY]))
    return <SpreedlyCheckoutButtonsContainer {...props} />;

  if (matchesPaymentGateway([PaymentGatewayType.INCENTIVIO_PAYMENTS]))
    return <IncentivioCheckoutButtons {...props} />;

  if (matchesPaymentGateway([PaymentGatewayType.CLOVER]))
    return <CloverCheckoutButtons {...props} />;

  if (matchesPaymentGateway([PaymentGatewayType.SQUARE]))
    return <SquareCheckoutButtons {...props} />;

  return null;
};
export default CheckoutButtonsWrapper;
