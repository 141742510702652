import Spinner from 'components/spinner/spinner.component';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectStatsigUser } from 'redux/user/user.selectors';
import { Statsig, StatsigProvider } from 'statsig-react';
import { getEnv } from 'util/getEnv';

const StatsigWrapper = ({ children }) => {
  const user = useSelector(selectStatsigUser);

  /* istanbul ignore next */
  return !!window.Cypress ? (
    <TestStatsigWrapper user={user}>{children}</TestStatsigWrapper>
  ) : (
    <StatsigProvider
      sdkKey={getEnv('REACT_APP_STATSIG_KEY')}
      waitForInitialization={false}
      options={{
        environment: { tier: getEnv('REACT_APP_ENV_NAME') },
      }}
      user={user}
      initializingComponent={<Spinner />}
    >
      {children}
    </StatsigProvider>
  );
};

const TestStatsigWrapper = ({ children, user }) => {
  useEffect(() => {
    if (window.statsigOverrides) {
      Object.entries(window.statsigOverrides.gates).forEach(
        ([gateName, value]) => {
          Statsig.overrideGate(gateName, value);
        },
      );
    }
  }, []);

  return (
    <StatsigProvider
      sdkKey="client-test"
      waitForInitialization={false}
      options={{
        environment: { tier: 'development' },
        localMode: true,
      }}
      user={user}
      initializingComponent={<Spinner />}
    >
      {children}
    </StatsigProvider>
  );
};

export default StatsigWrapper;
