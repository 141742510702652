import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import INC_BASE_API from 'apis/incentivio-api';
import {
  getResponseErrorCode,
  getResponseErrorMessage,
} from 'apis/incentivio-api.util';
import { toast } from 'react-toastify';

const sliceName = 'data/orderavailability';

const entityAdapter = createEntityAdapter({
  selectId: entity => entity.storeId,
});

const setAsapDuration = createAction(`${sliceName}/setAsapDuration`);

const fetchOrderAvailability = createAsyncThunk(
  `${sliceName}/fetchOrderAvailability`,
  async (
    {
      storeId,
      address: userAddress,
      orderId,
      requestedOrderType: orderType,
      selectedDay,
    },
    thunkAPI,
  ) => {
    try {
      const address = orderType === 'DELIVERY' ? userAddress : undefined;
      const response = await INC_BASE_API.post(
        `/locations/${storeId}/orderavailability`,
        {
          address,
          orderId,
          selectedDay,
        },
      );

      if (
        (orderType === 'PICKUP' && response.data.pickupAsapAvailable) ||
        (orderType === 'DELIVERY' && response.data.deliveryAsapAvailable)
      ) {
        const asapData = await fetchAsap(storeId, address, orderId, orderType);
        thunkAPI.dispatch(
          setAsapDuration({
            storeId,
            ...asapData,
          }),
        );
      }

      return {
        ...response.data,
        storeId,
      };
    } catch (error) {
      const errorMessage = getResponseErrorMessage(error);
      toast.error(errorMessage);
      return thunkAPI.rejectWithValue({
        errorCode: getResponseErrorCode(error),
        errorMessage,
      });
    }
  },
);

export const initialState = {
  ...entityAdapter.getInitialState(),
  loading: false,
  currentRequestId: null,
  error: null,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    upsertOne: entityAdapter.upsertOne,
    updateOne: entityAdapter.updateOne,
    removeOne: entityAdapter.removeOne,
    setAsapDuration(state, action) {
      entityAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderAvailability.pending, (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    });

    builder.addCase(fetchOrderAvailability.fulfilled, (state, action) => {
      if (state.loading && state.currentRequestId === action.meta.requestId) {
        state.loading = false;
        state.currentRequestId = null;
        entityAdapter.upsertOne(state, action.payload);
      }
    });

    builder.addCase(fetchOrderAvailability.rejected, (state, action) => {
      if (state.loading && state.currentRequestId === action.meta.requestId) {
        state.loading = false;
        state.currentRequestId = null;
        state.error = action.payload;
      }
    });
  },
});

export const actions = {
  ...slice.actions,
  fetchOrderAvailability,
  setAsapDuration,
};

export default slice.reducer;

export async function fetchAsap(storeId, userAddress, orderId, orderType) {
  try {
    const address = orderType === 'DELIVERY' ? userAddress : undefined;
    const asapData = await INC_BASE_API.post(
      `/locations/${storeId}/orderavailability/asap`,
      {
        address,
        orderId,
        orderType,
      },
    );
    if (orderType === 'PICKUP') {
      return { pickupAsapDuration: asapData.data?.asapDuration };
    }
    return { deliveryAsapDuration: asapData.data?.asapDuration };
  } catch (e) {
    if (orderType === 'PICKUP') {
      return { pickupAsapDuration: undefined };
    }
    return { deliveryAsapDuration: undefined };
  }
}
