import CustomInput from 'components/custom-input/custom-input.component';
import React, { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Group } from 'reakit/Group';
import { Rover, useRoverState } from 'reakit/Rover';
import * as S from './time-selector.styles';
import { scroller } from 'react-scroll';
import AvailabilityErrors from 'pages/store/new-order-form/availability-errors/availability-errors.component';
import LocationsCardSpinner from 'pages/locations/locations-card/locations-card-spinner/locations-card-spinner.component';
import { useNewOrderForm } from 'pages/store/new-order-form/useNewOrderForm.hooks';
import { useFieldNames } from '../useFieldNames.hooks';

const TimeSelector = ({
  times,
  name,
  defaultValue,
  onTimeClick = () => null,
  containerId = 'time-container',
  storeId,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const rover = useRoverState({ orientation: 'vertical' });
  const { fieldNames } = useFieldNames();

  const { orderAvailabilityError, isOrderAvailabilityLoading } =
    useNewOrderForm(storeId);

  useEffect(() => {
    if (!times[0]) return;

    if (!!defaultValue) {
      const defaultValueInTimes = times.find(
        time => time.key === defaultValue.key,
      );

      if (!!defaultValueInTimes) {
        scroller.scrollTo(defaultValueInTimes.key, { containerId });
        setValue(fieldNames.time, defaultValueInTimes);
        return;
      }
    }

    setValue(fieldNames.time, times[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times[0]]);

  if (isOrderAvailabilityLoading) return <LocationsCardSpinner />;

  if (!!orderAvailabilityError)
    return <AvailabilityErrors error={orderAvailabilityError} />;

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, name, ref } }) => (
          <CustomInput>
            <Group
              as={S.TimeToggleGroup}
              value={value}
              exclusive
              onChange={(_e, newValue) => !!newValue && onChange(newValue)}
              name={name}
              id={name}
              data-testid="TimeSelector.TimeToggleGroup"
              aria-label={t('orderDaySelector.ariaLabel')}
              ref={ref}
            >
              {times.map(time => (
                <Rover
                  {...rover}
                  as={S.TimeToggleButton}
                  key={time.key}
                  id={time.key}
                  value={time}
                  selected={time.dateString === value?.dateString}
                  aria-label={time.timeLabel}
                  data-testid="TimeSelector.TimeToggleButton"
                  onClick={() => onTimeClick({ time })}
                  disabled={!time.valid}
                >
                  {time.timeLabel}
                </Rover>
              ))}
            </Group>
          </CustomInput>
        )}
      />
    </>
  );
};

export default memo(TimeSelector);
