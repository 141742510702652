import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

export const CheckboxWrapper = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    font-size: 0.875rem;
    margin-left: ${({ theme }) => theme.spacing(0)}px;
    margin-top: ${({ theme }) => theme.spacing(-2)}px;
  }
`;
