import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const ResendCodeLink = styled(Link)`
  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.3;
    `}
`;
