import { OrderTypeContext } from 'pages/store/new-order-form/order-form-context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'redux/app/app.slice';
import { selectRequestedStoreOrderValueShortMessage } from 'redux/locations/locations.selectors';
import { CATERING } from 'util/constants';
import MinMaxMessage from './min-max-message/min-max-message.component';
import CateringPickupMessage from './min-max-message/min-max-catering-message/catering-pickup-message/catering-pickup-message.component';
import CateringDeliveryMessage from './min-max-message/min-max-catering-message/catering-delivery-message/catering-delivery-message.component';
import { selectHideCateringMinMaxShortMessage } from 'redux/config/config.selectors';

const MinMax = ({
  store: { storeId, pickupOrdersAccepted, deliveryOrdersAccepted },
}) => {
  const orderType = useContext(OrderTypeContext);
  const locationType = useSelector(selectors.selectLocationsLocationType);
  const minMaxMessage = useSelector(state =>
    selectRequestedStoreOrderValueShortMessage(state, storeId, orderType),
  );
  const hideCateringMinMaxShortMessage = useSelector(
    selectHideCateringMinMaxShortMessage,
  );

  if (locationType === CATERING)
    return (
      !hideCateringMinMaxShortMessage && (
        <>
          {pickupOrdersAccepted && <CateringPickupMessage storeId={storeId} />}
          {deliveryOrdersAccepted && (
            <CateringDeliveryMessage storeId={storeId} />
          )}
        </>
      )
    );

  return (
    <MinMaxMessage message={minMaxMessage} data-testid="MinMax.MinMaxMessage" />
  );
};

export default MinMax;
