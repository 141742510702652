import { Typography } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const EditButton = styled(IncButton)`
  min-width: 40px;
`;

export const Price = styled(Typography)`
  font-size: 1.15rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1.2rem;
  }
`;
