import INC_BASE_API from 'apis/incentivio-api';
import RecommendationActionTypes from './recommendations.types';
import { selectors as clientSelectors } from '../client/client.slice';
import {
  selectLocationId,
  selectOrderId,
  selectOrderItemExternalIdObjectArray,
  selectOrderItems,
  selectRequestedFulfillTime,
  selectDisplayTotalWithoutGratuity,
} from 'redux/cart/cart.selectors';
import { selectUserId } from 'redux/user/user.selectors';
import { selectRecommendationsEnabled } from 'redux/config/config.selectors';
import {
  selectRecommendationAccepted,
  selectIsUserSeenRecommendationItems,
} from './recommendations.selectors';
import { selectRequestedStore } from 'redux/locations/locations.selectors';

export const setRecommendationDetails = payload => ({
  type: RecommendationActionTypes.SET_RECOMMENDATION_DETAILS,
  payload,
});

export const setRecommendationAddedOrderItemIds = payload => ({
  type: RecommendationActionTypes.SET_RECOMMENDATION_ADDED_ORDER_ITEM_IDS,
  payload,
});

export const removeRecommendationAddedOrderItemId = payload => ({
  type: RecommendationActionTypes.REMOVE_RECOMMENDATION_ADDED_ORDER_ITEM_ID,
  payload,
});

export const resetRecommendations = () => ({
  type: RecommendationActionTypes.RESET_RECOMMENDATIONS,
});

export const retrieveRecommendations = () => {
  return async (dispatch, getState) => {
    const isUserSeenRecommendationItems = selectIsUserSeenRecommendationItems(
      getState(),
    );

    if (isUserSeenRecommendationItems) return;

    const clientId = clientSelectors.selectClientId(getState());
    const location = selectRequestedStore(getState());
    const requestedFulfillTime = selectRequestedFulfillTime(getState());
    const orderId = selectOrderId(getState());
    const displayOrderTotal = selectDisplayTotalWithoutGratuity(getState());
    const orderItemExternalIdObjectArray = selectOrderItemExternalIdObjectArray(
      getState(),
    );
    try {
      const response = await INC_BASE_API.post('/ml/mobile/user/basket', {
        orderItems: orderItemExternalIdObjectArray,
        clientId,
        locationId: location.storeId,
        orderId,
        orderDate: requestedFulfillTime.substring(0, 10),
        initialBasketValue: displayOrderTotal,
        timeZone: location.timeZone,
      });
      dispatch(setRecommendationDetails(response.data));
      return response.data;
    } catch (e) {
      dispatch(setRecommendationDetails());
      return null;
    }
  };
};

export const updateRecommendationsOrder = () => {
  return async (_dispatch, getState) => {
    const isRecommendationsEnabled = selectRecommendationsEnabled(getState());
    const recommendationAccepted = selectRecommendationAccepted(getState());
    const clientId = clientSelectors.selectClientId(getState());
    const locationId = selectLocationId(getState());
    const userId = selectUserId(getState());
    const orderId = selectOrderId(getState());
    const requestedFulfillTime = selectRequestedFulfillTime(getState());
    const displayOrderTotal = selectDisplayTotalWithoutGratuity(getState());
    const orderItems = selectOrderItems(getState());
    const checkoutBasket = orderItems.map(item => JSON.stringify(item));
    if (!isRecommendationsEnabled) return;

    try {
      const response = await INC_BASE_API.post('/ml/mobile/user/update/order', {
        clientId,
        locationId,
        userId,
        orderId,
        orderDate: requestedFulfillTime.substring(0, 10),
        checkoutBasketValue: displayOrderTotal,
        checkoutBasket,
        recommendationAccepted: recommendationAccepted ? '1' : '0',
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };
};
