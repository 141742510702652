import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
