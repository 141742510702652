import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getAddressFromPlaceSuggestion } from 'pages/locations/locations.utils';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import usePlacesAutocomplete from 'use-places-autocomplete';
import AddressAutoCompleteInput from './address-autocomplete-input/address-autocomplete-input.component';
import AddressAutocompleteOption from './address-autocomplete-option/address-autocomplete-option.component';
import * as S from './address-autocomplete.styles';
import { getOptionLabel } from './address-autocomplete.utils';

const AddressAutocomplete = ({
  name,
  control,
  defaultValue = null,
  rules = {},
}) => {
  const {
    suggestions: { data },
    setValue: setPlacesValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleInputChange = useCallback(
    (e, newValue) => setPlacesValue(newValue),
    [setPlacesValue],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ...rest } }) => (
        <S.CustomAutocomplete
          {...rest}
          fullWidth
          data-testid="AddressAutocomplete.CustomAutocomplete"
          filterOptions={x => x}
          ListboxProps={{
            'data-testid': 'AddressAutocomplete.CustomAutocomplete.Listbox',
          }}
          getOptionLabel={getOptionLabel}
          options={data}
          includeInputInList
          getOptionSelected={(option, value) =>
            option.place_id === value.placeId
          }
          value={value}
          onChange={async (e, newValue) => {
            if (!newValue) return onChange(null);
            const address = await getAddressFromPlaceSuggestion(newValue);
            onChange({
              ...address,
              description: newValue.description,
              main_text: newValue.structured_formatting?.main_text,
            });
          }}
          onInputChange={handleInputChange}
          renderInput={({ inputProps, ...paramsRest }) => (
            <AddressAutoCompleteInput
              name={name}
              fullWidth
              {...paramsRest}
              inputProps={{
                'aria-labelledby': 'address-control-label',
                ...inputProps,
              }}
            />
          )}
          renderOption={option => <AddressAutocompleteOption option={option} />}
          popupIcon={<LocationOnIcon />}
        />
      )}
    />
  );
};

export default AddressAutocomplete;
