import { MenuDialogProvider } from 'hooks/dialog/menu-dialog.context';
import { useMenuItemDialog } from 'hooks/dialog/menu-dialog.hooks';
import MenuModal from 'pages/menu/menu-modal/menu-modal.component';
import NotFoundModal from 'pages/menu/menu-modal/not-found-modal.component';
import { FormProvider, useForm } from 'react-hook-form';

const MenuDialog = () => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const menuDialogProps = useMenuItemDialog(methods);
  const { menuItemDialogOpen, notFoundDialogOpen } = menuDialogProps;

  return (
    <MenuDialogProvider value={menuDialogProps}>
      {menuItemDialogOpen && (
        <FormProvider {...methods}>
          <MenuModal open />
        </FormProvider>
      )}
      {notFoundDialogOpen && <NotFoundModal open />}
    </MenuDialogProvider>
  );
};

export default MenuDialog;
