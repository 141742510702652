import { Box } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { CheckoutH3 } from 'pages/checkout/checkout.styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCustomerInfo } from 'redux/cart/cart.selectors';
import CheckoutSectionPreview from '../../checkout-section/checkout-section-preview/checkout-section-preview.component';

const CheckoutContactInfoSectionPreview = () => {
  const customerInfo = useSelector(selectCustomerInfo);

  if (!customerInfo) return null;

  const { firstName, lastName, phoneNumber, email } = customerInfo ?? {};

  return (
    <CheckoutSectionPreview>
      <CheckoutH3 data-testid="CheckoutContactInfoSectionPreview.CheckoutH3.Name">
        {firstName} {lastName}
      </CheckoutH3>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box mr={1} display="flex" alignItems="center">
          <PhoneIcon color="primary" fontSize="small" />
          <Box
            ml="4px"
            data-testid="CheckoutContactInfoSectionPreview.Box.Phone"
          >
            {phoneNumber}
          </Box>
        </Box>
        <Box mr={1} display="flex" alignItems="center">
          <EmailIcon color="primary" fontSize="small" />
          <Box
            ml="4px"
            data-testid="CheckoutContactInfoSectionPreview.Box.Email"
          >
            {email}
          </Box>
        </Box>
      </Box>
    </CheckoutSectionPreview>
  );
};

export default CheckoutContactInfoSectionPreview;
