import BackAction from 'components/back-action/back-action.component';
import SpreedlyCardGuest from 'components/payment/spreedly/spreedly-card-guest/spreedly-card-guest.component';
import React, { useContext, useEffect } from 'react';
import CreditCardsController from '../../credit-cards-controller/credit-cards-controller.component';
import CheckoutContext from 'pages/checkout/checkout.context';
import { useResetPaymentValidation } from 'pages/checkout/checkout-payment-section/checkout-payment-section.hooks';

const NewSpreedlyCreditCardPanel = ({ cards, ...rest }) => {
  const { isSpreedlyFormStep, setIsSpreedlyFormStep } =
    useContext(CheckoutContext);
  const resetPaymentValidation = useResetPaymentValidation();

  const handleAddNewCardClick = () => {
    setIsSpreedlyFormStep(true);
  };

  const handleBack = () => {
    setIsSpreedlyFormStep(false);
    resetPaymentValidation();
  };

  useEffect(() => {
    setIsSpreedlyFormStep(cards.length === 0);
  }, [cards.length, setIsSpreedlyFormStep]);

  if (cards.length === 0) return <SpreedlyCardGuest />;

  if (!isSpreedlyFormStep)
    return (
      <CreditCardsController
        cards={cards}
        handleAddNewCardClick={handleAddNewCardClick}
        {...rest}
      />
    );

  return (
    <>
      <BackAction handleBack={handleBack} />
      <SpreedlyCardGuest />
    </>
  );
};

export default NewSpreedlyCreditCardPanel;
