export const defaultMessages = [
  {
    distributedMessageId: '865eaad0-2494-4965-9bed-974871065cb3',
    merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
    messageId: '2b0d5c1e-d29d-42f4-b081-e065db9d8dc5',
    languageCode: 'EN',
    title: 'WOV2 - Survey message - Auto',
    shortDescription: 'Short WOV2 - Survey message - Auto',
    longDescription:
      'Open your mobile app, or click <a href="https://order.incentiviodev.com/c/palmlanedev">here</a> and sign in to your account to answer the survey in your inbox.',
    smallImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/m/Survey-Auto_a88a1eb29e7748df9e8341a5ea8b1ab3.jpg',
    mediumImage: '',
    status: 'READ',
    distributedDate: '2024-07-09T18:45:10',
    updatedDate: '2024-07-09T18:45:10',
    messageType: 'SURVEY',
    surveyId: '650abc58d3d7dc536aca5e7e',
  },
  {
    distributedMessageId: '0f005361-053f-415b-ae5c-9349cfae6e8d',
    merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
    messageId: '8e42147a-068b-4bc8-8cc5-9806371211ea',
    languageCode: 'EN',
    title: '10% modifier offer',
    shortDescription: '10% modifier offer',
    longDescription: '10% modifier offer',
    smallImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/o/custard-pudding-00_3ba1991294e14843a77c37292a6d26ce_1cd9e8c1da644079a2120e45f2febf8c.jpg',
    mediumImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/o/custard-pudding-00_3ba1991294e14843a77c37292a6d26ce_1cd9e8c1da644079a2120e45f2febf8c.jpg',
    status: 'READ',
    distributedDate: '2023-11-16T10:47:51',
    updatedDate: '2023-11-16T10:47:51',
    messageType: 'SHAREABLE',
    surveyId: null,
    distributedEndDate: null,
  },
];
