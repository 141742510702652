import { getFormatttedOrderingTimeSlot } from '../../ordering-hours.util';
import * as S from './ordering-time-slot.styles';

const OrderingTimeSlot = ({ duration }) => {
  return (
    <S.TimeSlot component="li">
      {getFormatttedOrderingTimeSlot(duration)}
    </S.TimeSlot>
  );
};

export default OrderingTimeSlot;
