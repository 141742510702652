import { Tooltip } from '@material-ui/core';
import * as S from './header-tooltip.styles';

const HeaderTooltipIcon = ({ tooltipText, icon, ...rest }) => {
  return (
    <Tooltip title={tooltipText} enterDelay={500}>
      <S.CustomIconButton aria-label={tooltipText} {...rest}>
        {icon}
      </S.CustomIconButton>
    </Tooltip>
  );
};

export default HeaderTooltipIcon;
