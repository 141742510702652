import { useTranslation } from 'react-i18next';
import * as S from './verify-email.styles';
import Spinner from 'components/spinner/spinner.component';
import { useVerifyEmailRequest } from './useVerifyEmailRequest.hooks';
import VerifyEmailHeader from './verify-email-header/verify-email-header.component';
import VerifyEmailErrorMessage from './verify-email-error-message/verify-email-error-message.component';
import VerifyEmailSuccessMessage from './verify-email-success-message/verify-email-success-message.component';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { loading, error } = useVerifyEmailRequest();

  return (
    <S.Container data-testid="VerifyEmail.Container">
      {loading && <Spinner />}
      <S.ContentContainer elevation={2}>
        {!error && <VerifyEmailHeader title={t('verifyEmail.header')} />}
        <S.ContentBody>
          {error ? <VerifyEmailErrorMessage /> : <VerifyEmailSuccessMessage />}
        </S.ContentBody>
      </S.ContentContainer>
    </S.Container>
  );
};

export default VerifyEmail;
