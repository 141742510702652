import React, { forwardRef } from 'react';
import Input from '@material-ui/core/Input';
import { useTrimFields } from '../useTrimFields.hooks';

const TrimEmailField = forwardRef((props, ref) => {
  const { name, ...rest } = props;
  const { handleTrim, handleKeyPress } = useTrimFields(name);

  return (
    <Input
      type="email"
      name={name}
      onKeyPress={handleKeyPress}
      inputRef={ref}
      onBlur={handleTrim}
      {...rest}
    />
  );
});

export default TrimEmailField;
