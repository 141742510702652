import { getEnv } from 'util/getEnv';
import { grecaptcha } from 'util/grecaptcha';

const defaultActivityContent = () => ({
  messageList: {
    messages: [],
  },
  offerList: {
    offers: [],
  },
});

export const deleteActivityContent = (content, id) => {
  const baseActivityContent = defaultActivityContent();

  if (!content) {
    return undefined;
  }

  baseActivityContent.offerList.offers = content.offerList.offers.filter(
    offer => offer.distributedOfferId !== id,
  );
  baseActivityContent.messageList.messages =
    content.messageList.messages.filter(
      message => message.distributedMessageId !== id,
    );

  return baseActivityContent;
};

export const getActivityEvents = (messageId, socialMedia, checkIn) => {
  let smEvent;

  if (!!socialMedia) {
    switch (socialMedia) {
      case 'tw':
        smEvent = { 'socialmedia:tw': [`MSG:${messageId}`] };
        break;

      case 'fb':
        smEvent = { 'socialmedia:fb': [`MSG:${messageId}`] };
        break;

      default:
        smEvent = {};
    }
  }

  return smEvent ? { ...smEvent } : { 'check-in': [checkIn] };
};

export const getRecaptchaToken = async action => {
  const gre = grecaptcha();
  const recapthchaKey = getEnv('REACT_APP_RECAPTCHA_KEY');
  if (!!gre?.enterprise) {
    try {
      await new Promise(resolve => gre?.enterprise?.ready(resolve));
      return await gre.enterprise.execute(recapthchaKey, { action });
    } catch (e) {
      return null;
    }
  }
};
