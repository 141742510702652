import { dialogDescription2 } from 'components/custom-dialog/custom-dialog.constants';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import * as S from './message-long-description.styles';

const MessageLongDescription = ({ description }) => {
  return (
    <S.LongDescription id={dialogDescription2}>
      <LinkifyParse>{description}</LinkifyParse>
    </S.LongDescription>
  );
};

export default MessageLongDescription;
