import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';

const minHeight = 1;

export const TipGroup = styled(Box)`
  display: flex;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.bgColor};
  min-height: ${minHeight}px;
`;

export const TipButton = styled(Button)`
  &.MuiButton-root {
    text-transform: capitalize;
    min-width: 1px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 1px 10px;
    min-height: ${minHeight}px;
    flex: 1;
    opacity: 1;

    color: ${({ selected, theme }) =>
      selected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary};
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : 'transparent'};
    border-color: ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : 'transparent'};

    &.Mui-disabled {
      opacity: 0.4;
    }
  }
`;
