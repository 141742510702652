import React from 'react';
import CustomDialog from '../../custom-dialog/custom-dialog.component';
import Spinner from '../../spinner/spinner.component';
import CustomInput from '../../custom-input/custom-input.component';
import InputLabel from '@material-ui/core/InputLabel';
import FormError from '../../form-error/form-error.component';
import CustomCheckbox from '../../custom-checkbox/custom-checkbox.component';
import Grid from '@material-ui/core/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsCardLoading } from '../../../redux/user/user.selectors';
import {
  deletePaymentInstrument,
  editPaymentInstrument,
} from '../../../redux/user/user.actions';
import { useTranslation } from 'react-i18next';
import * as S from './card-item-edit.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';

const CardItemEdit = ({
  isOpen,
  handleClose,
  paymentInstrumentId,
  nickname,
  lastFour,
  isDefault,
  testId = 'CardItemEdit.CustomDialog',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsCardLoading);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = data => {
    dispatch(editPaymentInstrument(paymentInstrumentId, data)).then(res =>
      handleClose(),
    );
  };

  const handleDelete = async () => {
    const response = await dispatch(
      deletePaymentInstrument(paymentInstrumentId),
    );
    if (!!response) handleClose();
  };

  return (
    <CustomDialog
      open={isOpen}
      title="Edit Card"
      close={handleClose}
      testId={testId}
    >
      {isLoading && <Spinner />}
      <FormProvider {...methods}>
        <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
          <CustomInput>
            <InputLabel htmlFor="nickname">
              {t('cardItem.nickname.label')}*
            </InputLabel>
            <TrimTextField
              aria-label="Nickname"
              data-testid="nickname"
              fullWidth
              id="nickname"
              defaultValue={nickname}
              placeholder={t('cardItem.nickname.placeholder')}
              error={!!errors.nickname}
              inputProps={{
                'aria-required': true,
              }}
              {...register('nickname', {
                required: true,
                validate: value => !!value.trim(),
              })}
            />
            {errors.nickname && (
              <FormError errorMessage={t('register.errors.requiredField')} />
            )}
          </CustomInput>

          <CustomInput>
            <InputLabel htmlFor="cardNumber">
              {t('cardItem.cardNumber')} *
            </InputLabel>
            <TrimTextField
              fullWidth
              id="cardNumber"
              disabled
              name="cardNumber"
              defaultValue={`xxxx xxxx xxxx ${lastFour}`}
            />
          </CustomInput>

          <CustomInput>
            <CustomCheckbox
              defaultChecked={isDefault}
              name="isDefault"
              label="Set as default?"
              {...register('isDefault')}
            />
          </CustomInput>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <IncButtonDark
                onClick={handleDelete}
                data-testid="CardItemEdit.CustomButton.Delete"
                fullWidth
              >
                {t('cardItem.deleteBtn')}
              </IncButtonDark>
            </Grid>
            <Grid item xs={6}>
              <IncButton
                type="submit"
                data-testid="CardItemEdit.CustomButton.Save"
                fullWidth
              >
                {t('cardItem.saveBtn')}
              </IncButton>
            </Grid>
          </Grid>
        </S.CustomForm>
      </FormProvider>
    </CustomDialog>
  );
};

export default CardItemEdit;
