import React from 'react';
import * as S from './account-loyalty-points-box.styles';

const AccountLoyaltyPointsBox = ({ points, pointName }) => {
  return (
    <S.PointsBox
      borderRadius={5}
      p={1}
      textAlign="center"
      mb={[2, 2, 1]}
      fontSize={['1.5rem', '1.8rem']}
    >
      {points} {pointName}
    </S.PointsBox>
  );
};

export default AccountLoyaltyPointsBox;
