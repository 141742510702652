import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';

const PurchaseButton = ({ processOrder, ...rest }) => {
  const { t } = useTranslation();

  return (
    <IncButton
      onClick={processOrder}
      data-testid="GiftcardPaymentStep.CustomButton.Purchase"
      fullWidth
      {...rest}
    >
      {t('eGiftCard.payment.purchase')}
    </IncButton>
  );
};

export default PurchaseButton;
