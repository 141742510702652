import { FormControlLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPointsToSpend } from 'redux/cart/cart.utils';
import * as S from './offer-item.styles';
import OfferItemSlider from './offer-item-slider/offer-item-slider.component';
import { selectSelectedDiscount } from 'redux/cart/cart.selectors';

const OfferItem = ({ offer, active, setPointsToSpend, pointsToSpend }) => {
  const {
    distributedOfferId,
    title,
    smallImage,
    variablePoints,
    discountAmount,
    pointsCost,
    availablePoints,
  } = offer;
  const appliedDiscount = useSelector(selectSelectedDiscount);

  useEffect(() => {
    if (active) {
      if (appliedDiscount?.distributedOfferId === distributedOfferId) {
        setPointsToSpend(getPointsToSpend(appliedDiscount));
      } else {
        setPointsToSpend(pointsCost);
      }
    }
  }, [
    active,
    appliedDiscount,
    distributedOfferId,
    pointsCost,
    setPointsToSpend,
  ]);

  return (
    <>
      <FormControlLabel
        value={distributedOfferId}
        control={<Radio color="primary" />}
        label={
          <S.Label>
            <img alt="" src={smallImage} />
            {title}
          </S.Label>
        }
        data-testid="OfferItem.FormControlLabel"
      />
      {variablePoints && active && (
        <OfferItemSlider
          discountAmount={discountAmount}
          pointsCost={pointsCost}
          availablePoints={availablePoints}
          setPointsToSpend={setPointsToSpend}
          pointsToSpend={pointsToSpend}
        />
      )}
    </>
  );
};

export default OfferItem;
