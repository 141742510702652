import styled from 'styled-components';

export const elementStyles = {
  input: {
    'font-size': '1em',
  },
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
`;
