import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const StickyContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: calc(
      100vh - ${({ theme }) => theme.spacing(2)}px -
        ${({ theme }) => theme.navHeight} - 10px
    );
  }
`;

export const StickyScroll = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
