import { createSelector, createSlice } from '@reduxjs/toolkit';

const sliceName = 'loginDrawer';

const loginDrawer = createSlice({
  name: sliceName,
  initialState: {
    open: false,
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setInititalMachineState: (state, action) => {
      state.initial = action.payload;
    },
  },
});

// SELECTORS
const selectLoginDrawer = state => state.app.header.loginDrawer;

const selectIsOpen = createSelector(
  selectLoginDrawer,
  loginDrawer => loginDrawer.open,
);

const selectInititalMachineState = createSelector(
  selectLoginDrawer,
  loginDrawer => loginDrawer.initial,
);

// EXPORTS
export const actions = {
  ...loginDrawer.actions,
};

export const selectors = {
  selectLoginDrawer,
  selectIsOpen,
  selectInititalMachineState,
};

export default loginDrawer.reducer;
