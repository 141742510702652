import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { useIsStoreNotWithinDeliveryDistance } from 'hooks/useIsStoreWithinDeliveryDistance';

export const useOrderTypeSelectors = (
  storeDetails = {},
  isCateringStore = false,
) => {
  const { t } = useTranslation();
  const {
    pickupOrdersAccepted,
    deliveryOrdersAccepted,
    displayInfo,
    externalOrderingEnabled,
  } = storeDetails;
  const { title } = useDisplayInfo(displayInfo);
  const { pickupLabel, deliveryLabel } = useOrderTypeLabels();
  const isStoreNotWithinDeliveryDistance =
    useIsStoreNotWithinDeliveryDistance(storeDetails);

  const orderTypes = useMemo(() => {
    const tabs = [];
    if (externalOrderingEnabled) return;

    if (pickupOrdersAccepted)
      tabs.push({
        type: 'PICKUP',
        label: pickupLabel,
        'aria-label': t('orderTypeSelector.pickupAction.ariaLabel', {
          catering: isCateringStore ? t('orderTypeSelector.catering') : '',
          store: title,
        }),
        'data-testid': 'OrderTypeSelectors.CustomButton.Pickup',
      });

    if (deliveryOrdersAccepted)
      tabs.push({
        type: 'DELIVERY',
        label: deliveryLabel,
        'aria-label': t('orderTypeSelector.deliveryAction.ariaLabel', {
          store: title,
          catering: isCateringStore ? t('orderTypeSelector.catering') : '',
        }),
        'data-testid': 'OrderTypeSelectors.CustomButton.Delivery',
        disabled: isStoreNotWithinDeliveryDistance,
      });

    return tabs;
  }, [
    deliveryLabel,
    deliveryOrdersAccepted,
    externalOrderingEnabled,
    isCateringStore,
    isStoreNotWithinDeliveryDistance,
    pickupLabel,
    pickupOrdersAccepted,
    t,
    title,
  ]);

  return { orderTypes };
};
