import { loadStripe as realLoadStripe } from '@stripe/stripe-js';

export const loadStripe = (...args) => {
  return realLoadStripe(...args).then(result => {
    if (window.Cypress && window.incTest.stripe) {
      window.Stripe = {
        ...result,
        confirmCardSetup: (...args) =>
          window.incTest.stripe.confirmCardSetup(...args),
        confirmCardPayment: (...args) =>
          window.incTest.stripe.confirmCardPayment(...args),
        paymentRequest: (...args) => {
          const pr = result.paymentRequest(...args);
          return Object.assign(
            pr,
            window.incTest.stripe.paymentRequest(...args),
          );
        },
      };
      return window.Stripe;
    }
    return result;
  });
};
