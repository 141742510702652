import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  &.emptyCart {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled(Typography)`
  font-size: 1.125rem;
  margin-bottom: 0.5em;
`;

export const Body = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  && > *:last-child {
    border-bottom: none;
  }
`;

export const Footer = styled(Box)`
  border-top: 1px solid #d8d8d8;
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const SubtotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  span,
  p {
    font-size: 1.125rem;
  }
`;
