import styled, { keyframes, css } from 'styled-components';
import RoomIcon from '@material-ui/icons/Room';

const pulsate = keyframes`
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    50% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
`;

export const PinImage = styled.img`
  transform: translate(-50%, -50%);
  margin: -18px 0px 0px 3px;
  width: 50px;
  filter: contrast(${({ isGrayOut }) => (isGrayOut ? 0.5 : 1)});
`;

export const Pin = styled(RoomIcon)`
  transform: translate(-50%, -50%);
  margin: -15px 0px 0px 3px;
  opacity: ${({ isGrayOut }) => (isGrayOut ? 0.5 : 1)};
`;

export const Pulse = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  opacity: ${({ isGrayOut }) => (isGrayOut ? 0.5 : 1)};
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 4px 0px -4px;
  transform: rotateX(55deg);
  z-index: -2;

  &:after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: ${({ isGrayOut }) =>
      !isGrayOut &&
      css`
        ${pulsate} 3s ease-out
      `};
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px ${({ theme }) => theme.palette.primary.main};
    animation-delay: 1.1s;
  }
`;
