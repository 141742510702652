import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectLocationId } from 'redux/cart/cart.selectors';
import {
  makeSelectIsStoreAvailable,
  makeSelectIsMenuBrowsingStore,
} from 'redux/locations/locations.selectors';

export const useIsStoreDisabled = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const storeId = useSelector(selectLocationId);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());
  const available = useSelector(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(makeSelectIsStoreAvailable(storeId), [storeId]),
  );

  useEffect(() => {
    if (!available && storeId && !isMenuBrowsingStore) {
      toast.error(t('catalog.storeDisabled'));
      history.push(`/`);
    }
  }, [available, history, isMenuBrowsingStore, storeId, t]);
};
