import React from 'react';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import GiftCardAddValue from 'components/card-item/card-item-giftcard/giftcard-add-value/giftcard-add-value.component';
import GiftCardData from './gift-card-data/gift-card-data.component';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGiftCardData } from 'redux/user/user.actions';
import { useState } from 'react';
import Spinner from 'components/spinner/spinner.component';
import { selectIsCardLoading } from 'redux/user/user.selectors';

const GiftCardInsufficientBalance = ({ open, handleClose, cardIdentifier }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const isLoading = useSelector(selectIsCardLoading);

  useEffect(() => {
    const execute = async () => {
      const cardData = await dispatch(getGiftCardData(cardIdentifier));
      if (!cardData) {
        handleClose();
        return;
      }
      setData(cardData);
    };

    execute();
  }, [cardIdentifier, dispatch, handleClose]);

  return (
    <CustomDialog
      open={open}
      title={t('checkout.payment.giftCard.insufficientFunds.title')}
      close={handleClose}
      testId="GiftCardInsufficientBalance.CustomDialog"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <GiftCardData cardAccountBalance={data?.cardAccountBalance} />
          <GiftCardAddValue
            handleClose={handleClose}
            paymentInstrumentId={cardIdentifier}
            submitLabel={t('cardItem.addValueBtn')}
          />
        </>
      )}
    </CustomDialog>
  );
};

export default GiftCardInsufficientBalance;
