import React, { useEffect, useState } from 'react';
import CustomDialog from '../../custom-dialog/custom-dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './card-item-giftcard.styles';
import { selectGiftCardTitle } from 'redux/config/config.selectors';
import { getGiftCardData } from 'redux/user/user.actions';
import CardItemGiftCardContent from './card-item-giftcard-content/card-item-giftcard-content.component';

const CardItemGiftCard = ({ isOpen, handleClose, paymentInstrumentId }) => {
  const [data, setData] = useState(null);
  const giftCardTitle = useSelector(selectGiftCardTitle);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetGiftCardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetGiftCardData = async () => {
    const giftCardData = await dispatch(getGiftCardData(paymentInstrumentId));
    if (!giftCardData) {
      handleClose();
      return;
    }

    setData(giftCardData);
  };

  return (
    <CustomDialog
      open={isOpen}
      close={handleClose}
      testId="CardItem.CardItemGiftCard"
    >
      <S.ModalTitle>{giftCardTitle}</S.ModalTitle>
      <CardItemGiftCardContent
        handleGetGiftCardData={handleGetGiftCardData}
        paymentInstrumentId={paymentInstrumentId}
        data={data}
        handleClose={handleClose}
      />
    </CustomDialog>
  );
};

export default CardItemGiftCard;
