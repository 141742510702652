import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import React, { useCallback, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckoutSection from '../checkout-section/checkout-section.component';
import * as S from './checkout-order-instructions-section.styles';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderInstructions } from 'redux/cart/cart.actions';
import { selectOrderInstructions } from 'redux/cart/cart.selectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { orderInstructionsSchema } from '../checkout.schema';
import { ErrorMessage } from '@hookform/error-message';
import { useOrderInstructionsValidation } from './checkout-order-instructions-section.hooks';
import CheckoutContext from '../checkout.context';

const CheckoutOrderInstructionsSection = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(orderInstructionsSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = methods;
  const dispatch = useDispatch();
  const defaultValue = useSelector(selectOrderInstructions);
  const { validateCheckoutSections } = useContext(CheckoutContext);

  const submit = useCallback(() => {
    handleSubmit(
      () => {
        dispatch(setOrderInstructions(getValues('orderInstructions')));
        validateCheckoutSections();
      },
      err => {
        throw err;
      },
    )();
  }, [dispatch, getValues, handleSubmit, validateCheckoutSections]);

  const { validate } = useOrderInstructionsValidation(errors);

  return (
    <CheckoutSection
      title={t('checkout.orderInstructions.title')}
      headerProps={{ id: 'order-instructions-header' }}
      validate={validate}
      data-testid="CheckoutOrderInstructionsSection.CheckoutSection"
    >
      <FormProvider {...methods}>
        <S.CustomForm>
          <CustomInput>
            <TrimTextField
              fullWidth
              multiline
              maxRows={4}
              id="orderInstructions"
              placeholder={t('checkout.orderInstructions.placeholder')}
              {...register('orderInstructions')}
              aria-labelledby="order-instructions-header"
              onBlur={submit}
              defaultValue={defaultValue}
              error={!!errors.orderInstructions}
              inputProps={{
                'aria-label': t('checkout.orderInstructions.placeholder'),
              }}
            />
            <ErrorMessage
              name="orderInstructions"
              render={({ message }) => <FormError errorMessage={message} />}
            />
          </CustomInput>
        </S.CustomForm>
      </FormProvider>
    </CheckoutSection>
  );
};

export default CheckoutOrderInstructionsSection;
