import { useEffect } from 'react';
import { analyticsEnabledByEnv } from './analyticsEnabledByEnv';
import { getEnv } from './getEnv';
import { useStatsigGate } from './statsig.hooks';

// Assign this to window so we can override in Cypress tests. We don't want to
// load the actual code snippet in tests.
/* istanbul ignore next */
window.incHeapSnippet =
  window.incHeapSnippet ??
  (heapId => {
    var heapAnalytics = (window.heap = window.heap || []);

    heapAnalytics.factory = function (e) {
      return function () {
        heapAnalytics.push(
          [e].concat(Array.prototype.slice.call(arguments, 0)),
        );
      };
    };

    heapAnalytics.methods = [
      'addEventProperties',
      'addUserProperties',
      'clearEventProperties',
      'identify',
      'resetIdentity',
      'removeEventProperty',
      'setEventProperties',
      'track',
      'unsetEventProperty',
    ];

    for (var e = 0; e < heapAnalytics.methods.length; e++) {
      var key = heapAnalytics.methods[e];
      heapAnalytics[key] = heapAnalytics.factory(key);
    }

    heapAnalytics.load = function (e) {
      var r = document.createElement('script');
      r.type = 'text/javascript';
      r.async = !0;
      r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
      var a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(r, a);
    };

    heapAnalytics.appid = heapId;
    heapAnalytics.config = {};
    heapAnalytics.load(heapId);
  });

export const useInitHeap = () => {
  const heapId = getEnv('REACT_APP_HEAP_ANALYTICS_ID');
  const value = useStatsigGate('heap');

  useEffect(() => {
    if (value && analyticsEnabledByEnv() && !!heapId) {
      window.incHeapSnippet(heapId);
    }
  }, [heapId, value]);
};
