import { CircularProgress, alpha } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const Container = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, fade }) =>
    theme?.palette?.bgColor && fade === 'true'
      ? alpha(theme.palette.bgColor, 0.6)
      : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const CustomSpinner = styled(CircularProgress)`
  color: ${({ theme }) =>
    theme.palette ? theme.palette.primary.main : `black`};
`;
