import CustomCheckbox from 'components/custom-checkbox/custom-checkbox.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React from 'react';
import OptionItemLabel from '../option-item-label/option-item-label.component';
import { useOptionItemCheckbox } from './option-item-checkbox.hooks';

const OptionItemCheckbox = props => {
  const { item, isItemOutOfStock } = props;
  const { price, displayInfo, lockedModifier } = item;
  const { title, smallImage } = useDisplayInfo(displayInfo);
  const { checked, handleCheckboxChange, isDisableModifierGroup } =
    useOptionItemCheckbox(props);

  return (
    <CustomCheckbox
      checked={checked}
      onChange={handleCheckboxChange}
      title={title}
      disabled={isDisableModifierGroup || lockedModifier || isItemOutOfStock}
      label={
        <OptionItemLabel
          isItemOutOfStock={isItemOutOfStock}
          title={title}
          price={price}
          smallImage={smallImage}
        />
      }
      dataTestId="OptionItem"
    />
  );
};

export default OptionItemCheckbox;
