import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  selectCustomerInfo,
  selectDeliveryInstructions,
  selectOrderInstructions,
  selectOrderOption,
  selectOrderType,
} from 'redux/cart/cart.selectors';
import {
  makeSelectThankYouFulfillTime,
  selectConfirmThankYouMessage,
} from 'redux/common.selectors';
import { selectShowOrderNumber } from 'redux/config/config.selectors';
import { selectRequestedStore } from 'redux/locations/locations.selectors';
import { animateScroll as scroll } from 'react-scroll';
import { resetCart } from 'redux/cart/cart.actions';
import { useDisplayInfo } from 'hooks/useDisplayInfo';

export const useThankYou = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { pickupLabel, deliveryLabel } = useOrderTypeLabels();
  const { orderNumber, orderFulfillTime, trackingUrl } = state ?? {};
  const customerInfo = useSelector(selectCustomerInfo);
  const store = useSelector(selectRequestedStore);
  const orderType = useSelector(selectOrderType);
  const orderInstructions = useSelector(selectOrderInstructions);
  const deliveryInstructions = useSelector(selectDeliveryInstructions);
  const showOrderNumber = useSelector(selectShowOrderNumber);
  const orderOption = useSelector(selectOrderOption);

  const { title: storeTitle } = useDisplayInfo(store?.displayInfo);
  const history = useHistory();
  const { firstName } = customerInfo ?? {};
  const storeAddress = store?.address;
  const selectThankYouFulfillTime = useCallback(
    state => makeSelectThankYouFulfillTime(orderFulfillTime)(state),
    [orderFulfillTime],
  );

  const fulfillDateTime = useSelector(selectThankYouFulfillTime);

  const thankYouMessage = useSelector(
    selectConfirmThankYouMessage(orderNumber, fulfillDateTime),
  );

  const [orderInfo] = useState({
    orderNumber,
    firstName,
    fulfillDateTime,
    deliveryInstructions,
    orderInstructions,
    orderType,
    orderOption,
    thankYouMessage,
    storeAddress,
    storeTitle,
    trackingUrl,
  });

  const handleDone = () => {
    history.push('/');
  };

  useEffect(() => {
    if (!state || !customerInfo || !store) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop();
    }, 500);
  }, []);

  useEffect(() => {
    dispatch(resetCart());
  }, [dispatch]);

  return {
    showOrderNumber,
    orderInfo,
    pickupLabel,
    deliveryLabel,
    handleDone,
  };
};
