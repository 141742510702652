import { Box, FormControl, FormHelperText, Input } from '@material-ui/core';
import { useContext } from 'react';
import CloverFormContext from '../clover-form.context';

const CloverField = ({ id }) => {
  const { showLoading } = useContext(CloverFormContext);

  return (
    <Box marginBottom="8px">
      <FormControl variant="standard" fullWidth>
        <Input id={id} inputComponent={CloverInput} disabled={showLoading} />
        <FormHelperText id={`${id}-errors`} error />
      </FormControl>
    </Box>
  );
};

export default CloverField;

const CloverInput = ({ inputRef, ...props }) => (
  <div {...props} ref={inputRef} />
);
