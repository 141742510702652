import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family:${({ theme }) => theme.fonts?.primaryFont.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    min-width: 320px;
  }
  
  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
    }
  }

  .clover-footer {
    display: none;
  }
`;

export const AppContainer = styled.main`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.navHeight};
  background-color: ${({ theme }) => theme.palette.bgColor};
`;
