import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useEffect } from 'react';
import { useGetOrderOptions } from './useGetOrderOptions';

const useResetOrderOption = (store, orderType, setValue) => {
  const { orderOptions } = useGetOrderOptions(store, orderType);
  const {
    fieldNames: { orderOption },
  } = useFieldNames();

  useEffect(() => {
    if (orderOptions?.length < 1) setValue(orderOption, undefined);
  }, [orderOption, orderOptions?.length, setValue]);
};

export default useResetOrderOption;
