import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ResendCircularTimer from './resend-circular-timer/resend-circular-timer.component';
import { RESEND_EXPIRY_TIMER } from './resend-code.constants';
import { useResendCodeTimer } from './resend-code.hooks';
import * as S from './resend-code.styles';

const ResendCode = ({ email }) => {
  const { t } = useTranslation();
  const { progress, resendLinkClicked } = useResendCodeTimer(email);

  return (
    <Box
      display="flex"
      alignItems="center"
      margin="20px"
      justifyContent="center"
    >
      <ResendCircularTimer progress={progress} />
      <S.ResendCodeLink
        component="button"
        onClick={resendLinkClicked}
        disabled={progress < RESEND_EXPIRY_TIMER}
        color="textPrimary"
        data-testid="ResendCode.ResendCodeLink"
      >
        {t('verifyCode.resendCode')}
      </S.ResendCodeLink>
    </Box>
  );
};

export default ResendCode;
