import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const Container = styled(Box)`
  padding-top: 1em;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  min-height: 150px;
`;
