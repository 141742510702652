import { useRef, useEffect, useCallback } from 'react';

export const useResendTimer = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const startTimer = useCallback(() => {
    const tick = () => savedCallback.current();

    intervalRef.current = window.setInterval(tick, delay);
  }, [delay]);

  useEffect(() => {
    startTimer();
    return () => window.clearInterval(intervalRef.current);
  }, [startTimer]);

  return { intervalRef, startTimer };
};
