import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return <h3>{t('commonErrors.notFound')}</h3>;
};

export default NotFound;
