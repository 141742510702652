import { useIsNewSpreedlyCardFlow } from './credit-card-panel-controller.hooks';
import NewSpreedlyCreditCardPanel from './new-spreedly-credit-card-panel/new-spreedly-credit-card-panel.component';
import OldCreditCardPanel from './old-credit-card-panel/old-credit-card-panel.component';

const CreditCardPanelController = props => {
  const isNewSpreedlySaveCardFlow = useIsNewSpreedlyCardFlow();

  if (isNewSpreedlySaveCardFlow)
    return <NewSpreedlyCreditCardPanel {...props} />;

  return <OldCreditCardPanel {...props} />;
};

export default CreditCardPanelController;
