import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useResetDefaultValue = (name, defaultValue) => {
  const { resetField } = useFormContext();

  useEffect(() => {
    resetField(name, { defaultValue });
  }, [defaultValue, name, resetField]);
};
