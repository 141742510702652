import { useOpenMenuItemDialogPath } from 'hooks/dialog/menu-dialog.hooks';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeOrderItemStartAsync,
  updateOrderItemStartAsync,
} from 'redux/cart/cart.actions';
import {
  makeIsItemQtyBeingModified,
  makeSelectOrderItemPath,
  selectOrderDiscounts,
  selectOrderId,
} from 'redux/cart/cart.selectors';
import { addGroupIdToOptions } from 'redux/cart/cart.utils';
import { selectHideQtyPicker } from 'redux/config/config.selectors';

export const useSummaryItemComponent = item => {
  const dispatch = useDispatch();
  const orderId = useSelector(selectOrderId);
  const {
    displayInfo,
    quantity,
    options,
    itemId,
    itemInstructions,
    orderItemId,
    itemTotal: {
      total,
      displayTotal,
      displaySubtotal,
      displayPreDiscountSubtotal,
    },
    basePrice,
    itemDiscounts,
  } = item;
  const { title, smallImage } = useDisplayInfo(displayInfo);
  const hideQuantityPicker = useSelector(selectHideQtyPicker);
  const { groupId } = item.group;
  const [modifyQtyType, setModifyQtyType] = useState('');
  const selectModifyingQtyItemIds = useMemo(
    () => makeIsItemQtyBeingModified(orderItemId),
    [orderItemId],
  );
  const orderDiscounts = useSelector(selectOrderDiscounts);
  const isOrderDiscountApplied = useMemo(
    () => orderDiscounts.length > 0,
    [orderDiscounts],
  );
  const modifyingQtyItemIds = useSelector(selectModifyingQtyItemIds);

  const selectOrderItemPath = useMemo(
    () => makeSelectOrderItemPath(orderItemId),
    [orderItemId],
  );
  const orderItemPath = useSelector(selectOrderItemPath);
  const editInfo = { quantity, total, options, itemInstructions, orderItemId };
  const openModal = useOpenMenuItemDialogPath(orderItemPath, {
    editInfo,
  });

  const handleRemoveItem = () => {
    setModifyQtyType('REMOVE_ITEM');
    dispatch(removeOrderItemStartAsync(orderId, orderItemId, itemId));
  };

  const handleDecrement = () => {
    setModifyQtyType('-');

    if (quantity === 1) {
      dispatch(removeOrderItemStartAsync(orderId, orderItemId, itemId));
      return;
    }

    dispatch(
      updateOrderItemStartAsync({
        ...item,
        groupId: groupId,
        quantity: quantity - 1,
        options: addGroupIdToOptions(item.options),
      }),
    );
  };

  const handleIncrement = () => {
    setModifyQtyType('+');
    dispatch(
      updateOrderItemStartAsync({
        ...item,
        groupId: groupId,
        quantity: quantity + 1,
        options: addGroupIdToOptions(item.options),
      }),
    );
  };

  return {
    title,
    displayTotal,
    smallImage,
    modifyQtyType,
    modifyingQtyItemIds,
    options,
    quantity,
    openModal,
    handleRemoveItem,
    handleDecrement,
    handleIncrement,
    hideQuantityPicker,
    basePrice,
    displaySubtotal,
    itemDiscounts,
    displayPreDiscountSubtotal,
    isOrderDiscountApplied,
  };
};
