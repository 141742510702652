import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useScheduleDateController } from './schedule-date-controller.hooks';
import ScheduleDatePicker from './schedule-date-picker/schedule-date-picker.component';

const ScheduleDateController = props => {
  const { fieldNames } = useFieldNames();
  const { control } = useFormContext();

  const { getInitialFocusedDate } = useScheduleDateController(props);

  return (
    <Controller
      name={fieldNames.day}
      control={control}
      defaultValue={getInitialFocusedDate()}
      render={({ field }) => (
        <ScheduleDatePicker field={field} scheduleProps={props} />
      )}
    />
  );
};

export default ScheduleDateController;
