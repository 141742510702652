import CreateAccount from 'components/create-account/create-account.component';
import { useStep } from 'components/flow/step/step.hooks';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsGuestPurchaseEnabled } from 'redux/config/config.selectors';
import {
  selectIsLoggedIn,
  selectIsUserMissingRequiredFields,
} from 'redux/user/user.selectors';
import LoggedInUserMissingFields from '../logged-in-user-missing-fields/logged-in-user-missing-fields.component';

const CreateAccountStep = () => {
  const { nextStep, isActive } = useStep();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isUserMissingRequiredFields = useSelector(
    selectIsUserMissingRequiredFields,
  );
  const isGuestPurchaseEnabled = useSelector(selectIsGuestPurchaseEnabled);

  useEffect(() => {
    if (isActive && !isUserMissingRequiredFields) {
      nextStep();
    }
  }, [isActive, isUserMissingRequiredFields, nextStep]);

  if (isLoggedIn && isUserMissingRequiredFields) {
    return <LoggedInUserMissingFields nextStep={nextStep} />;
  }

  return (
    <CreateAccount
      onComplete={nextStep}
      guest={isGuestPurchaseEnabled}
      context={{ drawer: false }}
    />
  );
};

export default CreateAccountStep;
