import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSlug } from 'redux/cart/cart.selectors';
import { selectAllItemDetailsCsv } from 'redux/catalogs/catalogs.selectors';
import { downloadBlob } from 'util/downloadBlob';

const MenuExportCsv = () => {
  const itemsString = useSelector(selectAllItemDetailsCsv);
  const slug = useSelector(selectSlug);

  useEffect(() => {
    if (itemsString?.length > 0) {
      downloadBlob(
        new Blob([itemsString], { type: 'text/plain' }),
        `${slug}.csv`,
      );
    }
  }, [itemsString, slug]);

  return null;
};

export default MenuExportCsv;
