import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const ShareLabel = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 0.5em;
`;

export const ActionsGrid = styled(Grid)`
  margin-top: 1em;
  padding-top: 0.5em;
  border-top: 1px solid #979797;
`;
