import React from 'react';
import * as S from './order-item-options-collapse.styles';
import OrderItemOptionsList from './order-item-options-list/order-item-options-list.component';

const OrderItemOptionsCollapse = ({ options, isOpened }) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <S.CollapsibleDetails isOpened={isOpened}>
      <OrderItemOptionsList options={options} />
    </S.CollapsibleDetails>
  );
};

export default OrderItemOptionsCollapse;
