import ConfigActionTypes from './config.types';
import INC_BASE_API from '../../apis/incentivio-api';
import i18next from 'i18next';
import { selectors as clientSelectors } from '../client/client.slice';
import { selectBaseLocale } from '../i18n/i18n.selectors';
import { defaultLocale } from '../i18n/i18n.slice';

export const fetchConfigStart = () => ({
  type: ConfigActionTypes.FETCH_CONFIG_START,
});

export const fetchConfigSuccess = catalogData => ({
  type: ConfigActionTypes.FETCH_CONFIG_SUCCESS,
  payload: catalogData,
});

export const fetchConfigFailure = errorMessage => ({
  type: ConfigActionTypes.FETCH_CONFIG_FAILURE,
  payload: errorMessage,
});

export const setIsGoogleMapsLoaded = isGoogleMapsLoaded => ({
  type: ConfigActionTypes.SET_IS_GOOGLE_MAPS_LOADED,
  payload: isGoogleMapsLoaded,
});

export const fetchConfigStartAsync = () => {
  return (dispatch, getState) => {
    dispatch(fetchConfigStart());
    return INC_BASE_API.get(
      `/clients/${clientSelectors.selectClientId(getState())}/webappconfig`,
    )
      .then(response => {
        dispatch(fetchConfigSuccess(response.data));
        const baseLocale = selectBaseLocale(getState());
        const supportedLanguages = response.data.languagesSupported || [];

        if (supportedLanguages.includes(baseLocale)) {
          i18next.changeLanguage(baseLocale);
        } else {
          i18next.changeLanguage(defaultLocale);
        }

        return response.data;
      })
      .catch(error => {
        dispatch(fetchConfigFailure(error.message));
        return null;
      });
  };
};
