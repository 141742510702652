import { ErrorMessage } from '@hookform/error-message';
import { Input } from '@material-ui/core';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AddressAutoCompleteInput = ({
  name,
  InputLabelProps,
  inputProps,
  InputProps,
  onBlur,
  ...rest
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <CustomInput>
      <Input
        name={`${name}.input`}
        data-testid="AddressAutoCompleteInput.Input"
        placeholder={t('address.placesAutocomplete.placeholder')}
        error={!!errors[name]}
        inputProps={{
          'aria-describedby': 'FormError',
          ...inputProps,
          autoComplete: 'off',
        }}
        onBlur={onBlur}
        {...InputProps}
        {...rest}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormError errorMessage={message} />}
      />
    </CustomInput>
  );
};

export default AddressAutoCompleteInput;
