import MessagesDialog from 'components/messages-dialog/messages-dialog.component';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyDiscount } from 'redux/cart/cart.actions';
import { useMessages } from 'redux/data/messages/messages.hooks';
import { useOffers } from 'redux/data/offers/offers.hooks';
import CustomDialog from '../../../components/custom-dialog/custom-dialog.component';
import {
  deleteActivityBasedContent,
  getDistributedMessageData,
  getDistributedOfferData,
} from '../../../redux/user/user.actions';
import {
  selectAllActivityBasedContent,
  selectIsInboxItemLoading,
  selectIsLoggedIn,
} from '../../../redux/user/user.selectors';
import MessageCard from '../message-card/message-card.component';
import AccountInboxItem from '../account-inbox-item/account-inbox-item.component';

const AccountInboxMessage = ({
  message,
  checkoutPromoCode,
  handleClose,
  disableOnClick,
}) => {
  const {
    distributedMessageId,
    distributedOfferId,
    distributedDate,
    redeemType,
    messageType,
    title,
    status,
    smallImage,
    shortDescription,
    eligible,
    isLoyaltyOffer,
    shareTitle,
    redemptionEndDate,
    distributedEndDate,
    redemptionEndDateType,
  } = message;
  const type = redeemType ? redeemType : messageType;
  const disabled = isLoyaltyOffer && !eligible;
  const expireDate = redemptionEndDate ?? distributedEndDate;
  const isMessage = !!distributedMessageId;

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsInboxItemLoading);
  const [modalOpen, setModalOpen] = useState(false);
  const [finalType, setFinalType] = useState('');
  const allActivityBasedContent = useSelector(selectAllActivityBasedContent);
  const [isContentDialogOpen, setIsContentDialogOpen] = useState(false);
  const { deleteOffer, updateOfferStatus } = useOffers();
  const { deleteMessage, updateMessageStatus } = useMessages();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleDelete = async () => {
    if (distributedOfferId) {
      await deleteOffer(distributedOfferId);
      dispatch(deleteActivityBasedContent(distributedOfferId));
    } else if (distributedMessageId) {
      await deleteMessage(distributedMessageId);
      dispatch(deleteActivityBasedContent(distributedMessageId));
    }

    setModalOpen(false);
  };

  const handleOpenMessageOrOffer = async () => {
    if (disabled || disableOnClick) return;

    let distributedOfferData;
    if (!type || (['SHAREABLE', 'REFERRAL'].includes(type) && !shareTitle)) {
      if (distributedOfferId) {
        distributedOfferData = await dispatch(
          getDistributedOfferData(distributedOfferId),
        );
      } else if (distributedMessageId) {
        distributedOfferData = await dispatch(
          getDistributedMessageData(distributedMessageId),
        );
      }
    }

    const finalType =
      distributedOfferData?.redeemType ??
      distributedOfferData?.messageType ??
      type;

    setFinalType(finalType);

    if (status !== 'READ' && status !== 'EXPIRED' && isLoggedIn) {
      distributedOfferId
        ? updateOfferStatus(distributedOfferId, 'READ')
        : updateMessageStatus(distributedMessageId, 'READ');
    }

    setModalOpen(true);
  };

  return (
    <>
      <MessageCard
        title={title}
        image={smallImage}
        description={shortDescription}
        date={distributedDate}
        status={status}
        onClick={handleOpenMessageOrOffer}
        disabled={disabled}
        expireDate={expireDate}
        redemptionEndDateType={redemptionEndDateType}
        distributedMessageId={distributedMessageId}
      />
      <CustomDialog
        open={modalOpen}
        close={() => setModalOpen(false)}
        title={type === 'SURVEY' ? title : null}
        testId="AccountInboxMessage.CustomDialog"
      >
        <AccountInboxItem
          isLoading={isLoading}
          isMessage={isMessage}
          checkoutPromoCode={checkoutPromoCode}
          setModalOpen={setModalOpen}
          handleDelete={handleDelete}
          applyDiscount={applyDiscount}
          message={message}
          handleClose={handleClose}
          finalType={finalType}
          setIsContentDialogOpen={setIsContentDialogOpen}
        />
      </CustomDialog>

      {isContentDialogOpen && (
        <MessagesDialog
          open
          setOpen={setIsContentDialogOpen}
          messages={allActivityBasedContent}
          testId="AccountInboxMessage.MessagesDialog"
        />
      )}
    </>
  );
};

export default AccountInboxMessage;
