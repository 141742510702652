import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { itemSpacing } from '../account-order-history-item.styles';
import linkifyParse from 'components/linkify-parse/linkify-parse.component';

const contentSpacing = css`
  padding: 1em;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 1.5em;
  }
`;

export const CustomContainer = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin: 1em 0;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const Head = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${contentSpacing}
  .MuiSvgIcon-root {
    width: 2em;
    height: 2em;
    ${({ theme }) => theme.breakpoints.up('lg')} {
      width: 3em;
      height: 3em;
    }
  }
`;

export const For = styled(Typography)`
  font-weight: bold;
`;

export const LineItem = styled(Typography)`
  font-size: 0.875rem;
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
`;

export const Content = styled.div`
  ${contentSpacing}
`;

export const Total = styled(Typography)`
  font-size: 1.5rem;
  display: flex;
  padding: 0 0 0.8em;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 2rem;
    padding: 0 0 0.5em;
  }
`;

export const DetailContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
  ${itemSpacing}
`;

export const DetailLabel = styled(Typography)`
  font-size: 1.125rem;
  text-transform: capitalize;
`;

export const DetailItem = styled(Typography)`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  ${itemSpacing}
`;

export const BottomActions = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ItemInstructions = styled(Typography)`
  font-size: 0.75rem;
  margin-bottom: 0.2em;
`;

export const OrderOptionInstructions = styled(linkifyParse)`
  font-weight: bold;
  ${itemSpacing}
`;
