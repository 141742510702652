import { SubmitButton } from 'components/inc-button/inc-button.styles';
import OrderOptions from 'components/new-order/order-options/order-options.component';
import { useOrderOptions } from 'components/new-order/order-options/useOrderOptions';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { CARD_STEP } from 'pages/locations/locations.constants';
import AvailabilityErrors from 'pages/store/new-order-form/availability-errors/availability-errors.component';
import {
  OrderFormContext,
  OrderTypeContext,
} from 'pages/store/new-order-form/order-form-context';
import { useNewOrderSubmit } from 'pages/store/new-order-form/useNewOrderSubmit.hooks';
import { useLocationsCardOrderAvailability } from 'pages/store/new-order-form/useOrderAvailability.hooks';
import useSetAddressForDelivery from 'pages/store/new-order-form/useSetAddressForDelivery';
import useResetOrderOption from 'pages/store/new-order-form/useResetOrderOption';
import { useLocationsCardSelections } from 'pages/store/new-order-form/useSkipDayTimeSelector.hooks';
import useUnregisterAddressForPickup from 'pages/store/new-order-form/useUnregisterAddressForPickup.hooks';
import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import {
  selectIsFetching,
  selectRequestedAddress,
} from 'redux/cart/cart.selectors';
import { selectCatalogsIsFetching } from 'redux/catalogs/catalogs.selectors';
import ScheduleOrder from 'components/new-order/schedule-order/schedule-order.component';
import LocationsCardSpinner from '../locations-card-spinner/locations-card-spinner.component';
import { useNewOrderForm } from 'pages/store/new-order-form/useNewOrderForm.hooks';

const LocationsCardSelections = ({
  store,
  step,
  handleStep,
  closeReorderDialog,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit: rhfHandleSubmit,
    watch,
    setValue,
    unregister,
  } = useFormContext();
  const orderType = useContext(OrderTypeContext);
  const { fieldNames } = useFieldNames();
  const { handleSubmit } = useNewOrderSubmit(
    store,
    rhfHandleSubmit,
    closeReorderDialog,
  );
  const { skip, orderOptions } = useOrderOptions(store, orderType);
  const defaultAddress = useSelector(selectRequestedAddress);
  const address = watch(fieldNames.address, defaultAddress);
  const { setOptions } = useContext(OrderFormContext);
  const { pickupLabel, deliveryLabel } = useOrderTypeLabels(orderType);
  const isCartFetching = useSelector(selectIsFetching);
  const isCatalogsFetching = useSelector(selectCatalogsIsFetching);

  const { orderAvailabilityError, isOrderAvailabilityLoading } =
    useNewOrderForm(store.storeId);

  const isFetching =
    isCartFetching || isCatalogsFetching || isOrderAvailabilityLoading;

  useLocationsCardOrderAvailability(store, address, orderType);
  useResetOrderOption(store, orderType, setValue);
  useUnregisterAddressForPickup(orderType, unregister);
  useSetAddressForDelivery(orderType, defaultAddress);

  const { dayTimeSkip, firstAvailableTime } = useLocationsCardSelections(
    store,
    orderType,
  );

  const onSubmitOrderOptions = data => {
    setOptions(data);

    if (dayTimeSkip) {
      handleSubmit({ time: firstAvailableTime, dayTimeSkip });
    } else {
      handleStep(CARD_STEP.TIME_DAY_SELECTION, orderType);
    }
  };

  const onErrorOrderOptions = err => {
    if (!!err[fieldNames.address]) {
      scroller.scrollTo(fieldNames.address, {
        containerId: 'locationsListContainer',
        smooth: true,
      });
    }
  };

  const submitOrderOptions = () =>
    rhfHandleSubmit(onSubmitOrderOptions, onErrorOrderOptions)();

  useEffect(
    () => {
      if (dayTimeSkip && (skip || orderOptions.length === 0)) {
        handleSubmit({
          time: firstAvailableTime,
          [fieldNames.orderOption]: null,
          address,
          dayTimeSkip,
        });
      } else if (skip) {
        setOptions();
        handleStep(CARD_STEP.TIME_DAY_SELECTION, orderType);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      firstAvailableTime?.key,
      dayTimeSkip,
      fieldNames.orderOption,
      handleStep,
      orderOptions.length,
      setOptions,
      skip,
    ],
  );

  if (step === CARD_STEP.ORDER_OPTIONS && orderOptions.length > 0) {
    if (isFetching) return <LocationsCardSpinner />;

    if (!!orderAvailabilityError)
      return <AvailabilityErrors error={orderAvailabilityError} />;

    return (
      <>
        <div
          aria-live="polite"
          aria-atomic="true"
          aria-relevant="additions"
          data-testid="LocationsCardOptions.CardContent"
        >
          <OrderOptions
            store={store}
            orderType={orderType}
            label={
              orderType === 'PICKUP'
                ? t('orderOptionLabel.pickup', { pickupLabel })
                : t('orderOptionLabel.delivery', { deliveryLabel })
            }
          >
            <SubmitButton
              aria-label={t('locations.card.continueAction.ariaLabel')}
              data-testid="OrderOptionSelect.Continue"
              fullWidth
              onClick={submitOrderOptions}
            >
              {t('locations.card.continueAction.label')}
            </SubmitButton>
          </OrderOptions>
        </div>
      </>
    );
  }

  if (dayTimeSkip) return null;

  return (
    <ScheduleOrder
      store={store}
      orderType={orderType}
      onTimeClick={handleSubmit}
    />
  );
};

export default LocationsCardSelections;
