import { createSlice } from '@reduxjs/toolkit';

export const defaultLocale = 'en';

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    locale: defaultLocale,
  },
  reducers: {
    localeChanged(state, action) {
      state.locale = action.payload;
    },
  },
});

export default i18nSlice;
