import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { RESEND_EXPIRY_TIMER } from '../resend-code.constants';

const ResendCircularTimer = ({ progress }) => {
  const remainingTime = RESEND_EXPIRY_TIMER - progress;
  return (
    <Box position="relative" display="inline-flex" marginRight="10px">
      <CircularProgress
        variant="determinate"
        value={(progress / RESEND_EXPIRY_TIMER) * 100}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="primary"
        >{`${remainingTime}s`}</Typography>
      </Box>
    </Box>
  );
};

export default ResendCircularTimer;
