import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOffers } from 'redux/data/offers/offers.hooks';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog.component';
import { redeemOfferById } from '../../../../redux/user/user.actions';
import { Box } from '@material-ui/core';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import RenderCode from 'components/render-code/render-code.component';
import MessageTitle from '../message-title/message-title.component';
import MessageShortDescription from '../message-short-description/message-short-description.component';
import MessageLongDescription from '../message-long-description/message-long-description.component';

const PurchaseMessage = ({ messageData, checkoutPromoCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openWarning, setOpenWarning] = useState(false);
  const [continueRedeem, setContinueRedeem] = useState(false);
  const {
    purchaseTypes,
    title,
    shortDescription,
    longDescription,
    distributedOfferId,
    offerId,
    offerCode,
    universalOffer,
    codeFormat,
  } = messageData;

  const { removeOneOffer } = useOffers();

  const handleRedeemOffer = () => {
    dispatch(redeemOfferById(distributedOfferId));
    setContinueRedeem(true);
  };

  const handleWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    continueRedeem && removeOneOffer(distributedOfferId);
    setOpenWarning(false);
  };

  return (
    <>
      <MessageTitle title={title} />
      {!universalOffer &&
      purchaseTypes &&
      purchaseTypes.length &&
      purchaseTypes.find(purchaseType => purchaseType === 'STANDALONE') ? (
        <Box mt={2} mb={2}>
          <CustomDialog
            title="Redeem Offer"
            close={handleCloseWarning}
            open={openWarning}
            testId="PurchaseMessage.CustomDialog.Redeem"
          >
            {!continueRedeem ? (
              <>
                <Typography style={{ marginBottom: '1em' }}>
                  {t('accountInbox.purchaseMessage.continueRedeem')}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <IncButtonDark
                      onClick={() => setOpenWarning(false)}
                      data-testid="PurchaseMessage.CustomButton.Cancel"
                      fullWidth
                    >
                      {t('accountInbox.purchaseMessage.cancelAction')}
                    </IncButtonDark>
                  </Grid>
                  {!checkoutPromoCode && (
                    <Grid item xs={6}>
                      <IncButton
                        onClick={handleRedeemOffer}
                        data-testid="PurchaseMessage.CustomButton.Redeem"
                        fullWidth
                      >
                        {t('accountInbox.purchaseMessage.redeemAction')}
                      </IncButton>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Typography
                  style={{ marginBottom: '1em', textAlign: 'center' }}
                >
                  {t('accountInbox.purchaseMessage.offerCode')} {offerCode}
                </Typography>

                <Box mb="2em">
                  <RenderCode code={offerId} codeFormat={codeFormat} />
                </Box>

                <IncButton
                  onClick={handleCloseWarning}
                  data-testid="PurchaseMessage.CustomButton.Close"
                  fullWidth
                >
                  {t('accountInbox.purchaseMessage.closeAction')}
                </IncButton>
              </>
            )}
          </CustomDialog>
          {!checkoutPromoCode && (
            <IncButton
              onClick={handleWarning}
              data-testid="PurchaseMessage.RedeemButton"
              fullWidth
            >
              {t('accountInbox.purchaseMessage.redeemAction')}
            </IncButton>
          )}
        </Box>
      ) : null}

      <MessageShortDescription description={shortDescription} />
      <MessageLongDescription description={longDescription} />
    </>
  );
};

export default PurchaseMessage;
