import {
  PaymentOptionType,
  SpreedlyPaymentProcessorType,
} from 'components/payment/payment.constants';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import { useSpreedlyPaymentProcessorMatches } from '../spreedly-payments.hooks';
import SpreedlyToastApplePayButton from '../toast/apple-pay-button/apple-pay-button.component';

const SpreedlyWallet = ({ validate = () => true }) => {
  const { selectedPaymentOption } = usePaymentOptions();
  const matchesPaymentProcessor = useSpreedlyPaymentProcessorMatches();

  if (selectedPaymentOption === PaymentOptionType.APPLE_PAY) {
    if (matchesPaymentProcessor([SpreedlyPaymentProcessorType.TOAST]))
      return <SpreedlyToastApplePayButton validate={validate} />;
  }

  // Right now it is impossible to get here, but I could see it being possible in the future.
  // I'm keeping the null but ignoring it for code coverage.
  /* istanbul ignore next */
  return null;
};

export default SpreedlyWallet;
