const RecommendationActionTypes = {
  SET_RECOMMENDATION_DETAILS: 'SET_RECOMMENDATION_DETAILS',
  RESET_RECOMMENDATIONS: 'RESET_RECOMMENDATIONS',
  SET_RECOMMENDATION_ADDED_ORDER_ITEM_IDS:
    'SET_RECOMMENDATION_ADDED_ORDER_ITEM_IDS',
  REMOVE_RECOMMENDATION_ADDED_ORDER_ITEM_ID:
    'REMOVE_RECOMMENDATION_ADDED_ORDER_ITEM_ID',
};

export default RecommendationActionTypes;
