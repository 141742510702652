import { useProcessingUrl } from 'components/payment/payment.new.hooks';
import { useMobileAppListeners } from 'hooks/mobileAppListeners/useMobileAppListeners';
import { useHistory } from 'react-router-dom';

export const useOn3DSStatusUpdates = handleClose => {
  const history = useHistory();
  const processingUrl = useProcessingUrl();
  const fireListeners = useMobileAppListeners();

  const on3DSstatusUpdatesFn = threeDsStatusEvent => {
    if (!!window.appInterface || !!window.webkit) {
      fireListeners(threeDsStatusEvent);
      return;
    }

    if (threeDsStatusEvent.action !== 'challenge') {
      handleClose();
      history.push(processingUrl);
      return;
    }
  };

  return on3DSstatusUpdatesFn;
};
