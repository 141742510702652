import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TimeSelectorWrapper from 'components/new-order/time-selector-wrapper/time-selector-wrapper.component';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button, MuiThemeProvider } from '@material-ui/core';
import * as S from './schedule-date-picker.styles';
import { useScheduleDatePicker } from './useScheduleDatePicker.hooks';
import { useSchedulePickerTheme } from 'schedule-picker-theme.hooks';
import { CustomLuxonAdapter } from './CustomLuxonAdapter';
import { useTranslation } from 'react-i18next';

const ScheduleDatePicker = props => {
  const { t } = useTranslation();
  const {
    field: { onChange: datePickerChange, ref, ...fieldRest },
    scheduleProps,
  } = props;

  const {
    handleArrowClick,
    maxDate,
    minDate,
    pickerDate,
    handleDatePickerChange,
    handleDateSelectedClick,
    getNextEnableNoOfDays,
  } = useScheduleDatePicker(scheduleProps, datePickerChange);

  const standardTheme = useSchedulePickerTheme();

  if (scheduleProps.showCalendar || !pickerDate)
    return (
      <MuiPickersUtilsProvider utils={CustomLuxonAdapter}>
        <MuiThemeProvider theme={standardTheme}>
          <KeyboardDatePicker
            variant="static"
            onChange={handleDatePickerChange}
            inputProps={{ ref }}
            {...fieldRest}
            disableToolbar
            data-testid="ScheduleDatePicker.KeyboardDatePicker"
            minDate={minDate}
            maxDate={maxDate}
            rightArrowButtonProps={{
              'data-testid': 'ScheduleDatePicker.RightArrow',
              'aria-label': t(
                'todayOrScheduleSelector.schedule.calendar.rightArrow.ariaLabel',
              ),
            }}
            leftArrowButtonProps={{
              'data-testid': 'ScheduleDatePicker.LeftArrow',
              'aria-label': t(
                'todayOrScheduleSelector.schedule.calendar.leftArrow.ariaLabel',
              ),
            }}
            shouldDisableDate={day =>
              scheduleProps.offDays.includes(day.toISODate())
            }
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );

  return (
    <>
      <S.DateController
        data-testid="ScheduleDatePicker.SelectedDateController"
        display="flex"
        justifyContent="space-between"
        width="80%"
        margin="auto"
        alignItems="center"
        pb="1em"
      >
        <Button
          onClick={() => handleArrowClick('LEFT')}
          disabled={
            pickerDate
              .plus({ days: getNextEnableNoOfDays('LEFT') })
              .toISODate() < minDate.toISODate()
          }
          data-testid="ScheduleDatePicker.SelectedDateController.LeftArrow"
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          onClick={handleDateSelectedClick}
          data-testid="ScheduleDatePicker.SelectedDateController.SelectedDateControl"
        >
          {`${pickerDate.weekdayLong} ${pickerDate.toLocaleString()}`}
        </Button>
        <Button
          onClick={() => handleArrowClick('RIGHT')}
          disabled={
            pickerDate
              .plus({ days: getNextEnableNoOfDays('RIGHT') })
              .toISODate() >= maxDate.toISODate()
          }
          data-testid="ScheduleDatePicker.SelectedDateController.RightArrow"
        >
          <ChevronRightIcon />
        </Button>
      </S.DateController>
      <TimeSelectorWrapper {...scheduleProps} />
    </>
  );
};

export default ScheduleDatePicker;
