import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestedAddressIsValid } from 'redux/cart/cart.selectors';
import LocationsFinderCard from 'pages/locations/locations-card/locations-card.component';
import { CARD_STEP } from 'pages/locations/locations.constants';

const OrderHistorySelections = ({ store, orderType, ...rest }) => {
  const requestedAddressIsValid = useSelector(selectRequestedAddressIsValid);

  if (orderType === 'DELIVERY' && !requestedAddressIsValid) return null;

  return (
    <LocationsFinderCard
      store={store}
      cardStep={CARD_STEP.ORDER_OPTIONS}
      reorder
      orderType={orderType}
      selectedStore={store}
      {...rest}
    />
  );
};

export default OrderHistorySelections;
