import { useContext, useEffect } from 'react';
import SectionContext from '../checkout-section.context';

const CheckoutSectionEdit = ({ children }) => {
  const { setEditable, editing } = useContext(SectionContext);

  useEffect(() => {
    setEditable(true);

    return () => setEditable(false);
  }, [setEditable]);

  if (editing) return children;
  else return null;
};

export default CheckoutSectionEdit;
