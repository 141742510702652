/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';

export const useSpreedlyScriptHook = ({ url, script }) => {
  const handleError = useErrorHandler();

  useEffect(() => {
    if (!url) return;
    const scriptEle = document.createElement('script');

    scriptEle.setAttribute('src', url);
    scriptEle.setAttribute('id', 'spreedly-iframe-script');

    document.body.appendChild(scriptEle);

    scriptEle.addEventListener('load', () => {
      try {
        window.eval(script);
      } catch (e) {
        handleError(e);
      }
    });

    return () => {
      scriptEle.remove();
    };
  }, [url]);
};
