import axios from 'axios';
import INC_BASE_API from '../../apis/incentivio-api';
import { getResponseErrorMessage } from '../../apis/incentivio-api.util';
import { getLanguageKey, toISOStringLocal } from '../../utils';
import { selectLocationId } from '../cart/cart.selectors';
import { selectors as clientSelectors } from '../client/client.slice';
import { selectCatalogsIsFetching, selectChecksum } from './catalogs.selectors';
import CatalogActionTypes from './catalogs.types';
import { toast } from 'react-toastify';

export const fetchCatalogsStart = () => ({
  type: CatalogActionTypes.FETCH_CATALOGS_START,
});

export const fetchCatalogsSuccess = catalogData => ({
  type: CatalogActionTypes.FETCH_CATALOGS_SUCCESS,
  payload: catalogData,
});

export const fetchCatalogsFailure = errorMessage => ({
  type: CatalogActionTypes.FETCH_CATALOGS_FAILURE,
  payload: errorMessage,
});

const fetchOutOfStockItemsSuccess = itemIds => ({
  type: CatalogActionTypes.FETCH_OUT_OF_ITEMS_SUCCESS,
  payload: itemIds,
});

const fetchOutOfStockItemsFailure = errorMessage => ({
  type: CatalogActionTypes.FETCH_OUT_OF_ITEMS_FAILURE,
  payload: errorMessage,
});

export const getSingleItemDetailsSuccess = itemDetails => ({
  type: CatalogActionTypes.GET_SINGLE_ITEM_DETAILS_SUCCESS,
  payload: { itemDetails },
});

export const fetchOutOfStockItems = () => {
  return async (dispatch, getState) => {
    try {
      const storeId = selectLocationId(getState());
      const res = await INC_BASE_API.get(`/items/outofstock/${storeId}`);
      dispatch(fetchOutOfStockItemsSuccess(res.data));
    } catch (e) {
      dispatch(fetchOutOfStockItemsFailure());
      return e;
    }
  };
};

let cancelTokenSource;
let cancelToken;
export const fetchCatalogsStartAsync = () => {
  return async (dispatch, getState) => {
    try {
      if (selectCatalogsIsFetching(getState()) && cancelToken) {
        !!cancelTokenSource && cancelTokenSource.cancel(cancelToken);
      }
      cancelTokenSource = axios.CancelToken.source();
      cancelToken = cancelTokenSource.token;
      const storeId = selectLocationId(getState());
      if (!storeId) return null;
      const checksum = selectChecksum(getState()) ?? '1';
      const params = {
        checksum,
        clientId: clientSelectors.selectClientId(getState()),
        includeChildElements: 'true',
        localDate: toISOStringLocal(new Date()),
        storeId,
      };
      dispatch(fetchOutOfStockItems());
      dispatch(fetchCatalogsStart());
      let res;
      res = await INC_BASE_API.get('/cachedcatalogs/compressed', {
        params: { ...params, langCode: getLanguageKey() },
        cancelToken,
      });

      if (res.data.length === 0) {
        res = await INC_BASE_API.get('/cachedcatalogs/compressed', {
          params: { ...params, langCode: 'en' },
          cancelToken,
        });
      }

      cancelToken = null;

      dispatch(
        fetchCatalogsSuccess({
          catalogsArray: res.data,
          checksum: res.headers?.['incentivio-catalog-checksum'],
        }),
      );
      return res;
    } catch (e) {
      if (axios.isCancel(e)) return e;
      dispatch(fetchCatalogsFailure(getResponseErrorMessage(e)));
      return e;
    }
  };
};

export const getOrderItemDetailsStartAsync = ({
  catalogId,
  groupId,
  itemId,
}) => {
  return async (dispatch, getState) => {
    try {
      const params = {
        clientId: clientSelectors.selectClientId(getState()),
        storeId: selectLocationId(getState()),
        catalogId,
        groupId,
        itemId,
        langCode: getLanguageKey().toUpperCase(),
      };
      const res = await INC_BASE_API.get(`/cacheditems/item`, {
        params,
      });

      dispatch(getSingleItemDetailsSuccess(res.data));

      return res.data;
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      return null;
    }
  };
};
