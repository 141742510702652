import { useShowNewCheckoutPayments } from 'pages/checkout/checkout-payment-section/checkout-payment-section.hooks';
import CheckoutPaymentSectionNew from '../checkout-payment-section-new.component';

const CheckoutPaymentSectionWrapper = () => {
  const showNewCheckoutPayments = useShowNewCheckoutPayments();

  if (showNewCheckoutPayments) return <CheckoutPaymentSectionNew />;

  return null;
};
export default CheckoutPaymentSectionWrapper;
