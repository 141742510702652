import React from 'react';
import CheckoutSectionBase from './checkout-section-base.component';
import { useCheckoutSection } from './checkout-section.hooks';

const CheckoutSection = ({
  title,
  isEditing,
  onSubmit,
  onCancel,
  validate,
  children,
  ...rest
}) => {
  const context = useCheckoutSection({
    title,
    isEditing,
    onSubmit,
    onCancel,
    validate,
  });

  return (
    <CheckoutSectionBase context={context} {...rest}>
      {children}
    </CheckoutSectionBase>
  );
};

export default CheckoutSection;
