import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { signOut } from 'redux/user/user.actions';

export const useDeleteAccountDialog = () => {
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      await INC_BASE_API.patch(
        `/useraccounts`,
        [
          {
            attribute: 'isDeleted',
            value: 'true',
          },
        ],
        { authenticated: true },
      );
      dispatch(signOut());
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
    }
  };

  return { onSubmit };
};
