import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

export const xsHeight = '150px';
export const height = '220px';

export const MessageCard = styled(Card)`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const MessageImage = styled(CardMedia)`
  flex-shrink: 0;
  width: ${height};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: ${xsHeight};
  }
`;

export const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 0;
`;

export const Description = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const Bottom = styled(Box)`
  display: flex;
  padding: 1rem;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 1rem 1rem 1rem;
  }
`;

export const ActionArea = styled(CardActionArea)`
  display: flex;
`;

export const Header = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 1rem;
    font-weight: bold;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const SubHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
`;
