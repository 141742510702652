import { Box, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export const ContentContainer = styled(Paper)`
  width: 100%;
  height: fit-content;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 460px;
  }
`;

export const ContentHeader = styled(Typography)`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const ContentBody = styled(Box)`
  padding: 2rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
