import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import CheckoutContext from '../checkout.context';
import CheckoutOffersSectionApplicable from './checkout-offers-section-applicable/checkout-offers-section-applicable.component';
import CheckoutOffersSectionSignup from './checkout-offers-section-signup/checkout-offers-section-signup.component';

const CheckoutOffersSection = () => {
  const { openOffersModal } = useContext(CheckoutContext);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (isLoggedIn)
    return <CheckoutOffersSectionApplicable openModal={openOffersModal} />;

  return <CheckoutOffersSectionSignup />;
};

export default CheckoutOffersSection;
