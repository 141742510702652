import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectGiftCardLookUp,
  selectIsEmailGiftcardEnabled,
} from 'redux/config/config.selectors';

export const useGiftcardLanding = () => {
  const history = useHistory();
  const giftCardLookup = useSelector(selectGiftCardLookUp);
  const isEmailGiftcard = useSelector(selectIsEmailGiftcardEnabled);

  useEffect(() => {
    !giftCardLookup && !isEmailGiftcard && history.push('/giftcard/notenabled');
  }, [giftCardLookup, history, isEmailGiftcard]);

  return { isEmailGiftcard, giftCardLookup };
};
