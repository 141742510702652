import { toast } from 'react-toastify';
import CommonTypes from '../common.types';
import UserTypes from './user.types';
import { deleteActivityContent } from './user.utils';

const INITIAL_STATE = {
  userData: null,
  accessToken: null,
  refreshToken: null,
  rememberMe: null,
  isLoading: false,
  deliveryAddressList: [],
  paymentInstruments: [],
  isCardLoading: false,
  isInboxItemLoading: false,
  authProvider: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SIGN_UP_USER_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.SIGN_UP_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.SIGN_UP_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.SIGN_IN_USER_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.SIGN_IN_USER_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.token,
        refreshToken: action.payload.refreshToken,
        rememberMe:
          action.payload.rememberMe !== undefined
            ? action.payload.rememberMe
            : state.rememberMe,
        isLoading: false,
        authProvider: action.payload.authProvider,
      };

    case UserTypes.SIGN_IN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.REFRESH_TOKEN_START:
      return {
        ...state,
      };

    case UserTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.newToken,
        refreshToken: action.payload.newRefreshToken,
      };

    case UserTypes.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
      };

    case UserTypes.FETCH_USER_DATA_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isLoading: false,
      };

    case UserTypes.FETCH_USER_DATA_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        userData: null,
        accessToken: null,
        isLoading: false,
      };

    case UserTypes.SIGN_OUT:
      return {
        ...state,
        userData: null,
        accessToken: null,
        paymentInstruments: [],
        deliveryAddressList: [],
        refreshToken: null,
        loyaltyData: null,
        authProvider: null,
      };

    case UserTypes.REQUEST_PW_RESET_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.REQUEST_PW_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.REQUEST_PW_RESET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.FETCH_ADDRESSES_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        deliveryAddressList: action.payload.deliveryAddressList,
        deliveryAddressId: action.payload.id,
        isLoading: false,
      };

    case UserTypes.FETCH_ADDRESSES_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.FETCH_PAYMENT_INSTRUMENTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.FETCH_PAYMENT_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        paymentInstruments: action.payload,
        isLoading: false,
      };

    case UserTypes.FETCH_PAYMENT_INSTRUMENTS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.ADD_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.ADD_ADDRESS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.DELETE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.DELETE_ADDRESS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.ADD_EXISTING_GIFT_CARD_START:
      return {
        ...state,
        isCardLoading: true,
      };

    case UserTypes.ADD_EXISTING_GIFT_CARD_SUCCESS:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.ADD_EXISTING_GIFT_CARD_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.UPDATE_USER_DATA_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPDATE_USER_DATA_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPDATE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPDATE_ADDRESS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPDATE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPDATE_PASSWORD_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_START:
      return {
        ...state,
        isInboxItemLoading: true,
      };

    case UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_SUCCESS:
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_FAILURE:
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.GET_DISTRIBUTED_OFFER_DATA_START:
      return {
        ...state,
        isInboxItemLoading: true,
      };

    case UserTypes.GET_DISTRIBUTED_OFFER_DATA_SUCCESS:
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.GET_DISTRIBUTED_OFFER_DATA_FAILURE:
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.GET_ACTIVITY_BASED_CONTENT_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.GET_ACTIVITY_BASED_CONTENT_SUCCESS:
      return {
        ...state,
        activityBasedContent: action.payload,
        isLoading: false,
      };

    case UserTypes.DELETE_ACTIVITY_BASED_CONTENT:
      return {
        ...state,
        activityBasedContent: deleteActivityContent(
          state.activityBasedContent,
          action.payload,
        ),
      };

    case UserTypes.GET_ACTIVITY_BASED_CONTENT_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.REDEEM_OFFER_BY_ID_START:
      return {
        ...state,
      };

    case UserTypes.REDEEM_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
      };

    case UserTypes.REDEEM_OFFER_BY_ID_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
      };

    case UserTypes.GET_ORDER_HISTORY_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.GET_ORDER_HISTORY_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.ADD_PAYMENT_INSTRUMENT_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.ADD_PAYMENT_INSTRUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.ADD_PAYMENT_INSTRUMENT_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.EDIT_PAYMENT_INSTRUMENT_START:
      return {
        ...state,
        isCardLoading: true,
      };

    case UserTypes.EDIT_PAYMENT_INSTRUMENT_SUCCESS:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.EDIT_PAYMENT_INSTRUMENT_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.GET_GIFT_CARD_DATA_START:
      return {
        ...state,
        isCardLoading: true,
      };

    case UserTypes.GET_GIFT_CARD_DATA_SUCCESS:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.GET_GIFT_CARD_DATA_FAILURE:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.ADD_GIFT_CARD_VALUE_START:
      return {
        ...state,
        isCardLoading: true,
      };

    case UserTypes.ADD_GIFT_CARD_VALUE_SUCCESS:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.ADD_GIFT_CARD_VALUE_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.DELETE_PAYMENT_INSTRUMENT_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.DELETE_PAYMENT_INSTRUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.DELETE_PAYMENT_INSTRUMENT_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.BUY_GIFT_CARD_START:
      return {
        ...state,
        isCardLoading: true,
      };

    case UserTypes.BUY_GIFT_CARD_SUCCESS:
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.BUY_GIFT_CARD_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isCardLoading: false,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loyaltyData: action.payload,
        isLoading: false,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_SUCCESS:
      return {
        ...state,
        loyaltyHistoryData: action.payload,
        isLoading: false,
      };

    case UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
        loyaltyHistoryData: undefined,
      };

    case UserTypes.FETCH_SURVEY_FIELDS_START:
      return {
        ...state,
        isInboxItemLoading: true,
      };

    case UserTypes.FETCH_SURVEY_FIELDS_SUCCESS:
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.FETCH_SURVEY_FIELDS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isInboxItemLoading: false,
      };

    case UserTypes.SUBMIT_SURVEY_ANSWERS_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.SUBMIT_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.SUBMIT_SURVEY_ANSWERS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPLOAD_PROFILE_PIC_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.UPLOAD_PROFILE_PIC_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.SET_PROFILE_PIC_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.SET_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.SET_PROFILE_PIC_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.GET_GIFT_CARD_BY_TOKEN_START:
      return {
        ...state,
        isLoading: true,
      };

    case UserTypes.GET_GIFT_CARD_BY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserTypes.GET_GIFT_CARD_BY_TOKEN_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
      };

    case CommonTypes.ERROR_HARD_RESET:
      return {
        ...state,
        userData: null,
        isLoading: false,
        deliveryAddressList: [],
        paymentInstruments: [],
      };

    default:
      return state;
  }
};

export default userReducer;
