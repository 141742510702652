import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentContainer, ContentHeader } from '../../giftcard.styles';

const GiftcardLookupDisabled = () => {
  const { t } = useTranslation();

  return (
    <ContentContainer data-testid={'GiftcardLookupDisabled.ContentContainer'}>
      <ContentHeader component="h1" variant="h5" noBody>
        {t('eGiftCard.lookup.disabled')}
      </ContentHeader>
    </ContentContainer>
  );
};

export default GiftcardLookupDisabled;
