import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './option-item-quantity.styles';
import OptionItemLabel from '../option-item-label/option-item-label.component';
import { useOptionItemQuantity } from './option-item-quantity.hooks';

const OptionItemQuantity = props => {
  const { item, isItemOutOfStock } = props;
  const { price, lockedModifier, minQuantity, displayInfo } = item;
  const { t } = useTranslation();
  const { title, smallImage } = useDisplayInfo(displayInfo);
  const { handleAdd, handleRemove, qty, isMaxSelectionsReached } =
    useOptionItemQuantity(props);

  return (
    <S.QuantityContainer data-testid="OptionItem">
      <Box data-testid="OptionItemQuantity.QtyContainer">
        <S.QtyButton
          color="primary"
          aria-label={t('checkout.items.modifierQuantity.decrement', {
            title,
            qty,
          })}
          onClick={handleRemove}
          data-testid="OptionItemQuantity.Decrease"
          disabled={
            qty === 0 ||
            (lockedModifier && qty === (minQuantity ?? 1)) ||
            isItemOutOfStock
          }
        >
          <RemoveIcon fontSize="medium" />
        </S.QtyButton>
        <Box component="span" display="inline-flex">
          {qty}
        </Box>
        <S.QtyButton
          color="primary"
          aria-label={t('checkout.items.modifierQuantity.increment', {
            title,
            qty,
          })}
          onClick={handleAdd}
          data-testid="OptionItemQuantity.Increase"
          disabled={
            (item.maxQuantity !== 0 && qty === item.maxQuantity) ||
            isMaxSelectionsReached ||
            isItemOutOfStock
          }
        >
          <AddIcon fontSize="medium" />
        </S.QtyButton>
      </Box>
      <S.OptionQuantityContainer>
        <OptionItemLabel
          isItemOutOfStock={isItemOutOfStock}
          title={title}
          price={price}
          smallImage={smallImage}
        />
      </S.OptionQuantityContainer>
    </S.QuantityContainer>
  );
};

export default OptionItemQuantity;
