import { Box, Typography } from '@material-ui/core';

const MinMaxMessage = ({ message, 'data-testid': testId }) => {
  return (
    <Box fontWeight="bold" data-testid={testId}>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default MinMaxMessage;
