import { Box } from '@material-ui/core';
import ScrollToTop from 'components/scroll-to-top/scroll-to-top';
import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import MenuGroupContainer from './menu-group-container.component';

const MenuGroups = ({
  groups,
  catalogId,
  'data-testid': testId = 'CatalogMenu',
}) => {
  useEffect(() => {
    animateScroll.scrollToTop({ duration: 0 });
  }, []);

  return (
    <Box data-testid={testId}>
      {groups && (
        <>
          {groups.map(group => (
            <MenuGroupContainer
              catalogId={catalogId}
              group={group}
              key={group.groupId}
            />
          ))}
          <ScrollToTop />
        </>
      )}
    </Box>
  );
};

export default MenuGroups;
