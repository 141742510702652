import { Box, Grid } from '@material-ui/core';
import AddressFields from 'components/address/address-fields/address-fields.component';
import { useAddressFields } from 'components/address/address-fields/address-fields.hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';

const NewAddress = ({
  onSubmit,
  addressFieldsOptions,
  onCancel,
  cancelEnabled,
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation();
  const { addressInputProps, getAddress } = useAddressFields(
    'newAddress',
    null,
    methods,
    addressFieldsOptions,
  );

  const onSuccessSubmit = () => {
    const address = getAddress();
    onSubmit(address);
  };

  return (
    <FormProvider {...methods}>
      <Box mb={2}>
        <AddressFields {...addressInputProps} />

        <Grid container spacing={1}>
          {cancelEnabled && (
            <Grid item>
              <IncButtonDark
                onClick={onCancel}
                data-testid="NewAddress.CustomButton.Cancel"
                size="small"
              >
                {t('address.cancel')}
              </IncButtonDark>
            </Grid>
          )}
          <Grid item>
            <IncButton
              onClick={handleSubmit(onSuccessSubmit)}
              data-testid="NewAddress.CustomButton.Submit"
              size="small"
            >
              {t('address.save')}
            </IncButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default NewAddress;
