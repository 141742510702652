import { usePurchaseEmailGiftCard } from 'components/payment/incentivio/incentivio-payments.hooks';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import GiftCardPaymentStepNew from '../gift-card-payment-step-new/gift-card-payment-step-new.component';

const IncentivioGiftCardPaymentStep = props => {
  const { trigger, loading: purchaseLoading } = usePurchaseEmailGiftCard();

  const { selectedPaymentOption } = usePaymentOptions();

  const showWallet = [
    PaymentOptionType.GOOGLE_PAY,
    PaymentOptionType.APPLE_PAY,
  ].includes(selectedPaymentOption);

  return (
    <GiftCardPaymentStepNew
      trigger={trigger}
      purchaseLoading={purchaseLoading}
      fallbackPaymentToken="ONE_TIME_PAYMENT"
      selectedPaymentOption={selectedPaymentOption}
      showWallet={showWallet}
      {...props}
    />
  );
};
export default IncentivioGiftCardPaymentStep;
