import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import HeaderTooltipIcon from './header-tooltip-icon.component';

export const CustomIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.accentColor};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 6px;
    svg,
    .MuiAvatar-circular {
      width: 30px;
      height: 30px;
    }
  }
`;

export const ThemeIconButton = styled(HeaderTooltipIcon)`
  &:focus-visible {
    outline: 3px dashed white;
  }
`;
