import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGiftCardNumberInput = error => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (error?.type) {
      case 'required':
        return t('register.errors.requiredField');
      case 'maxLength':
        return t('register.errors.maxLength', { length: 40 });
    }
  }, [error, t]);

  return { errorMessage };
};
