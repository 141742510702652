import { useCallback, useContext, useMemo, useRef } from 'react';
import FlowContext from '../flow.context';
import StepContext from './step.context';

export const useStep = () => {
  const { steps, activeStep, setActiveStep, data, setData } =
    useContext(FlowContext);
  const { name, step } = useContext(StepContext);

  let thisStep = useRef(step);

  const nextStep = useCallback(() => {
    const stepKeys = Object.keys(steps);
    const nextIndex = stepKeys.indexOf(name) + 1;
    if (nextIndex >= stepKeys.length)
      throw new Error('Step index out of bounds');

    setActiveStep(stepKeys[nextIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const makeActive = useCallback(
    () => setActiveStep(name),
    [name, setActiveStep],
  );

  const isActive = useMemo(() => activeStep === name, [activeStep, name]);
  const isEditable = useMemo(() => step.editable, [step]);
  const isComplete = useMemo(() => {
    const stepKeys = Object.keys(steps);
    const thisIndex = stepKeys.indexOf(name);
    const activeIndex = stepKeys.indexOf(activeStep);
    return thisIndex < activeIndex;
  }, [activeStep, name, steps]);

  return {
    steps,
    activeStep,
    setActiveStep,
    nextStep,
    makeActive,
    isActive,
    isEditable,
    isComplete,
    step: thisStep.current,
    name,
    data,
    setData,
  };
};
