import React from 'react';
import * as S from './item-medium-image.styles';

const ItemMediumImage = ({ mediumImage }) => {
  return (
    <>
      {mediumImage.length === 1 && (
        <S.OriginalImg
          src={mediumImage[0]}
          alt=""
          data-testid="CatalogModal.OriginalImg"
        />
      )}

      {mediumImage.length > 1 ? (
        <S.CustomSlider tabIndex="0">
          {mediumImage.map((imgUrl, index) => (
            <S.OriginalImg
              key={`${index}-${imgUrl}`}
              data-src={imgUrl}
              alt=""
              data-testid="CatalogModal.OriginalImg"
            />
          ))}
        </S.CustomSlider>
      ) : null}
    </>
  );
};

export default ItemMediumImage;
