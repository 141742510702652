import styled from 'styled-components';

export const LoyaltyPunchImg = styled.img`
  width: 100%;
  height: auto;
`;

export const HasMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  height: 100%;
`;
