import { Add } from '@material-ui/icons';
import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';

const CreditCardPanelAddNewCardButton = ({ handleAddNewCardClick }) => {
  const { t } = useTranslation();

  return (
    <IncButton
      variant="text"
      fullWidth={false}
      data-testid="PaymentSelector.IncButton.AddNewCardButton"
      onClick={handleAddNewCardClick}
    >
      <Add />
      {t('payment.add')}
    </IncButton>
  );
};

export default CreditCardPanelAddNewCardButton;
