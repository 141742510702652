import { AccessTime } from '@material-ui/icons';
import { PaymentOptionType } from 'components/payment/payment.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentOption from './payment-option.component';

const PayLaterPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  const { t } = useTranslation();
  const title = t('paymentSelector.paymentOption.payLater.title');

  return (
    <PaymentOption
      icon={<AccessTime />}
      title={title}
      type={PaymentOptionType.PAY_LATER}
      {...rest}
      ref={ref}
    />
  );
});

export default PayLaterPaymentOption;
