import AddCardErrorBoundary from 'components/add-card-error-boundary/add-card-error-boundary.component';
import CardBox from 'components/add-purchase-gift-card/card-box/card-box.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import { useTranslation } from 'react-i18next';
import AddCreditCardModal from './add-credit-card-modal/add-credit-card-modal.component';

const AddCreditCard = () => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useCustomDialog();

  const onClick = () => {
    handleOpen();
  };

  return (
    <AddCardErrorBoundary name="my-cards-screen-add-credit-card">
      <CardBox
        onClick={onClick}
        text={t('addCreditCard.addCardAction')}
        testId="CardBox.IncButtonDark.AddCreditCard"
      />
      {open && <AddCreditCardModal handleClose={handleClose} />}
    </AddCardErrorBoundary>
  );
};

export default AddCreditCard;
