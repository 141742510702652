import { DateTime } from 'luxon';
import { getDetails, getLatLng } from 'use-places-autocomplete';
import { STORE_STATUSES } from './locations.constants';

export const slugify = string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const getLatLngFromStores = stores =>
  stores.map(({ latitude, longitude }) => ({ latitude, longitude }));

export const getFormattedLatLng = (latitude, longitude) => ({
  lat: latitude,
  lng: longitude,
});

// Return map bounds based on list of places
export const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach(store => {
    bounds.extend(new maps.LatLng(store.latitude, store.longitude));
  });

  return bounds;
};

// Fit map to its bounds after the api is loaded
export const updateMap = (map, maps, places) => {
  if (places) {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    places.length > 1 && map.fitBounds(bounds);
  }
};

export const isValidAddress = address =>
  !!address && !!address.lat && !!address.lon;

export const getMissingMandatoryFieldsInAddress = address => {
  const missingFields = [];
  if (!address) return [];
  if (!address.street1) missingFields.push('street1');
  if (!address.postalCode) missingFields.push('postalCode');
  if (!address.country) missingFields.push('country');
  if (!address.city) missingFields.push('city');
  if (!address.state) missingFields.push('state');
  return missingFields;
};

export const addressEquals = (address1, address2) =>
  [
    'street1',
    'street2',
    'city',
    'state',
    'country',
    'postalCode',
    'addressAlias',
    'aptSuite',
  ].every(property => address1?.[property] === address2?.[property]);

export const geoCodeToAddress = (geoCode, lat, lon) => {
  const { address_components, formatted_address } = geoCode;
  let address = { formatted_address, lat, lon };

  var addressMap = {
    street1: ['street_number'],
    street2: ['street_address', 'route'],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    country: ['country'],
    postalCode: ['postal_code'],
  };

  address_components.forEach(component => {
    Object.entries(addressMap).forEach(([key, value]) => {
      if (value.indexOf(component.types[0]) !== -1) {
        address[key] = component.long_name;
      }
    });
  });
  return address;
};

export const getSplitAddress = geoCode =>
  getLatLng(geoCode).then(({ lat, lng }) => {
    return geoCodeToAddress(geoCode, lat, lng);
  });

export const getAddressFromPlaceSuggestion = async suggestion => {
  const details = await getDetails({
    placeId: suggestion.place_id,
    fields: ['address_components', 'formatted_address', 'place_id', 'geometry'],
  });
  const splitAddress = geoCodeToAddress(
    details,
    details.geometry.location.lat(),
    details.geometry.location.lng(),
  );
  return {
    ...splitAddress,
    placeId: details.place_id,
  };
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const getDisplayStoreAddress = storeAddress => {
  let displayStoreAddress = '';

  if (storeAddress) {
    displayStoreAddress += storeAddress.streetAddress1
      ? storeAddress.streetAddress1
      : '';
    displayStoreAddress += storeAddress.streetAddress2
      ? ' ' + storeAddress.streetAddress2
      : '';
    displayStoreAddress += storeAddress.city ? ', ' + storeAddress.city : '';
    displayStoreAddress += storeAddress.state ? ', ' + storeAddress.state : '';
    displayStoreAddress += storeAddress.region
      ? ', ' + storeAddress.region
      : '';
    displayStoreAddress += storeAddress.postalCode
      ? ', ' + storeAddress.postalCode
      : '';
  }

  return displayStoreAddress;
};

export const isValidAsapDuration = asapDuration =>
  !isNaN(asapDuration) && asapDuration > 0;

const getTodayLocationData = (orderHours, timezone, offDays) => {
  const todayOrderingTimes = {
    durations: [],
    durationRelevantToUserTime: null,
  };
  const todayDateTime = DateTime.local({ zone: timezone });
  const isTodayOff =
    Array.isArray(offDays) && offDays.includes(todayDateTime.toISODate());

  if (isTodayOff) return todayOrderingTimes;

  const weekdayLong = todayDateTime.weekdayLong;
  const todayLocationData = orderHours?.find(
    hoursData => hoursData.dayOfWeek === weekdayLong?.toUpperCase(),
  );

  if (!todayLocationData?.timeSlots?.length) return todayOrderingTimes;

  const todayOpeningTimes = todayLocationData.timeSlots.map(
    ({ startTime, endTime }) => ({
      startTime: getTodayLuxonDateTimeForGivenTimeString(startTime, timezone),
      endTime: getTodayLuxonDateTimeForGivenTimeString(endTime, timezone),
    }),
  );

  todayOrderingTimes.durations = [...todayOpeningTimes].sort(
    (a, b) => a.startTime.diff(b.startTime).toObject().milliseconds,
  );

  todayOrderingTimes.durationRelevantToUserTime = [...todayOpeningTimes].sort(
    (a, b) =>
      Math.abs(a.startTime.diffNow('hours').hours) -
      Math.abs(b.startTime.diffNow('hours').hours),
  )[0];

  return todayOrderingTimes;
};

const getLocationOpenCloseStatus = duration => {
  if (!duration) return STORE_STATUSES.CLOSED;

  const { startTime, endTime } = duration;
  if (
    startTime.diffNow('hours').hours < 0 &&
    endTime.diffNow('hours').hours > 0
  ) {
    return STORE_STATUSES.OPEN;
  }

  return STORE_STATUSES.CLOSED;
};

export const getLocationOrderingInfo = (orderHours, timezone, offDays) => {
  const { durationRelevantToUserTime, durations } = getTodayLocationData(
    orderHours,
    timezone,
    offDays,
  );
  const status = getLocationOpenCloseStatus(durationRelevantToUserTime);

  return {
    durationRelevantToUserTime,
    durations,
    status,
  };
};

export const getTodayLuxonDateTimeForGivenTimeString = (timeString, zone) => {
  return DateTime.fromObject(
    {
      hour: timeString.split(':')[0],
      minute: timeString.split(':')[1],
    },
    { zone },
  );
};
