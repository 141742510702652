import { useCallback, useState } from 'react';
import {
  selectOverrideCatalogItemDetails,
  selectOverriddeGroupItemDetails,
} from 'redux/catalogs/catalogs.selectors';
import { getInvalidItemsForHiddenMenus } from 'redux/catalogs/catalogs.utils';
import { store } from 'redux/store';

export const useHiddenMenuError = () => {
  const [hiddenMenuErrorState, setHiddenMenuErrorState] = useState({});

  const getInvalidItems = useCallback((time, requestedOrderType) => {
    const overrideCatalogItemDetails = selectOverrideCatalogItemDetails(
      store.getState(),
    );

    const overrideGroupItemDetails = selectOverriddeGroupItemDetails(
      store.getState(),
    );

    const invalidItems = getInvalidItemsForHiddenMenus(
      overrideCatalogItemDetails,
      overrideGroupItemDetails,
      requestedOrderType,
      time,
    );

    return invalidItems;
  }, []);

  return { getInvalidItems, setHiddenMenuErrorState, hiddenMenuErrorState };
};
