import { DateTime } from 'luxon';
import { STORE_STATUSES } from 'pages/locations/locations.constants';
import { getLocationOrderingInfo } from 'pages/locations/locations.utils';
import { convertDistanceToMiles } from 'redux/cart/cart.utils';
import getDistance from 'geolib/es/getDistance';
import { selectors as appSelectors } from 'redux/app/app.slice';

const sortPickupLocationsWithOrderingHours = locations =>
  locations.sort(
    (a, b) =>
      (getLocationOrderingInfo(a.pickupOrderHours, a.timeZone, a.pickupOffDays)
        .status ===
        STORE_STATUSES.CLOSED) -
      (getLocationOrderingInfo(b.pickupOrderHours, b.timeZone, b.pickupOffDays)
        .status ===
        STORE_STATUSES.CLOSED),
  );

const getSortableAndUnsortableLocationsWithDistance = stores => {
  const sortableLocations = stores.filter(
    store =>
      store.distanceToUserInMiles !== null && store.distanceToUserInMiles <= 30,
  );
  const unsortableLocations = stores.filter(
    store =>
      store.distanceToUserInMiles === null || store.distanceToUserInMiles > 30,
  );

  return [sortableLocations, unsortableLocations];
};

const sortDeliveryLocationsWithOrderingHours = locations => {
  const [sortableDeliverableLocations, unsortableDeliverableLocations] =
    getSortableAndUnsortableLocationsWithDistance(locations);

  return [
    ...sortableDeliverableLocations.sort(
      (a, b) =>
        (getLocationOrderingInfo(
          a.deliveryOrderHours,
          a.timeZone,
          a.deliveryOffDays,
        ).status ===
          STORE_STATUSES.CLOSED) -
        (getLocationOrderingInfo(
          b.deliveryOrderHours,
          b.timeZone,
          b.deliveryOffDays,
        ).status ===
          STORE_STATUSES.CLOSED),
    ),
    ...unsortableDeliverableLocations,
  ];
};

const sortDeliveryLocationsWithOrderingHoursAndDeliveryRadius = locations => {
  const deliverableLocations = locations.filter(
    loc => loc.withInDeliveryRadius !== false,
  );
  const unDeliverableLocations = locations.filter(
    loc => loc.withInDeliveryRadius === false,
  );

  const [sortableDeliverableLocations, unsortableDeliverableLocations] =
    getSortableAndUnsortableLocationsWithDistance(deliverableLocations);

  return [
    ...sortDeliveryLocationsWithOrderingHours(sortableDeliverableLocations),
    ...unsortableDeliverableLocations,
    ...unDeliverableLocations,
  ];
};

const getCateringLocationStatus = (pickupStatus, deliveryStatus) => {
  if ([pickupStatus, deliveryStatus].includes(STORE_STATUSES.OPEN))
    return STORE_STATUSES.OPEN;

  return STORE_STATUSES.CLOSED;
};

const sortCateringLocationsWithOrderingHours = locations => {
  const [sortableDeliverableLocations, unsortableDeliverableLocations] =
    getSortableAndUnsortableLocationsWithDistance(locations);

  return [
    ...sortableDeliverableLocations.sort(
      (a, b) =>
        (getCateringLocationStatus(
          getLocationOrderingInfo(
            a.pickupOrderHours,
            a.timeZone,
            a.pickupOffDays,
          ).status,
          getLocationOrderingInfo(
            a.deliveryOrderHours,
            a.timeZone,
            a.deliveryOffDays,
          ).status,
        ) ===
          STORE_STATUSES.CLOSED) -
        (getCateringLocationStatus(
          getLocationOrderingInfo(
            b.pickupOrderHours,
            b.timeZone,
            b.pickupOffDays,
          ).status,
          getLocationOrderingInfo(
            b.deliveryOrderHours,
            b.timeZone,
            b.deliveryOffDays,
          ).status,
        ) ===
          STORE_STATUSES.CLOSED),
    ),
    ...unsortableDeliverableLocations,
  ];
};

export const getRequestedCateringLocations = stores =>
  sortCateringLocationsWithOrderingHours(stores);

export const getRequestedPickupLocations = (stores, searchLocation) => {
  if (!!searchLocation) return stores;
  const [sortableLocations, unsortableLocations] =
    getSortableAndUnsortableLocationsWithDistance(stores);

  return [
    ...sortPickupLocationsWithOrderingHours(sortableLocations),
    ...unsortableLocations,
  ];
};

export const getRequestedDeliveryLocations = (stores, withDeliveryRadius) => {
  if (withDeliveryRadius)
    return sortDeliveryLocationsWithOrderingHoursAndDeliveryRadius(stores);
  return sortDeliveryLocationsWithOrderingHours(stores);
};

const getIsoDatesForThisWeek = timeZone =>
  Array.from({ length: 7 }, (_, i) =>
    DateTime.local({ zone: timeZone }).plus({ days: i }).toISODate(),
  );

export const getThisWeekSameHoursPerRate = (sameHoursPerDate, timeZone) => {
  const { startDate, endDate, sameHoursPerDate: dateObj } = sameHoursPerDate;
  const sameHoursInThisWeek = [];
  const startDateLuxonDateTime = DateTime.fromISO(startDate, {
    zone: timeZone,
  });
  const endDateLuxonDateTime = DateTime.fromISO(endDate, {
    zone: timeZone,
  });

  getIsoDatesForThisWeek(timeZone).forEach(date => {
    const isoDate = DateTime.fromISO(date, {
      zone: timeZone,
    });
    if (
      startDateLuxonDateTime.diff(isoDate, 'days').toObject().days <= 0 &&
      endDateLuxonDateTime.diff(isoDate, 'days').toObject().days >= 0
    )
      sameHoursInThisWeek.push({
        ...dateObj,
        dayOfWeek: isoDate.weekdayLong.toUpperCase(),
      });
  });

  return sameHoursInThisWeek;
};

export const getThisWeekDiffHoursPerRate = (diffHoursPerRate, timeZone) => {
  const { diffHoursPerDate: dateObject } = diffHoursPerRate;

  const diffHoursInThisWeek = [];

  getIsoDatesForThisWeek(timeZone).forEach(date => {
    if (!!dateObject[date]) diffHoursInThisWeek.push(dateObject[date]);
  });

  return diffHoursInThisWeek;
};

const mergeOrderingHours = (orderingHours, thisWeekTemporaryHours) => {
  const mergedArray = [...orderingHours, ...thisWeekTemporaryHours];
  const ids = mergedArray.map(({ dayOfWeek }) => dayOfWeek);
  const mergedHours = mergedArray.filter(
    ({ dayOfWeek }, index) => !ids.includes(dayOfWeek, index + 1),
  );
  return mergedHours.map(({ timeSlots, dayOfWeek }) => ({
    timeSlots,
    dayOfWeek,
  }));
};

export const transformLocationResponse = (response, getState) => {
  let modifiedResponse = { ...response };
  let storePickupOrderHours;
  let storeDeliveryOrderHours;

  modifiedResponse.stores = modifiedResponse.stores.map(store => {
    const {
      pickupTemporaryHours,
      pickupOrderHours,
      timeZone,
      deliveryTemporaryHours,
      deliveryOrderHours,
      latitude,
      longitude,
    } = store;
    storePickupOrderHours = getOrderHours(
      pickupTemporaryHours,
      pickupOrderHours ?? [],
      timeZone,
    );
    storeDeliveryOrderHours = getOrderHours(
      deliveryTemporaryHours,
      deliveryOrderHours ?? [],
      timeZone,
    );

    return {
      ...store,
      distanceToUserInMiles: getUserDistanceToStore(
        { latitude, longitude },
        getState,
      ),
      pickupOrderHours: storePickupOrderHours.map(
        ({ timeSlots, dayOfWeek }) => ({
          timeSlots,
          dayOfWeek,
        }),
      ),
      deliveryOrderHours: storeDeliveryOrderHours.map(
        ({ timeSlots, dayOfWeek }) => ({
          timeSlots,
          dayOfWeek,
        }),
      ),
    };
  });
  return modifiedResponse;
};

// Priority for temporaryHours
const getOrderHours = (temporaryHours, storeOrderHours, timeZone) => {
  let thisWeekTemporaryHours;
  if (Array.isArray(temporaryHours)) {
    temporaryHours.forEach(temporaryHour => {
      if (!!temporaryHour.sameHoursPerDate) {
        thisWeekTemporaryHours = getThisWeekSameHoursPerRate(
          temporaryHour,
          timeZone,
        );
        storeOrderHours = mergeOrderingHours(
          storeOrderHours,
          thisWeekTemporaryHours,
        );
      } else if (!!temporaryHour.diffHoursPerDate) {
        thisWeekTemporaryHours = getThisWeekDiffHoursPerRate(
          temporaryHour,
          timeZone,
        );
        storeOrderHours = mergeOrderingHours(
          storeOrderHours,
          thisWeekTemporaryHours,
        );
      }
    });
  }
  return storeOrderHours;
};

const getUserDistanceToStore = (storeLatLon, getState) => {
  const { latitude: storeLatitude, longitude: storeLongitude } = storeLatLon;
  const { latitude, longitude } = appSelectors.selectLocationFetchingLatLon(
    getState(),
  );

  if (isNaN(latitude) || isNaN(storeLatitude)) return null;

  return convertDistanceToMiles(
    getDistance(
      { latitude, longitude },
      {
        latitude: storeLatitude,
        longitude: storeLongitude,
      },
    ),
  );
};
