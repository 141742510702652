import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './giftcard-landing.styles';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
} from '../giftcard.styles';
import { useGiftcardLanding } from './giftcard-landing.hooks';

const GiftcardLanding = ({ history }) => {
  const { t } = useTranslation();
  const { isEmailGiftcard, giftCardLookup } = useGiftcardLanding();

  return (
    <ContentContainer
      elevation={2}
      data-testid={'GiftcardLandingPage.ContentContainer'}
    >
      <ContentHeader
        component="h1"
        variant="h5"
        data-testid={'GiftcardLandingPage.ContentHeader'}
      >
        {t('eGiftCard.landing.header')}
      </ContentHeader>
      <ContentBody data-testid={'GiftcardLandingPage.ContentBody'}>
        <S.LandingTitle variant="h6">
          {t('eGiftCard.landing.title')}
        </S.LandingTitle>
        {isEmailGiftcard && (
          <S.LandingLinkButton
            onClick={() => history.push('/giftcard/purchase')}
            fullWidth
            data-testid="GiftcardLandingPage.Purchase"
          >
            {t('eGiftCard.landing.purchase')}
          </S.LandingLinkButton>
        )}
        {giftCardLookup && (
          <S.LandingLinkButton
            onClick={() => history.push('/giftcard/lookup')}
            fullWidth
            data-testid="GiftcardLandingPage.Lookup"
          >
            {t('eGiftCard.landing.check')}
          </S.LandingLinkButton>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default GiftcardLanding;
