import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  text-align: center;
`;

export const Image1 = styled.img`
  margin: -2px 8px;
  max-width: 12px;
`;

export const Image2 = styled.img`
  margin: -4px 8px;
  max-width: 20px;
`;

export const PirvacyPolicy = styled.a`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
`;

export const SecurePayments = styled.div`
  color: #b1b6b8;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
`;
