import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocationId } from 'redux/cart/cart.selectors';
import { selectRequestedFulfillTimeAvailabilityObject } from 'redux/data/orderavailability/orderavailability.selectors';

const OrderDateTime = () => {
  const { t } = useTranslation();
  const storeId = useSelector(selectLocationId);
  const dateTime = useSelector(state =>
    selectRequestedFulfillTimeAvailabilityObject(state, storeId),
  );

  return (
    <>
      {dateTime?.dayLabel !== t('locations.card.today') &&
        `${dateTime?.displayDateTime.toLocaleString({
          month: '2-digit',
          day: '2-digit',
        })} `}
      {dateTime?.timeLabel}
    </>
  );
};

export default OrderDateTime;
