// Unfortunately I have to hack cypress in here in order to test this.
// Couldn't figure out another way to do it with redirects. Tried a bunch of stuff like mocking,
// but the redirect always occurred before the mock took place

const location = {
  setLocationHref: href => {
    if (window.Cypress) window.incTest.redirect = href;
    else window.location.href = href;
  },
  getLocationHref: () => window.location.href,
  getSearchParam: name => {
    let params = new URL(window.location).searchParams;
    return params.get(name);
  },
  getLocationHost: () => window.location.host,
  getLocationOrigin: () => window.location.origin,
  getLocationHostname: () => window.location.hostname,
  getLocationPathname: () => window.location.pathname,
  getBaseURL: () => `${window.location.protocol}//${window.location.host}`,
  getClientURL: () => {
    const baseUrl = location.getBaseURL();
    const pathname = location.getLocationPathname();
    const splitPathname = pathname.split('/');
    if (splitPathname[1] === 'c') {
      // dynamic client
      const clientPath = `${splitPathname[1]}/${splitPathname[2]}`;
      return `${baseUrl}/${clientPath}`;
    } else {
      // static client
      return baseUrl;
    }
  },
};

export default location;
