import create from 'zustand';

export const makeUseLoading = () =>
  create((set, get) => ({
    loading: false,
    promises: [],
    setLoading: boolean => {
      set(() => ({
        loading: boolean,
      }));
    },
    addPromise: async promise => {
      set(state => ({ loading: true, promises: [...state.promises, promise] }));
      promise
        .catch(() => {})
        .finally(() => {
          const newPromises = get().promises.filter(p => p !== promise);
          set(() => ({
            loading: newPromises.length > 0,
            promises: newPromises,
          }));
        });
      return promise;
    },
  }));
