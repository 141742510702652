const CatalogsActionTypes = {
  FETCH_CATALOGS_START: 'FETCH_CATALOGS_START',
  FETCH_CATALOGS_SUCCESS: 'FETCH_CATALOGS_SUCCESS',
  FETCH_CATALOGS_FAILURE: 'FETCH_CATALOGS_FAILURE',
  FETCH_OUT_OF_ITEMS_SUCCESS: 'FETCH_OUT_OF_ITEMS_SUCCESS',
  FETCH_OUT_OF_ITEMS_FAILURE: 'FETCH_OUT_OF_ITEMS_FAILURE',
  GET_SINGLE_ITEM_DETAILS_SUCCESS: 'GET_SINGLE_ITEM_DETAILS_SUCCESS',
};

export default CatalogsActionTypes;
