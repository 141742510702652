import { useEffect, useMemo } from 'react';
import { useAppcontent } from 'redux/data/appcontent/appcontent.hooks';

const refreshLoaderId = 'account-inbox-refresh-loader-id';
const infiniteLoaderId = 'account-inbox-infinite-loader-id';

export const useInbox = () => {
  const {
    fetchAppcontent,
    refreshAppcontent,
    inboxContent,
    total,
    hasMore,
    loading,
  } = useAppcontent();

  const finalContent = useMemo(() => inboxContent, [inboxContent]);
  const finalTotal = useMemo(() => total, [total]);

  useEffect(() => {
    if (!loading) {
      // Look for new messages if we revisit the page
      refreshAppcontent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshLoading = useMemo(() => !hasMore && loading, [hasMore, loading]);

  const infiniteLoading = useMemo(() => hasMore && loading, [hasMore, loading]);

  const containerAriaAttributes = useMemo(() => {
    let attributes = {};

    if (refreshLoading) {
      attributes = {
        'aria-describedby': refreshLoaderId,
        'aria-busy': true,
      };
    } else if (infiniteLoading) {
      attributes = {
        'aria-describedby': infiniteLoaderId,
        'aria-busy': true,
      };
    }

    return attributes;
  }, [infiniteLoading, refreshLoading]);

  return {
    fetchAppcontent,
    finalContent,
    finalTotal,
    hasMore,
    loading,
    refreshLoading,
    infiniteLoading,
    refreshLoaderId,
    infiniteLoaderId,
    containerAriaAttributes,
  };
};
