import { useCallback, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  selectDeliveryInstructions,
  selectOrderType,
} from 'redux/cart/cart.selectors';
import { deliveryInstructionsSchema } from '../checkout.schema';
import { selectDeliveryInstructions as selectDeliveryInstructionsEnabled } from 'redux/config/config.selectors';

export const useDeliveryInstructionsValidation = errors => {
  const store = useStore();

  const validate = useCallback(async () => {
    await deliveryInstructionsSchema.validate({
      deliveryInstructions: selectDeliveryInstructions(store.getState()),
    });
    return errors['deliveryInstructions'];
  }, [errors, store]);

  return { validate };
};

export const useCheckoutDeliveryInstructionsSection = () => {
  const orderType = useSelector(selectOrderType);
  const showDeliveryInstructions = useSelector(
    selectDeliveryInstructionsEnabled,
  );
  const show = useMemo(
    () => orderType === 'DELIVERY' && showDeliveryInstructions,
    [orderType, showDeliveryInstructions],
  );

  return { show };
};
