import { useCallback, useState } from 'react';
import SpreedlyCheckoutButtonsContext from './spreedly-checkout-buttons.context';

const SpreedlyCheckoutButtonsProvider = ({ children }) => {
  const [threeDSTransactionToken, setThreeDSTransactionToken] = useState(null);

  const threeDS2ModalClose = useCallback(() => {
    setThreeDSTransactionToken(null);
  }, [setThreeDSTransactionToken]);

  return (
    <SpreedlyCheckoutButtonsContext.Provider
      value={{
        threeDSTransactionToken,
        setThreeDSTransactionToken,
        threeDS2ModalClose,
      }}
    >
      {children}
    </SpreedlyCheckoutButtonsContext.Provider>
  );
};

export default SpreedlyCheckoutButtonsProvider;
