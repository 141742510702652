import React, { useMemo } from 'react';
import * as S from './menu-card-new.styles';

const MenuCardNew = ({
  onClick,
  onKeyPress,
  children,
  testId = 'MenuCard',
  disabled,
  ...rest
}) => {
  const handleClick = useMemo(
    () => (!disabled ? onClick : () => {}),
    [disabled, onClick],
  );
  const handleKeyPress = useMemo(
    () => (!disabled ? onKeyPress : () => {}),
    [disabled, onKeyPress],
  );

  return (
    <S.GridItem item xs={12} sm={6} md={6} lg={4} component="li" {...rest}>
      <S.Container
        container
        data-testid={testId}
        disabled={disabled}
        aria-disabled={disabled ? 'true' : undefined}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
      >
        {children}
      </S.Container>
    </S.GridItem>
  );
};

export default MenuCardNew;
