import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'redux/app/app.slice';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import PaymentSelector from '../payment-selector.component';

const GiftCardPaymentSelector = ({ cards, selectedCard, onChangeCard }) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { selectedPaymentOption, setSelectedPaymentOption, paymentOptions } =
    usePaymentOptions();

  const preferredGiftCardPaymentOption = useSelector(
    selectors.selectUserPreferredGiftCardPaymentOption,
  );
  const onChangePaymentOption = type => {
    setSelectedPaymentOption(type);
    if (isLoggedIn) {
      dispatch(actions.setLoggedInPreferredGiftCardPaymentOption(type));
    } else {
      dispatch(actions.setPreferredGiftCardPaymentOption(type));
    }
  };

  useEffect(() => {
    if (!selectedPaymentOption && Object.keys(paymentOptions).length > 0) {
      onChangePaymentOption(
        preferredGiftCardPaymentOption ??
          Object.keys(paymentOptions).find(
            paymentOptionType => paymentOptions[paymentOptionType],
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentSelector
      paymentOptions={paymentOptions}
      selectedPaymentOption={selectedPaymentOption}
      onChangePaymentOption={onChangePaymentOption}
      cards={cards}
      selectedCard={selectedCard}
      onChangeCard={onChangeCard}
    />
  );
};

export default GiftCardPaymentSelector;
