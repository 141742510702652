import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppcontent } from 'redux/data/appcontent/appcontent.hooks';
import { getLoyaltyAccounts } from 'redux/user/user.actions';
import { selectIsLoading } from 'redux/user/user.selectors';

const initialLoaderId = 'account-loyalty-initial-loader-id';
const infiniteLoaderId = 'account-loyalty-infinite-loader-id';
const containerId = 'account-loyalty-container-id';

export const useLoyaltyContent = () => {
  const {
    fetchLoyaltyContent,
    loyaltyContent,
    totalLoyalty,
    hasMore,
    loading,
  } = useAppcontent();

  const { loading: loayltyAccountsLoading } = useFetchLoyaltyAccountsData();

  const finalContent = useMemo(() => loyaltyContent, [loyaltyContent]);
  const finalTotal = useMemo(() => totalLoyalty, [totalLoyalty]);

  const initialLoading = useMemo(
    () => loayltyAccountsLoading ?? loading,
    [loading, loayltyAccountsLoading],
  );

  const infiniteLoading = useMemo(() => hasMore && loading, [hasMore, loading]);

  const containerAriaAttributes = useMemo(() => {
    let attributes = {};

    if (initialLoading) {
      attributes = {
        'aria-describedby': initialLoaderId,
        'aria-busy': true,
      };
    } else if (infiniteLoading) {
      attributes = {
        'aria-describedby': infiniteLoaderId,
        'aria-busy': true,
      };
    }

    return attributes;
  }, [infiniteLoading, initialLoading]);

  return {
    fetchAppcontent: fetchLoyaltyContent,
    finalContent,
    finalTotal,
    hasMore,
    loading,
    infiniteLoading,
    initialLoading,
    initialLoaderId,
    infiniteLoaderId,
    containerId,
    containerAriaAttributes,
  };
};

export const useHistoryView = () => {
  const [showHistory, setShowHistory] = useState(false);

  const handleHistoryClick = () => {
    setShowHistory(true);
  };

  const handleRewardsClick = () => {
    setShowHistory(false);
  };

  return {
    showHistory,
    handleHistoryClick,
    handleRewardsClick,
  };
};

export const useFetchLoyaltyAccountsData = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(getLoyaltyAccounts());
  }, [dispatch]);

  return { loading };
};
