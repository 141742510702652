import { Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

export const FormContainer = styled(Paper)`
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const InfoAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled(IncButton)`
  margin: 0.5em 0 !important;
`;
