import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export const Buttons = styled(Grid)`
  margin-top: 1.5em;
`;

export const EmptyContent = styled(Typography)`
  text-align: center;
`;
