import { Box } from '@material-ui/core';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import GiftCardInsufficientBalance from 'components/gift-card-insufficient-balance/gitft-card-insufficient-balance.component';
import { useSelector } from 'react-redux';
import { selectPaymentInstrumentId } from 'redux/cart/cart.selectors';
import CheckoutButtonsWrapper from '../checkout-buttons-new/checkout-buttons-wrapper/checkout-buttons-wrapper.component';

const CheckoutButtonsSection = () => {
  const {
    open: insufficientOpen,
    handleClose: insufficientModalClose,
    setOpen: insufficientModalSetOpen,
  } = useCustomDialog();

  const paymentInstrumentId = useSelector(selectPaymentInstrumentId);

  return (
    <Box mb={0}>
      <CheckoutButtonsWrapper
        insufficientModalSetOpen={insufficientModalSetOpen}
      />
      {insufficientOpen && (
        <GiftCardInsufficientBalance
          open
          handleClose={insufficientModalClose}
          cardIdentifier={paymentInstrumentId}
        />
      )}
    </Box>
  );
};

export default CheckoutButtonsSection;
