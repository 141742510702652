import { Box } from '@material-ui/core';
import PlaceOrder from 'pages/checkout/checkout-buttons/place-order/place-order.component';
import { useNewCheckoutButtons } from '../../checkout-buttons-new.hooks';
import { useSquarePaymentsPlaceOrder } from 'components/payment/square/square-payments.hooks';

const SquareCheckoutButtons = ({ insufficientModalSetOpen }) => {
  const { trigger } = useSquarePaymentsPlaceOrder();
  const { showPlaceOrder, handlePlaceOrder, loading } = useNewCheckoutButtons(
    trigger,
    insufficientModalSetOpen,
  );

  return (
    <Box data-testid="CheckoutButtons.Grid">
      {showPlaceOrder && (
        <PlaceOrder handlePlaceOrder={handlePlaceOrder} disabled={loading} />
      )}
    </Box>
  );
};

export default SquareCheckoutButtons;
