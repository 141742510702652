import i18n from 'i18next';
import { isValidAddress } from 'pages/locations/locations.utils';
import { getFormattedAddressString } from 'utils';

export const validateStreetNumber = async option =>
  isValidAddress(option) || i18n.t('address.select.errors.required');

export const validateRequired = option =>
  !!option || i18n.t('address.placesAutocomplete.errors.required');

export const addressValidation = {
  validate: {
    required: validateRequired,
    streetNumber: validateStreetNumber,
  },
};

export const getOptionLabel = option => {
  if (option.addressId) return getFormattedAddressString(option);
  return typeof option === 'string' ? option : option.description;
};
