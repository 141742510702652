import { useContext } from 'react';
import SectionContext from '../checkout-section.context';

const CheckoutSectionPreview = ({ children }) => {
  const { editable, editing } = useContext(SectionContext);

  if (editable && !editing) return children;
  else return null;
};

export default CheckoutSectionPreview;
