import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Name = styled(Typography)`
  font-size: ${({ fontSize }) => fontSize}rem;
  display: flex;
  align-items: center;
  .MuiAvatar-root {
    width: 35px;
    height: 35px;
    margin-right: 0.5em;
  }
`;
