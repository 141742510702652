import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressesStartAsync } from 'redux/user/user.actions';
import {
  selectDeliveryAddressList,
  selectIsLoggedIn,
  selectIsUserDataLoaded,
} from 'redux/user/user.selectors';
import LoggedInAddressControls from './logged-in-address-controls/logged-in-address-controls.component';
import LoggedOutAddressControls from './logged-out-address-controls/logged-out-address-controls.component';
import { selectRequestedAddress } from 'redux/cart/cart.selectors';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useAddress } from '../useAddress';

const AddressControls = props => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isUserDataLoaded = useSelector(selectIsUserDataLoaded);
  const savedAddresses = useSelector(selectDeliveryAddressList);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const requestedAddress = useSelector(selectRequestedAddress);
  const {
    fieldNames: { address },
  } = useFieldNames();
  useAddress();

  useEffect(() => {
    if (isLoggedIn && isUserDataLoaded && savedAddresses?.length < 1) {
      setLoaded(false);
      dispatch(fetchAddressesStartAsync()).then(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isUserDataLoaded, dispatch]);

  if (!loaded) return null;

  return isLoggedIn ? (
    <LoggedInAddressControls
      {...props}
      name={address}
      defaultValue={requestedAddress}
    />
  ) : (
    <LoggedOutAddressControls
      {...props}
      name={address}
      defaultValue={requestedAddress}
    />
  );
};

export default AddressControls;
