import { useResendTimer } from 'components/verify-code/resend-code/useResendTimer.hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resendVerificationMail } from 'redux/user/user.actions';
import { RESEND_EXPIRY_TIMER } from './resend-code.constants';

export const useResendCodeTimer = email => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const { intervalRef, startTimer } = useResendTimer(() => {
    setProgress(progress => progress + 1);
  }, 1000);

  useEffect(() => {
    if (progress >= RESEND_EXPIRY_TIMER) {
      clearInterval(intervalRef.current);
      setProgress(RESEND_EXPIRY_TIMER);
      return;
    }
  }, [intervalRef, progress]);

  const resendLinkClicked = () => {
    setProgress(0);
    startTimer();
    dispatch(resendVerificationMail(email));
  };

  return {
    progress,
    resendLinkClicked,
  };
};
