import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useVerifyEmailRequest = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const code = useURLSearchParams().get('code');

  useEffect(() => {
    const verifyEmailRequest = async () => {
      setLoading(true);
      try {
        await INC_BASE_API.get(`verifyemail?code=${code}`);
      } catch (error) {
        toast.error(getResponseErrorMessage(error));
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (!!code) verifyEmailRequest();
    else setError(true);
  }, [code, setLoading]);

  return { loading, error };
};
