import { useCheckoutOrderAvailability } from 'pages/store/new-order-form/useOrderAvailability.hooks';
import { memo } from 'react';

const CheckoutOrderAvailability = ({
  store,
  address,
  orderType,
  selectedDay,
}) => {
  useCheckoutOrderAvailability(store, address, orderType, selectedDay);

  return null;
};

export default memo(CheckoutOrderAvailability);
