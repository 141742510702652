import React from 'react';
import * as S from '../reset-password/reset-password.styles';
import { useTranslation } from 'react-i18next';

import Spinner from 'components/spinner/spinner.component';
import { Typography } from '@material-ui/core';
import { useOptOutEmail } from './unsubscribe.hooks';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const Unsubscribe = () => {
  const { t } = useTranslation();

  const { loading, error, errorTitle, errorDesc } = useOptOutEmail();
  const title = usePageTitle(t('head.title.unsubscribe'));

  return (
    <S.Container data-testid={'Unsubscribe.Container'}>
      <Helmet title={title} />
      {loading && <Spinner />}
      <S.ContentContainer elevation={2}>
        <S.ContentHeader
          component="h1"
          variant="h5"
          aria-label={t('optOut.title')}
          data-testid={'Unsubscribe.Title'}
        >
          {error ? errorTitle : t('optOut.title')}
        </S.ContentHeader>
        <S.ContentBody>
          <Typography
            component="h2"
            aria-label={t('optOut.description')}
            data-testid={'Unsubscribe.Description'}
          >
            {error ? errorDesc : t('optOut.description')}
          </Typography>
        </S.ContentBody>
      </S.ContentContainer>
    </S.Container>
  );
};

export default Unsubscribe;
