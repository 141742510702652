import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DARK_THEME } from 'theme.constants';
import { selectors } from 'redux/app/app.slice';
import { useIsStoreNotWithinDeliveryDistance } from 'hooks/useIsStoreWithinDeliveryDistance';
import { CATERING } from 'util/constants';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';

export const useMapStyles = () => {
  const savedTheme = useSelector(selectors.selectTheme);

  const styles = useMemo(
    () =>
      savedTheme === DARK_THEME
        ? [
            { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
            {
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#242f3e' }],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [{ color: '#746855' }],
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{ color: '#263c3f' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#6b9a76' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#38414e' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#212a37' }],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9ca5b3' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{ color: '#746855' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#1f2835' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#f3d19c' }],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{ color: '#2f3948' }],
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#17263c' }],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#515c6d' }],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#17263c' }],
            },
          ]
        : [],
    [savedTheme],
  );

  return styles;
};

export const useLocationsMapIsGrayOut = store => {
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(store.storeId),
  );

  const locationType = useSelector(selectors.selectLocationsLocationType);
  const isStoreNotWithinDeliveryDistance =
    useIsStoreNotWithinDeliveryDistance(store);

  if (isMenuBrowsingStore) return false;

  if (locationType === CATERING)
    return !store.pickupOrdersAccepted && !store.deliveryOrdersAccepted;

  return (
    isStoreNotWithinDeliveryDistance ||
    (!store.pickupOrdersAccepted && !store.deliveryOrdersAccepted)
  );
};
