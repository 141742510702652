import { Typography } from '@material-ui/core';
import { useLoginLink } from 'hooks/useLoginLink';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsWOV2Enabled } from 'redux/config/config.selectors';
import * as S from './verify-sms-success-message.styles';

const VerifySmsSuccessMessage = () => {
  const { t } = useTranslation();
  const isWOV2Enabled = useSelector(selectIsWOV2Enabled);

  const handleLoginLinkClick = useLoginLink();

  if (!isWOV2Enabled) {
    return (
      <Typography
        component="h2"
        aria-label={t('verifySms.successMessage.complete')}
      >
        {t('verifySms.successMessage.complete')}
      </Typography>
    );
  }

  return (
    <Typography component="h2">
      {t('verifySms.successMessage.anons.1')}{' '}
      <S.LinkButton
        component="button"
        variant="body1"
        onClick={handleLoginLinkClick}
        data-testid="VerifySmsSuccessMessage.LinkButton"
        aria-label={t('verifySms.successMessage.anons.ariaLabel')}
      >
        {t('verifySms.successMessage.anons.login')}
      </S.LinkButton>{' '}
      {t('verifySms.successMessage.anons.2')}
    </Typography>
  );
};

export default VerifySmsSuccessMessage;
