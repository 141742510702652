import { Provider } from './hidden-menu-error-dialog.context';
import { useHiddenMenuError } from './useHiddenMenuError.hooks';
import HiddenMenuErrorDialog from './hidden-menu-error-dialog.component';

const HiddenMenuErrorProvider = ({ children }) => {
  const hooks = useHiddenMenuError();
  return (
    <Provider value={hooks}>
      {children}
      <HiddenMenuErrorDialog />
    </Provider>
  );
};

export default HiddenMenuErrorProvider;
