import CreditCardPanelAddNewCardButton from '../credit-card-panel-add-new-card-button/credit-card-panel-add-new-card-button.component';
import CreditCardPanelCardSelect from '../credit-card-panel-card-select/credit-card-panel-card-select.component';

const CreditCardsController = ({ handleAddNewCardClick, ...props }) => {
  return (
    <>
      <CreditCardPanelCardSelect {...props} />
      <CreditCardPanelAddNewCardButton
        handleAddNewCardClick={handleAddNewCardClick}
      />
    </>
  );
};

export default CreditCardsController;
