import INC_BASE_API from 'apis/incentivio-api';
import { useEffect, useState } from 'react';

export const useVerifyPasswordRequest = (token, setLoading) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    const verifyPwRequest = async () => {
      setLoading(true);
      try {
        await INC_BASE_API.get(`verifypwresetrequest?id=${token}`);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (!!token) verifyPwRequest();
    else setError(true);
  }, [setLoading, token]);

  return { error };
};
