import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectHideQtyPicker } from 'redux/config/config.selectors';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';

export const useItemQtyPicker = () => {
  const hideQtyPicker = useSelector(selectHideQtyPicker);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());
  const { itemQuantity, setItemQuantity, visibility, handleSetItemPrice } =
    useContext(MenuDialogContext);

  const handleAdd = () => {
    setItemQuantity(itemQuantity + 1);
    handleSetItemPrice(itemQuantity + 1);
  };

  const handleRemove = () => {
    if (itemQuantity === 1) return;
    setItemQuantity(itemQuantity - 1);
    handleSetItemPrice(itemQuantity - 1);
  };

  return {
    hideQtyPicker,
    visibility,
    handleAdd,
    handleRemove,
    itemQuantity,
    isMenuBrowsingStore,
  };
};
