import { Box, CircularProgress, Grid, Grow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as S from '../account-inbox.styles';
import { useTranslation } from 'react-i18next';
import AccountInboxMessage from '../account-inbox-message/account-inbox-message.component';

const Messages = ({
  refreshLoading,
  refreshLoaderId,
  finalTotal,
  fetchAppcontent,
  hasMore,
  infiniteLoading,
  infiniteLoaderId,
  containerId,
  finalContent,
  loading,
  disableOnClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {refreshLoading && (
        <Grow in>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            data-testid="AccountInbox.Box.LoadMore"
          >
            <Box mr={1}>
              <CircularProgress id={refreshLoaderId} aria-label="spinner" />
            </Box>
            {t('accountInbox.refresh')}
          </Box>
        </Grow>
      )}
      {finalTotal > 0 ? (
        <InfiniteScroll
          dataLength={finalTotal}
          scrollableTarget={containerId}
          next={fetchAppcontent}
          hasMore={hasMore}
          loader={
            infiniteLoading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={2}
                data-testid="AccountInbox.Box.InfiniteLoad"
              >
                <CircularProgress id={infiniteLoaderId} aria-label="spinner" />
              </Box>
            )
          }
        >
          <S.MessageGrid container spacing={1}>
            {finalContent.map((message, i) => (
              <Grow
                in
                style={{ transitionDelay: i * 50 }}
                key={
                  message.distributedMessageId
                    ? message.distributedMessageId
                    : message.distributedOfferId
                }
              >
                <Grid item xs={12}>
                  <AccountInboxMessage
                    message={message}
                    disableOnClick={disableOnClick}
                  />
                </Grid>
              </Grow>
            ))}
          </S.MessageGrid>
        </InfiniteScroll>
      ) : (
        !loading && (
          <S.EmptyInbox>
            <Typography>{t('accountInbox.emptyMessage')}</Typography>
          </S.EmptyInbox>
        )
      )}
    </>
  );
};

export default Messages;
