import { CloverInstance } from './clover.instance';
import LogRocket from 'util/getLogRocket';

export const cloverSubmitButtonClick = async e => {
  e?.preventDefault();
  try {
    const result = await CloverInstance.getInstance().createToken();
    if (!result.errors) {
      const payload = {
        token: result.token,
        month: Number(result.card.exp_month),
        year: Number(result.card.exp_year),
        lastFour: result.card.last4,
        brand: result.card.brand,
      };

      // Android event listener
      if (window.appInterface !== undefined)
        window.appInterface.postMessage(JSON.stringify(payload));

      // IOS event listener
      if (window.webkit !== undefined)
        window.webkit.messageHandlers.iosListener.postMessage(
          JSON.stringify(payload),
        );

      return payload;
    }
  } catch (e) {
    LogRocket.captureException(e, {
      extra: {
        message: 'Clover hosted web payment page',
      },
    });
  }
};
