import i18n from 'i18n';
import { actions, createMachine } from 'xstate';
const { pure } = actions;

export const accountMachine = (initial = 'initial') =>
  createMachine({
    id: 'account',
    initial,
    states: {
      initial: {
        entry: pure(context =>
          context.drawer === true ? ['setDark', 'setTitle'] : [],
        ),
        on: {
          LOGIN: 'login',
          REGISTER: 'register',
          GUEST: 'guest',
        },
        meta: {
          title: i18n.t('drawer.login.loginOrRegister'),
        },
      },
      login: {
        entry: pure(context =>
          context.drawer === true ? ['setLight', 'setTitle'] : [],
        ),
        on: {
          BACK: 'initial',
          FORGOT_PASSWORD: 'forgot_password',
          REGISTER: 'register',
          CODE_VERIFICATION: 'code_verification',
        },
        meta: {
          title: i18n.t('drawer.login.login'),
        },
      },
      forgot_password: {
        entry: pure(context =>
          context.drawer === true ? ['setLight', 'setTitle'] : [],
        ),
        on: {
          BACK: 'login',
        },
        meta: {
          title: i18n.t('drawer.login.forgotPassword'),
        },
      },
      register: {
        entry: pure(context =>
          context.drawer === true ? ['setLight', 'setTitle'] : ['refresh'],
        ),
        on: {
          BACK: 'initial',
          LOGIN: 'login',
          CODE_VERIFICATION: 'code_verification',
          REGISTER: 'register',
        },
        meta: {
          title: i18n.t('drawer.login.signUp'),
        },
      },
      guest: {
        entry: pure(context =>
          context.drawer === true ? ['setLight', 'setTitle'] : [],
        ),
        on: {
          BACK: 'initial',
        },
      },
      code_verification: {
        entry: pure(context =>
          context.drawer === true ? ['setLight', 'setTitle'] : [],
        ),
        on: {
          LOGIN: 'login',
          REGISTER: 'register',
        },
        meta: {
          title: i18n.t('verifyCode.title'),
        },
      },
    },
  });
