/**
 * We are loading the heap snippet asynchronously depending upon webAppConfig
 * settings. This means we could potentially try and use Segment before it is
 * loaded. I have seen this with the 'identify' call since we trigger that on
 * app load if the user is logged in. See fetchUserDataStartAsync in
 * user.actions.
 *
 * The code below wraps window.heap with a promise that will wait up to
 * config.maxTime for window.heap to be defined. It will check at a defined
 * interval to see if window.heap is defined. If it becomes defined, we will
 * execute all queued calls to heap. If it does not become defined after
 * config.maxTime, we return a 'fake' heap implementation.
 */

let heapPromise;

const fakeHeap = {
  track: () => undefined,
  identify: () => undefined,
  addUserProperties: () => undefined,
};

const getHeap = config => {
  if (!heapPromise) {
    let intervalId;
    let intervalCount = 0;

    heapPromise = new Promise(resolve => {
      intervalId = setInterval(() => {
        ++intervalCount;
        if (!!window.heap) {
          resolve(window.heap);
          clearInterval(intervalId);
        } else if (intervalCount * config.intervalDuration > config.maxTime) {
          resolve(fakeHeap);
          clearInterval(intervalId);
        }
      }, config.intervalDuration);
    });
  }

  return heapPromise;
};

export const heap = {
  config: {
    intervalDuration: 100,
    maxTime: 6000,
  },
  track: async args => (await getHeap(heap.config)).track(args),
  identify: async args => (await getHeap(heap.config)).identify(args),
  addUserProperties: async args =>
    (await getHeap(heap.config)).addUserProperties(args),
  reset: () => {
    heapPromise = undefined;
    heap.config = {
      intervalDuration: 100,
      maxTime: 6000,
    };
  },
};
