import { Box } from '@material-ui/core';
import CreateAccount from 'components/create-account/create-account.component';
import GuestForm from 'components/guest-form/guest-form.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutSectionEdit from '../checkout-section/checkout-section-edit/checkout-section-edit.component';
import CheckoutSection from '../checkout-section/checkout-section.component';
import CheckoutContactInfoSectionPreview from './checkout-contact-info-section-preview/checkout-contact-info-section-preview.component';
import {
  useCustomerSectionComponent,
  useCustomerValidation,
} from './checkout-contact-info-section.hooks';
import { useIsGuestCheckoutSupported } from 'components/payment/payment.new.hooks';

const CheckoutContactInfoSection = ({ ...rest }) => {
  const { t } = useTranslation();
  const { customerInfo, submitCustomerInfo, formMethods } =
    useCustomerSectionComponent();
  const isGuestCheckoutSupported = useIsGuestCheckoutSupported();
  const { validate } = useCustomerValidation();

  return (
    <CheckoutSection
      title={t('checkout.customer.title')}
      onSubmit={submitCustomerInfo}
      validate={validate}
      data-testid="CheckoutContactInfoSection.CheckoutSection"
      changeButtonProps={{
        'aria-label': t('checkout.section.changeButton.contactInfo.ariaLabel'),
      }}
      {...rest}
    >
      {!!customerInfo ? (
        <>
          <CheckoutContactInfoSectionPreview />
          <CheckoutSectionEdit>
            <GuestForm methods={formMethods} customerInfo={customerInfo} />
          </CheckoutSectionEdit>
        </>
      ) : (
        <Box data-testid="CheckoutContactInfoSection.Box.Auth">
          <CreateAccount
            onComplete={() => null}
            guest={isGuestCheckoutSupported}
            context={{ drawer: false }}
          />
        </Box>
      )}
    </CheckoutSection>
  );
};

export default CheckoutContactInfoSection;
