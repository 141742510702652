import HeaderDrawer from 'components/header-drawer/header-drawer.component';
import EnvForm from './env-form/env-form.component';

const EnvDrawer = ({ isDrawerOpen, handleToggleDrawer }) => {
  return (
    <HeaderDrawer
      open={isDrawerOpen}
      onOpen={handleToggleDrawer}
      onClose={handleToggleDrawer}
      isLight
    >
      <EnvForm />
    </HeaderDrawer>
  );
};

export default EnvDrawer;
