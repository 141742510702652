import React from 'react';

import * as S from './form.styles';

const Form = ({ children, dataTestId = 'CustomForm', ...rest }) => (
  <S.CustomForm data-testid={dataTestId} {...rest}>
    {children}
  </S.CustomForm>
);

export default Form;
