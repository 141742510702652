import { useTheme } from '@material-ui/core';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import { useValideMinMax } from 'hooks/useMinMaxValidation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { scroller } from 'react-scroll';
import { selectItemsCount, selectSlug } from 'redux/cart/cart.selectors';
import {
  makeSelectIsMenuBrowsingStore,
  selectRequestedStore,
} from 'redux/locations/locations.selectors';

export const useCheckout = () => {
  const [sectionValidators, setSectionValidator] = useState({});
  const [validationState, setValidationState] = useState({});
  const [editingState, setEditingState] = useState({});

  const [isSpreedlyFormStep, setIsSpreedlyFormStep] = useState(false);

  const getSectionEditingState = useCallback(
    id => editingState[id],
    [editingState],
  );
  const setSectionEditingState = useCallback(
    (id, newState) => setEditingState(curr => ({ ...curr, [id]: newState })),
    [],
  );

  const setSectionValidation = useCallback(
    (sectionName, value) => {
      setSectionValidator(current => ({ ...current, [sectionName]: value }));
    },
    [setSectionValidator],
  );

  const theme = useTheme();
  const offset = useMemo(
    () => -parseInt(theme.navHeight.replace('px', '')),
    [theme.navHeight],
  );
  const scrollToSection = useCallback(
    id => {
      scroller.scrollTo(id, {
        offset,
        smooth: true,
      });
    },
    [offset],
  );

  const setSectionValidationState = useCallback((id, state) => {
    setValidationState(current => ({ ...current, [id]: state }));
  }, []);

  const clearSectionValidationStateById = useCallback(id => {
    setValidationState(current => ({ ...current, [id]: undefined }));
  }, []);

  const getSectionValidationState = useCallback(
    id => validationState[id],
    [validationState],
  );

  const validateSection = useCallback(
    async (id, contextData = {}) => {
      if (!!sectionValidators[id]) {
        const result = await sectionValidators[id](contextData);
        setSectionValidationState(id, result);
        return result;
      }
    },
    [sectionValidators, setSectionValidationState],
  );

  const validateCheckoutSections = useCallback(
    async rest => {
      let first = true;
      Object.entries(editingState).forEach(([id, value]) => {
        if (!!value) {
          setSectionValidationState(id, { message: 'Save or Cancel' });
          first && scrollToSection(id);
          first = false;
        }
      });

      if (!first) return first;

      await Promise.all(
        Object.keys(sectionValidators).map(async id => {
          if (!!(await validateSection(id, rest))) {
            first && scrollToSection(id);
            first = false;
          }
        }),
      );

      return first;
    },
    [
      editingState,
      scrollToSection,
      sectionValidators,
      setSectionValidationState,
      validateSection,
    ],
  );
  const {
    open: offersModalOpen,
    handleClose: closeOffersModal,
    handleOpen: openOffersModal,
  } = useCustomDialog();

  return useMemo(
    () => ({
      setSectionValidation,
      validateCheckoutSections,
      validateSection,
      getSectionValidationState,
      setSectionValidationState,
      clearSectionValidationStateById,
      validationState,
      getSectionEditingState,
      setSectionEditingState,
      scrollToSection,
      offersModalOpen,
      closeOffersModal,
      openOffersModal,
      isSpreedlyFormStep,
      setIsSpreedlyFormStep,
    }),
    [
      setSectionValidation,
      validateCheckoutSections,
      validateSection,
      getSectionValidationState,
      setSectionValidationState,
      clearSectionValidationStateById,
      validationState,
      getSectionEditingState,
      setSectionEditingState,
      scrollToSection,
      offersModalOpen,
      closeOffersModal,
      openOffersModal,
      isSpreedlyFormStep,
      setIsSpreedlyFormStep,
    ],
  );
};

export const useMinMaxValidation = isLoading => {
  const history = useHistory();
  const storeSlug = useSelector(selectSlug);
  const { isMinMaxError } = useValideMinMax();

  useEffect(() => {
    if (isLoading) return;

    if (isMinMaxError()) history.push(`/locations/${storeSlug}/menu`);
  }, [history, storeSlug, isLoading, isMinMaxError]);
};

export const useItemCountValidation = () => {
  const itemsCount = useSelector(selectItemsCount);
  const history = useHistory();
  const storeSlug = useSelector(selectSlug);

  useEffect(() => {
    if (itemsCount === 0) history.push(`/locations/${storeSlug}/menu`);
  }, [history, itemsCount, storeSlug]);
};

export const useIsAdditionalLinkEnabled = () => {
  const history = useHistory();
  const store = useSelector(selectRequestedStore);
  const storeSlug = useSelector(selectSlug);

  useEffect(() => {
    if (store?.externalOrderingEnabled) {
      history.push(`/locations/${storeSlug}`);
    }
  }, [history, store?.externalOrderingEnabled, storeSlug]);
};

export const useIsMenuBrowsingEnabled = () => {
  const history = useHistory();
  const storeSlug = useSelector(selectSlug);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());

  useEffect(() => {
    if (isMenuBrowsingStore) history.push(`/locations/${storeSlug}`);
  }, [history, isMenuBrowsingStore, storeSlug]);
};
