import { PaymentGatewayType } from 'components/payment/payment.constants';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import OrderPaymentSelector from '../order-payment-selector.component';
import IncentivioOrderPaymentSelector from './incentivio-order-payment-selector/incentivio-order-payment-selector.component';

const OrderPaymentSelectorWrapper = () => {
  const matchesPaymentGateway = usePaymentGatewayMatches();

  if (
    matchesPaymentGateway([
      PaymentGatewayType.SPREEDLY,
      PaymentGatewayType.CLOVER,
      PaymentGatewayType.SQUARE,
    ])
  )
    return <OrderPaymentSelector />;

  return <IncentivioOrderPaymentSelector />;
};

export default OrderPaymentSelectorWrapper;
