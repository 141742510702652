import * as S from './thank-you.styles';
import { getDisplayStoreAddress } from '../locations/locations.utils';
import IncButton from 'components/inc-button/inc-button.component';
import InfoView from './info-view/info-view.component';
import TrackOrder from 'components/track-order/track-order.component';
import { useTranslation } from 'react-i18next';
import { useThankYou } from './thank-you.hooks';
import { Box } from '@material-ui/core';
import PickupLocation from './pickup-location/pickup-location.component';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import { BoldDescription } from './info-view/info-view.styles';
import { useSelector } from 'react-redux';
import { selectRequestedAddress } from 'redux/cart/cart.selectors';
import { getFormattedAddress } from 'utils';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const ThankYouPage = () => {
  const { t } = useTranslation();
  const { showOrderNumber, orderInfo, pickupLabel, deliveryLabel, handleDone } =
    useThankYou();
  const title = usePageTitle(t('head.title.thankYou'));

  const {
    orderNumber,
    firstName,
    orderType,
    trackingUrl,
    storeTitle,
    deliveryInstructions,
    orderOption,
    orderInstructions,
    thankYouMessage,
    storeAddress,
    fulfillDateTime,
  } = orderInfo;

  const formattedFulfillDateTime = fulfillDateTime.toLocaleString({
    weekday: 'long',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const deliveryAddress = useSelector(selectRequestedAddress);

  return (
    <S.CustomContainer maxWidth="md" data-testid="ThankYouPage.CustomContainer">
      <Helmet title={title} />
      <S.Body>
        <S.Title>{t('checkout.thankYouPage.title')}</S.Title>
        <S.Description>
          {t('checkout.thankYouPage.description', { firstName })}
        </S.Description>
        <S.ReviewBox maxWidth="450px">
          {showOrderNumber && orderNumber && (
            <InfoView
              title={t('checkout.thankYouPage.orderIdLabel')}
              description={<BoldDescription>{orderNumber}</BoldDescription>}
            />
          )}

          {orderType === 'PICKUP' ? (
            <InfoView
              title={t('checkout.thankYouPage.timeLabel.pickup', {
                pickupLabel,
              })}
              description={
                <BoldDescription>{formattedFulfillDateTime}</BoldDescription>
              }
            />
          ) : (
            <InfoView
              title={t('checkout.thankYouPage.timeLabel.delivery', {
                deliveryLabel,
              })}
              description={
                <BoldDescription>{formattedFulfillDateTime}</BoldDescription>
              }
            />
          )}

          {orderType === 'PICKUP' ? (
            <PickupLocation
              title={t('checkout.thankYouPage.location.pickup', {
                pickupLabel,
              })}
              storeTitle={storeTitle}
              storeAddress={getDisplayStoreAddress(storeAddress)}
            />
          ) : (
            <InfoView
              title={t('checkout.thankYouPage.location.delivery', {
                deliveryLabel,
              })}
              description={getFormattedAddress(deliveryAddress)}
            />
          )}

          {orderOption?.instructions && (
            <InfoView
              title={t('checkout.thankYouPage.instructions')}
              description={
                <LinkifyParse>{orderOption.instructions}</LinkifyParse>
              }
            />
          )}

          {orderOption?.informationFieldInput && (
            <InfoView
              title={orderOption?.informationField}
              description={orderOption?.informationFieldInput}
            />
          )}

          {deliveryInstructions && (
            <InfoView
              title={t('checkout.deliveryInstructions.title')}
              description={deliveryInstructions}
            />
          )}

          {orderInstructions && (
            <InfoView
              title={t('checkout.orderInstructions.title')}
              description={orderInstructions}
            />
          )}
        </S.ReviewBox>
      </S.Body>
      <Box>
        {thankYouMessage && (
          <Box marginTop="2em">
            {thankYouMessage.map((line, index) => (
              <S.ThankYouMessage key={index}>{line}</S.ThankYouMessage>
            ))}
          </Box>
        )}

        <Box maxWidth="400px" m="auto" paddingBottom="1.5em" paddingTop="1.5em">
          <TrackOrder trackingUrl={trackingUrl} />
        </Box>

        <Box maxWidth="400px" m="auto">
          <IncButton
            onClick={handleDone}
            data-testid="ThankYouPage.DoneBtn"
            fullWidth
          >
            {t('checkout.thankYouPage.doneAction')}
          </IncButton>
        </Box>
      </Box>
    </S.CustomContainer>
  );
};

export default ThankYouPage;
