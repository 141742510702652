import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import App from './App';
import './i18n';
import './index.css';
import { store } from './redux/store';
import setupLogrocket from 'setupLogrocket';
import ErrorBoundary from 'components/error-boundary/error-boundary.component';
import { install } from 'resize-observer';

if (!window.ResizeObserver) install();

setupLogrocket();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary name="index">
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

if (window.Cypress) {
  window.store = store;
  window.i18next = i18next;
  window.toast = toast;
}
