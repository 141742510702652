import {
  getResponseErrorCode,
  getResponseErrorMessage,
} from 'apis/incentivio-api.util';
import { Buffer } from 'buffer';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useScript from 'react-script-hook';
import { toast } from 'react-toastify';
import { selectApplePayId } from 'redux/config/config.selectors';
import { selectRequestedStoreDITitle } from 'redux/locations/locations.selectors';
import { getApplePaySession } from 'util/getApplePaySession';
import location from 'util/location';
import { v4 as uuidv4 } from 'uuid';
import { PaymentType } from '../../payment.constants';
import { NewPaymentContext } from '../../payment.context';
import {
  initOrder,
  useInitGiftCardBody,
  useInitOrderBody,
  useIsApplePayEnabled,
  useMakePayment,
  useMakePaymentBody,
} from '../../payment.new.hooks';
import { getEnv } from 'util/getEnv';

export const useCanMakePayments = () => {
  const merchantId = useSelector(selectApplePayId);

  return async () =>
    getApplePaySession()
      ? await getApplePaySession().canMakePayments(merchantId)
      : false;
};

export const useCanMakePaymentsWithActiveCard = () => {
  const merchantId = useSelector(selectApplePayId);

  return async () =>
    getApplePaySession()
      ? await getApplePaySession().canMakePaymentsWithActiveCard(merchantId)
      : false;
};

export const makeApplicationData = puid =>
  Buffer.from(JSON.stringify({ puid })).toString('base64');

export const useApplePayPaymentRequest = () => {
  const { initOrderResponse, amount, walletLabel } =
    useContext(NewPaymentContext);

  return useCallback(
    puid =>
      !!initOrderResponse
        ? {
            countryCode: initOrderResponse.attributes.countrycode,
            currencyCode: initOrderResponse.attributes.currencycode,
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            total: {
              label: walletLabel,
              type: 'final',
              amount: amount / 1000,
            },
            applicationData: makeApplicationData(puid),
          }
        : {},
    [amount, initOrderResponse, walletLabel],
  );
};

export const useApplePaySession = () => {
  const createPaymentRequest = useApplePayPaymentRequest();
  const restaurantName = useSelector(selectRequestedStoreDITitle);
  const { type } = useContext(NewPaymentContext);
  const history = useHistory();
  const orderInitOrderBody = useInitOrderBody();
  const giftCardInitOrderBody = useInitGiftCardBody();
  const { trigger: triggerMakePayment } = useMakePayment(useMakePaymentBody());

  return useCallback(() => {
    let session, paymentRequest;
    try {
      const puid = uuidv4();
      paymentRequest = createPaymentRequest(puid);
      session = new (getApplePaySession())(3, paymentRequest);

      session.onvalidatemerchant = async _event => {
        try {
          const initOrderBody = {
            ...(type === PaymentType.ORDER
              ? orderInitOrderBody
              : giftCardInitOrderBody),
            attributes: {
              VALIDATION_URL: getEnv(
                'REACT_APP_TOAST_APPLE_PAY_VALIDATION_URL',
              ),
              MERCHANT_DOMAIN: location.getLocationHostname(),
              RESTAURANT_NAME: restaurantName,
            },
          };

          const response = await initOrder(initOrderBody);
          session.completeMerchantValidation(
            JSON.parse(response.data.attributes.APPLE_PAY_SESSION_RESPONSE),
          );
        } catch (err) {
          if (getResponseErrorCode(err)) {
            toast.error(getResponseErrorMessage(err));
          }
          toast.error(err.message);
        }
      };

      session.onpaymentauthorized = async event => {
        try {
          const response = await triggerMakePayment({
            paymentToken: convertPaymentToByteArray(event.payment),
            additionalAttributes: {
              APPLICATION_DATA: paymentRequest.applicationData,
              PUID: puid,
            },
          });
          session.completePayment({
            status: getApplePaySession().STATUS_SUCCESS,
          });
          history.push('/checkout/thankyou', response.data);
        } catch (err) {
          toast.error(getResponseErrorMessage(err));
          session.completePayment({
            status: getApplePaySession().STATUS_FAILURE,
            errors: [new window.ApplePayError('unknown')],
          });
        }
      };
    } catch (err) {
      toast.error(err.message);
    }

    return session;
  }, [
    createPaymentRequest,
    giftCardInitOrderBody,
    history,
    orderInitOrderBody,
    restaurantName,
    triggerMakePayment,
    type,
  ]);
};

export const useInitApplePay = () => {
  const { setWalletsLoading, walletsLoading } = useContext(NewPaymentContext);
  const isApplePayEnabled = useIsApplePayEnabled();

  const [loading, error] = useScript({
    src: isApplePayEnabled
      ? 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
      : null,
  });

  useEffect(() => {
    setWalletsLoading?.(loading);
  }, [loading, setWalletsLoading]);

  return [walletsLoading, error];
};

export const useApplePay = () => {
  const [loading, scriptError] = useInitApplePay();
  const isApplePayEnabled = useIsApplePayEnabled();
  const createApplePaySession = useApplePaySession();
  const canMakePayments = useCanMakePayments();

  return useMemo(
    () => ({
      createApplePaySession,
      canMakePayments,
      loading,
      scriptError,
      enabled: isApplePayEnabled,
    }),
    [
      canMakePayments,
      createApplePaySession,
      isApplePayEnabled,
      loading,
      scriptError,
    ],
  );
};

const convertPaymentToByteArray = payment =>
  Buffer.from(JSON.stringify(payment)).toString('base64');
