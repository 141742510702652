import React, { useContext, useEffect, useMemo } from 'react';
import FlowContext from '../flow.context';
import { Provider } from './step.context';
import * as S from './step.styles';

const Step = ({
  name,
  initial = false,
  editable = true,
  children,
  testId = 'Step.Container',
}) => {
  const { steps, addStep, removeStep } = useContext(FlowContext);
  const step = useMemo(
    () => ({
      initial,
      editable,
    }),
    [initial, editable],
  );

  useEffect(() => {
    if (!steps[name]) {
      addStep(name, step);
    }

    return () => removeStep(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider value={{ name, step }}>
      <S.StepContainer data-testid={testId}>{children}</S.StepContainer>
    </Provider>
  );
};

export default Step;
