import { CustomSpinner } from 'components/spinner/spinner.styles';
import * as S from './clover-spinner.styles';

const CloverSpinner = () => {
  return (
    <S.Container>
      <CustomSpinner data-testid="CloverForm.Loader" />
    </S.Container>
  );
};

export default CloverSpinner;
