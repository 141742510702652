import * as S from './verify-sms-header.styles';

const verifySmsHeader = ({ title }) => {
  return (
    <S.ContentHeader variant="h5" aria-label={title}>
      {title}
    </S.ContentHeader>
  );
};

export default verifySmsHeader;
