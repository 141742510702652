import { useOrderOptions } from 'components/new-order/order-options/useOrderOptions';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFieldNames } from '../../../components/new-order/useFieldNames.hooks';

export const useStoreOrderOptions = (store, orderType) => {
  const { watch, clearErrors } = useFormContext();
  const { fieldNames } = useFieldNames();
  const orderOption = watch(fieldNames.orderOption);

  useEffect(() => {
    clearErrors(fieldNames.informationField);
  }, [clearErrors, fieldNames.informationField, orderOption]);

  const { orderOptions, defaultOrderOption, skip } = useOrderOptions(
    store,
    orderType,
  );

  return { orderOptions, defaultOrderOption, orderOption, skip };
};
