import { Box, Grid } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import {
  getExceptionMessage,
  usePurchaseGiftCard,
} from 'components/payment/incentivio/incentivio-payments.hooks';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import WalletButton from 'components/payment/wallet-button/wallet-button.component';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchPaymentInstrumentsStartAsync } from 'redux/user/user.actions';
import { selectPaymentInstrumentById } from 'redux/user/user.selectors';

const NewButtons = ({
  validate,
  handleClose,
  submitDisabled,
  submitLabel,
  selection,
  cardIdentifier,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedPaymentOption } = usePaymentOptions();
  const showWalletButton = [
    PaymentOptionType.APPLE_PAY,
    PaymentOptionType.GOOGLE_PAY,
  ].includes(selectedPaymentOption);

  const { trigger, loading } = usePurchaseGiftCard();

  const paymentInstrumentSelector = useCallback(
    state => selectPaymentInstrumentById(selection)(state),
    [selection],
  );
  const paymentInstrument = useSelector(paymentInstrumentSelector);

  const submitCardPayment = async () => {
    try {
      await validate();
      await trigger(paymentInstrument.paymentToken, cardIdentifier);
      dispatch(fetchPaymentInstrumentsStartAsync());
      handleClose();
    } catch (e) {
      toast.error(getExceptionMessage(e));
    }
  };

  return (
    <Box mt={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <IncButtonDark
            onClick={handleClose}
            data-testid="GiftCardAddValue.CustomButton.Cancel"
            fullWidth
          >
            {t('cardItem.cancelBtn')}
          </IncButtonDark>
        </Grid>
        <Grid item xs={6}>
          {showWalletButton ? (
            <WalletButton validate={validate} />
          ) : (
            <IncButton
              type="button"
              className="inlineSubmitButton"
              data-testid="GiftCardAddValue.CustomButton.PayNow"
              fullWidth
              disabled={submitDisabled || loading}
              onClick={submitCardPayment}
            >
              {submitLabel ?? t('cardItem.payNowBtn')}
            </IncButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewButtons;
