import { Box, Typography } from '@material-ui/core';
import QRCode from 'components/qr-code/qr-code.component';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import Spinner from '../../../components/spinner/spinner.component';
import { getGiftCardByToken } from '../../../redux/user/user.actions';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
} from '../giftcard.styles';
import AnonBody from './anon-body/anon-body.component';
import LoggedInBody from './logged-in-body/logged-in-body.component';
import { selectIsHideQrCode } from 'redux/config/config.selectors';
import GiftCardDetails from './giftcard-details/giftcard-details.component';

const GiftcardViewPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [giftcard, setGiftcard] = useState({});
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hideQrCode = useSelector(selectIsHideQrCode);

  useEffect(() => {
    dispatch(getGiftCardByToken(token)).then(response => {
      setIsLoading(false);
      response?.data && setGiftcard(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <ContentContainer
        elevation={2}
        data-testid={'GiftcardViewPage.ContentContainer'}
      >
        <ContentHeader component="h1" variant="h5">
          {t('eGiftCard.view.header')}
        </ContentHeader>
        <ContentBody>
          {!!giftcard?.cardIdentifier ? (
            <>
              <GiftCardDetails giftcard={giftcard} />
              {!hideQrCode && (
                <Box mb={1}>
                  <QRCode
                    value={giftcard?.cardIdentifier}
                    data-testid="GiftcardView.QRCode"
                  />
                </Box>
              )}
              {isLoggedIn ? <LoggedInBody giftcard={giftcard} /> : <AnonBody />}
            </>
          ) : (
            !isLoading && (
              <Typography variant="h5" component="p">
                {t('eGiftCard.cardNotFound')}
              </Typography>
            )
          )}
        </ContentBody>
      </ContentContainer>
    </>
  );
};

export default GiftcardViewPage;
