import { Box, Typography } from '@material-ui/core';
import React from 'react';

const AvailabilityErrors = ({ error }) => {
  return (
    <Box position="relative" minHeight="200px">
      <Typography>{error}</Typography>
    </Box>
  );
};

export default AvailabilityErrors;
