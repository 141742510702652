import { Box } from '@material-ui/core';
import CardSelect from '../../card-select/card-select.component';
import { CreditCard } from '@material-ui/icons';

const CreditCardPanelCardSelect = ({ cards, selectedCard, onChangeCard }) => {
  if (cards.length === 0) return null;

  return (
    <Box mb={1}>
      <CardSelect
        cards={cards}
        value={selectedCard}
        onChange={onChangeCard}
        icon={<CreditCard fontSize="large" />}
      />
    </Box>
  );
};

export default CreditCardPanelCardSelect;
