import React from 'react';
import { ItemDetail } from '../menu-modal.styles';
import * as S from './item-description.styles';

const ItemDescription = ({
  title,
  shortDescription,
  longDescription,
  itemId,
}) => {
  return (
    <ItemDetail>
      <S.ItemName
        component="h2"
        data-testid="CatalogModal.ItemDetail.Title"
        id={`dialog-title-${itemId}`}
      >
        {title}
      </S.ItemName>
      {shortDescription && (
        <S.ItemDescription data-testid="CatalogModal.ItemDetail.ShortDescription">
          {shortDescription}
        </S.ItemDescription>
      )}
      {longDescription && (
        <S.ItemDescription data-testid="CatalogModal.ItemDetail.LongDescription">
          {longDescription}
        </S.ItemDescription>
      )}
    </ItemDetail>
  );
};

export default ItemDescription;
