import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomInput from '../custom-input/custom-input.component';
import FormError from '../form-error/form-error.component';
import Form from '../form/form.component';
import Spinner from '../spinner/spinner.component';
import * as S from './forgot-password.styles';
import IncButton from 'components/inc-button/inc-button.component';
import TrimEmailField from 'components/form/form-fields/trim-email-field/trim-email-field.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const ForgotPassword = ({ send, onSubmit }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitWrapper = async data => {
    setIsLoading(true);
    const res = await onSubmit(data);
    setIsLoading(false);
    res === 200 ? setSuccess(true) : setError(res);
  };

  if (success) {
    return (
      <S.BackToLogin data-testid="testSuccessfulRequest">
        <Typography>{t('forgotPassword.requestSuccess')}</Typography>
        <span data-testid="testLoginSpan" onClick={() => send('BACK')}>
          {t('forgotPassword.back')}
        </span>
      </S.BackToLogin>
    );
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmitWrapper)}>
        <MandatoryFieldsInstruction />
        <S.Title>{t('forgotPassword.title')}</S.Title>
        {isLoading && <Spinner />}
        <CustomInput>
          <InputLabel>{t('signIn.label.email')}*</InputLabel>
          <TrimEmailField
            fullWidth
            inputProps={{
              'data-testid': 'email',
              autocomplete: 'email',
              'aria-required': true,
            }}
            placeholder={t('forgotPassword.placeholder.email')}
            error={!!errors.email}
            {...register('email', {
              required: t('forgotPassword.errors.email'),
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('forms.errors.email.invalid'),
              },
            })}
          />
          {errors.email && <FormError errorMessage={errors.email.message} />}
        </CustomInput>
        {error && <FormError errorMessage={error} />}
        <IncButton type="submit" fullWidth>
          {t('forgotPassword.label.submit')}
        </IncButton>
      </Form>
    </FormProvider>
  );
};

export default ForgotPassword;
