import { selectLocationId, selectOrderType } from 'redux/cart/cart.selectors';
import { CATERING } from 'util/constants';

export const storeIdPropSelector = (state, storeId) =>
  storeId ?? selectLocationId(state);

export const orderTypePropSelector = (state, _storeId, orderType) =>
  orderType === CATERING
    ? selectOrderType(state)
    : orderType ?? selectOrderType(state);
