import { ErrorMessage } from '@hookform/error-message';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const InformationField = ({ selectedOption = {}, name, defaultValue = '' }) => {
  const { control } = useFormContext();
  const { informationField, errorMessageIfInformationFieldIsEmpty } =
    selectedOption;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: errorMessageIfInformationFieldIsEmpty,
        validate: value =>
          !value?.trim() ? errorMessageIfInformationFieldIsEmpty : true,
      }}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <CustomInput>
          <InputLabel htmlFor={name} data-testid="InformationField.InputLabel">
            {informationField}
            {errorMessageIfInformationFieldIsEmpty ? '*' : ''}
          </InputLabel>
          <TrimTextField
            fullWidth
            inputProps={{
              'data-testid': 'InformationField.Input',
              'aria-required': true,
            }}
            error={!!errors[name]}
            id={name}
            {...field}
          />
          <ErrorMessage
            name={name}
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>
      )}
    />
  );
};

export default InformationField;
