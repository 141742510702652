import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
`;

export const TopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 12px;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 1.2rem;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
