import { Box } from '@material-ui/core';
import ItemAndModierDiscounts from 'components/item-and-modifier-discounts/item-and-modifier-discounts.component';
import React from 'react';
import { convertCentsToDollar } from 'utils';
import * as S from './order-item-options-list.styles';

const OrderItemOptionsList = ({ options, spacing = 0 }) => {
  return (
    <S.OptionContainer>
      {options.map((option, i) => {
        const {
          itemId,
          displayInfo: { title },
          quantity,
          itemDiscounts,
          basePrice,
        } = option;

        return (
          <S.OptionDetailContainer key={`options-list-${itemId}-${i}`}>
            <S.OptionDetail
              spacing={spacing}
              data-testid="OrderItemOptionsList.OptionDetail"
            >
              <Box display="inline" mr="3px">
                {quantity > 1 && `(${quantity}x) `}
                {title}
              </Box>
              {Number(basePrice) > 0 ? (
                <span>(+{convertCentsToDollar(basePrice)})</span>
              ) : null}
            </S.OptionDetail>
            {itemDiscounts?.length > 0 && (
              <ItemAndModierDiscounts
                itemDiscounts={itemDiscounts}
                dataTestId="Modifier.Discount"
                spacing={spacing + 10}
              />
            )}
            {option.options?.length > 0 && (
              <OrderItemOptionsList
                options={option.options}
                spacing={spacing + 10}
              />
            )}
          </S.OptionDetailContainer>
        );
      })}
    </S.OptionContainer>
  );
};

export default OrderItemOptionsList;
