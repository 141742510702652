import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DELIVERY } from 'util/constants';

export const useSetAddressForDelivery = (orderType, address) => {
  const { fieldNames } = useFieldNames();
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (orderType === DELIVERY && !getValues(fieldNames.address))
      setValue(fieldNames.address, address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, fieldNames.address, getValues, setValue]);
};

export default useSetAddressForDelivery;
