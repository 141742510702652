import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const ModalDescription = styled(Typography)`
  text-align: center;
  margin-bottom: 1em;
`;

export const YourNumber = styled(Typography)`
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  span {
    display: block;
  }
`;
