import { useState } from 'react';
import useScript from 'react-script-hook';
import { getEnv } from 'util/getEnv';
import CloverFormContext from './clover-form.context';

const CloverFormWrapper = ({
  children,
  addNewCardLoading = false,
  cloverScriptSrc,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [loading, error] = useScript({
    src: cloverScriptSrc ?? getEnv('REACT_APP_CLOVER_URL'),
  });

  return (
    <CloverFormContext.Provider
      value={{
        showLoading: showLoading || loading || addNewCardLoading,
        setShowLoading,
        scriptError: error,
        scriptLoading: loading,
      }}
    >
      {children}
    </CloverFormContext.Provider>
  );
};

export default CloverFormWrapper;
