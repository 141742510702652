import { Typography } from '@material-ui/core';
import { IncButtonLight } from 'components/inc-button/inc-button.styles';
import styled from 'styled-components';

export const LandingTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
`;

export const LandingLinkButton = styled(IncButtonLight)`
  margin: 0.5em 0;
`;
