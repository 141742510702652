const LocationsActionTypes = {
  FETCH_LOCATIONS_START: 'FETCH_LOCATIONS_START',
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAILURE: 'FETCH_LOCATIONS_FAILURE',
  FETCH_CATERING_LOCATIONS_START: 'FETCH_CATERING_LOCATIONS_START',
  FETCH_SINGLE_LOCATION_START: 'FETCH_SINGLE_LOCATION_START',
  FETCH_SINGLE_LOCATION_SUCCESS: 'FETCH_SINGLE_LOCATION_SUCCESS',
  FETCH_SINGLE_LOCATION_FAILURE: 'FETCH_SINGLE_LOCATION_FAILURE',
  FETCH_CATERING_LOCATIONS_SUCCESS: 'FETCH_CATERING_LOCATIONS_SUCCESS',
  FETCH_CATERING_LOCATIONS_FAILURE: 'FETCH_CATERING_LOCATIONS_FAILURE',
  FETCH_MENU_BROWSING_LOCATIONS_START: 'FETCH_MENU_BROWSING_LOCATIONS_START',
  FETCH_CATERING_MENU_BROWSING_LOCATIONS_START:
    'FETCH_CATERING_MENU_BROWSING_LOCATIONS_START',
  FETCH_MENU_BROWSING_LOCATIONS_SUCCESS:
    'FETCH_MENU_BROWSING_LOCATIONS_SUCCESS',
  FETCH_CATERING_MENU_BROWSING_LOCATIONS_SUCCESS:
    'FETCH_CATERING_MENU_BROWSING_LOCATIONS_SUCCESS',
  FETCH_MENU_BROWSING_LOCATIONS_FAILURE:
    'FETCH_MENU_BROWSING_LOCATIONS_FAILURE',
  FETCH_CATERING_MENU_BROWSING_LOCATIONS_FAILURE:
    'FETCH_CATERING_MENU_BROWSING_LOCATIONS_FAILURE',
  SET_REQUESTED_STORES: 'SET_REQUESTED_STORES',
};

export default LocationsActionTypes;
