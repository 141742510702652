import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStep } from '../step/step.hooks';
import * as S from './step-header.styles';

const StepHeader = ({ label, component = 'h2', variant = 'h6', className }) => {
  const { isEditable, isComplete, makeActive } = useStep();
  const { t } = useTranslation();

  return (
    <S.Container data-testid="StepHeader.Container" className={className}>
      <S.CustomTypography component={component} variant={variant}>
        <span>{label}</span>
        {isEditable && isComplete && (
          <S.CustomLink
            component="button"
            variant="subtitle1"
            onClick={makeActive}
            data-testid="StepHeader.CustomLink.Edit"
          >
            {t('flow.edit')}
          </S.CustomLink>
        )}
        {isComplete && <CheckIcon color="primary" />}
      </S.CustomTypography>
    </S.Container>
  );
};

export default StepHeader;
