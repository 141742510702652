import { Grid } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import { PaymentGatewayType } from 'components/payment/payment.constants';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import { useTranslation } from 'react-i18next';
import NewButtons from '../new-buttons/new-buttons.component';

const GiftcardAddValueButtons = ({
  handleClose,
  submitDisabled,
  submitLabel,
  validate,
  selection,
  cardIdentifier,
}) => {
  const { t } = useTranslation();
  const matchesPaymentGateway = usePaymentGatewayMatches();

  if (matchesPaymentGateway([PaymentGatewayType.INCENTIVIO_PAYMENTS]))
    return (
      <NewButtons
        validate={validate}
        handleClose={handleClose}
        submitDisabled={submitDisabled}
        submitLabel={submitLabel}
        selection={selection}
        cardIdentifier={cardIdentifier}
      />
    );
  else
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <IncButtonDark
            onClick={handleClose}
            data-testid="GiftCardAddValue.CustomButton.Cancel"
            fullWidth
          >
            {t('cardItem.cancelBtn')}
          </IncButtonDark>
        </Grid>
        <Grid item xs={6}>
          <IncButton
            type="submit"
            className="inlineSubmitButton"
            data-testid="GiftCardAddValue.CustomButton.PayNow"
            fullWidth
            disabled={submitDisabled}
          >
            {submitLabel ?? t('cardItem.payNowBtn')}
          </IncButton>
        </Grid>
      </Grid>
    );
};
export default GiftcardAddValueButtons;
