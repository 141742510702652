import { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SMS_CODE, SMS_LINK } from 'redux/config/config.constants';
import { selectUserAccountVerificationMethod } from 'redux/config/config.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

export const useOnLogout = effect => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const prevIsLoggedIn = useRef(isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn && !!prevIsLoggedIn.current) {
      effect();
    }
    prevIsLoggedIn.current = isLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
};

export const useVerificationMessage = () => {
  const { t } = useTranslation();
  const verificationMethod = useSelector(selectUserAccountVerificationMethod);

  const [state, setState] = useState({
    signupSuccess: false,
    verified: true,
    phoneNumber: '',
    previous: '',
    password: '',
    email: '',
  });

  const verificationMessage = useMemo(
    () =>
      verificationMethod === SMS_LINK
        ? t('signIn.smsVerificationLink.message')
        : t('signIn.emailVerificationLink.message'),
    [t, verificationMethod],
  );

  const label = useMemo(() => {
    if (state.previous === 'LOGIN') return t('verifyCode.genericLabel');

    if (verificationMethod === SMS_CODE)
      return t('verifyCode.smsCode.inputLabel', {
        lastFour: state.phoneNumber.substring(state.phoneNumber.length - 4),
      });

    return t('verifyCode.emailCode.inputLabel', { email: state.email });
  }, [state.email, state.phoneNumber, state.previous, t, verificationMethod]);

  return {
    verificationMessage,
    verificationMethod,
    label,
    state,
    setState,
  };
};
