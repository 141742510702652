import { useAddPaymentInstrument } from 'components/payment/payment.new.hooks';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import { useState } from 'react';
import CardPaymentModal from 'components/payment/card-payment-modal/card-payment-modal.component';
import CreditCardsController from '../../credit-cards-controller/credit-cards-controller.component';

const OldCreditCardPanel = ({ cards, selectedCard, onChangeCard }) => {
  const addPaymentInstrument = useAddPaymentInstrument();
  const { open, handleClose, handleOpen } = useCustomDialog();
  const [loading, setLoading] = useState(false);

  const handleNewCard = async cardInfo => {
    setLoading(true);
    const res = await addPaymentInstrument(cardInfo);
    if (!!res) {
      onChangeCard(res.paymentInstrumentId);
      handleClose();
    }
    setLoading(false);
  };

  return (
    <>
      <CreditCardsController
        cards={cards}
        selectedCard={selectedCard}
        onChangeCard={onChangeCard}
        handleAddNewCardClick={handleOpen}
      />

      {open && (
        <CardPaymentModal
          open
          handleClose={handleClose}
          handleCardInfo={handleNewCard}
          loading={loading}
        />
      )}
    </>
  );
};

export default OldCreditCardPanel;
