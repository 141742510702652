import CustomInput from 'components/custom-input/custom-input.component';
import React from 'react';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as S from './order-type-selector.styles';
import { useTranslation } from 'react-i18next';
import OrderTypeSelectorButtons from './order-type-selector-buttons/order-type-selector-buttons.component';

const OrderTypeSelector = ({ types, defaultValue, name }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  if (types?.length > 0) {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, name, ref } }) => (
          <CustomInput>
            <OrderTypeSelectorButtons
              types={types}
              as={S.OrderTypeToggleGroup}
              roverAs={S.OrderTypeToggleButton}
              value={value}
              exclusive
              onChange={(_e, newValue) => !!newValue && onChange(newValue)}
              name={name}
              id={name}
              data-testid="OrderTypeSelector.CircleToggleGroup"
              aria-label={t('orderTypeSelector.ariaLabel')}
              ref={ref}
            />
          </CustomInput>
        )}
      />
    );
  } else {
    return null;
  }
};

export default memo(OrderTypeSelector);
