import i18n from 'i18next';
import partition from 'lodash.partition';
import { DateTime } from 'luxon';

export const convertCentsToDollar = (centsPrice = 0) =>
  formatDollar(centsPrice / 1000);

export const formatDollar = (amount = 0) =>
  amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const toISOStringLocal = d => {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds())
  );
};

export const getLanguageIndex = () => {
  switch (i18n.language) {
    case 'en':
      return 0;
    case 'fr':
      return 1;
    case 'es':
      return 2;
    default:
      return 0;
  }
};

export const getLanguageKey = () => {
  switch (i18n.language) {
    case 'en':
    case 'fr':
    case 'es':
      return i18n.language;
    default:
      return 'en';
  }
};

export const getFormattedThankYouMessage = (
  text,
  orderDate,
  orderNumber,
  storeTitle,
) =>
  text
    ? text.map(item =>
        item
          .replace(
            '{Date}',
            orderDate.toLocaleString({ month: 'short', day: 'numeric' }),
          )
          .replace('{Time}', orderDate.toFormat('h:mm a'))
          .replace('{Store}', storeTitle)
          .replace('{OrderNumber}', orderNumber),
      )
    : null;

export const sortItemsByDisplayRank = items =>
  items ? [...items].sort((a, b) => a.displayRank - b.displayRank) : [];

export const getTranslationFromArray = (
  translationArray,
  localeIndex,
  fallback,
) => translationArray?.[localeIndex] ?? fallback;

export const getTranslationFromObject = (
  translationObject,
  locale,
  fallback,
) => {
  if (!translationObject || Object.keys(translationObject).length < 1)
    return fallback;
  return (
    translationObject[locale.toUpperCase()] ||
    translationObject[locale.toLowerCase()] ||
    fallback
  );
};

export const openWindow = (url, target, settings) => {
  window.open(url, target, settings);
};

export const filteredAndSortedGroups = array => {
  if (!!array) {
    const reducedArray = array.reduce((acc, current) => {
      const x = acc.find(item => item.groupId === current.groupId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return sortItemsByDisplayRank(reducedArray).filter(
      group => group.items.length > 0,
    );
  }
  return array;
};

const partitionModifiersLogic = group =>
  group.extendedAttributes.display_in_item_detail_screen?.toLowerCase() ===
    'true' ||
  (group.minItemSelections > 0 &&
    group.extendedAttributes.display_in_item_detail_screen?.toLowerCase() !==
      'false');

export const getOptionCategories = optionGroups =>
  partition(filteredAndSortedGroups(optionGroups), partitionModifiersLogic);

export const getFormattedAddressString = address => {
  let formattedAddress = '';
  if (address) {
    if (address.formatted_address) {
      return address.formatted_address;
    }
    const { street1, street2, city, country } = address;
    const street = `${street1}${street2 ? ` ${street2}` : ''}`;

    return [street, city, country].filter(field => !!field).join(', ');
  }

  return formattedAddress;
};

export const summation = num => (num * (num + 1)) / 2;

export const getFormattedAddress = address => {
  if (!address || Object.keys(address)?.length < 1) return '';
  const { street1, street2, aptSuite, city, state, postalCode } = address;
  const street = `${street1}${!!street2 ? ` ${street2}` : ''}`;
  let addressString = [street, aptSuite, city, state, postalCode]
    .filter(field => !!field)
    .join(', ');
  return addressString;
};

export const isExpired = isoString =>
  DateTime.local() > DateTime.fromISO(isoString, { zone: 'utc' });

export const getDateOfBirth = (birthMonth, birthDay) =>
  !!birthMonth && !!birthDay ? `1900-${birthMonth}-${birthDay}` : null;
