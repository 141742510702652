import ConfirmPassword from 'components/form/form-fields/confirm-password/confirm-password.component';
import Email from 'components/form/form-fields/email.component';
import FirstName from 'components/form/form-fields/first-name.component';
import LastName from 'components/form/form-fields/last-name.component';
import Password from 'components/form/form-fields/password/password.component';
import Form from 'components/form/form.component';
import InputPhone from 'components/input-phone/input-phone.component';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InfoAlert, SubmitButton } from '../sms-signup.styles';
import { Box } from '@material-ui/core';
import { useGetUserDetails, useMobileSignup } from '../sms-signup.hooks';
import Spinner from 'components/spinner/spinner.component';
import TocAndPrivacy from 'components/toc-and-privacy/toc-and-privacy.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const SmsSignupForm = () => {
  const { t } = useTranslation();
  const { data, loading: getUserDetailsLoading, error } = useGetUserDetails();
  const { mobileSignup } = useMobileSignup();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = formData => {
    mobileSignup(formData);
  };

  if (getUserDetailsLoading) return <Spinner />;

  return (
    <Box data-testid="SmsSignup.Container">
      <InfoAlert
        severity={!!error ? 'error' : 'info'}
        data-testid="SmsSignupForm.Alert"
      >
        {error ?? data?.message}
      </InfoAlert>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MandatoryFieldsInstruction />
          <FirstName
            register={register}
            errors={errors}
            defaultValue={data?.userAccountDTO?.firstName}
          />
          <LastName
            register={register}
            errors={errors}
            defaultValue={data?.userAccountDTO?.lastName}
          />
          <Email register={register} errors={errors} />
          <Password />
          <ConfirmPassword />
          <InputPhone
            defaultValue={data?.userAccountDTO?.phoneNumber}
            disabled
          />

          <SubmitButton
            type="submit"
            data-testid="SmsSignupForm.CustomButton.Submit"
            fullWidth
            disabled={isSubmitting}
          >
            {t('register.label.createAccount')}
          </SubmitButton>

          <TocAndPrivacy
            description={t('register.termsAndConditions.description')}
          />
        </Form>
      </FormProvider>
    </Box>
  );
};

export default SmsSignupForm;
