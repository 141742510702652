import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsOrderAvailabilityLoading,
  selectWithinDeliveryDistance,
  selectOrderAvailabilityError,
  selectAvailableDays,
} from 'redux/data/orderavailability/orderavailability.selectors';
import { useFieldNames } from '../../../components/new-order/useFieldNames.hooks';
import { useTranslation } from 'react-i18next';
import { useGetOrderType } from './useGetOrderType';
import { useFormContext } from 'react-hook-form';

export const useNewOrderForm = storeId => {
  const { t } = useTranslation();
  const { fieldNames } = useFieldNames();
  const { watch, control } = useFormContext();
  const orderType = useGetOrderType();
  const address = watch(fieldNames.address);
  const isOrderAvailabilityLoading = useSelector(
    selectIsOrderAvailabilityLoading,
  );
  const withinDeliveryDistance = useSelector(state =>
    selectWithinDeliveryDistance(state, storeId),
  );
  const reduxOrderAvailabilityError = useSelector(selectOrderAvailabilityError);
  const availableDays = useSelector(state =>
    selectAvailableDays(state, storeId, orderType),
  );

  const orderAvailabilityError = useMemo(() => {
    const deliveryDistanceMessage = t('errors.deliveryDistance');
    const orderingUnavailableMessage = t('errors.orderingUnavailable');

    if (orderType === 'DELIVERY') {
      if (
        withinDeliveryDistance === false &&
        !!address &&
        address !== 'new' &&
        address !== 'default'
      )
        return deliveryDistanceMessage;

      if (!!reduxOrderAvailabilityError) return reduxOrderAvailabilityError;
    }

    if (orderType === 'PICKUP' && !!reduxOrderAvailabilityError) {
      if (reduxOrderAvailabilityError === deliveryDistanceMessage) return null;

      return reduxOrderAvailabilityError;
    }

    if (availableDays?.length < 1) return orderingUnavailableMessage;
  }, [
    address,
    availableDays,
    orderType,
    reduxOrderAvailabilityError,
    t,
    withinDeliveryDistance,
  ]);

  return {
    fieldNames,
    orderType,
    control,
    isOrderAvailabilityLoading,
    address,
    orderAvailabilityError,
  };
};
