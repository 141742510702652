import SpreedlyContext from 'components/payment/spreedly/spreedly.context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import GiftCardSpreedlyPurchaseButton from './gift-card-spreedly-purchase-button/gift-card-spreedly-purchase-button.component';
import PurchaseButton from './purchase-button/purchase-button.component';

const GiftCardPurchaseButton = props => {
  const loggedIn = useSelector(selectIsLoggedIn);
  const spreedlyContext = useContext(SpreedlyContext);

  if (!loggedIn && !!spreedlyContext)
    return (
      <GiftCardSpreedlyPurchaseButton
        {...props}
        cardInfo={spreedlyContext.cardInfo}
      />
    );

  return <PurchaseButton {...props} />;
};

export default GiftCardPurchaseButton;
