import React from 'react';
import { Box } from '@material-ui/core';
import StoreTitle from 'components/store-title/store-title.component';
import StoreDistance from '../locations-card-overview/store-distance/store-distance.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';

const LocationsCardHeader = ({ store }) => {
  const { title } = useDisplayInfo(store.displayInfo);

  return (
    <Box display="flex" justifyContent="space-between">
      <StoreTitle name={title} component="h2" />
      <StoreDistance distanceToUserInMiles={store.distanceToUserInMiles} />
    </Box>
  );
};

export default LocationsCardHeader;
