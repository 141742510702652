export const CloverInstance = (() => {
  let instance;

  const createInstance = apikey => {
    return new window.Clover(apikey);
  };

  return {
    getInstance: apikey => {
      if (!instance) instance = createInstance(apikey);
      return instance;
    },
  };
})();
