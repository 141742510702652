import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import { useCallback, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useOptionItemRegister = args => {
  const { minItemSelections, name } = args;
  const { t } = useTranslation();
  const { register, getValues } = useFormContext();

  const validateMinSelections = useCallback(() => {
    const data = getValues(name);
    const minError = t(
      `catalog.modal.addToCart.modifierRequiredError_${
        minItemSelections === 1 ? 'one' : 'other'
      }`,
      {
        minItemSelections,
      },
    );

    if (!data) return true;

    const totalSelections = data.reduce(
      (total, item) => item.quantity + total,
      0,
    );

    if (totalSelections < minItemSelections) return minError;

    return true;
  }, [getValues, minItemSelections, name, t]);

  useEffect(() => {
    register(name, { value: [], validate: validateMinSelections });
  }, [name, register, validateMinSelections]);
};

export const useIsMaxItemSelectionsReached = (
  name,
  maxItemSelections = 0,
  minQuantity,
  itemQuantity = 0,
) => {
  const { getValues } = useFormContext();
  const values = getValues(name) ?? [];
  const currentTotalItemSelectedCount = values.reduce(
    (count, cur) => cur.quantity + count,
    0,
  );

  if (!maxItemSelections) return false;

  if (itemQuantity > 0)
    return currentTotalItemSelectedCount >= maxItemSelections;

  return currentTotalItemSelectedCount + minQuantity > maxItemSelections;
};

export const useUnregisterOnRemove = () => {
  const { getValues, unregister } = useFormContext();

  const unRegisterOnRemove = matcher => {
    Object.keys(getValues().options).forEach(key => {
      if (key.startsWith(`${matcher}-`)) {
        unregister(`options.${key}`);
      }
    });
  };

  return { unRegisterOnRemove };
};

export const useClearEditOptions = () => {
  const { editInfo, setEditInfo } = useContext(MenuDialogContext);

  const clearEditOptions = () => {
    if (!!editInfo) setEditInfo(info => ({ ...info, options: null }));
  };

  return clearEditOptions;
};
