import { PaymentGatewayType } from 'components/payment/payment.constants';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentInstrumentsStartAsync } from 'redux/user/user.actions';
import {
  selectIsLoading,
  selectIsLoggedIn,
  selectPaymentInstrumentsCards,
} from 'redux/user/user.selectors';
import SpreedlyGiftCardPaymentStep from './spreedly-giftcard-payment-step/spreedly-giftcard-payment-step.component';
import IncentivioGiftCardPaymentStep from './incentivio-giftcard-payment-step/incentivio-giftcard-payment-step.component';
import CloverGiftCardPaymentStep from './clover-giftcard-payment-step/clover-giftcard-payment-step.component';
import SquareGiftCardPaymentStep from './square-giftcard-payment-step/square-giftcard-payment-step.component';

const GiftcardPaymentStep = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const cards = useSelector(selectPaymentInstrumentsCards);
  const isLoading = useSelector(selectIsLoading);

  const [selectedCard, setSelectedCard] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchPaymentInstrumentsStartAsync()).then(res => {
        if (res) {
          const paymentInstruments = res.data?.paymentInstruments
            ? res.data.paymentInstruments.filter(
                ({ paymentType }) => paymentType === 'Card',
              )
            : null;
          if (paymentInstruments.length > 0) {
            const defaultPayment = paymentInstruments.find(
              ({ isDefault }) => isDefault,
            );
            setSelectedCard(
              defaultPayment?.paymentInstrumentId ??
                paymentInstruments[0].paymentInstrumentId,
            );
          }
        }
      });
    }
  }, [dispatch, isLoggedIn]);

  const handlePaymentSelection = paymentData => {
    setSelectedCard(paymentData);
  };

  const matchesPaymentGateway = usePaymentGatewayMatches();

  if (matchesPaymentGateway([PaymentGatewayType.INCENTIVIO_PAYMENTS]))
    return (
      <IncentivioGiftCardPaymentStep
        cards={cards}
        handleSelection={handlePaymentSelection}
        selection={selectedCard}
        isLoading={isLoading}
      />
    );

  if (matchesPaymentGateway([PaymentGatewayType.SPREEDLY]))
    return (
      <SpreedlyGiftCardPaymentStep
        cards={cards}
        handleSelection={handlePaymentSelection}
        selection={selectedCard}
        isLoading={isLoading}
      />
    );

  if (matchesPaymentGateway([PaymentGatewayType.CLOVER]))
    return (
      <CloverGiftCardPaymentStep
        cards={cards}
        handleSelection={handlePaymentSelection}
        selection={selectedCard}
        isLoading={isLoading}
      />
    );

  if (matchesPaymentGateway([PaymentGatewayType.SQUARE]))
    return (
      <SquareGiftCardPaymentStep
        cards={cards}
        handleSelection={handlePaymentSelection}
        selection={selectedCard}
        isLoading={isLoading}
      />
    );

  return null;
};

export default GiftcardPaymentStep;
