import EnvDrawer from 'components/env-config/env-drawer/env-drawer.component';
import HeaderTooltipIcon from 'components/header/header-togglers/header-tooltip-icon/header-tooltip-icon.component';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloudIcon from '@material-ui/icons/Cloud';

const EnvConfig = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <HeaderTooltipIcon
        tooltipText={t('header.envConfig.icon.tooltipText')}
        onClick={handleToggleDrawer}
        icon={
          <CloudIcon
            htmlColor={!!sessionStorage.getItem('env') ? 'blue' : undefined}
          />
        }
        data-testid="EnvConfig.HeaderTooltipIcon"
      />

      <EnvDrawer
        isDrawerOpen={isDrawerOpen}
        handleToggleDrawer={handleToggleDrawer}
      />
    </>
  );
};

export default EnvConfig;
