import styled from 'styled-components';

export const DescriptionOne = styled.div`
  margin-bottom: 10px;
`;

export const DescriptionTwo = styled.div`
  font-style: italic;
  margin-bottom: 10px;
`;
