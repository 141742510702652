import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

export const IncButtonDark = styled(IncButton)`
  color: #252525;
  background-color: transparent;
  border: 1px #252525 solid;

  :hover {
    background-color: #252525;
    color: white;
  }

  ${({ theme }) =>
    theme.palette.type === 'dark' &&
    `
    color: ${theme.palette.text.primary};
    border-color: ${theme.palette.text.primary};

    :hover {
      background-color: ${theme.palette.text.primary};
      color: black;
    }
  `}
`;

export const IncButtonLight = styled(IncButton)`
  color: #252525;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  :hover {
    background-color: white;
  }
`;

export const IncButtonDarkIcon = styled(IncButtonDark)`
  .MuiSvgIcon-root {
    margin-left: 0.2em;
  }
`;

export const SubmitButton = styled(IncButton)`
  margin: 2em 0;
`;
