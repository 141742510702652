import { Box, Divider } from '@material-ui/core';
import { IncButtonLight } from 'components/inc-button/inc-button.styles';
import SummaryItem from 'components/summary-item/summary-item.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectOrderItems, selectSlug } from 'redux/cart/cart.selectors';
import * as S from './checkout-items-section.styles';
import CheckoutSection from '../checkout-section/checkout-section.component';

const CheckoutItemsSection = ({ ...rest }) => {
  const { t } = useTranslation();
  const items = useSelector(selectOrderItems);
  const history = useHistory();
  const storeSlug = useSelector(selectSlug);
  const handleAddItems = () => {
    history.push(`/locations/${storeSlug}/menu`);
  };

  return items?.length ? (
    <CheckoutSection
      title={t('checkout.items.title')}
      data-testid="CheckoutItemsSection.CheckoutSection"
      {...rest}
    >
      <S.ItemsContainer>
        {items.map((item, i) => (
          <Box key={`overview-${item.orderItemId}`}>
            <Box mb={1}>
              <SummaryItem item={item} />
            </Box>
            {i !== items?.length - 1 && (
              <Box mb={1}>
                <Divider />
              </Box>
            )}
          </Box>
        ))}
      </S.ItemsContainer>
      <Box mt={[2, 2, 1]}>
        <IncButtonLight
          fullWidth
          onClick={handleAddItems}
          data-testid="CheckoutItemsSection.IncButtonLight.AddMore"
        >
          {t('checkout.items.addMoreItems')}
        </IncButtonLight>
      </Box>
    </CheckoutSection>
  ) : null;
};

export default CheckoutItemsSection;
