import React from 'react';
import { useStep } from '../step/step.hooks';
import * as S from './step-body.styles';

const StepBody = ({ children }) => {
  const { isActive } = useStep();
  return isActive ? <S.Container>{children}</S.Container> : null;
};

export default StepBody;
