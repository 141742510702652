import { useGetDisplayInfo } from 'hooks/useDisplayInfo';
import { useEffect } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeSelectMenuGroups,
  selectMenuCatalogs,
} from 'redux/catalogs/catalogs.selectors';
import { selectLogo } from 'redux/config/config.selectors';

export const makeCatalogTab = (catalog, catalogDisplayInfo) => ({
  imageSrc: catalogDisplayInfo.smallImage?.[0],
  label: catalogDisplayInfo.title,
  catalogId: catalog.catalogId,
});

const findCatalog = (catalogs, id) =>
  catalogs?.find(catalog => catalog.catalogId === id);

export const useCatalogTabs = () => {
  const catalogsArray = useSelector(selectMenuCatalogs);
  const getDisplayInfo = useGetDisplayInfo();
  const [tabValue, setTabValue] = useState(catalogsArray?.[0]?.catalogId);
  const fallbackImage = useSelector(selectLogo);

  useEffect(() => {
    if (!findCatalog(catalogsArray, tabValue))
      setTabValue(catalogsArray?.[0]?.catalogId);
  }, [catalogsArray, tabValue]);

  const tabs = catalogsArray.map(catalog =>
    makeCatalogTab(catalog, getDisplayInfo(catalog.displayInfo)),
  );

  const catalogsHaveImages = useMemo(
    () => tabs.some(tab => !!tab.imageSrc),
    [tabs],
  );

  const selectedCatalog = useMemo(
    () => findCatalog(catalogsArray, tabValue),
    [catalogsArray, tabValue],
  );

  const handleTabChange = useCallback((_event, catalogId) => {
    setTabValue(catalogId);
  }, []);

  // eslint-disable-next-line
  const groupsSelector = useCallback(makeSelectMenuGroups(selectedCatalog), [
    selectedCatalog,
  ]);
  const selectedGroups = useSelector(groupsSelector);

  return {
    tabs,
    tabValue,
    fallbackImage,
    selectedCatalog,
    selectedGroups,
    catalogsHaveImages,
    setTabValue,
    handleTabChange,
  };
};
