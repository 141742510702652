import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoyaltyHeaderRectangle1 } from '../../../../../redux/config/config.selectors';
import AccountLoyaltyLogo from '../../account-loyalty-logo/account-loyalty-logo.component';
import * as S from './account-loyalty-custom-logo.styles';

const AccountLoyaltyCustomLogo = ({ handleLogoClick }) => {
  const boxColor1 = useSelector(selectLoyaltyHeaderRectangle1);

  return (
    <S.LoyaltyCustomLogoHeaderContainer>
      <S.LoyaltyLogoBox color={boxColor1} />
      <S.LoyaltyLogoBox />
      <S.LoyaltyCustomLogoContainer onClick={handleLogoClick}>
        <AccountLoyaltyLogo />
      </S.LoyaltyCustomLogoContainer>
    </S.LoyaltyCustomLogoHeaderContainer>
  );
};

export default AccountLoyaltyCustomLogo;
