import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoyaltyLogo } from '../../../../redux/config/config.selectors';
import * as S from './account-loyalty-logo.styles';

const AccountLoyaltyLogo = () => {
  const loyaltyLogo = useSelector(selectLoyaltyLogo);

  return (
    <S.LoyaltyLogo
      data-testid="AccountLoyaltyLogo.LoyaltyLogo"
      src={loyaltyLogo}
      alt=""
    />
  );
};

export default AccountLoyaltyLogo;
