import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AccountInboxPage from '../../pages/account-inbox/account-inbox.component';
import AccountMyCardsPage from '../../pages/account-my-cards/account-my-cards.component';
import AccountOrderHistoryPage from '../../pages/account-order-history/account-order-history.component';
import AccountSettingsPage from '../../pages/account-settings/account-settings.component';
import { selectInbox } from '../../redux/config/config.selectors';
import ProtectedRoute from 'components/protected-route/protected-route.component';

const Account = () => {
  const inbox = useSelector(selectInbox);

  return (
    <Switch>
      <Route
        path="/settings"
        render={() => (
          <ProtectedRoute>
            <AccountSettingsPage />
          </ProtectedRoute>
        )}
      />

      <Route
        path="/inbox"
        render={() =>
          inbox && (
            <ProtectedRoute>
              <AccountInboxPage />
            </ProtectedRoute>
          )
        }
      />

      <Route
        path="/order-history"
        render={() => (
          <ProtectedRoute>
            <AccountOrderHistoryPage />
          </ProtectedRoute>
        )}
      />

      <Route
        path="/my-cards"
        render={() => (
          <ProtectedRoute>
            <AccountMyCardsPage />
          </ProtectedRoute>
        )}
      />
    </Switch>
  );
};

export default Account;
