import {
  getResponseErrorCode,
  getResponseIncErrorMessage,
} from 'apis/incentivio-api.util';

export class StripeError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'StripeError';
    this.cause = cause;
  }
}

export class IncentivioApiError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'IncentivioApiError';
    this.cause = cause;
    this.incentivioCode = getResponseErrorCode(cause);
    this.incentivioMessage = getResponseIncErrorMessage(cause);
  }
}
