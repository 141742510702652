import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors as appcontentSelectors } from 'redux/data/appcontent/appcontent.selectors';
import { useMessages } from '../messages/messages.hooks';
import { useOffers } from '../offers/offers.hooks';
import { selectors as offersSelectors } from '../offers/offers.slice';

export const useAppcontent = () => {
  const {
    fetchMessages,
    refreshMessages,
    messagesRetrievedPages,
    messagesHasMore,
  } = useMessages();

  const { fetchOffers, refreshOffers, offersRetrievedPages, offersHasMore } =
    useOffers();

  const fetchLoyaltyContent = useCallback(
    async ({ page, count } = {}) => {
      if (offersHasMore) {
        return await fetchOffers({
          page: page || (offersRetrievedPages ? offersRetrievedPages + 1 : 1),
          count,
        });
      }
    },
    [fetchOffers, offersHasMore, offersRetrievedPages],
  );

  const fetchAppcontent = useCallback(
    ({ page, count } = {}) => {
      const promises = [];
      offersHasMore &&
        promises.push(
          fetchOffers({
            page: page || (offersRetrievedPages ? offersRetrievedPages + 1 : 1),
            count,
          }),
        );
      messagesHasMore &&
        promises.push(
          fetchMessages({
            page:
              page || (messagesRetrievedPages ? messagesRetrievedPages + 1 : 1),
            count,
          }),
        );

      return Promise.all(promises);
    },
    [
      fetchMessages,
      fetchOffers,
      messagesHasMore,
      messagesRetrievedPages,
      offersHasMore,
      offersRetrievedPages,
    ],
  );

  const refreshAppcontent = useCallback(
    () => Promise.all([refreshMessages(), refreshOffers()]),
    [refreshMessages, refreshOffers],
  );

  const inboxContent = useSelector(appcontentSelectors.selectInboxContent);
  const loyaltyContent = useSelector(offersSelectors.selectSortedOffers);
  const total = useSelector(appcontentSelectors.selectTotal);
  const totalLoyalty = useSelector(offersSelectors.selectTotalLoyalty);
  const hasMore = useSelector(appcontentSelectors.selectHasMore);
  const loading = useSelector(appcontentSelectors.selectLoading);

  return {
    fetchAppcontent,
    fetchLoyaltyContent,
    refreshAppcontent,
    inboxContent,
    loyaltyContent,
    total,
    totalLoyalty,
    hasMore,
    loading,
  };
};
