import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCart } from 'redux/cart/cart.actions';
import {
  selectLocationId,
  selectOrderId,
  selectOrderType,
  selectRequestedAddress,
} from 'redux/cart/cart.selectors';
import { selectStoreAvailabilityExists } from 'redux/data/orderavailability/orderavailability.selectors';
import { actions } from 'redux/data/orderavailability/orderavailability.slice';

export const useStorePageOrderAvailability = (store, address, orderType) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.fetchOrderAvailability({
        storeId: store.storeId,
        requestedOrderType: orderType ?? 'PICKUP',
        address,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      orderType === 'DELIVERY' &&
      !!address &&
      address !== 'new' &&
      address !== 'default'
    ) {
      dispatch(
        actions.fetchOrderAvailability({
          storeId: store.storeId,
          address,
          requestedOrderType: orderType,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.addressId]);
};

export const useLocationsCardOrderAvailability = (
  store,
  address,
  orderType,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderType === 'PICKUP')
      dispatch(
        actions.fetchOrderAvailability({
          storeId: store.storeId,
          requestedOrderType: orderType,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderType === 'DELIVERY' && !!address?.addressId)
      dispatch(
        actions.fetchOrderAvailability({
          storeId: store.storeId,
          address,
          requestedOrderType: orderType,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.addressId, orderType]);
};

export const useCheckoutOrderAvailability = (
  store,
  address,
  orderType,
  selectedDay,
) => {
  const dispatch = useDispatch();
  const orderId = useSelector(selectOrderId);

  useEffect(() => {
    if (
      orderType === 'DELIVERY' &&
      (!address || address === 'new' || address === 'default')
    )
      return;
    dispatch(
      actions.fetchOrderAvailability({
        storeId: store.storeId,
        requestedOrderType: orderType,
        address: orderType === 'DELIVERY' ? address : undefined,
        orderId,
        selectedDay,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.addressId]);
};

export const useInitOrderAvailability = () => {
  const dispatch = useDispatch();
  const locationId = useSelector(selectLocationId);
  const orderType = useSelector(selectOrderType);
  const orderId = useSelector(selectOrderId);
  const address = useSelector(selectRequestedAddress);
  const storeAvailabilityExists = useSelector(state =>
    selectStoreAvailabilityExists(state, locationId),
  );
  const history = useHistory();

  useEffect(() => {
    const execute = async () => {
      if (!!orderId && !!locationId && !storeAvailabilityExists) {
        const response = await dispatch(
          actions.fetchOrderAvailability({
            storeId: locationId,
            requestedOrderType: orderType,
            orderId,
            address,
          }),
        );

        if (
          !!response.error &&
          response.payload.errorCode ===
            'DELIVERY_CONNECTOR_VALIDATION_FAILED_SPECIFIC'
        ) {
          dispatch(resetCart());
          history.push('/');
        }
      }
    };

    execute();
  }, [
    address,
    dispatch,
    history,
    locationId,
    orderId,
    orderType,
    storeAvailabilityExists,
  ]);
};
