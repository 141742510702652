import CloverCard from '../clover/clover-card/clover-card.component';
import StripeCard from '../incentivio/stripe-card/stripe-card.component';
import { PaymentGatewayType } from '../payment.constants';
import { usePaymentGatewayMatches } from '../payment.new.hooks';
import SpreedlyCard from '../spreedly/spreedly-card/spreedly-card.component';
import SquareCard from '../square/square-card/square-card.component';

const CardPayment = ({ ...rest }) => {
  const matchesPaymentGateway = usePaymentGatewayMatches();

  if (matchesPaymentGateway([PaymentGatewayType.SPREEDLY]))
    return <SpreedlyCard {...rest} />;

  if (matchesPaymentGateway([PaymentGatewayType.SQUARE]))
    return <SquareCard {...rest} />;

  if (matchesPaymentGateway([PaymentGatewayType.CLOVER]))
    return <CloverCard {...rest} />;

  return <StripeCard {...rest} />;
};

export default CardPayment;
