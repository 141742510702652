import { PaymentOptionType } from 'components/payment/payment.constants';
import { NewPaymentContext } from 'components/payment/payment.context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setPaymentInstrumentId } from 'redux/cart/cart.actions';
import {
  selectIsFreeOrder,
  selectPaymentInstrumentId,
  selectPaymentOption,
} from 'redux/cart/cart.selectors';
import { fetchPaymentInstrumentsStartAsync } from 'redux/user/user.actions';
import {
  selectDefaultCardPaymentInstrument,
  selectDefaultGiftCardPaymentInstrument,
  selectIsLoggedIn,
  selectPaymentInstrumentsCards,
  selectPaymentInstrumentsGiftCards,
} from 'redux/user/user.selectors';
import { useResetPaymentValidation } from '../checkout-payment-section/checkout-payment-section.hooks';
import { useCheckoutSection } from '../checkout-section/checkout-section.hooks';
import { paymentSchemaNew } from '../checkout.schema';
import { useIsNewSpreedlyCardFlow } from 'components/payment/payment-selector/credit-card-panel/credit-card-panel-controller/credit-card-panel-controller.hooks';

export const useCheckoutPaymentSectionNewComponent = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { t } = useTranslation();
  const title = useMemo(() => t('checkout.payment.title'), [t]);

  useEffect(() => {
    isLoggedIn && dispatch(fetchPaymentInstrumentsStartAsync());
  }, [dispatch, isLoggedIn]);

  useSelectedCard();
  useSelectedGiftCard();
  const resetCheckoutValidation = useResetPaymentValidation();

  useEffect(() => {
    resetCheckoutValidation();
  }, [isLoggedIn, resetCheckoutValidation]);

  const { validate } = usePaymentValidation();
  const context = useCheckoutSection({
    isEditing: false,
    title,
    validate,
  });

  return { checkoutSectionContext: context };
};

export const useSelectedCard = () => {
  const dispatch = useDispatch();
  const cards = useSelector(selectPaymentInstrumentsCards);
  const defaultCard = useSelector(selectDefaultCardPaymentInstrument);
  const cartPaymentInstrumentId = useSelector(selectPaymentInstrumentId);
  const { paymentOption } = useContext(NewPaymentContext);

  useEffect(() => {
    selectedCardHelper(
      cards,
      paymentOption,
      PaymentOptionType.CARD,
      cartPaymentInstrumentId,
      defaultCard,
      dispatch,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, defaultCard, dispatch, paymentOption]);
};

export const useSelectedGiftCard = () => {
  const dispatch = useDispatch();
  const cards = useSelector(selectPaymentInstrumentsGiftCards);
  const defaultCard = useSelector(selectDefaultGiftCardPaymentInstrument);
  const cartPaymentInstrumentId = useSelector(selectPaymentInstrumentId);
  const { paymentOption } = useContext(NewPaymentContext);

  useEffect(() => {
    selectedCardHelper(
      cards,
      paymentOption,
      PaymentOptionType.GIFT_CARD,
      cartPaymentInstrumentId,
      defaultCard,
      dispatch,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, defaultCard, dispatch, paymentOption]);
};

const selectedCardHelper = (
  cards,
  paymentOption,
  targetPaymentOption,
  cartPaymentInstrumentId,
  defaultCard,
  dispatch,
) => {
  if (cards?.length === 0 || paymentOption !== targetPaymentOption) return;

  if (cards.some(card => card.paymentInstrumentId === cartPaymentInstrumentId))
    return;
  // if not, use the default card and set selected in redux
  else if (!!defaultCard) {
    dispatch(setPaymentInstrumentId(defaultCard.paymentInstrumentId));
  } else {
    dispatch(setPaymentInstrumentId(cards[0].paymentInstrumentId));
  }
};

export const usePaymentValidation = () => {
  const store = useStore();
  const isNewSpreedlyCardFlow = useIsNewSpreedlyCardFlow();

  const validate = useCallback(
    async contextData => {
      try {
        const paymentInstrumentId = selectPaymentInstrumentId(store.getState());
        const paymentOption = selectPaymentOption(store.getState());
        await paymentSchemaNew.validate(
          {
            paymentInstrument: paymentInstrumentId,
            paymentOption,
          },
          {
            context: {
              isFreeOrder: selectIsFreeOrder(store.getState()),
              isNewSpreedlyCardFlow,
              ...contextData,
            },
          },
        );
      } catch (err) {
        return err;
      }
    },
    [store, isNewSpreedlyCardFlow],
  );

  return { validate, schema: paymentSchemaNew };
};
