import { usePaymentOptions } from 'components/payment/payment.new.hooks';
import GiftCardPaymentStepNew from '../gift-card-payment-step-new/gift-card-payment-step-new.component';
import { useSquarePurchaseEmailGiftCard } from 'components/payment/square/square-payments.hooks';

const SquareGiftCardPaymentStep = props => {
  const { trigger, loading: purchaseLoading } =
    useSquarePurchaseEmailGiftCard();

  const { selectedPaymentOption } = usePaymentOptions();

  return (
    <GiftCardPaymentStepNew
      trigger={trigger}
      purchaseLoading={purchaseLoading}
      selectedPaymentOption={selectedPaymentOption}
      {...props}
    />
  );
};

export default SquareGiftCardPaymentStep;
