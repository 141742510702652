import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLocationId,
  selectOrderId,
  selectOrderType,
  selectRequestedAddress,
  selectRequestedFulfillTime,
  selectTotal,
} from 'redux/cart/cart.selectors';
import { actions } from 'redux/data/orderavailability/orderavailability.slice';
import { fetchAsap } from 'redux/data/orderavailability/orderavailability.slice';

export const useFetchAsapDuration = () => {
  const storeId = useSelector(selectLocationId);
  const address = useSelector(selectRequestedAddress);
  const orderId = useSelector(selectOrderId);
  const orderType = useSelector(selectOrderType);
  const requestedFulfillTime = useSelector(selectRequestedFulfillTime);
  const total = useSelector(selectTotal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestedFulfillTime !== 'asap') return null;
    const fetchAsapDuration = async () => {
      const asapData = await fetchAsap(storeId, address, orderId, orderType);

      dispatch(
        actions.setAsapDuration({
          storeId,
          ...asapData,
        }),
      );
    };
    fetchAsapDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
};
