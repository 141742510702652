import Payment from 'components/payment/payment.component';
import { useOrderPayment } from 'components/payment/payment.new.hooks';
import CheckoutSections from '../checkout-sections/checkout-sections.component';

const CheckoutPaymentWrapper = () => {
  const newPaymentContext = useOrderPayment();

  return (
    <Payment value={newPaymentContext}>
      <CheckoutSections />
    </Payment>
  );
};
export default CheckoutPaymentWrapper;
