import React from 'react';
import { useSelector } from 'react-redux';
import AccountLoyaltyCustom from './account-loyalty-custom/account-loyalty-custom.component';
import AccountLoyaltyCustomTwo from './account-loyalty-custom-two/account-loyalty-custom-two.component';
import AccountLoyaltyDefault from './account-loyalty-default/account-loyalty-default.component';
import { selectLoyaltyType } from '../../../redux/config/config.selectors';

const AccountLoyaltyBody = ({ points, pointName }) => {
  const type = useSelector(selectLoyaltyType);

  switch (type) {
    case 'CUSTOM':
      return <AccountLoyaltyCustom points={points} pointName={pointName} />;

    case 'CUSTOM2':
      return <AccountLoyaltyCustomTwo points={points} />;

    default:
      return <AccountLoyaltyDefault points={points} pointName={pointName} />;
  }
};

export default AccountLoyaltyBody;
