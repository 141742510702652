import CommonTypes from '../common.types';
import ConfigActionTypes from './config.types';

const INITIAL_STATE = {
  isFetching: false,
  isGoogleMapsLoaded: false,
};

const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConfigActionTypes.FETCH_CONFIG_START:
      return {
        ...state,
        isFetching: true,
      };

    case ConfigActionTypes.FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };

    case ConfigActionTypes.FETCH_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case ConfigActionTypes.SET_IS_GOOGLE_MAPS_LOADED:
      return {
        ...state,
        isGoogleMapsLoaded: action.payload,
      };

    case CommonTypes.ERROR_HARD_RESET:
      return {
        ...INITIAL_STATE,
        images: state.images,
        colors: state.colors,
        fonts: state.fonts,
      };

    default:
      return state;
  }
};

export default configReducer;
