import { useAppleID } from 'hooks/appleSignIn.hooks';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { useDispatch } from 'react-redux';
import { socialSignInStartAsync } from 'redux/user/user.actions';
import * as S from './apple-signup.styles';

const AppleSignup = ({ btnText, send, setError }) => {
  const AppleID = useAppleID();
  const dispatch = useDispatch();

  const onClick = async () => {
    let jwt, userData;

    try {
      const response = await AppleID.auth.signIn();
      const id_token = response?.authorization?.id_token;
      const firstName = response?.user?.name?.firstName;
      const lastName = response?.user?.name?.lastName;
      const decoded = jwt_decode(id_token);
      const email = response?.user?.name?.email ?? decoded.email;

      jwt = id_token;
      userData = { firstName, lastName, email };

      const data = { email, jwt, authProvider: 'APPLE' };

      await dispatch(socialSignInStartAsync(data));
    } catch (e) {
      if (!!e.response) {
        if (e.response.data?.error === 'invalid_grant') {
          // take user to sign up
          send('REGISTER', {
            registerData: {
              userData,
              jwt,
              authProvider: 'APPLE',
            },
          });
        } else {
          setError(e.response.headers?.['incentivio-message']);
        }
      } else {
        setError(e.message);
      }
    }
  };

  // Tested manually
  /* istanbul ignore next */
  if (!AppleID) return null;

  return (
    <S.SignupButton
      onClick={onClick}
      fullWidth
      data-testid="CreateAccountStep.CustomButton.Apple"
      startIcon={<S.AppleIcon />}
    >
      {btnText}
    </S.SignupButton>
  );
};

export default AppleSignup;
