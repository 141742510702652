import React from 'react';

import * as S from './custom-input.styles';

const CustomInput = ({
  children,
  style,
  className,
  testId = 'CustomInput',
}) => (
  <S.Container
    style={style}
    className={`${className} input-container`}
    data-testid={testId}
  >
    {children}
  </S.Container>
);

export default CustomInput;
