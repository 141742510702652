import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLogo } from 'redux/config/config.selectors';
import * as S from './coming-soon.styles';

const ComingSoon = () => {
  const { t } = useTranslation();
  const logo = useSelector(selectLogo);

  return (
    <S.Container data-testid="ComingSoon.Container">
      <S.Logo src={logo} alt="" />
      <S.TextContainer variant="h1">{t('comingSoonText')}</S.TextContainer>
    </S.Container>
  );
};

export default ComingSoon;
