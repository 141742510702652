import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import CustomInput from 'components/custom-input/custom-input.component';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const EnvSelectField = ({ environments, selectedEnv, setSelectedEnv }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const onChange = ({ target: { value } }) => {
    setSelectedEnv(value);
    setValue('REACT_APP_MOBILE_API_URL', value.baseUrl);
  };

  return (
    <CustomInput>
      <FormControl fullWidth>
        <InputLabel aria-label={t('envConfig.form.baseUrlSelect.label')}>
          {t('envConfig.form.baseUrlSelect.label')}
        </InputLabel>
        <Select
          fullWidth
          onChange={onChange}
          SelectDisplayProps={{ 'data-testid': 'Select.Environment' }}
          MenuProps={{
            MenuListProps: { 'data-testid': 'Select.Environment.Menu' },
          }}
          value={selectedEnv}
        >
          {environments.map(environment => (
            <MenuItem value={environment} key={environment.environmentName}>
              {environment.environmentName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CustomInput>
  );
};

export default EnvSelectField;
