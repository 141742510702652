import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const CartPrice = styled.span`
  margin-left: 6px;
`;

export const CartButtonSpinner = styled(CircularProgress)`
  display: block;
  color: inherit;
  margin-left: 6px;
`;
