import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAnalytics } from 'util/analytics';

export const useOnRouteChange = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      getAnalytics().page();
    });

    return unlisten;
  }, [history]);
};
