import localforage from 'localforage';
import { getClientId } from './clientId';

const clientLocalForage = {
  setItem: (key, value) =>
    localforage.setItem(`${getClientId()}.${key}`, value),
  getItem: key => localforage.getItem(`${getClientId()}.${key}`),
  removeItem: key => localforage.removeItem(`${getClientId()}.${key}`),
  clear: () => localforage.clear(),
};

export default clientLocalForage;
