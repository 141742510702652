import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './message-status.styles';

const MessageStatus = ({ status, disabled }) => {
  const { t } = useTranslation();
  const label = useMemo(() => {
    if (status === 'NEW') {
      return t('accountInbox.new');
    }
    if (disabled) {
      return t('accountInbox.almostThere');
    }
  }, [disabled, status, t]);

  return !!label ? (
    <S.StatusChip
      size="small"
      label={label}
      status={status}
      data-testid="MessageStatus.StatusChip"
    />
  ) : null;
};

export default MessageStatus;
