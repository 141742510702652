import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMemo, useState } from 'react';
import { useSetupPaymentIntent } from '../incentivio-payments.hooks';
import { useGetCardNickname } from 'pages/checkout/checkout-payment-section/checkout-payment-section.hooks';

export const useStripeCardComponent = handleCardInfo => {
  const stripe = useStripe();
  const elements = useElements();
  const clientSecret = useSetupPaymentIntent();
  const nickname = useGetCardNickname();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const attaching = useMemo(
    () => !stripe || !elements || !clientSecret,
    [clientSecret, elements, stripe],
  );

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement('card'),
          },
        },
      );

      if (error) {
        setError(error);
        return;
      }

      if (!!setupIntent.payment_method) {
        await handleCardInfo({
          token: setupIntent.payment_method,
          full_name: nickname,
          month: 0,
          year: 0,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return { attaching, onSubmit, loading, error };
};

export const useStripeCardGuestComponent = () => {
  const stripe = useStripe();
  const elements = useElements();

  const attaching = useMemo(() => !stripe || !elements, [elements, stripe]);

  return { attaching };
};
