import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Question = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 2.5em;
`;

export const WarningBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background-color: #ea170b;
  margin-bottom: 3em;
  margin-top: 2em;
  color: white;
  border-radius: 4px;

  p {
    font-size: 0.875rem;
  }
  .MuiSvgIcon-root {
    margin-right: 1em;
  }
`;
