import { useSelector } from 'react-redux';
import {
  selectAvailableDays,
  selectAvailableTimes,
} from 'redux/data/orderavailability/orderavailability.selectors';
import { useFieldNames } from '../../../components/new-order/useFieldNames.hooks';
import { useFormContext } from 'react-hook-form';
import { selectDisplayTimeZone } from 'redux/locations/locations.selectors';
import { Settings } from 'luxon';

export const useOrderDayTimes = (store, orderType) => {
  const { fieldNames } = useFieldNames();
  const { watch } = useFormContext();
  const timeZoneName = useSelector(state =>
    selectDisplayTimeZone(state, store.storeId),
  ).name;

  Settings.defaultZone = timeZoneName;

  const availableDays = useSelector(state =>
    selectAvailableDays(state, store.storeId, orderType),
  );

  let day = watch(fieldNames.day, availableDays[0]) ?? availableDays[0];
  if (day?.isLuxonDateTime) {
    day = day.toISODate();
  }
  const availableTimes = useSelector(state =>
    selectAvailableTimes(state, store.storeId, orderType, day),
  );

  return {
    timeZoneName,
    availableTimes,
    availableDays,
    day,
  };
};
