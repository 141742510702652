import RecommendationActionTypes from './recommendations.types';

const INITIAL_STATE = {
  recommendationAddedOrderItemIds: [],
};

const recommendationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RecommendationActionTypes.SET_RECOMMENDATION_DETAILS:
      return {
        ...state,
        recommendationData: action.payload,
      };

    case RecommendationActionTypes.SET_RECOMMENDATION_ADDED_ORDER_ITEM_IDS:
      return {
        ...state,
        recommendationAddedOrderItemIds: [
          ...state.recommendationAddedOrderItemIds,
          action.payload,
        ],
      };

    case RecommendationActionTypes.REMOVE_RECOMMENDATION_ADDED_ORDER_ITEM_ID:
      const itemIds = [...state.recommendationAddedOrderItemIds];
      itemIds.splice(action.payload, 1);
      return {
        ...state,
        recommendationAddedOrderItemIds: itemIds,
      };

    case RecommendationActionTypes.RESET_RECOMMENDATIONS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default recommendationsReducer;
