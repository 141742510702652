import { Avatar, ButtonBase, CardHeader } from '@material-ui/core';
import styled from 'styled-components';

export const PaymentOptionButton = styled(ButtonBase)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  display: block;
  width: 100%;
  text-align: left;

  ${({ selected, theme }) =>
    selected
      ? `
    border-color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};

    .MuiAvatar-root {
      color: ${theme.palette.primary.contrastText};
    }
  `
      : `

    .MuiAvatar-root {
      color: ${theme.palette.text.primary};
    }
  
  `}
`;

export const PaymentOptionCardHeader = styled(CardHeader)`
  padding: 8px;
  .MuiCardHeader-title {
    font-weight: bold;
  }
`;

export const ImageAvatar = styled(Avatar)`
  border-radius: 0;
  img {
    object-fit: contain;
  }
`;

export const IconAvatar = styled(Avatar)`
  background-color: transparent;
`;
