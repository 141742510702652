import HiddenMenuErrorContext from 'components/hidden-menu-error-dialog/hidden-menu-error-dialog.context';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { slugify } from 'pages/locations/locations.utils';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import { scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import { addOrderItemsForReorder, newOrder } from 'redux/cart/cart.actions';
import { fetchCatalogsStartAsync } from 'redux/catalogs/catalogs.actions';
import { useTheme } from 'styled-components';
import { OrderFormContext } from './order-form-context';

export const useNewOrderSubmit = (
  store,
  rhfHandleSubmit,
  closeReorderDialog,
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fieldNames } = useFieldNames();
  const { t } = useTranslation();
  const theme = useTheme();
  const { storeTitle } = useParams();
  const slug = useMemo(
    () => storeTitle ?? slugify(store.displayInfo?.[0]?.title),
    [store.displayInfo, storeTitle],
  );

  const isReorder = useRouteMatch(['/order-history']);
  const storePage = useRouteMatch(['/locations/:storeTitle']);
  const orderContext = useContext(OrderFormContext);
  const location = useLocation();
  const locationState = location.state;

  const { setHiddenMenuErrorState, getInvalidItems } = useContext(
    HiddenMenuErrorContext,
  );

  const onSubmit = async formData => {
    const data = { ...orderContext?.options, ...formData };
    const response = await dispatch(
      newOrder({
        ...data,
        orderType:
          data.orderType === 'CATERING'
            ? data[fieldNames.cateringOrderType]
            : data.orderType,
        location: { locationId: store.storeId, slug },
        orderOption: !!data[fieldNames.orderOption]
          ? {
              ...data[fieldNames.orderOption],
              informationFieldInput: data[fieldNames.informationField],
            }
          : undefined,
      }),
    );

    if (!!response) {
      if (storePage) {
        history.push({
          pathname: `${location.pathname}${
            location.pathname.slice(-1) === '/' ? '' : '/'
          }menu`,
          search: locationState?.mik ? `?mik=${locationState?.mik}` : null,
        });
        return;
      }

      if (isReorder) {
        reorderSumbit(data);
        return;
      }

      history.push(`/locations/${slug}/menu`);
    }
  };

  const reorderSumbit = async ({ time, orderType, dayTimeSkip }) => {
    await dispatch(fetchCatalogsStartAsync());

    const addOrderItemsResponse = await dispatch(
      addOrderItemsForReorder(orderContext?.orderItems),
    );

    if (!!addOrderItemsResponse) {
      const invalidItemDetails = getInvalidItems(time, orderType);

      if (invalidItemDetails.length > 0) {
        setHiddenMenuErrorState({
          open: true,
          invalidItemDetails,
          callback: () => history.push(`/checkout`),
          closeReorderDialog,
          dayTimeSkip,
        });
        return;
      }
      history.push(`/checkout`);
      return;
    }
  };

  const onError = err => {
    if (!!err[fieldNames.address]) {
      scroller.scrollTo(fieldNames.address, {
        containerId: storePage ? undefined : 'locationsListContainer',
        smooth: true,
        offset: -parseInt(theme.navHeight?.split('p')?.[0] ?? 0),
      });
      toast.error(t('errors.requiredAddress'));
    }
  };

  const handleSubmit = (paramData = {}) =>
    rhfHandleSubmit(data => onSubmit({ ...data, ...paramData }), onError)();

  return { handleSubmit };
};
