import styled from 'styled-components';
import { FormControlLabel, InputLabel } from '@material-ui/core';
import Rating from 'components/form/form-fields/rating/rating.component';

export const CustomRating = styled(Rating)`
  label {
    font-size: 1.5rem;
    color: #ffb400;
  }
`;

export const FieldLabel = styled(InputLabel)`
  font-size: 1rem !important;
  font-weight: 600;
`;

export const CustomRadio = styled(FormControlLabel)`
  width: 100%;

  .MuiRadio-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
