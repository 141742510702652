import React from 'react';
import * as S from './menu-card-new-item-content-price.styles';
import { convertCentsToDollar } from 'utils';

const MenuCardNewItemContentPrice = ({ price }) => {
  const dollarPrice = convertCentsToDollar(price);

  return price !== 0 ? (
    <S.Price data-testid="CatalogCardPrice.Price">{dollarPrice}</S.Price>
  ) : null;
};

export default MenuCardNewItemContentPrice;
