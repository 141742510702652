import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useURLSearchParams } from './useURLSearchParams';
import { useSelector } from 'react-redux';
import { selectAreAllLocationsTypesLoadedOnce } from 'redux/locations/locations.selectors';

export const useQueryParamOrderTypeSelect = orderTypes => {
  const searchParams = useURLSearchParams();
  const searchParamOrderType = searchParams.get('orderType');
  const history = useHistory();

  const matchedQueryParamOrderType = orderTypes.find(
    ({ type }) => type === searchParamOrderType?.toUpperCase(),
  )?.type;

  const isAllLocationsLoaded = useSelector(
    selectAreAllLocationsTypesLoadedOnce,
  );

  const isSearchParamIsCatering =
    searchParamOrderType?.toUpperCase() === 'CATERING';

  const fixSearchParams = () => {
    if (
      !searchParamOrderType ||
      matchedQueryParamOrderType ||
      !isAllLocationsLoaded
    )
      return;
    searchParams.delete('orderType');
    history.replace({ search: searchParams.toString() });
  };

  if (isSearchParamIsCatering) fixSearchParams();
  else fixSearchParams();

  return matchedQueryParamOrderType;
};
