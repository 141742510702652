import { useEffect, useState } from 'react';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import * as S from './threeds2.styles';
import useScript from 'react-script-hook';
import { useOn3DSStatusUpdates } from './threeds2.hooks';
import Spinner from 'components/spinner/spinner.component';

const ThreeDS2 = ({ environmentKey, token, handleClose }) => {
  const searchParams = useURLSearchParams();
  const transactionToken = token ?? searchParams.get('token');
  const [loaded, setLoaded] = useState(false);

  const [loading, error] = useScript({
    src: 'https://core.spreedly.com/iframe/iframe-v1.min.js',
    checkForExisting: true,
    onload: () => {
      setLoaded(true);
    },
  });

  useEffect(() => {
    if (!!error)
      throw new Error(`Spreedly script loading error: ${error.message}`);
  }, [error]);

  useEffect(() => {
    if (!transactionToken) throw new Error('Transaction token not set');
  }, [transactionToken]);

  const on3DSstatusUpdatesFn = useOn3DSStatusUpdates(handleClose);

  useEffect(() => {
    if (!window.Spreedly) return;

    const lifecycle = new window.Spreedly.ThreeDS.Lifecycle({
      environmentKey: environmentKey ?? searchParams.get('environmentkey'),
      hiddenIframeLocation: 'device-fingerprint',
      challengeIframeLocation: 'challenge',
      transactionToken,
      challengeIframeClasses: 'challenge-iframe',
    });

    // Setup event handling and kickoff 3DSecure lifecycle
    window.Spreedly.on('3ds:status', on3DSstatusUpdatesFn);

    lifecycle.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <>
      {loading && <Spinner data-testid="ThreeDS2.Spinner" />}
      <S.HiddenDiv id="device-fingerprint" />
      <S.HiddenDiv id="challenge-modal">
        <div id="challenge"></div>
      </S.HiddenDiv>
    </>
  );
};

export default ThreeDS2;
