export const getStaticOptionItemPrices = options => {
  if (!options) return 0;
  return options.reduce((result, currentValue) => {
    return (
      result +
      (currentValue.price * currentValue.quantity +
        getStaticOptionItemPrices(currentValue.options) * currentValue.quantity)
    );
  }, 0);
};

export const getStaticItemPrice = item => {
  if (!item) return 0;
  return (item.price + getStaticOptionItemPrices(item.options)) * item.quantity;
};
