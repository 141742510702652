import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSocialSignInAppleClientId } from 'redux/config/config.selectors';
import location from 'util/location';

let incAppleID;

export const getAppleID = () => incAppleID;

export const useInitAppleID = () => {
  const appleSignInId = useSelector(selectSocialSignInAppleClientId);

  useEffect(() => {
    if (appleSignInId && !incAppleID) {
      incAppleID = window.AppleID;
      if (getAppleID()) {
        getAppleID().auth.init({
          clientId: appleSignInId,
          scope: 'name email',
          redirectURI: location.getClientURL().toLowerCase(),
          usePopup: true,
        });
      }
    }
  }, [appleSignInId]);
};

export const useAppleID = () => getAppleID();
