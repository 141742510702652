import styled from 'styled-components';
import { Typography, Link } from '@material-ui/core';

export const InfoBox = styled.div`
  border: 1px solid #979797;
  border-radius: 4px;
  min-height: 150px;
  padding: 1.5em;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.background.paper};

  &.addBox {
    display: flex;
    align-items: center;
  }

  button.darkBorder {
    max-width: 150px;
    margin: auto;
  }
`;

export const Edit = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;

  svg {
    pointer-events: none;
    margin-left: 4px;
    width: 20px;
  }
`;

export const InfoTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.5em;
  align-items: self-start;
  display: flex;
  justify-content: space-between;
`;

export const InfoText = styled(Typography)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text.primaryLight};
`;

export const PaymentIcon = styled.img`
  width: 50px;
  height: auto;
  display: block;
  margin-bottom: 0.5em;
`;
