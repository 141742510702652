import { useContext, useEffect } from 'react';
import { CloverInstance } from '../clover.instance';
import CloverField from './clover-field/clover-field.component';
import CloverFormContext from './clover-form.context';
import * as S from './clover-form.styles';
import CloverSpinner from './clover-spinner/clover-spinner.component';

const CloverForm = ({ apikey }) => {
  const { showLoading, scriptLoading, scriptError } =
    useContext(CloverFormContext);

  useEffect(() => {
    if (scriptLoading || !!scriptError) return;
    const elements = CloverInstance.getInstance(apikey).elements();
    const cardNumber = elements.create('CARD_NUMBER', S.elementStyles);
    const cardDate = elements.create('CARD_DATE', S.elementStyles);
    const cardCvv = elements.create('CARD_CVV', S.elementStyles);
    const cardPostalCode = elements.create('CARD_POSTAL_CODE', S.elementStyles);

    cardNumber.mount('#card-number');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');

    const cardNumberError = document.getElementById('card-number-errors');
    const cardDateError = document.getElementById('card-date-errors');
    const cardCvvError = document.getElementById('card-cvv-errors');
    const cardPostalCodeError = document.getElementById(
      'card-postal-code-errors',
    );

    ['change', 'blur'].forEach(eventType => {
      cardNumber.addEventListener(eventType, event => {
        cardNumberError.innerHTML = event.CARD_NUMBER.error ?? '';
      });
      cardDate.addEventListener(eventType, event => {
        cardDateError.innerHTML = event.CARD_DATE.error ?? '';
      });
      cardCvv.addEventListener(eventType, event => {
        cardCvvError.innerHTML = event.CARD_CVV.error ?? '';
      });
      cardPostalCode.addEventListener(eventType, event => {
        cardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error ?? '';
      });
    });
  }, [apikey, scriptError, scriptLoading]);

  useEffect(() => {
    if (!!scriptError)
      throw new Error(`Clover script loading error: ${scriptError.message}`);
  }, [scriptError]);

  useEffect(() => {
    if (!apikey) throw new Error('Clover api key not set');
  }, [apikey]);

  return (
    <S.Container data-testid="CloverCard.Container">
      {showLoading && <CloverSpinner />}
      <S.Form
        action="/charge"
        method="post"
        id="payment-form"
        style={{ width: '100%' }}
      >
        <CloverField id="card-number" />
        <CloverField id="card-date" />
        <CloverField id="card-cvv" />
        <CloverField id="card-postal-code" />

        <div id="card-errors" role="alert" />

        <div id="card-response" role="alert" />
      </S.Form>
    </S.Container>
  );
};

export default CloverForm;
