import Head from 'components/head/head.component';
import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import Spinner from '../../components/spinner/spinner.component';
import { persistor } from '../../redux/store';
import AwaitRecaptcha from './await-recaptcha';
import Client from './client.component';
import Preload from './preload.component';

const PreClient = () => {
  useEffect(() => {
    persistor.persist();
  }, []);

  return (
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <Preload>
        <Head />
        <AwaitRecaptcha>
          <Client />
        </AwaitRecaptcha>
      </Preload>
    </PersistGate>
  );
};

export default PreClient;
