import {
  selectRecaptchaAddGiftCard,
  selectRecaptchaCreateAccount,
  selectRecaptchaLogin,
  selectRecaptchaPayment,
} from 'redux/config/config.selectors';
import { getRecaptchaToken } from 'redux/user/user.utils';
import minimatch from 'minimatch';
import { store } from 'redux/store';

const recaptchaMap = {
  '**/makepayment': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/makepayment',
  },
  '**/preparepayment': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/makepayment',
  },
  '**/makeguestpayment': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/makeguestpayment',
  },
  '**/prepareguestpayment': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/makeguestpayment',
  },
  '/useraccounts': {
    selector: selectRecaptchaCreateAccount,
  },
  '/oauth/token': {
    selector: selectRecaptchaLogin,
  },
  '/giftcard/paymentinstruments': {
    selector: selectRecaptchaAddGiftCard,
  },
  '/paymentinstruments': {
    selector: selectRecaptchaPayment,
    method: 'post',
  },
  '/giftcard/add-value*': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/giftcard/addValue',
  },
  '/giftcard/add-value/payment/prepare': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/giftcard/addValue',
  },
  '/purchase/ecard': {
    selector: selectRecaptchaPayment,
  },
  '/ecard/purchase/payment/prepare': {
    selector: selectRecaptchaPayment,
    recaptchaId: '/purchase/ecard',
  },
  '/giftcard/purchase': {
    selector: () => true,
  },
  '/giftcard/purchase/payment/prepare': {
    selector: () => true,
    recaptchaId: '/giftcard/purchase',
  },
  '/giftcard/lookup': {
    selector: () => true,
    recaptchaId: '/giftcard/lookup',
  },
};

export const recaptchaInterceptorRunWhen = config => {
  const recaptchaKey = Object.keys(recaptchaMap).find(key =>
    minimatch(config.url, key),
  );

  if (!recaptchaKey) return false;

  const recaptchaValue = recaptchaMap[recaptchaKey];

  if (recaptchaValue.method && config.method !== recaptchaValue.method)
    return false;

  return true;
};

export const recaptchaInterceptor = async config => {
  const recaptchaKey = Object.keys(recaptchaMap).find(key =>
    minimatch(config.url, key),
  );

  const recaptchaValue = recaptchaMap[recaptchaKey];

  if (recaptchaValue.selector(store.getState())) {
    const recaptchaId = recaptchaValue.recaptchaId ?? recaptchaKey;
    const recaptchaToken = await getRecaptchaToken(recaptchaId);
    if (!!recaptchaToken) {
      config.headers['g-recaptcha-token'] = recaptchaToken;
    }
  }

  return config;
};
