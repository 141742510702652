import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './account-order-history-item.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';
import { useAccountOrderHistoryItem } from './account-order-history-item.hooks';

const AccountOrderHistoryItem = ({
  order,
  handleDetailItem,
  handleReorder,
}) => {
  const { t } = useTranslation();
  const {
    orderTotal: { displayTotal },
    orderItems,
    createdDate,
    status,
    locationId,
    orderDiscounts,
  } = order;

  const { isOrderDiscountApplied } = useAccountOrderHistoryItem(orderDiscounts);

  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(locationId),
  );

  return (
    <>
      <S.CustomContainer data-testid="AccountOrderHistoryItem.CustomContainer">
        <S.Head>
          <S.OrderStatus className={status === 'CLOSED' ? 'completed' : ''}>
            {status === 'CANCELED' ? (
              <>
                <CancelIcon />
                {t('accountPastOrders.cancelledStatus')}
              </>
            ) : (
              <>
                <CheckCircleOutlineIcon />
                {t('accountPastOrders.completedStatus')}
              </>
            )}
          </S.OrderStatus>
          <S.TimeAgo
            title={DateTime.fromISO(createdDate, {
              zone: 'utc',
            })
              .setZone('local')
              .toLocaleString(DateTime.DATETIME_SHORT)}
          >
            {DateTime.fromISO(createdDate, { zone: 'utc' }).toRelative()}
          </S.TimeAgo>
        </S.Head>
        <S.ItemsContainer>
          {orderItems.map(
            ({ orderItemId, displayInfoTitle, quantity, itemTotal }) => (
              <S.Item key={orderItemId}>
                <div>
                  <S.ItemName>
                    <S.Qty>{quantity}</S.Qty>
                    {displayInfoTitle}
                  </S.ItemName>
                </div>

                <Typography>
                  $
                  {isOrderDiscountApplied
                    ? itemTotal.displayPreDiscountSubtotal
                    : itemTotal.displayTotal}
                </Typography>
              </S.Item>
            ),
          )}
        </S.ItemsContainer>
        <S.Footer container spacing={2}>
          <Grid item xs={12} sm={6}>
            <S.Total>
              {t('accountPastOrders.totalLabel')}: ${displayTotal}
            </S.Total>
          </Grid>
          <S.Buttons item xs={12} sm={6}>
            <IncButtonDark
              data-testid="AccountOrderHistoryItem.CustomButton.MoreDetailsButton"
              onClick={handleDetailItem}
            >
              {t('accountPastOrders.moreDetailsAction')}
            </IncButtonDark>
            {!isMenuBrowsingStore && (
              <IncButton
                data-testid="AccountOrderHistoryItem.CustomButton.ReorderButton"
                onClick={() => handleReorder(order.orderId)}
              >
                {t('accountPastOrders.reorderAction')}
              </IncButton>
            )}
          </S.Buttons>
        </S.Footer>
      </S.CustomContainer>
    </>
  );
};

export default AccountOrderHistoryItem;
