import { useCallback, useContext, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { getSquareApplicationErrorMessage } from '../square.utils';
import { useSelector } from 'react-redux';
import { DARK_THEME } from 'theme.constants';
import { selectors } from 'redux/app/app.slice';
import getSquare from 'util/getSquare';
import { NewPaymentContext } from 'components/payment/payment.context';
import { useSquareContext } from '../square.context';

export const useSquareCardTokenize = () => {
  const { squareCard } = useSquareContext();
  const handleError = useErrorHandler();

  const tokenize = useCallback(async () => {
    if (!squareCard) return;

    try {
      const tokenResult = await squareCard.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else if (tokenResult.status !== 'Invalid') {
        tokenResult?.errors?.length &&
          tokenResult.errors.forEach(e =>
            toast.error(getSquareApplicationErrorMessage(e)),
          );
      }
    } catch (e) {
      if (e?.errors?.length) {
        e?.errors?.length &&
          e.errors.forEach(e =>
            toast.error(getSquareApplicationErrorMessage(e)),
          );
      }
      handleError(e);
    }
  }, [squareCard, handleError]);

  return tokenize;
};

export const useGetSqaureCard = () => {
  const theme = useSelector(selectors.selectTheme);
  const handleError = useErrorHandler();
  const { initOrderResponse } = useContext(NewPaymentContext);

  const { applicationId, locationId } = initOrderResponse?.attributes ?? {};

  const cardOptions = useMemo(
    () => ({
      style:
        theme === DARK_THEME
          ? {
              '.message-text': {
                color: 'white',
              },
              '.message-icon': {
                color: 'white',
              },
            }
          : {},
    }),
    [theme],
  );

  const getCard = useCallback(async () => {
    const square = getSquare();

    if (!square) return;

    try {
      const payments = square.payments(applicationId, locationId);
      return await payments.card(cardOptions);
    } catch (e) {
      handleError(e);
    }
  }, [cardOptions, handleError, applicationId, locationId]);

  return getCard;
};
