import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import GiftCardAddValue from 'components/card-item/card-item-giftcard/giftcard-add-value/giftcard-add-value.component';

const PurchaseGiftCard = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomDialog
        open={open}
        close={handleClose}
        title={t('addPurchaseGiftCard.purchaseCard.title')}
        tabIndex="0"
        testId="PurchaseGiftCard.CustomDialog"
      >
        <GiftCardAddValue handleClose={handleClose} />
      </CustomDialog>
    </>
  );
};
export default PurchaseGiftCard;
