import { useMemo } from 'react';

export const useFieldNames = () => {
  const fieldNames = useMemo(
    () => ({
      orderType: 'orderType',
      address: 'address',
      day: 'day',
      time: 'time',
      orderOption: 'orderOption',
      informationField: 'orderOption-informationField',
      cateringOrderType: 'catering-orderType',
    }),
    [],
  );

  return { fieldNames };
};
