import React, { useContext } from 'react';
import OptionItemQuantity from './option-item-quantity/option-item-quantity.component';
import OptionItemCheckbox from './option-item-checkbox/option-item-checkbox.component';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import { useIsItemOutOfStock } from 'pages/menu/menu.hooks';

export const OptionItem = props => {
  const {
    item,
    groupFormKey,
    maxItemSelections,
    group: { groupId },
    parentIds,
    identifier,
    parentName,
  } = props;
  const name = `options.${groupFormKey}`;

  const { catalogId } = useContext(MenuDialogContext);

  const isItemOutOfStock = useIsItemOutOfStock(item.itemId);

  const itemDetails = {
    itemId: item.itemId,
    groupId,
    parentIds,
    quantity: 1,
    identifier,
    parentName,
    options: [],
    price: item.price,
    itemInstructions: '',
    catalogId,
    groupFormKey,
  };

  if (
    maxItemSelections !== 1 &&
    (item.maxQuantity === 0 || item.maxQuantity > 1)
  ) {
    return (
      <OptionItemQuantity
        name={name}
        itemDetails={itemDetails}
        isItemOutOfStock={isItemOutOfStock}
        {...props}
      />
    );
  }

  return (
    <OptionItemCheckbox
      name={name}
      itemDetails={itemDetails}
      isItemOutOfStock={isItemOutOfStock}
      {...props}
    />
  );
};

export default OptionItem;
