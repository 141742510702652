import { Box, Grid, Paper } from '@material-ui/core';
import Spinner from 'components/spinner/spinner.component';
import StoreCard from 'components/store/store-card/store-card.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useIsViewOnlyLocationEnabled } from 'hooks/useIsViewOnlyLocationEnabled';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectIsFetching } from 'redux/cart/cart.selectors';
import { makeSelectStoreBySlug } from 'redux/locations/locations.selectors';
import StoreActions from './store-actions/store-actions.component';

const StoreLanding = () => {
  const { storeTitle } = useParams();
  const isFetching = useSelector(selectIsFetching);
  const store = useSelector(
    // eslint-disable-next-line
    useCallback(makeSelectStoreBySlug(storeTitle), [storeTitle]),
  );
  const { title: storeName } = useDisplayInfo(store?.displayInfo);
  const title = usePageTitle(storeName);
  const viewOnlyLocationEnabled = useIsViewOnlyLocationEnabled(store);

  return (
    <>
      {isFetching && (
        <Box position="absolute" top={0} right={0} left={0} height="100vh">
          <Spinner />
        </Box>
      )}
      {!!store && (
        <Box
          p={[0, 2]}
          display="flex"
          justifyContent="center"
          data-testid="StoreLanding.Box"
        >
          <Helmet title={title} />
          <Box width={[1, 1, viewOnlyLocationEnabled ? 599 : 960]}>
            <Paper>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={viewOnlyLocationEnabled ? 12 : 6}>
                  <StoreCard store={store} />
                </Grid>
                {!viewOnlyLocationEnabled && (
                  <Grid item xs={12} sm={6}>
                    <Box p={2}>
                      <StoreActions store={store} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StoreLanding;
