import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useClearEditOptions,
  useIsMaxItemSelectionsReached,
  useOptionItemRegister,
  useUnregisterOnRemove,
} from '../option-item.hooks';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';

export const useOptionItemQuantity = props => {
  const {
    item,
    name,
    minItemSelections,
    editOptions,
    isParentEdited,
    isItemOutOfStock,
    itemDetails,
    isParentAndEditFlow,
    maxItemSelections,
  } = props;

  const { handleSetItemPrice } = useContext(MenuDialogContext);
  const { itemId, defaultModifier, minQuantity, formKey } = item;
  const {
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext();
  const [qty, setQty] = useState(0);

  useOptionItemRegister({ minItemSelections, name });
  const { unRegisterOnRemove } = useUnregisterOnRemove();

  const clearEditOptions = useClearEditOptions();

  const isMaxSelectionsReached = useIsMaxItemSelectionsReached(
    name,
    maxItemSelections,
    item.minQuantity > 0 ? item.minQuantity : 1,
    qty,
  );

  useEffect(() => {
    if (
      !!editOptions ||
      isItemOutOfStock ||
      isParentEdited ||
      isParentAndEditFlow
    )
      return;

    if (defaultModifier) {
      handleQtyChange(minQuantity || 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemOutOfStock]);

  useEffect(() => {
    editOptions?.forEach(option => {
      if (option.itemId === itemId) {
        handleQtyChange(option.quantity, { edit: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQtyChange = (qty, opt = {}) => {
    const data = getValues(name) ?? [];
    const isItemAlreadyAdded = data.find(item => item.itemId === itemId);
    if (qty === 0) {
      unRegisterOnRemove(itemDetails.identifier);
      setValue(
        name,
        data.filter(item => item.itemId !== itemId),
      );
    } else if (!!isItemAlreadyAdded) {
      setValue(
        name,
        data.map(item => {
          if (item.itemId === itemId) return { ...item, quantity: qty };
          return item;
        }),
      );
    } else {
      setValue(name, [...data, { ...itemDetails, quantity: qty }]);
    }
    setQty(qty);
    errors.options?.[formKey] && trigger(name);
    if (!opt.edit) handleSetItemPrice();
  };

  const handleRemove = () => {
    clearEditOptions();
    if (qty === minQuantity) {
      handleQtyChange(0);
    } else {
      handleQtyChange(qty - 1);
    }
  };

  const handleAdd = () => {
    if (qty === 0 && minQuantity) {
      handleQtyChange(minQuantity);
    } else {
      handleQtyChange(qty + 1);
    }
  };
  return { handleAdd, handleRemove, qty, isMaxSelectionsReached };
};
