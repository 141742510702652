import React from 'react';
import { ErrorBoundary as REB } from 'react-error-boundary';
import { useDefaultOnReset } from './error-boundary.hooks';
import { defaultErrorHandler } from './error-boundary.utils';
import ErrorFallback from './error-fallback/error-fallback.component';
import TestError from './test-error/test-error.component';

const ErrorBoundary = ({
  name,
  FallbackComponent = ErrorFallback,
  onError = defaultErrorHandler(name),
  onReset,
  children,
  ...rest
}) => {
  const defaultOnReset = useDefaultOnReset();

  return (
    <REB
      FallbackComponent={FallbackComponent}
      onError={onError}
      onReset={onReset || defaultOnReset}
      {...rest}
    >
      <TestError name={name} />
      {children}
    </REB>
  );
};

export default ErrorBoundary;
