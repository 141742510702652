import { Box, Typography } from '@material-ui/core';

const InfoText = ({ text }) => {
  return (
    <Box p={1}>
      <Typography variant="subtitle2">{text}</Typography>
    </Box>
  );
};

export default InfoText;
