import CreateAccount from 'components/create-account/create-account.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../drawer/drawer.component';
import { useDrawerLoginOptions } from './drawer-login-options.hooks';
import * as S from './drawer-login-options.styles';

const DrawerLoginOptions = () => {
  const { t } = useTranslation();
  const {
    drawerState,
    initialMachineState,
    handleDrawerToggle,
    isLight,
    setIsLight,
    drawerTitle,
    setDrawerTitle,
  } = useDrawerLoginOptions();

  return (
    <Drawer
      toggleText={t('drawer.login.toggle')}
      isLight={isLight}
      handleToggleDrawer={handleDrawerToggle}
      isDrawerOpen={drawerState}
      togglerButtonProps={{
        'data-testid': 'DrawerLoginOptions.Toggle',
        'aria-label': t('drawer.login.toggle'),
      }}
    >
      <S.Title>{drawerTitle}</S.Title>
      <CreateAccount
        handleDrawerTitle={setDrawerTitle}
        setIsLight={setIsLight}
        initial={initialMachineState}
      />
    </Drawer>
  );
};

export default DrawerLoginOptions;
