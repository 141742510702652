import { Box, Divider } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { shouldSkipOrderOptions } from 'components/new-order/order-options/useOrderOptions';
import OrderDateTime from 'components/order-date-time/order-date-time.component';
import { useGetOrderTypeIcon } from 'components/order-type-icon/order-type-icon.hooks';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import CheckoutSectionPreview from 'pages/checkout/checkout-section/checkout-section-preview/checkout-section-preview.component';
import { MapsLink } from 'pages/checkout/checkout.styles';
import { getDisplayStoreAddress } from 'pages/locations/locations.utils';
import { useGetOrderOptions } from 'pages/store/new-order-form/useGetOrderOptions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectOrderOptionInfo,
  selectOrderType,
  selectRequestedAddress,
} from 'redux/cart/cart.selectors';
import { selectRequestedStoreGoogleMapsQueryUrl } from 'redux/common.selectors';
import {
  selectRequestedStore,
  selectRequestedStoreAddress,
} from 'redux/locations/locations.selectors';
import { getFormattedAddress } from 'utils';
import OrderDetailsRow from '../order-details-row/order-details-row.component';

const CheckoutOrderDetailsPreview = () => {
  const { t } = useTranslation();
  const orderType = useSelector(selectOrderType);
  const store = useSelector(selectRequestedStore);
  const { orderOptions } = useGetOrderOptions(store, orderType);
  const { orderTypeLabel } = useOrderTypeLabels();
  const { optionName, information } = useSelector(selectOrderOptionInfo) ?? {};
  const orderOptionString = useMemo(() => {
    if (shouldSkipOrderOptions(orderOptions)) return null;
    const arr = [];
    optionName && arr.push(optionName);
    information && arr.push(information);

    return arr.join(' | ');
  }, [information, optionName, orderOptions]);
  const storeAddress = useSelector(selectRequestedStoreAddress);
  const userAddress = useSelector(selectRequestedAddress);
  const storeMapLink = useSelector(selectRequestedStoreGoogleMapsQueryUrl);
  const orderTypeIcon = useGetOrderTypeIcon();

  return (
    <CheckoutSectionPreview>
      <Box display="flex" flexDirection="column">
        <OrderDetailsRow
          icon={orderTypeIcon}
          title={orderTypeLabel}
          subtitle={
            orderOptionString ? (
              <Box
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {orderOptionString}
              </Box>
            ) : null
          }
          pb={[2, 2, 2, 1]}
          testIdPrefix="CheckoutOrderDetailsPreview.OrderType"
        />

        <Divider />

        <Box display="flex" flexWrap="wrap" py={[2, 2, 2, 1]}>
          <OrderDetailsRow
            icon={LocationOnIcon}
            title={
              orderType === 'PICKUP'
                ? t('checkout.orderDetails.preview.storeAddress')
                : t('checkout.orderDetails.preview.address')
            }
            subtitle={
              orderType === 'PICKUP' ? (
                <MapsLink
                  href={storeMapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getDisplayStoreAddress(storeAddress)}
                </MapsLink>
              ) : (
                getFormattedAddress(userAddress)
              )
            }
            flex="1"
            minWidth="264px"
            testIdPrefix="CheckoutOrderDetailsPreview.Address"
          />
        </Box>

        <Divider />

        <OrderDetailsRow
          icon={AccessTimeIcon}
          title={t('checkout.orderDetails.preview.time')}
          subtitle={<OrderDateTime />}
          pt={[2, 2, 2, 1]}
          testIdPrefix="CheckoutOrderDetailsPreview.Time"
        />
      </Box>
    </CheckoutSectionPreview>
  );
};

export default CheckoutOrderDetailsPreview;
