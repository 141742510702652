import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const SavedText = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 0.5em;
`;

export const SliderWrapper = styled.div`
  width: calc(100% - 4.5em);
  margin: auto;
`;
