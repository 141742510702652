import { createSelector } from 'reselect';
import { isExpired } from 'utils';
import {
  createFetchEntities,
  makeEntityAdapter,
  makeFetchNew,
  makeInitialState,
  makeSelectors,
  makeSlice,
  makeUpdateStatus,
} from '../appcontent/appcontent.utils';
import { selectData } from '../data.selectors';

const sliceName = 'data/messages';

const entityAdapter = makeEntityAdapter('distributedMessageId');

export const selectors = makeSelectors(
  'Messages',
  'messages',
  selectData,
  entityAdapter,
);

selectors.selectNonExpiredMessages = createSelector(
  selectors.selectAll,
  allMessages =>
    allMessages.filter(
      message =>
        !message.distributedEndDate || !isExpired(message.distributedEndDate),
    ),
);

const fetchMessages = createFetchEntities(
  `${sliceName}/fetchMessages`,
  '/appmessages',
  response => slice.actions.upsertMany(response.data?.messages ?? []),
  response => slice.actions.totalPages(response.data?.paging?.totalPages ?? 0),
  (page, thunkAPI) =>
    slice.actions.retrievedPages(
      Math.max(page, selectors.selectRetrievedPages(thunkAPI.getState())),
    ),
  response => response.data?.messages,
  {
    count: 50,
    languageCode: 'EN',
    page: 1,
  },
  {
    condition: (_, { getState }) => !selectors.selectLoading(getState()),
  },
);

const refreshMessages = makeFetchNew(
  `${sliceName}/refreshMessages`,
  '/appmessages',
  response => slice.actions.upsertMany(response.data?.messages ?? []),
  response => response.data?.messages,
  {
    count: 10,
    languageCode: 'EN',
    page: 1,
  },
);

export const initialState = makeInitialState(entityAdapter);

export const slice = makeSlice(
  sliceName,
  initialState,
  entityAdapter,
  fetchMessages,
  refreshMessages,
);

const deleteMessage = makeUpdateStatus(
  `${sliceName}/deleteMessage`,
  '/changedistributedmessagestatus',
  distributedMessageId => ({ distributedMessageId, status: 'DELETED' }),
  distributedMessageId => slice.actions.removeOne(distributedMessageId),
);

const updateMessageStatus = makeUpdateStatus(
  `${sliceName}/updateStatus`,
  '/changedistributedmessagestatus',
  (distributedMessageId, status) => ({ distributedMessageId, status }),
  (id, status) => slice.actions.updateOne({ id, changes: { status } }),
);

export const actions = {
  ...slice.actions,
  fetchMessages,
  refreshMessages,
  deleteMessage,
  updateMessageStatus,
};

export default slice.reducer;
