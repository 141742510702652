import * as S from './clover-footer.styles';

const CloverFooter = () => {
  return (
    <S.Container>
      <S.SecurePayments>
        <S.Image1
          alt=""
          src="https://checkout.sandbox.dev.clover.com/assets/icons/lock.png"
        />
        Secure Payments Powered by <strong>Clover</strong>
        <S.Image2
          alt=""
          src="https://checkout.sandbox.dev.clover.com/assets/icons/clover-symbol.png"
        />
      </S.SecurePayments>
      <S.PirvacyPolicy
        title="Privacy Policy"
        href="https://www.clover.com/privacy-policy"
      >
        Privacy Policy
      </S.PirvacyPolicy>
    </S.Container>
  );
};

export default CloverFooter;
