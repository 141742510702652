export const CARD_STEP = {
  OVERVIEW: 'OVERVIEW',
  ORDER_OPTIONS: 'ORDER_OPTIONS',
  TIME_DAY_SELECTION: 'TIME_DAY_SELECTION',
};

export const STORE_STATUSES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};
