import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeDiscount } from '../../redux/cart/cart.actions';
import { selectOrderAndItemDiscounts } from '../../redux/cart/cart.selectors';
import TotalItem from '../total-item/total-item.component';
import * as S from './order-discounts.styles';

const OrderDiscounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const discounts = useSelector(selectOrderAndItemDiscounts);

  if (!discounts) return null;
  return (
    <Box data-testid="OrderDiscounts.Box">
      {discounts.map(({ discountId, description, displayAppliedDiscount }) => (
        <TotalItem key={discountId} data-testid="OrderDiscounts.TotalItem">
          <Typography>{description}</Typography>
          <Typography>
            <S.Remove
              onClick={() =>
                dispatch(
                  removeDiscount(
                    discountId,
                    description,
                    displayAppliedDiscount,
                  ),
                )
              }
              data-testid="OrderDiscounts.Remove"
            >
              {t('drawer.cart.removeDiscountAction')}
            </S.Remove>
            -${displayAppliedDiscount}
          </Typography>
        </TotalItem>
      ))}
    </Box>
  );
};

export default OrderDiscounts;
