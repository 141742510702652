import { memo } from 'react';
import CloverForm from 'pages/clover/clover-form/clover-form.component';
import CloverSubmitButton from 'pages/clover/clover-submit-button/clover-submit-button.component';
import CloverFooter from '../clover-footer/clover-footer.component';
import { useTranslation } from 'react-i18next';
import CloverFormWrapper from 'pages/clover/clover-form/clover-form.wrapper';
import { useCloverCard } from './clover-card.hooks';

const CloverCard = ({
  handleCardInfo,
  hideSubmitButton = false,
  loading: addNewCardLoading,
}) => {
  const { t } = useTranslation();
  const { cloverScriptSrc, apikey } = useCloverCard();

  return (
    <CloverFormWrapper
      addNewCardLoading={addNewCardLoading}
      cloverScriptSrc={cloverScriptSrc}
    >
      <CloverForm apikey={apikey} />
      {!hideSubmitButton && (
        <CloverSubmitButton
          callback={handleCardInfo}
          label={t('clover.addToAccountButton.label')}
        />
      )}
      <CloverFooter />
    </CloverFormWrapper>
  );
};

export default memo(CloverCard);
