import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const TogglerButton = styled(Button)`
  color: ${({ theme }) => theme.palette.accentColor};
  text-transform: capitalize;
  svg,
  .MuiAvatar-circular {
    display: flex;
    margin-right: 0.3rem;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    svg,
    .MuiAvatar-circular {
      width: 30px;
      height: 30px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: unset;
    white-space: nowrap;
    .MuiAvatar-circular {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;
