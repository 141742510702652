import INC_BASE_API from 'apis/incentivio-api';
import {
  getResponseErrorCode,
  getResponseErrorMessage,
} from 'apis/incentivio-api.util';
import { useIncErrorHandler } from 'components/error-boundary/error-boundary.hooks';
import EmailGiftcardPurchaseContext from 'pages/giftcard/giftcard-purchase/giftcard-purchase.context';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateOrderItemStartAsync } from 'redux/cart/cart.actions';
import {
  selectCustomerInfo,
  selectDeliveryInstructions,
  selectIsFreeOrder,
  selectOrder,
  selectOrderId,
  selectOrderInstructions,
  selectOrderItems,
  selectOrderLocationId,
  selectOrderMerchantId,
  selectOrderOptionInfo,
  selectOrderType,
  selectPaymentInstrumentId,
  selectTip,
  selectTotalWithoutGratuityWithTip,
} from 'redux/cart/cart.selectors';
import { addGroupIdToOptions } from 'redux/cart/cart.utils';
import { fetchOutOfStockItems } from 'redux/catalogs/catalogs.actions';
import {
  selectGiftCardDefaultLocation,
  selectGiftCardDefaultMerchant,
  selectGuestGiftCardOrderPaymentsEnabled,
  selectIsActiveGiftCard,
  selectIsGuestCheckoutEnabled,
  selectPurchaseWithGiftCard,
  selectRecommendationsEnabled,
} from 'redux/config/config.selectors';
import { selectRequestedStoreDITitle } from 'redux/locations/locations.selectors';
import { updateRecommendationsOrder } from 'redux/recommendations/recommendations.actions';
import { addPaymentInstrument, lookupGiftCard } from 'redux/user/user.actions';
import {
  selectIsLoggedIn,
  selectPaymentInstrumentById,
  selectUserData,
} from 'redux/user/user.selectors';
import { getAnalytics } from 'util/analytics';
import { IncentivioApiError } from 'util/errors';
import {
  PaymentGatewayType,
  PaymentOptionType,
  PaymentType,
  SpreedlyPaymentProcessorType,
  paymentOptionToPaymentTypeMap,
} from './payment.constants';
import { NewPaymentContext } from './payment.context';
import { getSpreedlyPaymentProcessorType } from './spreedly/spreedly-payments.hooks';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';

export const initOrder = (initOrderBody, options = {}) => {
  const data = {
    attributes: {},
    otherData: 'null',
    ...initOrderBody,
  };

  return INC_BASE_API.post('/orders/payment/initorder', data, options);
};

export const useInitOrderRequest = initOrderBody => {
  const [loading, setLoading] = useState(false);
  const [initOrderResponse, setInitOrderResponse] = useState();
  const [paymentGatewayType, setPaymentGatewayType] = useState();
  const initOrderErrorHandler = useInitOrderErrorHandler();

  useEffect(() => {
    const execute = async () => {
      try {
        setLoading(true);
        const response = await initOrder(initOrderBody);
        setInitOrderResponse(response.data);
        setPaymentGatewayType(
          getPaymentGatewayType(response?.data?.attributes),
        );
      } catch (error) {
        initOrderErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, setLoading, initOrderResponse, paymentGatewayType };
};

export const getPaymentGatewayType = attributes =>
  Object.values(PaymentGatewayType).find(
    value => value === attributes?.gateway,
  );

export const usePaymentContext = (
  type,
  initOrderBody,
  amount,
  walletLabel,
  cardIdentifier,
  afterWalletPay,
) => {
  const [walletsLoading, setWalletsLoading] = useState();
  const [walletSupport, setWalletSupport] = useState({});
  const [paymentOption, setPaymentOption] = useState();
  const typeRef = useRef(type);
  const cardIdentifierRef = useRef(cardIdentifier);
  const afterWalletPayRef = useRef(afterWalletPay);

  const { loading, setLoading, initOrderResponse, paymentGatewayType } =
    useInitOrderRequest(initOrderBody);

  return useMemo(
    () => ({
      type: typeRef.current,
      loading,
      setLoading,
      initOrderResponse,
      paymentGatewayType,
      walletsLoading,
      setWalletsLoading,
      walletSupport,
      setWalletSupport,
      amount,
      walletLabel,
      paymentOption,
      setPaymentOption,
      cardIdentifier: cardIdentifierRef.current,
      afterWalletPay: afterWalletPayRef.current,
    }),
    [
      loading,
      setLoading,
      initOrderResponse,
      paymentGatewayType,
      walletsLoading,
      walletSupport,
      amount,
      walletLabel,
      paymentOption,
    ],
  );
};

export const useOrderPayment = () => {
  const { t } = useTranslation();
  const initOrderBody = useInitOrderBody();
  const amount = useSelector(selectTotalWithoutGratuityWithTip);
  const store = useSelector(selectRequestedStoreDITitle);
  const label = useMemo(
    () => t('payment.orderWalletLabel', { store }),
    [store, t],
  );
  return usePaymentContext(PaymentType.ORDER, initOrderBody, amount, label);
};

export const useGiftCardPayment = (
  amount,
  walletLabel,
  cardIdentifier,
  afterWalletPay,
) => {
  const initOrderBody = useInitGiftCardBody();
  return usePaymentContext(
    PaymentType.GIFT_CARD,
    initOrderBody,
    amount,
    walletLabel,
    cardIdentifier,
    afterWalletPay,
  );
};

export const useAddCreditCardThroughMyCards = () => {
  const initOrderBody = useInitGiftCardBody();
  return useInitOrderRequest(initOrderBody);
};

export const useEmailGiftCardPayment = (amount, walletLabel) => {
  const initOrderBody = useMyCardInitBody();

  return usePaymentContext(
    PaymentType.EMAIL_GIFT_CARD,
    initOrderBody,
    amount,
    walletLabel,
  );
};

export const useInitOrderBody = () => {
  const orderId = useSelector(selectOrderId);
  const locationId = useSelector(selectOrderLocationId);
  const merchantId = useSelector(selectOrderMerchantId);

  return { orderId, locationId, merchantId };
};

export const useInitGiftCardBody = () => {
  const locationId = useSelector(selectGiftCardDefaultLocation);
  const merchantId = useSelector(selectGiftCardDefaultMerchant);

  return { locationId, merchantId };
};

const useMyCardInitBody = useInitGiftCardBody;

export const useInitOrderErrorHandler = () => {
  const history = useHistory();
  const handleError = useErrorHandler();

  return error => {
    if (
      error.response?.headers?.['incentivio-code'] === 'BAD_REQUEST' &&
      error.response?.headers?.['incentivio-message'].includes(
        'Order is already closed',
      )
    ) {
      const errorMessage = getResponseErrorMessage(error);
      toast.error(errorMessage);
      history.push('/');
    } else {
      const errorMessage = getResponseErrorMessage(error);
      toast.error(errorMessage);
      handleError(error);
    }
  };
};

export const usePaymentConfig = () => {
  const { initOrderResponse } = useContext(NewPaymentContext);
  const orderType = useSelector(selectOrderType);
  return useMemo(
    () =>
      initOrderResponse?.paymentConfigs?.find(
        paymentConfig => paymentConfig.orderType === orderType,
      ),
    [initOrderResponse, orderType],
  );
};

export const usePaymentMode = () => {
  const paymentConfig = usePaymentConfig();

  return useMemo(
    () => paymentConfig?.paymentModes?.[0] ?? 'PAYMENT_MANDATORY',
    [paymentConfig],
  );
};

export const useIsGuestCheckoutSupported = () => {
  const paymentConfig = usePaymentConfig();
  const isGuestCheckoutEnabled = useSelector(selectIsGuestCheckoutEnabled);

  return useMemo(
    () => isGuestCheckoutEnabled && !!paymentConfig?.guestCheckoutSupported,
    [isGuestCheckoutEnabled, paymentConfig?.guestCheckoutSupported],
  );
};

export const usePaymentOptions = () => {
  const isPayLaterEnabled = useIsPayLaterEnabled();
  const { walletSupport } = useWallets();
  const areGiftCardsEnabled = useAreGiftCardsEnabled();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const customerInfo = useSelector(selectCustomerInfo);
  const { paymentOption, setPaymentOption } = useContext(NewPaymentContext);
  const isGuestCheckoutEnabled = useIsGuestCheckoutSupported();
  const guestGiftCardOrderPaymentsEnabled = useSelector(
    selectGuestGiftCardOrderPaymentsEnabled,
  );

  const paymentOptions = useMemo(
    () => ({
      [PaymentOptionType.CARD]: isLoggedIn,
      [PaymentOptionType.GUEST_CARD]: !isLoggedIn,
      [PaymentOptionType.PAY_LATER]: isPayLaterEnabled,
      [PaymentOptionType.GOOGLE_PAY]: walletSupport?.googlePay,
      [PaymentOptionType.APPLE_PAY]: walletSupport?.applePay,
      [PaymentOptionType.GIFT_CARD]: isLoggedIn && areGiftCardsEnabled,
      [PaymentOptionType.GUEST_GIFT_CARD]:
        guestGiftCardOrderPaymentsEnabled &&
        isGuestCheckoutEnabled &&
        !isLoggedIn &&
        !!customerInfo &&
        areGiftCardsEnabled,
    }),
    [
      areGiftCardsEnabled,
      customerInfo,
      guestGiftCardOrderPaymentsEnabled,
      isGuestCheckoutEnabled,
      isLoggedIn,
      isPayLaterEnabled,
      walletSupport?.applePay,
      walletSupport?.googlePay,
    ],
  );

  return {
    selectedPaymentOption: paymentOption,
    setSelectedPaymentOption: setPaymentOption,
    paymentOptions,
  };
};

export const useOrderPaymentOptions = () => {
  const { paymentOptions, ...rest } = usePaymentOptions();
  const purchaseWithGiftCard = useSelector(selectPurchaseWithGiftCard);

  const newPaymentOptions = useMemo(() => {
    if (paymentOptions[PaymentOptionType.GIFT_CARD])
      return {
        ...paymentOptions,
        [PaymentOptionType.GIFT_CARD]: purchaseWithGiftCard,
      };

    return paymentOptions;
  }, [paymentOptions, purchaseWithGiftCard]);

  return {
    paymentOptions: newPaymentOptions,
    ...rest,
  };
};

export const useIsPayLaterEnabled = () => {
  const paymentMode = usePaymentMode();
  const paymentType = usePaymentType();

  return useMemo(
    () =>
      paymentMode === 'PAYMENT_OPTIONAL' &&
      paymentType !== PaymentType.GIFT_CARD &&
      paymentType !== PaymentType.EMAIL_GIFT_CARD,
    [paymentMode, paymentType],
  );
};

export const useAreGiftCardsEnabled = () => {
  const { loading: newPaymentLoading } = useContext(NewPaymentContext);
  const isActiveGiftCard = useSelector(selectIsActiveGiftCard);
  const paymentType = usePaymentType();

  return useMemo(
    () =>
      !newPaymentLoading &&
      !!isActiveGiftCard &&
      paymentType !== PaymentType.GIFT_CARD &&
      paymentType !== PaymentType.EMAIL_GIFT_CARD,
    [isActiveGiftCard, newPaymentLoading, paymentType],
  );
};

export const useWallets = () => {
  const { walletsLoading, setWalletsLoading, walletSupport, setWalletSupport } =
    useContext(NewPaymentContext);

  return {
    loading: walletsLoading,
    setLoading: setWalletsLoading,
    walletSupport,
    setWalletSupport,
  };
};

export const useCustomerInfo = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const customerInfo = useSelector(selectCustomerInfo);
  const userData = useSelector(selectUserData);
  return useMemo(
    () => (isLoggedIn ? userData : customerInfo),
    [customerInfo, isLoggedIn, userData],
  );
};

export const usePaymentType = () => useContext(NewPaymentContext).type;

export const useAddPaymentInstrument = () => {
  const dispatch = useDispatch();
  const paymentType = usePaymentType();

  return useCallback(
    cardInfo =>
      dispatch(
        addPaymentInstrument(
          cardInfo,
          [PaymentType.GIFT_CARD, PaymentType.EMAIL_GIFT_CARD].includes(
            paymentType,
          ),
        ),
      ),
    [dispatch, paymentType],
  );
};

export const useSetGuestAnalyticsBeforePayment = () => {
  const orderId = useSelector(selectOrderId);
  const {
    email,
    firstName,
    lastName,
    phoneNumber: phone,
  } = useSelector(selectCustomerInfo) ?? {};

  const setAnalytics = () => {
    getAnalytics().trackPaymentInfoEntered({ orderId });
    getAnalytics().identifyGuest({ firstName, lastName, email, phone });
  };

  return { setAnalytics };
};

export const useSetBorwserInfo = () => {
  const { initOrderResponse } = useContext(NewPaymentContext);
  const handleIncError = useIncErrorHandler();

  const setBrowserInfo = body => {
    const modifiedBody = { ...body };
    if (
      initOrderResponse?.attributes?.ENABLED_3DS2?.toLowerCase() === 'true' &&
      [
        paymentOptionToPaymentTypeMap.CARD,
        paymentOptionToPaymentTypeMap.GUEST_CARD,
      ].includes(modifiedBody.paymentType)
    ) {
      if (!modifiedBody.additionalAttributes)
        modifiedBody.additionalAttributes = {};
      try {
        modifiedBody.additionalAttributes.BROWSER_INFO =
          window.Spreedly.ThreeDS.serialize(
            '05',
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
          );
      } catch (e) {
        handleIncError(e, 'browser-info');
      }
    }
    return modifiedBody;
  };
  return setBrowserInfo;
};

export const useMakePayment = body => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const order = useSelector(selectOrder);
  const storeTitle = useSelector(selectRequestedStoreDITitle);
  const dispatch = useDispatch();
  const { setAnalytics } = useSetGuestAnalyticsBeforePayment();
  const setBrowserInfo = useSetBorwserInfo();

  const trigger = async (additionalData = {}) => {
    setLoading(true);
    try {
      if (!isLoggedIn) setAnalytics();
      const modifiedBody = setBrowserInfo(body);
      const endpoint = isLoggedIn ? 'makepayment' : 'makeguestpayment';
      const response = await INC_BASE_API.post(
        `/orders/${order.orderId}/${endpoint}`,
        { ...modifiedBody, ...additionalData },
        {
          authenticated: isLoggedIn ? true : undefined,
        },
      );

      getAnalytics().trackOrderCompleted(order, storeTitle);
      dispatch(updateRecommendationsOrder());

      return response;
    } catch (e) {
      throw new IncentivioApiError('makepayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const useHandleOutOfStockRequest = () => {
  const dispatch = useDispatch();

  return error => {
    if (
      ['ITEM_OUT_OF_STOCK', 'MODIFIER_OUT_OF_STOCK'].includes(
        getResponseErrorCode(error),
      )
    ) {
      dispatch(fetchOutOfStockItems());
    }
  };
};

export const usePaymentGatewayMatches = () => {
  const paymentContext = useContext(NewPaymentContext);

  return paymentGatewayTypes =>
    paymentGatewayTypes.includes(paymentContext?.paymentGatewayType);
};

export const useProcessingUrl = () => {
  const orderId = useSelector(selectOrderId);

  return `/checkout/processing/${orderId}`;
};

export const usePurchaseEmailGiftCardBody = paymentToken => {
  const defaultGiftCardLocation = useSelector(selectGiftCardDefaultLocation);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { paymentOption } = useContext(NewPaymentContext);
  const {
    firstName,
    lastName,
    email,
    phoneNumber: phone,
  } = useSelector(selectCustomerInfo) ?? {};
  const giftcardData = useContext(EmailGiftcardPurchaseContext) ?? {};

  const body = {
    amount: giftcardData.amount * 1000,
    locationId: defaultGiftCardLocation,
    isOneTimeTransaction: !isLoggedIn,
    paymentToken,
    paymentType: paymentOptionToPaymentTypeMap[paymentOption],
    paymentMode: 'PAYMENT_MANDATORY',
    sourceType: 'WEB',
    recipient: {
      name: giftcardData.to,
      email: giftcardData.recipientEmail,
      message: giftcardData.message,
    },
    sender: {
      firstName,
      lastName,
      email,
      phone,
    },
    from: giftcardData.from,
  };

  return body;
};

export const useIsApplePayEnabled = () => {
  const initOrderResponse = useContext(NewPaymentContext)?.initOrderResponse;

  return (
    initOrderResponse?.attributes?.applepayenabled === 'true' ||
    (getSpreedlyPaymentProcessorType(initOrderResponse) ===
      SpreedlyPaymentProcessorType.TOAST &&
      initOrderResponse?.attributes?.TOAST_APPLE_PAY_ENABLED === 'true')
  );
};

export const useValidateRecommendationStatus = () => {
  const recommendationsEnabled = useSelector(selectRecommendationsEnabled);
  const orderItems = useSelector(selectOrderItems);
  const dispatch = useDispatch();

  const validate = async () => {
    if (!recommendationsEnabled) return;

    for (const item of orderItems) {
      if (!item.additionalAttributes?.recommendation_status)
        await dispatch(
          updateOrderItemStartAsync({
            ...item,
            groupId: item.group.groupId,
            options: addGroupIdToOptions(item.options),
            additionalAttributes: { recommendation_status: 'PRE' },
          }),
        );
    }
  };

  return { validate };
};

export const useMakePaymentBody = () => {
  const { email, firstName, lastName, phoneNumber } =
    useSelector(selectCustomerInfo) ?? {};
  const deliveryInstructions = useSelector(selectDeliveryInstructions);
  const orderNote = useSelector(selectOrderInstructions);
  const orderOptionInfo = useSelector(selectOrderOptionInfo);
  const tip = useSelector(selectTip) * 1000;
  const isFreeOrder = useSelector(selectIsFreeOrder);
  const paymentInstrumentId = useSelector(selectPaymentInstrumentId);
  const paymentInstrumentSelector = useCallback(
    state => selectPaymentInstrumentById(paymentInstrumentId)(state),
    [paymentInstrumentId],
  );
  const paymentInstrument = useSelector(paymentInstrumentSelector);
  const paymentMode = usePaymentMode();
  const { paymentOption } = useContext(NewPaymentContext);

  let data = {
    email,
    firstName,
    lastName,
    phone: phoneNumber,
    deliveryInstructions,
    orderNote,
    orderOptionInfo,
    gratuity: tip === 0 ? null : tip,
    isOneTimeTransaction: false,
    savePaymentInstrument: false,
    sourceType: 'WEB',
    paymentMode,
  };

  if (isFreeOrder) {
    data.paymentType = 'PAYMENT_SKIPPED';
  } else if (paymentMode === 'NO_PAYMENT') {
    data.paymentType = 'NO_PAYMENT';
  } else {
    data.paymentType = paymentOptionToPaymentTypeMap[paymentOption];
    switch (paymentOption) {
      case PaymentOptionType.CARD:
        data.paymentToken = paymentInstrument?.paymentToken;
        break;

      case PaymentOptionType.GIFT_CARD:
        data.paymentToken = paymentInstrumentId;
        data.cardType = paymentInstrument?.cardType;
        break;

      case PaymentOptionType.APPLE_PAY:
        data.paymentToken = 'ONE_TIME_PAYMENT';
        break;

      default:
        break;
    }
  }

  return data;
};

export const usePaymentGiftCardAsAGuest = () => {
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const trigger = async () => {
    const form = document.getElementById('guest-gift-card-payment-form');
    form.requestSubmit();
    const giftCardNumber = document.getElementById('giftCardNumberInput').value;
    const securityCodeElement = document.getElementById('cardSecurityCode');

    const isSecurityCodeRequired = !!securityCodeElement
      ? securityCodeElement.value
      : true;

    if (!!giftCardNumber && isSecurityCodeRequired) {
      const lookupResponse = await addPromise(
        lookupGiftCard({
          cardNumber: giftCardNumber,
          cardSecurityCode: securityCodeElement?.value,
          errorOnAssigned: true,
        }),
      );

      return { lookupToken: lookupResponse?.data?.token, giftCardNumber };
    }
  };
  return { trigger };
};
