import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from './checkout-section.context';
import * as S from './checkout-section.styles';

const CheckoutSectionBase = ({
  disabled = false,
  children,
  context,
  headerProps = {},
  changeButtonProps = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const { id, title, editing, editable, setEditing, submit, cancel, errors } =
    context;

  return (
    <Provider value={context}>
      <S.CustomPaper
        elevation={0}
        p={[2, 2, 2, 1]}
        component={Box}
        display="flex"
        flexDirection="column"
        mb={[2, 2, 2, 1]}
        id={id}
        error={!!errors ? 'true' : undefined}
        data-testid="CheckoutSectionBase.CustomPaper"
        {...rest}
      >
        <S.DisabledBackdrop open={disabled}></S.DisabledBackdrop>
        {(title || editable) && (
          <Box
            display="flex"
            mb={1}
            justifyContent="space-between"
            alignItems="center"
            zIndex="10"
          >
            {title && (
              <S.SectionHeader
                data-testid="CheckoutSectionBase.SectionHeader"
                {...headerProps}
              >
                {title}
              </S.SectionHeader>
            )}
            {editable && !editing && (
              <Box my="-6px">
                <IncButton
                  variant="text"
                  onClick={setEditing}
                  data-testid="CheckoutSectionBase.IncButton.Change"
                  {...changeButtonProps}
                >
                  {t('checkout.section.changeButton.label')}
                </IncButton>
              </Box>
            )}
          </Box>
        )}
        <Box zIndex="10">{children}</Box>
        {errors && (
          <Box mt={1} data-testid="CheckoutSectionBase.Box.Errors">
            <Alert severity="error" icon={<WarningIcon />}>
              {errors}
            </Alert>
          </Box>
        )}
        {editable && editing && (
          <Box mt={1} display="flex" justifyContent="flex-end" zIndex="10">
            <Box mr={1}>
              <IncButtonDark
                size="small"
                onClick={cancel}
                data-testid="CheckoutSectionBase.IncButtonDark.Cancel"
              >
                Cancel
              </IncButtonDark>
            </Box>
            <IncButton
              size="small"
              onClick={submit}
              data-testid="CheckoutSectionBase.IncButton.Save"
            >
              Save
            </IncButton>
          </Box>
        )}
      </S.CustomPaper>
    </Provider>
  );
};

export default CheckoutSectionBase;
