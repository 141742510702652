import React, { useCallback, useMemo } from 'react';
import MenuCardNew from '../menu-card-new.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import * as S from './menu-card-new-group.styles';
import MenuCardNewGroupContent from './menu-card-new-group-content/menu-card-new-group-content.component';
import MenuCardNewImage from '../menu-card-new-image/menu-card-new-image.component';

const MenuCardNewGroup = ({ group, handleSetGroup, ...rest }) => {
  const { smallImage, title, shortDescription } = useDisplayInfo(
    group.displayInfo,
  );

  const onClick = useCallback(() => {
    handleSetGroup(group);
  }, [group, handleSetGroup]);

  const size = useMemo(
    () => group.items?.length + group.subGroups?.length,
    [group],
  );

  return (
    <MenuCardNew onClick={onClick} onKeyPress={() => {}} {...rest}>
      <S.Container>
        <MenuCardNewImage smallImage={smallImage} />
        <MenuCardNewGroupContent
          title={title}
          description={shortDescription}
          size={size}
        />
      </S.Container>
    </MenuCardNew>
  );
};

export default MenuCardNewGroup;
