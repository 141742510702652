import { useSelector } from 'react-redux';
import {
  selectDietaryDisplayInfo,
  selectSignUpPreferencesInfoDisabled,
} from 'redux/config/config.selectors';
import { selectUserDietaryPreferences } from 'redux/user/user.selectors';
import MultiSelect from '../multi-select/multi-select.component';
import SingleSelect from '../single-select/single-select.component';

const Dietary = () => {
  const dietaryDispalyInfo = useSelector(selectDietaryDisplayInfo);
  const userDietaryPreferences = useSelector(selectUserDietaryPreferences);
  const signUpPreferencesInfoDisabled = useSelector(
    selectSignUpPreferencesInfoDisabled,
  );

  if (signUpPreferencesInfoDisabled) return null;

  if (dietaryDispalyInfo.multiSelect) {
    return (
      <MultiSelect
        info={dietaryDispalyInfo}
        userDietaryPreferences={userDietaryPreferences}
        name="DIETARY_PREFERENCES"
        testIdPrefix="Dietary"
      />
    );
  }

  return (
    <SingleSelect
      info={dietaryDispalyInfo}
      userDietaryPreferences={userDietaryPreferences}
      name="DIETARY_PREFERENCES"
      testIdPrefix="Dietary"
    />
  );
};

export default Dietary;
