import { Box } from '@material-ui/core';
import React from 'react';

const Amount = ({ value, label }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={1}
      px={2}
    >
      <Box fontSize="1.5rem">${value}</Box>
      <Box fontSize="0.75rem">{label}</Box>
    </Box>
  );
};

export default Amount;
