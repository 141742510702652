import React from 'react';
import AddressControlsField from '../address-controls-field/address-controls-field.component';

const LoggedInAddressControls = ({ ...rest }) => {
  return (
    <AddressControlsField
      dataTestId="LoggedInAddressControls.Label"
      {...rest}
    />
  );
};

export default LoggedInAddressControls;
