import React from 'react';

import * as S from './spinner.styles';

const Spinner = ({
  id = 'spinner',
  fade = true,
  size = 60,
  'data-testid': dataTestId = 'spinner',
}) => (
  <S.Container
    className="loading-spinner"
    data-testid={dataTestId}
    fade={fade?.toString()}
  >
    <S.CustomSpinner id={id} aria-label="spinner" size={size} />
  </S.Container>
);

export default Spinner;
