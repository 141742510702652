import PaymentErrorBoundary from 'components/payment/payment-error-boundary/payment-error-boundary.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GiftcardPurchaseSteps from './giftcard-purchase-steps/giftcard-purchase-steps.component';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
} from '../giftcard.styles';
import { useSelector } from 'react-redux';
import { selectIsEmailGiftcardEnabled } from 'redux/config/config.selectors';
import GiftcardDisabledPage from '../giftcard-disabled/giftcard-disabled.component';

const GiftcardPurchasePage = () => {
  const { t } = useTranslation();
  const isEmailGiftCardEnabled = useSelector(selectIsEmailGiftcardEnabled);

  if (!isEmailGiftCardEnabled) return <GiftcardDisabledPage />;

  return (
    <ContentContainer
      elevation={2}
      data-testid={'GiftcardPurchasePage.ContentContainer'}
    >
      <ContentHeader
        component="h1"
        variant="h5"
        data-testid={'GiftcardPurchasePage.ContentHeader'}
      >
        {t('eGiftCard.purchase.header')}
      </ContentHeader>
      <ContentBody data-testid={'GiftcardPurchasePage.ContentBody'}>
        <PaymentErrorBoundary name="payment-egiftcard">
          <GiftcardPurchaseSteps />
        </PaymentErrorBoundary>
      </ContentBody>
    </ContentContainer>
  );
};

export default GiftcardPurchasePage;
