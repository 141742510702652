import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React, { useState } from 'react';
import * as S from './menu-group.styles';
import MenuGroup from './menu-group.component';

const MenuGroupContainer = ({ catalogId, group }) => {
  const [selectedGroup, setSelectedGroup] = useState(group);
  const { title: selectedGroupTitle } = useDisplayInfo(
    selectedGroup.displayInfo,
  );

  return (
    <S.GroupsContainer
      maxWidth="xl"
      id={group.groupId}
      aria-label={selectedGroupTitle}
      data-testid="CatalogGroup.GroupsContainer"
    >
      <MenuGroup
        catalogId={catalogId}
        group={group}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
      />
    </S.GroupsContainer>
  );
};

export default MenuGroupContainer;
