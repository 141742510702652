import { useSelector } from 'react-redux';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';
import { CATERING } from 'util/constants';
import { selectors } from 'redux/app/app.slice';
import CateringOrderingHours from './catering-ordering-hours/catering-ordering-hours.component';
import NonCateringOrderingHours from './non-catering-ordering-hours copy/non-catering-ordering-hours.component';

const OrderingHours = ({ store, disabled }) => {
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(store.storeId),
  );
  const locationType = useSelector(selectors.selectLocationsLocationType);

  if (disabled || isMenuBrowsingStore) return null;

  if (locationType === CATERING) return <CateringOrderingHours store={store} />;

  return <NonCateringOrderingHours store={store} />;
};

export default OrderingHours;
