import { Route } from 'react-router';
import ResetPassword from 'pages/reset-password/reset-password.component';
import VerifyEmail from 'pages/verify-email/verify-email.component';
import VerifySms from 'pages/verify-sms/verify-sms.component';

const AuthRoutes = () => [
  <Route path="/auth/resetpassword" component={ResetPassword} />,
  <Route path="/auth/verifyEmail" component={VerifyEmail} />,
  <Route path="/auth/verifySms" component={VerifySms} />,
];

export default AuthRoutes;
