import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsURLSearchParamMatch = (key, value) => {
  const urlSearchParams = useURLSearchParams();
  return useMemo(
    () => urlSearchParams.get(key) === value,
    [key, urlSearchParams, value],
  );
};

export const useURLSearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
