import { isValidAsapDuration } from 'pages/locations/locations.utils';
import { memo } from 'react';
import { getHoursText, getMinutesText } from 'redux/cart/cart.utils';

const AsapDurationLabel = ({ asapDuration }) => {
  if (!isValidAsapDuration(asapDuration)) return null;
  const minutes = asapDuration % 60;
  const hours = parseInt(asapDuration / 60);
  return (
    <>
      ({getHoursText(hours)}
      {getMinutesText(minutes, hours)})
    </>
  );
};

export default memo(AsapDurationLabel);
