import { dialogTitle } from 'components/custom-dialog/custom-dialog.constants';
import * as S from './message-title.styles';

const MessageTitle = ({ title, mediumImage }) => {
  return (
    <S.TitleBox $isImage={!!mediumImage}>
      <S.Title id={dialogTitle}>{title}</S.Title>
    </S.TitleBox>
  );
};

export default MessageTitle;
