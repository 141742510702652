/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useState } from 'react';

const Image = props => {
  const [errored, setErrored] = useState(false);

  return errored ? null : <img onError={() => setErrored(true)} {...props} />;
};

export default Image;
