import { Grid } from '@material-ui/core';
import { getPanelId, getTabId, useTabContext } from '@material-ui/lab';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Rover, useRoverState } from 'reakit';
import ApplePayPaymentOption from './payment-option/apple-pay-payment-option.component';
import CardPaymentOption from './payment-option/card-payment-option.component';
import GiftCardPaymentOption from './payment-option/gift-card-payment-option.component';
import GooglePayPaymentOption from './payment-option/google-pay-payment-option.component';
import GuestCardPaymentOption from './payment-option/guest-card-payment-option.component';
import GuestGiftCardPaymentOption from './payment-option/guest-gift-card-payment-option.component';
import PayLaterPaymentOption from './payment-option/pay-later-payment-option.component';

const paymentOptionTypeMap = {
  [PaymentOptionType.CARD]: CardPaymentOption,
  [PaymentOptionType.GUEST_CARD]: GuestCardPaymentOption,
  [PaymentOptionType.GOOGLE_PAY]: GooglePayPaymentOption,
  [PaymentOptionType.APPLE_PAY]: ApplePayPaymentOption,
  [PaymentOptionType.GIFT_CARD]: GiftCardPaymentOption,
  [PaymentOptionType.GUEST_GIFT_CARD]: GuestGiftCardPaymentOption,
  [PaymentOptionType.PAY_LATER]: PayLaterPaymentOption,
};

const PaymentOptions = ({ options = {}, onChange }) => {
  const { t } = useTranslation();
  const tabContext = useTabContext();
  const roverProps = useRoverState({
    currentId: getTabId(tabContext, tabContext.value),
  });

  const handleChange = useCallback(
    type => {
      onChange(type);
    },
    [onChange],
  );

  const enabledOptions = useMemo(
    () =>
      Object.entries(paymentOptionTypeMap).filter(
        ([mapOption]) => !!options[mapOption],
      ),
    [options],
  );

  useEffect(() => {
    if (
      !enabledOptions.some(
        enabledOption => enabledOption[0] === tabContext.value,
      )
    ) {
      handleChange(enabledOptions[0][0]);
    }
  }, [enabledOptions, handleChange, tabContext.value]);

  return (
    <Grid
      container
      spacing={1}
      data-testid="PaymentOptions"
      aria-label={t('paymentSelector.paymentOptions')}
      role="tablist"
    >
      {enabledOptions.map(([type, Component]) => (
        <Grid
          item
          xs={12}
          sm={enabledOptions.length === 1 ? 4 : true}
          key={type}
        >
          <Rover
            {...roverProps}
            as={Component}
            id={getTabId(tabContext, type)}
            onClick={handleChange}
            role="tab"
            aria-controls={getPanelId(tabContext, type)}
            selected={type === tabContext.value}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default PaymentOptions;
