import * as S from './thank-you.styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Container, Link, Typography } from '@material-ui/core';
import VerifyCode from 'components/verify-code/verify-code.component.jsx';
import { EMAIL_CODE, SMS_CODE } from 'redux/config/config.constants.js';
import { selectClientName } from 'redux/config/config.selectors';
import { useHistory } from 'react-router-dom';
import ResendCode from 'components/verify-code/resend-code/resend-code.component';

const Thankyou = ({
  state,
  verificationMethod,
  verificationMessage,
  label,
}) => {
  const { t } = useTranslation();
  const clientName = useSelector(selectClientName);
  const { push } = useHistory();

  const handleProceedToClick = () => {
    push('/');
  };

  const showVerifyCodeUi =
    !state.verified && [SMS_CODE, EMAIL_CODE].includes(verificationMethod);

  const isVerificationLink =
    !state.verified && ![SMS_CODE, EMAIL_CODE].includes(verificationMethod);

  return (
    <S.ThankYouWrapper>
      <S.ThankyouMessage gutterBottom variant="h5">
        {t('signUp.thankyouMessage')}
      </S.ThankyouMessage>

      {isVerificationLink && (
        <Typography variant="body2" aria-label={verificationMessage}>
          {verificationMessage}
        </Typography>
      )}

      {showVerifyCodeUi ? (
        <Container maxWidth="xs">
          <VerifyCode label={label} state={state} />
        </Container>
      ) : (
        <Typography gutterBottom variant="h6">
          <Link
            component="button"
            onClick={handleProceedToClick}
            variant="h6"
            aria-label={t('signUp.linkText', { clientName })}
          >
            {t('signUp.linkText', { clientName })}
          </Link>
        </Typography>
      )}

      {isVerificationLink && <ResendCode email={state.email} />}
    </S.ThankYouWrapper>
  );
};

export default Thankyou;
