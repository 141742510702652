import i18n from 'i18n';
import { useEffect, useMemo } from 'react';
import { PaymentGatewayType } from './payment.constants';

export const usePaymentConnector = (initOrderAttributes, initOrderLoading) => {
  const isSquare = useMemo(
    () => !!initOrderAttributes?.applicationId,
    [initOrderAttributes],
  );

  const isSpreedly = useMemo(
    () => !!initOrderAttributes?.hostedPaymentPageHtml,
    [initOrderAttributes],
  );

  const isIncentivioPayments = useMemo(
    () =>
      initOrderAttributes?.gateway === PaymentGatewayType.INCENTIVIO_PAYMENTS,
    [initOrderAttributes],
  );

  const isClover = useMemo(
    () => initOrderAttributes?.gateway === PaymentGatewayType.CLOVER,
    [initOrderAttributes],
  );

  useEffect(() => {
    if (
      !initOrderLoading &&
      !!initOrderAttributes &&
      !isSquare &&
      !isSpreedly &&
      !isIncentivioPayments &&
      !isClover
    ) {
      throw new Error(i18n.t('payment.errors.unsupportedPaymentConfiguration'));
    }
  }, [
    initOrderAttributes,
    initOrderLoading,
    isIncentivioPayments,
    isSpreedly,
    isClover,
    isSquare,
  ]);
};
