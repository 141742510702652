import styled from 'styled-components';

export const LoyaltyCustomLogoHeaderContainer = styled.div`
  height: 130px;
  position: relative;
`;

export const LoyaltyLogoBox = styled.div`
  height: 65px;
  width: 100%;
  border-radius: 0.3em;
  background-color: ${({ theme, color }) =>
    color ?? theme.palette.background.paper};
`;

export const LoyaltyCustomLogoContainer = styled.button`
  position: absolute;
  top: 0;
  height: 130px;
  background: none;
  border: none;
  cursor: pointer;
  margin: auto;
  width: 100%;
`;
