import { Box } from '@material-ui/core';
import * as S from './ordering-time-slots.styles';
import OrderingTimeSlot from './ordering-time-slot/ordering-time-slot.component';

const OrderingTimeSlots = ({ title, durations, titleTestId }) => {
  if (durations.length === 0) return null;

  return (
    <Box data-testid="OrderingHours.Box">
      <S.Title data-testid={titleTestId}>{title}</S.Title>
      <S.TimeSlotList>
        {durations.map(duration => (
          <OrderingTimeSlot
            duration={duration}
            key={duration.startTime.toISO()}
          />
        ))}
      </S.TimeSlotList>
    </Box>
  );
};

export default OrderingTimeSlots;
