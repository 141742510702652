import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectShouldLoadRecaptcha } from 'redux/config/config.selectors';

export const useAwaitRecaptcha = () => {
  const shouldLoadRecaptcha = useSelector(selectShouldLoadRecaptcha);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(!shouldLoadRecaptcha);

  useEffect(() => {
    window.onRecaptchaLoad = () => {
      setRecaptchaLoaded(true);
    };
  }, []);

  return recaptchaLoaded;
};
