import { Brightness4, Brightness7 } from '@material-ui/icons';
import { ThemeIconButton } from 'components/header/header-togglers/header-tooltip-icon/header-tooltip.styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'redux/app/app.slice';
import { DARK_THEME, DEFAULT_THEME } from 'theme.constants';

const ThemeToggle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const savedTheme = useSelector(selectors.selectTheme);
  const toggleTheme = useCallback(() => {
    const newTheme = savedTheme === DARK_THEME ? DEFAULT_THEME : DARK_THEME;
    dispatch(actions.setTheme(newTheme));
  }, [dispatch, savedTheme]);

  return (
    <ThemeIconButton
      tooltipText={
        savedTheme === DARK_THEME
          ? t('header.themeToggle.changeToLight')
          : t('header.themeToggle.changeToDark')
      }
      onClick={toggleTheme}
      icon={savedTheme === DARK_THEME ? <Brightness7 /> : <Brightness4 />}
      data-testid="ThemeToggle.HeaderTooltipIcon"
      focusRipple={false}
    />
  );
};

export default ThemeToggle;
