import { InputLabel, TextField } from '@material-ui/core';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSecurityCodeEnabled } from 'redux/config/config.selectors';

const GiftCardSecurityCodeInput = ({ label }) => {
  const securityCodeEnabled = useSelector(selectSecurityCodeEnabled);
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (!securityCodeEnabled) return null;

  const { ref, ...registerProps } = register('cardSecurityCode', {
    required: true,
  });

  return (
    <CustomInput>
      <InputLabel htmlFor="cardSecurityCode">
        {label ?? t('giftCardSecurityCode.inputLabel')}*
      </InputLabel>
      <TextField
        id="cardSecurityCode"
        inputProps={{
          'aria-required': true,
        }}
        {...registerProps}
        inputRef={ref}
        fullWidth
        inputMode="numeric"
        onKeyPress={event => {
          if (!/[0-9]/.test(event.key) || event.target.value.length > 14) {
            event.preventDefault();
          }
        }}
      />
      {errors.cardSecurityCode && (
        <FormError errorMessage={t('register.errors.requiredField')} />
      )}
    </CustomInput>
  );
};

export default GiftCardSecurityCodeInput;
