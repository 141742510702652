export const PaymentOptionType = Object.freeze({
  CARD: 'CARD',
  GUEST_CARD: 'GUEST_CARD',
  GIFT_CARD: 'GIFT_CARD',
  GUEST_GIFT_CARD: 'GUEST_GIFT_CARD',
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY: 'APPLE_PAY',
  PAY_LATER: 'PAY_LATER',
});

export const PaymentGatewayType = Object.freeze({
  INCENTIVIO_PAYMENTS: 'incentivio-payments',
  SPREEDLY: 'incentivio-conpay-spreedly',
  SQUARE: 'incentivio-conpos-square',
  CLOVER: 'incentivio-conpos-clover',
});

export const PaymentType = Object.freeze({
  ORDER: 'ORDER',
  GIFT_CARD: 'GIFT_CARD',
  EMAIL_GIFT_CARD: 'EMAIL_GIFT_CARD',
});

export const PaymentInstrumentType = Object.freeze({
  CARD: 'Card',
  GIFT_CARD: 'Gift Card',
});

export const paymentOptionToPaymentTypeMap = {
  [PaymentOptionType.CARD]: 'CARD_NOT_PRESENT',
  [PaymentOptionType.GUEST_CARD]: 'CARD_NOT_PRESENT',
  [PaymentOptionType.GIFT_CARD]: 'GIFT_CARD',
  [PaymentOptionType.GOOGLE_PAY]: 'GOOGLE_PAY',
  [PaymentOptionType.APPLE_PAY]: 'APPLE_PAY',
  [PaymentOptionType.PAY_LATER]: 'NO_PAYMENT',
  [PaymentOptionType.GUEST_GIFT_CARD]: 'GIFT_CARD',
};

export const SpreedlyPaymentProcessorType = Object.freeze({
  TOAST: 'TOAST',
  OTHER: 'OTHER',
});
