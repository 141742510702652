import { Box } from '@material-ui/core';
import React from 'react';
import Theme from 'Theme';
import { useViewMessageInbox } from '../view-message-inbox/view-message-inbox.hooks';
import AccountInboxListView from 'pages/account-inbox/account-inbox-list-view/account-inbox-list-view.component';

const ViewMessagesList = () => {
  const props = useViewMessageInbox();

  return (
    <Theme>
      <Box data-testid="ViewMessages.List">
        <AccountInboxListView {...props} disableOnClick />
      </Box>
    </Theme>
  );
};

export default ViewMessagesList;
