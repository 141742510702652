import React from 'react';
import * as S from './payment-option.styles';

const PaymentOption = React.forwardRef(
  ({ icon, avatar, title, type, onClick, selected = false, ...rest }, ref) => {
    const handleClick = e => onClick(type, e);

    return (
      <S.PaymentOptionButton
        {...rest}
        selected={selected}
        onClick={handleClick}
        ref={ref}
        data-testid="PaymentOption"
        focusRipple
      >
        <S.PaymentOptionCardHeader
          avatar={avatar ? avatar : <S.IconAvatar>{icon}</S.IconAvatar>}
          title={title}
        />
      </S.PaymentOptionButton>
    );
  },
);
export default PaymentOption;
