import { Typography } from '@material-ui/core';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import styled from 'styled-components';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

export const AdditionalStoreText = styled(LinkifyParse)`
  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DeliveryRadiusError = styled(Typography)`
  flex: 1;
  margin-left: 10px;
`;

export const ErrorIcon = styled(ReportProblemOutlinedIcon)`
  align-items: center;
  margin: auto;
`;
