import React from 'react';
import * as S from './tab-image.styles';

const TabImage = ({ selected, ...rest }) => {
  return (
    <S.ImageWrapper selected={selected}>
      <S.CustomAvatar {...rest} />
    </S.ImageWrapper>
  );
};

export default TabImage;
