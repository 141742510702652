import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import Grid from '@material-ui/core/Grid';

export const Title = styled(Typography)`
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
`;

export const SubHead = styled(Typography)`
  font-size: 1.125rem;
  margin-bottom: 2em;
  text-align: center;
`;

export const LoyaltyGridContainer = styled(Grid)`
  margin-bottom: 1em;
  max-height: 310px;
  overflow-y: auto;
`;

export const CustomQRCode = styled(QRCode)`
  display: block;
  margin: 0 auto 1em;
  max-width: 100%;
  height: auto;
`;
