import { TabContext } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'use-resize-observer';
import { useDayTabsComponent } from './day-tabs.hooks';
import * as S from './day-tabs.styles';

const DayTabs = ({ availableDays, name, children, defaultValue }) => {
  const { days, value, handleChange } = useDayTabsComponent(
    availableDays,
    name,
    defaultValue,
  );
  const { t } = useTranslation();
  const [tabBasis, setTabBasis] = useState('0');
  const [tabsShown, setTabsShown] = useState(7);
  const onResize = ({ width }) => {
    const newTabsShown =
      tabsShown >= 7
        ? Math.floor(width / S.minTabWidth)
        : Math.floor((width - S.minTabWidth / 2) / S.minTabWidth);
    if (newTabsShown >= 7) {
      setTabBasis('0');
      setTabsShown(7);
    } else if (newTabsShown !== tabsShown) {
      setTabsShown(newTabsShown);
      setTabBasis(`calc(100% / ${newTabsShown}.5)`);
    }
  };
  const { ref } = useResizeObserver({
    onResize,
    box: 'border-box',
  });

  return (
    <TabContext value={value}>
      <S.DayTabList
        onChange={handleChange}
        id={name}
        data-testid="DayTabs.DayTabList"
        aria-label={t('orderDaySelector.ariaLabel')}
        variant="scrollable"
        ref={ref}
      >
        {days.map(day => (
          <S.DayTab
            label={
              <>
                <div>{day.dayString}</div>
                <div>{day.dayOfMonth}</div>
              </>
            }
            value={day.isoDateString}
            key={day.isoDateString}
            disabled={!day.enabled}
            data-testid="DayTabs.DayTab"
            flexbasis={tabBasis.toString()}
          />
        ))}
      </S.DayTabList>
      {children}
    </TabContext>
  );
};

export default DayTabs;
