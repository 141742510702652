import { useMemo } from 'react';
import { sortItemsByDisplayRank } from 'utils';

export const useGetOrderOptions = (store, orderType) => {
  const orderOptions = useMemo(
    () =>
      sortItemsByDisplayRank(
        orderType === 'DELIVERY'
          ? store?.deliveryOrderOptions
          : store?.pickupOrderOptions,
      ),
    [orderType, store],
  );

  const defaultOrderOption = orderOptions?.[0] ?? {};
  return { orderOptions, defaultOrderOption };
};
