import { Alert } from '@material-ui/lab';
import ActionLink from 'components/action-link/action-link.component';
import React from 'react';
import { Fade as RevealFade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { useDrawerLoginOptions } from 'components/drawer-login-options/drawer-login-options.hooks';
import { Box } from '@material-ui/core';

const CheckoutOffersSectionSignup = () => {
  const { t } = useTranslation();
  const { setDrawerState } = useDrawerLoginOptions();

  return (
    <RevealFade direction="right" duration={600} triggerOnce>
      <Alert
        severity="info"
        icon={<LocalOfferIcon />}
        data-testid="OffersSection"
      >
        <Box display="flex" alignItems="flex-end" lineHeight="normal">
          <ActionLink
            onClick={() => setDrawerState(true)}
            data-testid="OffersSection.ActionLink.SignUp"
          >
            {t('drawer.login.registerAction')}
          </ActionLink>
          <Box mx="2.5px">{t('checkout.offers.or')}</Box>
          <ActionLink
            onClick={() => setDrawerState(true)}
            data-testid="OffersSection.ActionLink.SignIn"
          >
            {t('drawer.login.loginAction')}
          </ActionLink>
          <Box ml="2.5px">{t('checkout.offers.viewAvailable')}</Box>
        </Box>
      </Alert>
    </RevealFade>
  );
};

export default CheckoutOffersSectionSignup;
