import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { tabValues } from './constants';
import { useTranslation } from 'react-i18next';
import { actions } from 'redux/data/orderavailability/orderavailability.slice';
import { useFormContext } from 'react-hook-form';
import { useFieldNames } from '../useFieldNames.hooks';
import { DateTime } from 'luxon';

export const useTodayOrScheduleSelector = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();
  const { fieldNames } = useFieldNames();
  const [showCalendar, setShowCalendar] = useState(true);

  const {
    defaultDay,
    store: { storeId },
    orderType,
    isTodayOff,
    todayDateTime,
  } = props;

  const [tabValue, setTabValue] = useState(tabValues.today);

  useEffect(() => {
    if (
      (defaultDay && defaultDay !== todayDateTime.current.toISODate()) ||
      isTodayOff
    )
      setTabValue(tabValues.schedule);
    else setTabValue(tabValues.today);
  }, [defaultDay, isTodayOff, todayDateTime]);

  const handleTabChange = (_e, val) => {
    setTabValue(val);
    if (val === tabValues.today) {
      dispatch(
        actions.fetchOrderAvailability({
          requestedOrderType: orderType,
          storeId,
          selectedDay: DateTime.local().toISODate(),
          address: getValues(fieldNames.address),
        }),
      );
      setValue(fieldNames.day, DateTime.local().toISODate());
    }
  };

  const tabs = [
    {
      label: t('todayOrScheduleSelector.schedule.label'),
      value: tabValues.schedule,
      'data-testid': 'TodayOrScheduleSelector.Tab.Schedule',
    },
  ];

  if (!isTodayOff)
    tabs.unshift({
      label: t('todayOrScheduleSelector.today.label'),
      value: tabValues.today,
      'data-testid': 'TodayOrScheduleSelector.Tab.Today',
    });

  return {
    tabValue,
    handleTabChange,
    tabs,
    showCalendar,
    setShowCalendar,
  };
};
