import Email from 'components/form/form-fields/email.component';
import FirstName from 'components/form/form-fields/first-name.component';
import LastName from 'components/form/form-fields/last-name.component';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import Form from '../form/form.component';
import InputPhone from '../input-phone/input-phone.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const GuestForm = ({ methods, customerInfo = {} }) => {
  const {
    register,
    formState: { errors },
  } = methods;

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isEmailDisabled = isLoggedIn && !!customerInfo.email;

  return (
    <FormProvider {...methods}>
      <Form>
        <MandatoryFieldsInstruction />
        <FirstName
          register={register}
          errors={errors}
          defaultValue={customerInfo.firstName}
        />

        <LastName
          register={register}
          errors={errors}
          defaultValue={customerInfo.lastName}
        />

        <Email
          register={register}
          errors={errors}
          defaultValue={customerInfo.email}
          disabled={isEmailDisabled}
        />

        <InputPhone
          defaultValue={customerInfo.phoneNumber}
          isGuest={!isLoggedIn}
        />
      </Form>
    </FormProvider>
  );
};

export default GuestForm;
