import { useTranslation } from 'react-i18next';
import * as S from './time-zone-text.styles';

const TimeZoneText = ({ timeZoneName }) => {
  const { t } = useTranslation();

  return (
    <S.TimeZone>{t('locations.card.timeZone', { timeZoneName })}</S.TimeZone>
  );
};

export default TimeZoneText;
