import { Box } from '@material-ui/core';
import React from 'react';
import * as S from './add-menu-item.styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const AddMenuItem = ({ Icon, title }) => {
  return (
    <S.Container>
      <Box display="flex">
        <AddCircleIcon color="primary" />
      </Box>
      <Box ml="1rem">
        <Box fontWeight="bold">{title}</Box>
      </Box>
    </S.Container>
  );
};

export default AddMenuItem;
