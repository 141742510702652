import Certificate from 'components/icons/certificate/certificate.component';

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const CustomCertificate = styled(Certificate)`
  animation-name: ${rotate};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
  animation-timing-function: linear;
`;
