import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AccountLoyaltyLogo from '../account-loyalty-logo/account-loyalty-logo.component';
import { selectLoyaltyPointImgs } from '../../../../redux/config/config.selectors';
import { LoyaltyGridContainer } from '../../account-loyalty.styles';
import * as S from './account-loyalty-custom-two.styles';
import AccountLoyaltyCustomTwoPunch from './account-loyalty-custom-two-punch/account-loyalty-custom-two-punch.component';
import LoyaltySubTitles from '../loyalty-subtitles/loyalty-subtitles.component';
import { useHistoryView } from 'components/account-loyalty/account-loyalty.hooks';
import BackToRewards from '../back-to-rewards/back-to-rewards.component';
import Messages from 'pages/account-inbox/messages/messages.component';
import AccountLoyaltyActivity from '../account-loyalty-activity/account-loyalty-activity.component';
import { LoyaltyContext } from 'components/account-loyalty/account-loyalty.context';

const AccountLoyaltyCustomTwo = ({ points }) => {
  const loyaltyPointImgs = useSelector(selectLoyaltyPointImgs);
  const { handleHistoryClick, handleRewardsClick, showHistory } =
    useHistoryView();
  const loyaltyContent = useContext(LoyaltyContext);

  return (
    <>
      <BackToRewards
        handleRewardsClick={handleRewardsClick}
        hidden={!showHistory}
      />
      {!showHistory ? (
        <>
          <LoyaltySubTitles
            handleHistoryClick={handleHistoryClick}
            hidden={showHistory}
          />
          <AccountLoyaltyLogo />
          {loyaltyPointImgs[2] && (
            <S.LoyaltyBannerOne src={loyaltyPointImgs[2]} alt="" />
          )}
          <LoyaltyGridContainer container spacing={2}>
            <AccountLoyaltyCustomTwoPunch
              points={points}
              loyaltyPointImgs={loyaltyPointImgs}
            />
          </LoyaltyGridContainer>
          <Messages {...loyaltyContent} />
        </>
      ) : (
        <AccountLoyaltyActivity />
      )}
    </>
  );
};

export default AccountLoyaltyCustomTwo;
