import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LocationOn } from '@material-ui/icons';

const StoreDistance = ({ distanceToUserInMiles }) => {
  const { t } = useTranslation();

  if (distanceToUserInMiles === null) return null;

  return (
    <Box display="flex" whiteSpace="nowrap" marginLeft="1rem">
      <LocationOn />
      <Typography>
        {distanceToUserInMiles} {t('locations.card.miles')}
      </Typography>
    </Box>
  );
};

export default StoreDistance;
