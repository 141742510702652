import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectStoreDI } from 'redux/locations/locations.selectors';

export const useStoreDisplayInfo = store => {
  const selectStoreDI = useCallback(
    state => makeSelectStoreDI(store)(state),
    [store],
  );
  const storeDI = useSelector(selectStoreDI);

  return { storeDITitle: storeDI.title };
};
