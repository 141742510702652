const UserTypes = {
  SIGN_UP_USER_START: 'SIGN_UP_USER_START',
  SIGN_UP_USER_SUCCESS: 'SIGN_UP_USER_SUCCESS',
  SIGN_UP_USER_FAILURE: 'SIGN_UP_USER_FAILURE',
  SIGN_IN_USER_START: 'SIGN_IN_USER_START',
  SIGN_IN_USER_SUCCESS: 'SIGN_IN_USER_SUCCESS',
  SIGN_IN_USER_FAILURE: 'SIGN_IN_USER_FAILURE',
  REFRESH_TOKEN_START: 'REFRESH_TOKEN_START',
  SIGN_OUT: 'SIGN_OUT',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  FETCH_USER_DATA_START: 'FETCH_USER_DATA_START',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILURE: 'FETCH_USER_DATA_FAILURE',
  REQUEST_PW_RESET_START: 'REQUEST_PW_RESET_START',
  REQUEST_PW_RESET_SUCCESS: 'REQUEST_PW_RESET_SUCCESS',
  REQUEST_PW_RESET_FAILURE: 'REQUEST_PW_RESET_FAILURE',
  FETCH_ADDRESSES_START: 'FETCH_ADDRESSES_START',
  FETCH_ADDRESSES_SUCCESS: 'FETCH_ADDRESSES_SUCCESS',
  FETCH_ADDRESSES_FAILURE: 'FETCH_ADDRESSES_FAILURE',
  FETCH_PAYMENT_INSTRUMENTS_START: 'FETCH_PAYMENT_INSTRUMENTS_START',
  FETCH_PAYMENT_INSTRUMENTS_SUCCESS: 'FETCH_PAYMENT_INSTRUMENTS_SUCCESS',
  FETCH_PAYMENT_INSTRUMENTS_FAILURE: 'FETCH_PAYMENT_INSTRUMENTS_FAILURE',
  ADD_ADDRESS_START: 'ADD_ADDRESS_START',
  ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',
  DELETE_ADDRESS_START: 'DELETE_ADDRESS_START',
  DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILURE: 'DELETE_ADDRESS_FAILURE',
  UPDATE_ADDRESS_START: 'UPDATE_ADDRESS_START',
  UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',
  UPDATE_USER_DATA_START: 'UPDATE_USER_DATA_START',
  UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE: 'UPDATE_USER_DATA_FAILURE',
  UPDATE_PASSWORD_START: 'UPDATE_PASSWORD_START',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
  SET_UNREAD_MESSAGES_COUNT: 'SET_UNREAD_MESSAGES_COUNT',
  GET_DISTRIBUTED_MESSAGE_DATA_START: 'GET_DISTRIBUTED_MESSAGE_DATA_START',
  GET_DISTRIBUTED_MESSAGE_DATA_SUCCESS: 'GET_DISTRIBUTED_MESSAGE_DATA_SUCCESS',
  GET_DISTRIBUTED_MESSAGE_DATA_FAILURE: 'GET_DISTRIBUTED_MESSAGE_DATA_FAILURE',
  GET_DISTRIBUTED_OFFER_DATA_START: 'GET_DISTRIBUTED_OFFER_DATA_START',
  GET_DISTRIBUTED_OFFER_DATA_SUCCESS: 'GET_DISTRIBUTED_OFFER_DATA_SUCCESS',
  GET_DISTRIBUTED_OFFER_DATA_FAILURE: 'GET_DISTRIBUTED_OFFER_DATA_FAILURE',
  GET_ACTIVITY_BASED_CONTENT_START: 'GET_ACTIVITY_BASED_CONTENT_START',
  GET_ACTIVITY_BASED_CONTENT_SUCCESS: 'GET_ACTIVITY_BASED_CONTENT_SUCCESS',
  GET_ACTIVITY_BASED_CONTENT_FAILURE: 'GET_ACTIVITY_BASED_CONTENT_FAILURE',
  DELETE_ACTIVITY_BASED_CONTENT: 'DELETE_ACTIVITY_BASED_CONTENT',
  REDEEM_OFFER_BY_ID_START: 'REDEEM_OFFER_BY_ID_START',
  REDEEM_OFFER_BY_ID_SUCCESS: 'REDEEM_OFFER_BY_ID_SUCCESS',
  REDEEM_OFFER_BY_ID_FAILURE: 'REDEEM_OFFER_BY_ID_FAILURE',
  GET_ORDER_HISTORY_START: 'GET_ORDER_HISTORY_START',
  GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
  GET_ORDER_HISTORY_FAILURE: 'GET_ORDER_HISTORY_FAILURE',
  ADD_PAYMENT_INSTRUMENT_START: 'ADD_PAYMENT_INSTRUMENT_START',
  ADD_PAYMENT_INSTRUMENT_SUCCESS: 'ADD_PAYMENT_INSTRUMENT_SUCCESS',
  ADD_PAYMENT_INSTRUMENT_FAILURE: 'ADD_PAYMENT_INSTRUMENT_FAILURE',
  EDIT_PAYMENT_INSTRUMENT_START: 'EDIT_PAYMENT_INSTRUMENT_START',
  EDIT_PAYMENT_INSTRUMENT_SUCCESS: 'EDIT_PAYMENT_INSTRUMENT_SUCCESS',
  EDIT_PAYMENT_INSTRUMENT_FAILURE: 'EDIT_PAYMENT_INSTRUMENT_FAILURE',
  GET_GIFT_CARD_DATA_START: 'GET_GIFT_CARD_DATA_START',
  GET_GIFT_CARD_DATA_SUCCESS: 'GET_GIFT_CARD_DATA_SUCCESS',
  GET_GIFT_CARD_DATA_FAILURE: 'GET_GIFT_CARD_DATA_FAILURE',
  ADD_GIFT_CARD_VALUE_START: 'ADD_GIFT_CARD_VALUE_START',
  ADD_GIFT_CARD_VALUE_SUCCESS: 'ADD_GIFT_CARD_VALUE_SUCCESS',
  ADD_GIFT_CARD_VALUE_FAILURE: 'ADD_GIFT_CARD_VALUE_FAILURE',
  DELETE_PAYMENT_INSTRUMENT_START: 'DELETE_PAYMENT_INSTRUMENT_START',
  DELETE_PAYMENT_INSTRUMENT_SUCCESS: 'DELETE_PAYMENT_INSTRUMENT_SUCCESS',
  DELETE_PAYMENT_INSTRUMENT_FAILURE: 'DELETE_PAYMENT_INSTRUMENT_FAILURE',
  BUY_GIFT_CARD_START: 'BUY_GIFT_CARD_START',
  BUY_GIFT_CARD_SUCCESS: 'BUY_GIFT_CARD_SUCCESS',
  BUY_GIFT_CARD_FAILURE: 'BUY_GIFT_CARD_FAILURE',
  GET_LOYALTY_ACCOUNTS_START: 'GET_LOYALTY_ACCOUNTS_START',
  GET_LOYALTY_ACCOUNTS_SUCCESS: 'GET_LOYALTY_ACCOUNTS_SUCCESS',
  GET_LOYALTY_ACCOUNTS_FAILURE: 'GET_LOYALTY_ACCOUNTS_FAILURE',
  GET_LOYALTY_ACCOUNTS_HISTORY_START: 'GET_LOYALTY_ACCOUNTS_HISTORY_START',
  GET_LOYALTY_ACCOUNTS_HISTORY_SUCCESS: 'GET_LOYALTY_ACCOUNTS_HISTORY_SUCCESS',
  GET_LOYALTY_ACCOUNTS_HISTORY_FAILURE: 'GET_LOYALTY_ACCOUNTS_HISTORY_FAILURE',
  FETCH_SURVEY_FIELDS_START: 'FETCH_SURVEY_FIELDS_START',
  FETCH_SURVEY_FIELDS_SUCCESS: 'FETCH_SURVEY_FIELDS_SUCCESS',
  FETCH_SURVEY_FIELDS_FAILURE: 'FETCH_SURVEY_FIELDS_FAILURE',
  SUBMIT_SURVEY_ANSWERS_START: 'SUBMIT_SURVEY_ANSWERS_START',
  SUBMIT_SURVEY_ANSWERS_SUCCESS: 'SUBMIT_SURVEY_ANSWERS_SUCCESS',
  SUBMIT_SURVEY_ANSWERS_FAILURE: 'SUBMIT_SURVEY_ANSWERS_FAILURE',
  UPLOAD_PROFILE_PIC_START: 'UPLOAD_PROFILE_PIC_START',
  UPLOAD_PROFILE_PIC_SUCCESS: 'UPLOAD_PROFILE_PIC_SUCCESS',
  UPLOAD_PROFILE_PIC_FAILURE: 'UPLOAD_PROFILE_PIC_FAILURE',
  SET_PROFILE_PIC_START: 'SET_PROFILE_PIC_START',
  SET_PROFILE_PIC_SUCCESS: 'SET_PROFILE_PIC_SUCCESS',
  SET_PROFILE_PIC_FAILURE: 'SET_PROFILE_PIC_FAILURE',
  ADD_EXISTING_GIFT_CARD_START: 'ADD_EXISTING_GIFT_CARD_START',
  ADD_EXISTING_GIFT_CARD_SUCCESS: 'ADD_EXISTING_GIFT_CARD_SUCCESS',
  ADD_EXISTING_GIFT_CARD_FAILURE: 'ADD_EXISTING_GIFT_CARD_FAILURE',
  GET_GIFT_CARD_BY_TOKEN_START: 'GET_GIFT_CARD_BY_TOKEN_START',
  GET_GIFT_CARD_BY_TOKEN_FAILURE: 'GET_GIFT_CARD_BY_TOKEN_FAILURE',
  GET_GIFT_CARD_BY_TOKEN_SUCCESS: 'GET_GIFT_CARD_BY_TOKEN_SUCCESS',
};

export default UserTypes;
