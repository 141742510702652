import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { IncentivioPaymentsProvider } from './incentivio-payments.context';
import {
  useIncentivioPayments,
  useInitPaymentRequest,
} from './incentivio-payments.hooks';

const IncentivioPayments = ({ children }) => {
  const incPaymentsContext = useIncentivioPayments();

  return (
    <IncentivioPaymentsProvider value={incPaymentsContext}>
      {!!incPaymentsContext.stripePromise && (
        <Elements
          stripe={incPaymentsContext.stripePromise}
          options={{
            appearance: {
              rules: {},
            },
          }}
        >
          {children}
          <InitPaymentRequest />
        </Elements>
      )}
    </IncentivioPaymentsProvider>
  );
};

const InitPaymentRequest = React.memo(() => {
  useInitPaymentRequest();

  return null;
});

export default IncentivioPayments;
