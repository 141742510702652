import { Modal } from '@material-ui/core';
import { NewPaymentContext } from 'components/payment/payment.context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ThreeDS2 from '../threeds2.component';
import * as S from './threeds2-modal.styles';
import SpreedlyCheckoutButtonsContext from 'pages/checkout/checkout-buttons-new/checkout-buttons-wrapper/spreedly-checkout-buttons/spreedly-checkout-buttons-container/spreedly-checkout-buttons-provider/spreedly-checkout-buttons.context';

const ThreeDS2Modal = () => {
  const { t } = useTranslation();
  const { initOrderResponse } = useContext(NewPaymentContext);
  const { threeDSTransactionToken, threeDS2ModalClose } = useContext(
    SpreedlyCheckoutButtonsContext,
  );

  return (
    <Modal
      open={!!threeDSTransactionToken}
      onClose={threeDS2ModalClose}
      aria-labelledby={t('3ds2.modal.ariaLabelledBy')}
      aria-describedby={t('3ds2.modal.ariaDescribedBy')}
    >
      <S.Container>
        <ThreeDS2
          environmentKey={initOrderResponse?.attributes?.gatewayMerchantId}
          token={threeDSTransactionToken}
          handleClose={threeDS2ModalClose}
        />
      </S.Container>
    </Modal>
  );
};

export default ThreeDS2Modal;
