import { useTranslation } from 'react-i18next';
import OrderingTimeSlots from '../../ordering-time-slots/ordering-time-slots.component';
import { getLocationOrderingInfo } from 'pages/locations/locations.utils';

const DeliveryOrderHours = ({ store }) => {
  const { t } = useTranslation();
  const { deliveryOrderHours, timeZone, deliveryOffDays } = store;
  const { durations } = getLocationOrderingInfo(
    deliveryOrderHours,
    timeZone,
    deliveryOffDays,
  );

  return (
    <OrderingTimeSlots
      title={t('locations.card.todayOrderingHours.delivery')}
      durations={durations}
      titleTestId="OrderHours.OrderingTimeSlots.Title"
    />
  );
};

export default DeliveryOrderHours;
