import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

export const InnerContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark'
      ? theme.palette.background.primary
      : theme.palette.primary.main};
  width: 100vw;
  padding: 1.5em 2em;
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? theme.palette.text.primary : 'white'};
  display: flex;
  flex: 1;
  flex-direction: column;

  button {
    margin: 0.5em 0;
  }

  &.light {
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 450px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
`;
