import { Typography } from '@material-ui/core';
import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import ConfirmPassword from 'components/form/form-fields/confirm-password/confirm-password.component';
import Password from 'components/form/form-fields/password/password.component';
import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useVerifyPasswordRequest } from '../reset-password.hooks';
import ResetPasswordSuccessMessage from './reset-password-success-message/reset-password-success-message.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const ResetPasswordForm = ({ setLoading }) => {
  const [success, setSuccess] = useState(false);
  const token = useURLSearchParams().get('id');
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { error } = useVerifyPasswordRequest(token, setLoading);

  const onSubmit = async ({ password, confirmPassword }) => {
    setLoading(true);
    try {
      await INC_BASE_API.post('resetpassword', {
        newPassword: password,
        newPassword2: confirmPassword,
        token,
      });
      setSuccess(true);
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    return (
      <Typography
        component="h2"
        aria-label={t('resetPassword.verifyTokenError')}
      >
        {t('resetPassword.verifyTokenError')}
      </Typography>
    );

  if (success) return <ResetPasswordSuccessMessage />;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <MandatoryFieldsInstruction />
        <Password />

        <ConfirmPassword />

        <IncButton
          type="submit"
          data-testid="ResetPasswordForm.CustomButton.Submit"
          fullWidth
        >
          {t('resetPassword.button')}
        </IncButton>
      </Form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
