import { useGetOrderOptions } from 'pages/store/new-order-form/useGetOrderOptions';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFieldNames } from '../useFieldNames.hooks';

export const useOrderOptions = (store, orderType) => {
  const { register, setValue } = useFormContext();
  const { orderOptions, defaultOrderOption } = useGetOrderOptions(
    store,
    orderType,
  );

  const {
    fieldNames: { orderOption: name },
  } = useFieldNames();

  const skip = useMemo(
    () => shouldSkipOrderOptions(orderOptions),
    [orderOptions],
  );

  useEffect(() => {
    if (skip) {
      register(name, {
        type: 'custom',
        value: orderOptions[0],
      });
      // Must also setValue in case the field has already been registered
      setValue(name, orderOptions[0]);
    }
  }, [name, orderOptions, register, skip, orderType, setValue]);

  return { skip, defaultOrderOption, orderOptions, name };
};

export const shouldSkipOrderOptions = orderOptions =>
  orderOptions.length === 1 &&
  !orderOptions[0].instructions &&
  !orderOptions[0].informationField;
