import { useSelector } from 'react-redux';
import MinMaxCateringMessage from '../min-max-catering-message.component';
import {
  selectRequestedStoreMaxValue,
  selectRequestedStoreMinValue,
} from 'redux/locations/locations.selectors';
import { DELIVERY } from 'util/constants';

const CateringDeliveryMessage = ({ storeId }) => {
  const deliveryMin = useSelector(state =>
    selectRequestedStoreMinValue(state, storeId, DELIVERY),
  );
  const deliveryMax = useSelector(state =>
    selectRequestedStoreMaxValue(state, storeId, DELIVERY),
  );

  return (
    <MinMaxCateringMessage
      translationString="locations.card.catering.minMax.delivery"
      min={deliveryMin}
      max={deliveryMax}
      data-testid="CateringDeliveryMessage.MinMaxCateringMessage"
    />
  );
};

export default CateringDeliveryMessage;
