import React from 'react';
import { CardGiftcard } from '@material-ui/icons';
import { PaymentOptionType } from 'components/payment/payment.constants';
import PaymentOption from './payment-option.component';
import { useTranslation } from 'react-i18next';

const GiftCardPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  const { t } = useTranslation();
  const title = t('paymentSelector.paymentOption.giftCard.title');

  return (
    <PaymentOption
      icon={<CardGiftcard />}
      title={title}
      type={PaymentOptionType.GIFT_CARD}
      {...rest}
      ref={ref}
    />
  );
});

export default GiftCardPaymentOption;
