import { Box, Fade } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import ImageTab from 'components/image-tab/image-tab.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuGroups from '../menu-group/menu-groups.component';
import { CustomTabList } from '../menu.styles';
import { useCatalogTabs } from './multiple-catalog-menu.hooks';
import * as S from './multiple-catalog-menu.styles';

const MultipleCatalogMenu = () => {
  const {
    tabs,
    tabValue,
    fallbackImage,
    selectedGroups,
    catalogsHaveImages,
    handleTabChange,
  } = useCatalogTabs();
  const { t } = useTranslation();

  return (
    <TabContext value={tabValue}>
      <CustomTabList
        onChange={handleTabChange}
        indicatorColor="primary"
        aria-label={t('catalog.tabs.catalogs')}
        variant="scrollable"
        scrollButtons="auto"
        data-testid="Menu.Tabs"
      >
        {tabs.map(({ label, imageSrc, catalogId }) => (
          <ImageTab
            key={`tab-${catalogId}`}
            value={catalogId}
            imageSrc={catalogsHaveImages ? imageSrc || fallbackImage : null}
            label={label}
            wrapped
            data-testid="Menu.Tab"
          />
        ))}
      </CustomTabList>
      {tabs.map(({ catalogId }) => (
        <S.CustomTabPanel value={catalogId} key={`ctp-${catalogId}`}>
          <Fade in={true} timeout={{ appear: 1000, enter: 1000 }}>
            <Box data-testid="CatalogBody.Container">
              <MenuGroups groups={selectedGroups} catalogId={catalogId} />
            </Box>
          </Fade>
        </S.CustomTabPanel>
      ))}
    </TabContext>
  );
};

export default MultipleCatalogMenu;
