import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GiftcardDisabled from './giftcard-disabled/giftcard-disabled.component';
import GiftcardLanding from './giftcard-landing/giftcard-landing.component';
import GiftcardLookupPage from './giftcard-lookup/giftcard-lookup.component';
import GiftcardPurchasePage from './giftcard-purchase/giftcard-purchase.component';
import GiftcardThankYouPage from './giftcard-thank-you/giftcard-thank-you.component';
import GiftcardViewPage from './giftcard-view/giftcard-view.component';
import * as S from './giftcard.styles';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const GiftcardPage = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const title = usePageTitle(t('head.title.giftCard'));

  return (
    <S.Container>
      <Helmet title={title} />
      <Switch>
        <Route exact path={`${path}/`} component={GiftcardLanding} />

        <Route
          exact
          path={`${path}/purchase`}
          component={GiftcardPurchasePage}
        />

        <Route
          exact
          path={`${path}/thankyou`}
          component={GiftcardThankYouPage}
        />

        <Route
          exact
          path={`${path}/view/:token`}
          component={GiftcardViewPage}
        />

        <Route exact path={`${path}/lookup`} component={GiftcardLookupPage} />

        <Route exact path={`${path}/notenabled`} component={GiftcardDisabled} />
      </Switch>
    </S.Container>
  );
};

export default GiftcardPage;
