import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const RewardsLink = styled(Button)`
  text-decoration: underline;

  .MuiSvgIcon-root {
    margin-right: 0.2em;
  }

  :hover {
    text-decoration: underline;
  }
`;
