import React from 'react';
import { useSelector } from 'react-redux';
import { selectItemInstructionsAdditionalText1 } from 'redux/config/config.selectors';
import * as S from './item-instruction-additional-text.styles';

const ItemInstructionAdditionalText = () => {
  const itemInstructionsAdditionalText1 = useSelector(
    selectItemInstructionsAdditionalText1,
  );

  if (!itemInstructionsAdditionalText1) return null;
  return (
    <S.SpecialInsAdditionalText>
      {itemInstructionsAdditionalText1}
    </S.SpecialInsAdditionalText>
  );
};

export default ItemInstructionAdditionalText;
