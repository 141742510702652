import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ContentContainer = styled(Paper)`
  width: 100%;
  height: fit-content;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 460px;
  }
`;

export const Description = styled(Typography)`
  padding-left: 1rem;
`;
