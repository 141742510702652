import { NewPaymentContext } from 'components/payment/payment.context';
import { useContext } from 'react';

export const useCloverCard = () => {
  const { initOrderResponse } = useContext(NewPaymentContext);
  const hostedPaymentUrl = initOrderResponse?.attributes?.hostedPaymentUrl;
  const cloverScriptSrc = hostedPaymentUrl?.includes('incentivio.com')
    ? 'https://checkout.clover.com/sdk.js'
    : 'https://checkout.sandbox.dev.clover.com/sdk.js';

  const apikey = hostedPaymentUrl?.split?.('?apikey=')?.[1];

  return { cloverScriptSrc, apikey };
};
