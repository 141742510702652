import { slugify } from 'pages/locations/locations.utils';
import { useHistory } from 'react-router-dom';

export const useViewMenuClicked = storeTitle => {
  const history = useHistory();
  const slug = slugify(storeTitle);

  const handleMenuBrowsingClick = () => {
    history.push(`/locations/${slug}/menu`);
  };

  return handleMenuBrowsingClick;
};
