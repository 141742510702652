import { Box, Link, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1rem;
`;

export const CustomTypography = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: bold;

  > * {
    margin-right: 0.5rem;
  }
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  text-decoration: underline;
`;
