import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

export const Container = styled(Box)`
  position: relative;
  padding: 1em;
  flex-grow: 1;
`;

export const AllLocationsDeliveryError = styled(Typography)`
  margin: 10px 0;
`;
