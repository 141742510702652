import FlowContext from 'components/flow/flow.context';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLogo } from 'redux/config/config.selectors';
import * as S from './giftcard-preview.styles';

const GiftcardPreview = ({ giftcardData }) => {
  const { t } = useTranslation();
  const { activeStep } = useContext(FlowContext);
  const logo = useSelector(selectLogo);

  return activeStep === 'payment' ? (
    <S.Container container spacing={1}>
      <S.CardContainer item xs={12}>
        <S.CardBody>
          <S.CardLeft>
            <S.CardValue>${giftcardData.amount.toFixed(2)}</S.CardValue>
          </S.CardLeft>
          <S.CardRight>
            <S.LogoImage src={logo} alt="" />
          </S.CardRight>
        </S.CardBody>
      </S.CardContainer>
      <S.FieldContainer item xs={12}>
        <div>
          <S.FieldName>{t('eGiftCard.preview.from')}</S.FieldName>:{' '}
          {giftcardData.from}
        </div>
        <div>
          <S.FieldName>{t('eGiftCard.preview.to')}</S.FieldName>:{' '}
          {giftcardData.to}
        </div>
        <div>
          <S.FieldName>{t('eGiftCard.preview.email')}</S.FieldName>:{' '}
          {giftcardData.recipientEmail}
        </div>
        {giftcardData.message && (
          <div>
            <S.FieldName>{t('eGiftCard.preview.message')}</S.FieldName>:{' '}
            {giftcardData.message}
          </div>
        )}
      </S.FieldContainer>
    </S.Container>
  ) : null;
};

export default GiftcardPreview;
