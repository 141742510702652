import { useElements, useStripe } from '@stripe/react-stripe-js';
import INC_BASE_API from 'apis/incentivio-api';
import { usePurchaseGiftcardLoading } from 'components/card-item/card-item-giftcard/giftcard-add-value/giftcard-add-value.component';
import { usePoller } from 'hooks/usePoller';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import EmailGiftcardPurchaseContext from 'pages/giftcard/giftcard-purchase/giftcard-purchase.context';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  selectCustomerInfo,
  selectDeliveryInstructions,
  selectIsFreeOrder,
  selectOrderId,
  selectOrderInstructions,
  selectOrderLocationId,
  selectOrderMerchantId,
  selectOrderOptionInfo,
  selectPaymentInstrumentId,
  selectPaymentOption,
  selectTip,
} from 'redux/cart/cart.selectors';
import {
  selectGiftCardDefaultLocation,
  selectGiftCardDefaultMerchant,
} from 'redux/config/config.selectors';
import {
  selectClientId,
  selectEmail,
  selectFirstName,
  selectIsLoggedIn,
  selectLastName,
  selectPaymentInstrumentById,
  selectPhoneNumber,
  selectUserId,
} from 'redux/user/user.selectors';
import { IncentivioApiError, StripeError } from 'util/errors';
import { getEnv } from 'util/getEnv';
import location from 'util/location';
import { loadStripe } from 'util/stripe';
import {
  paymentOptionToPaymentTypeMap,
  PaymentOptionType,
  PaymentType,
} from '../payment.constants';
import { NewPaymentContext } from '../payment.context';
import {
  initOrder,
  useHandleOutOfStockRequest,
  useMakePayment,
  usePaymentGiftCardAsAGuest,
  usePaymentMode,
  useProcessingUrl,
  usePurchaseEmailGiftCardBody,
  useWallets,
} from '../payment.new.hooks';
import IncentivioPaymentsContext from './incentivio-payments.context';

export const useIncentivioPayments = () => {
  const { loading: walletsLoading = false } = useWallets();
  const [promiseLoading, setPromiseLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState();
  const [paymentRequest, setPaymentRequest] = useState();
  const handleError = useErrorHandler();

  const loading = useMemo(
    () => walletsLoading || promiseLoading,
    [promiseLoading, walletsLoading],
  );

  useEffect(() => {
    const stripePromise = loadStripe(getEnv('REACT_APP_STRIPE_PK'));
    setStripePromise(stripePromise);
    stripePromise
      .then(() => {
        setPromiseLoading(false);
      })
      .catch(e => {
        setPromiseLoading(false);
        handleError(new StripeError('Could not load Payments', e));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    stripePromise,
    loading,
    paymentRequest,
    setPaymentRequest,
  };
};

export const usePaymentRequest = () => {
  const { loading } = useWallets();
  const { paymentRequest } = useContext(IncentivioPaymentsContext);

  return { paymentRequest, loading };
};

export const useInitPaymentRequest = () => {
  const stripe = useStripe();
  const { loading } = useWallets();
  const { paymentRequest } = useContext(IncentivioPaymentsContext);
  const { initOrderResponse, amount, walletLabel, type, paymentOption } =
    useContext(NewPaymentContext);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const updatePaymentRequest = useUpdatePaymentRequest();
  const createPaymentRequest = useCreatePaymentRequest();
  const paymentMethodHandler = usePaymentMethodHandler();
  const giftCardPaymentMethodHandler = useGiftCardPaymentMethodHandler();
  const emailGiftCardPaymentMethodHandler =
    useEmailGiftCardPaymentMethodHandler();

  useEffect(() => {
    if (stripe && !paymentRequest && Number.isInteger(amount)) {
      createPaymentRequest(
        amount,
        initOrderResponse?.attributes.currencycode,
        walletLabel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, amount]);

  useEffect(() => {
    if (paymentRequest) {
      updatePaymentRequest(
        amount,
        initOrderResponse?.attributes.currencycode,
        walletLabel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, initOrderResponse?.attributes.currencycode]);

  useEffect(() => {
    if (paymentRequest) {
      let handler;
      // eslint-disable-next-line default-case
      switch (type) {
        case PaymentType.ORDER:
          handler = paymentMethodHandler;
          break;
        case PaymentType.GIFT_CARD:
          handler = giftCardPaymentMethodHandler;
          break;
        case PaymentType.EMAIL_GIFT_CARD:
          handler = emailGiftCardPaymentMethodHandler;
          break;
      }
      paymentRequest.on('paymentmethod', handler);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod');
      }
    };
  }, [
    paymentRequest,
    type,
    amount,
    paymentOption,
    isLoggedIn,
    paymentMethodHandler,
    giftCardPaymentMethodHandler,
    emailGiftCardPaymentMethodHandler,
  ]);

  return { paymentRequest, loading };
};

export const useCreatePaymentRequest = () => {
  const stripe = useStripe();
  const { setWalletSupport, setLoading } = useWallets();
  const { setPaymentRequest } = useContext(IncentivioPaymentsContext);

  return async (amount, currency, label) => {
    setLoading(true);
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: currency.toLowerCase(),
      total: {
        amount: amount / 10,
        label,
      },
    });

    setPaymentRequest(pr);
    setWalletSupport(await setWalletSupportHelper(pr));
    setLoading(false);
  };
};

export const useUpdatePaymentRequest = () => {
  const { paymentRequest } = useContext(IncentivioPaymentsContext);
  const { setWalletSupport, setLoading } = useWallets();

  return async (amount, currency, label) => {
    if (paymentRequest) {
      setLoading(true);
      await paymentRequest.update({
        currency: currency.toLowerCase(),
        total: {
          amount: amount / 10,
          label,
        },
      });

      setWalletSupport(await setWalletSupportHelper(paymentRequest));
      setLoading(false);
    }
  };
};

export const usePaymentMethodHandler = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { trigger: triggerPreparePayment } = usePreparePayment();
  const { trigger: triggerPrepareGuestPayment } = usePrepareGuestPayment();
  const { trigger: triggerConfirmCardPayment } = useConfirmCardPayment();
  const goToProcessingScreen = useGoToProcessingScreen();
  const return_url = useReturnUrl();
  const stripe = useStripe();

  return async ev => {
    try {
      let preparePaymentResponse, clientSecret;
      if (isLoggedIn) {
        preparePaymentResponse = await triggerPreparePayment();
      } else {
        preparePaymentResponse = await triggerPrepareGuestPayment();
      }

      clientSecret = preparePaymentResponse.data.attributes.clientSecret;

      const result = await triggerConfirmCardPayment(clientSecret, {
        payment_method: ev.paymentMethod.id,
        return_url,
      });

      if (result.paymentIntent.status === 'requires_action') {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        ev.complete('success');
        if (error) {
          toast.error(error.message);
        } else {
          goToProcessingScreen();
        }
      } else {
        ev.complete('success');
        goToProcessingScreen();
      }
    } catch (e) {
      ev.complete('fail');
      toast.error(e?.cause?.message ?? e?.message ?? e);
    }
  };
};

export const useGiftCardPaymentMethodHandler = () => {
  const stripe = useStripe();
  const { trigger: triggerPreparePayment } = usePrepareGiftCardPayment();
  const { trigger: triggerConfirmCardPayment } = useConfirmCardPayment();
  const { trigger: triggerStatusPoll } = usePollGiftCardTransaction();
  const addPromise = usePurchaseGiftcardLoading(state => state.addPromise);
  const { afterWalletPay, cardIdentifier } = useContext(NewPaymentContext);

  return async ev => {
    try {
      const preparePaymentResponse = await addPromise(
        triggerPreparePayment('ONE_TIME_PAYMENT', cardIdentifier),
      );
      const clientSecret = preparePaymentResponse.data.attributes.clientSecret;

      const result = await addPromise(
        triggerConfirmCardPayment(clientSecret, {
          payment_method: ev.paymentMethod.id,
        }),
      );

      const pollResult = await addPromise(
        triggerStatusPoll(
          preparePaymentResponse?.data?.attributes.incentivioTxId,
        ),
      );

      ev.complete('success');

      if (result.paymentIntent.status === 'requires_action') {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          toast.error(error.message);
        } else {
          afterWalletPay(pollResult);
        }
      } else {
        afterWalletPay(pollResult);
      }
    } catch (e) {
      ev.complete('fail');
      toast.error(getExceptionMessage(e));
    }
  };
};

export const useEmailGiftCardPaymentMethodHandler = () => {
  const stripe = useStripe();
  const history = useHistory();
  const { trigger: triggerPreparePayment } = usePrepareEmailGiftCardPayment();
  const { trigger: triggerConfirmCardPayment } = useConfirmCardPayment();
  const { trigger: triggerStatusPoll } = usePollGiftCardTransaction();
  const addPromise = usePurchaseGiftcardLoading(state => state.addPromise);
  const giftcardData = useContext(EmailGiftcardPurchaseContext);

  return async ev => {
    try {
      const preparePaymentResponse = await addPromise(
        triggerPreparePayment('ONE_TIME_PAYMENT'),
      );
      const clientSecret = preparePaymentResponse.data.attributes.clientSecret;

      const result = await addPromise(
        triggerConfirmCardPayment(clientSecret, {
          payment_method: ev.paymentMethod.id,
        }),
      );

      const pollResult = await addPromise(
        triggerStatusPoll(
          preparePaymentResponse?.data?.attributes.incentivioTxId,
        ),
      );

      ev.complete('success');

      if (result.paymentIntent.status === 'requires_action') {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          toast.error(error.message);
        } else {
          history.push('/giftcard/thankyou', {
            ...giftcardData,
            ...pollResult.data,
          });
        }
      } else {
        history.push('/giftcard/thankyou', {
          ...giftcardData,
          ...pollResult.data,
        });
      }
    } catch (e) {
      ev.complete('fail');
      toast.error(getExceptionMessage(e));
    }
  };
};

const setWalletSupportHelper = async pr => {
  const walletSupport = await pr.canMakePayment();
  return !walletSupport
    ? {
        applePay: false,
        googlePay: false,
      }
    : walletSupport;
};

export const useSetupPaymentIntent = () => {
  const { type } = useContext(NewPaymentContext);
  const [clientSecret, setClientSecret] = useState();

  const locationId = useSelector(selectOrderLocationId);
  const merchantId = useSelector(selectOrderMerchantId);

  const giftCardLocationId = useSelector(selectGiftCardDefaultLocation);
  const giftCardMerchantId = useSelector(selectGiftCardDefaultMerchant);

  useEffect(() => {
    const execute = async () => {
      const initOrderBody = {
        locationId:
          type === PaymentType.ORDER ? locationId : giftCardLocationId,
        merchantId:
          type === PaymentType.ORDER ? merchantId : giftCardMerchantId,
        attributes: { mode: 'setup' },
      };
      const initOrderResponse = await initOrder(initOrderBody, {
        authenticated: true,
      });
      setClientSecret(initOrderResponse.data.attributes.clientSecret);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return clientSecret;
};

export const usePreparePaymentBody = () => {
  const { email, firstName, lastName, phoneNumber } =
    useSelector(selectCustomerInfo) ?? {};
  const deliveryInstructions = useSelector(selectDeliveryInstructions);
  const orderNote = useSelector(selectOrderInstructions);
  const orderOptionInfo = useSelector(selectOrderOptionInfo);
  const tip = useSelector(selectTip) * 1000;
  const isFreeOrder = useSelector(selectIsFreeOrder);
  const paymentInstrumentId = useSelector(selectPaymentInstrumentId);
  const paymentInstrumentSelector = useCallback(
    state => selectPaymentInstrumentById(paymentInstrumentId)(state),
    [paymentInstrumentId],
  );
  const paymentInstrument = useSelector(paymentInstrumentSelector);
  const paymentMode = usePaymentMode();
  const { paymentOption } = useContext(NewPaymentContext);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  let data = {
    email,
    firstName,
    lastName,
    phone: phoneNumber,
    deliveryInstructions,
    orderNote,
    orderOptionInfo,
    gratuity: tip === 0 ? null : tip,
    isOneTimeTransaction: !isLoggedIn,
    savePaymentInstrument: false,
    sourceType: 'WEB',
    paymentMode,
  };

  if (isFreeOrder) {
    data.paymentType = 'PAYMENT_SKIPPED';
  } else if (paymentMode === 'NO_PAYMENT') {
    data.paymentType = 'NO_PAYMENT';
  } else {
    data.paymentType = paymentOptionToPaymentTypeMap[paymentOption];
    // eslint-disable-next-line default-case
    switch (paymentOption) {
      case PaymentOptionType.CARD:
        data.paymentToken = paymentInstrument?.paymentToken;
        break;

      case PaymentOptionType.GIFT_CARD:
        data.paymentToken = paymentInstrumentId;
        data.cardType = paymentInstrument?.cardType;
        break;

      case PaymentOptionType.PAY_LATER:
        // Nothing additional
        break;

      case PaymentOptionType.GOOGLE_PAY:
        data.paymentToken = 'ONE_TIME_PAYMENT';
        break;

      case PaymentOptionType.APPLE_PAY:
        data.paymentToken = 'ONE_TIME_PAYMENT';
        break;

      case PaymentOptionType.GUEST_CARD:
        data.paymentToken = 'ONE_TIME_PAYMENT';
        break;
    }
  }

  return data;
};

export const usePreparePayment = () => {
  const [loading, setLoading] = useState(false);
  const orderId = useSelector(selectOrderId);
  const body = usePreparePaymentBody();
  const handleOutOfStockRequest = useHandleOutOfStockRequest();

  const trigger = async () => {
    setLoading(true);
    try {
      const response = await INC_BASE_API.post(
        `/orders/${orderId}/preparepayment`,
        body,
        {
          authenticated: true,
        },
      );

      return response;
    } catch (e) {
      handleOutOfStockRequest(e);
      throw new IncentivioApiError('preparepayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const usePrepareGuestPayment = () => {
  const [loading, setLoading] = useState(false);
  const orderId = useSelector(selectOrderId);
  const body = usePreparePaymentBody();

  const trigger = async (overrides = {}) => {
    setLoading(true);
    try {
      const response = await INC_BASE_API.post(
        `/orders/${orderId}/prepareguestpayment`,
        { ...body, ...overrides },
      );

      return response;
    } catch (e) {
      throw new IncentivioApiError('prepareguestpayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const usePrepareEmailGiftCardPayment = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const body = usePurchaseEmailGiftCardBody();

  const trigger = async paymentToken => {
    setLoading(true);
    try {
      const response = await INC_BASE_API.post(
        `/giftcard/purchase/payment/prepare`,
        { ...body, paymentToken },
        {
          authenticated: isLoggedIn,
        },
      );

      return response;
    } catch (e) {
      throw new IncentivioApiError('usePrepareEmailGiftCardPayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const usePrepareGiftCardPayment = () => {
  const [loading, setLoading] = useState(false);
  const defaultGiftCardLocation = useSelector(selectGiftCardDefaultLocation);
  const clientId = useSelector(selectClientId);
  const userId = useSelector(selectUserId);
  const { amount } = useContext(NewPaymentContext);
  const email = useSelector(selectEmail);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const phone = useSelector(selectPhoneNumber);

  const trigger = async (paymentToken, cardIdentifier) => {
    const body = {
      amount,
      clientId,
      firstName,
      email,
      deliveryInstructions: null,
      gratuity: 0,
      isOneTimeTransaction: false,
      lastName,
      locationId: defaultGiftCardLocation,
      orderNote: '',
      paymentMode: null,
      paymentToken,
      paymentType: 'CARD_NOT_PRESENT',
      phone,
      sourceType: 'WEB',
      userId,
      cardIdentifier,
    };

    setLoading(true);
    try {
      const response = await INC_BASE_API.post(
        cardIdentifier
          ? `/giftcard/add-value/payment/prepare`
          : `/ecard/purchase/payment/prepare`,
        body,
        {
          authenticated: true,
        },
      );

      return response;
    } catch (e) {
      throw new IncentivioApiError('usePrepareGiftCardPayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const usePollGiftCardTransaction = () => {
  const [loading, setLoading] = useState(false);
  const transactionId = useRef();

  const apiCall = async () => {
    try {
      setLoading(true);
      const response = await INC_BASE_API.get(
        `/giftcard/transaction/${transactionId.current}/payment/status`,
      );

      if (response?.data?.paymentStatus === 'PAYMENT_SUCCESS') {
        setLoading(false);
        return response;
      } else if (response?.data?.paymentStatus === 'PAYMENT_PENDING') {
        return false;
      } else {
        setLoading(false);
        return new IncentivioApiError(response?.data?.paymentStatus);
      }
    } catch (e) {
      setLoading(false);
      return new IncentivioApiError('Transaction Status Failure', e);
    }
  };

  const { start } = usePoller(apiCall, 2000, 60000);

  const trigger = async txId => {
    transactionId.current = txId;
    const result = await start();
    if (!result) {
      throw new Error('Transaction Status Timeout');
    } else if (result instanceof Error) {
      throw result;
    }
    return result;
  };

  return { trigger, loading };
};

export const usePurchaseEmailGiftCard = () => {
  const { loading: prepareLoading, trigger: triggerPreparePayment } =
    usePrepareEmailGiftCardPayment();
  const { loading: confirmCardLoading, trigger: triggerConfirmCardPayment } =
    useConfirmCardPayment();
  const { loading: pollLoading, trigger: triggerStatusPoll } =
    usePollGiftCardTransaction();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const elements = useElements();

  const loading = useMemo(
    () => prepareLoading || confirmCardLoading || pollLoading,
    [confirmCardLoading, pollLoading, prepareLoading],
  );

  const trigger = async paymentToken => {
    const preparePaymentResponse = await triggerPreparePayment(paymentToken);
    if (!isLoggedIn) {
      await triggerConfirmCardPayment(
        preparePaymentResponse.data.attributes.clientSecret,
        {
          payment_method: {
            card: elements?.getElement('card'),
          },
        },
      );
    }
    const result = await triggerStatusPoll(
      preparePaymentResponse?.data?.attributes.incentivioTxId,
    );

    return result;
  };

  return { loading, trigger };
};

export const usePurchaseGiftCard = () => {
  const { loading: prepareLoading, trigger: triggerPreparePayment } =
    usePrepareGiftCardPayment();
  const { loading: pollLoading, trigger: triggerStatusPoll } =
    usePollGiftCardTransaction();
  const addPromise = usePurchaseGiftcardLoading(state => state.addPromise);

  const loading = useMemo(
    () => prepareLoading || pollLoading,
    [pollLoading, prepareLoading],
  );

  const trigger = async (giftcardData, paymentToken) => {
    const preparePaymentResponse = await addPromise(
      triggerPreparePayment(giftcardData, paymentToken),
    );
    const statusResult = await addPromise(
      triggerStatusPoll(
        preparePaymentResponse?.data?.attributes.incentivioTxId,
      ),
    );

    return statusResult;
  };

  return { loading, trigger };
};

export const useReturnUrl = () => {
  const processingUrl = useProcessingUrl();

  return `${location.getClientURL()}${processingUrl}`;
};

export const useConfirmCardPayment = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const trigger = async (clientSecret, data, options) => {
    setLoading(true);
    try {
      const result = await stripe.confirmCardPayment(
        clientSecret,
        data,
        options,
      );

      if (result.error)
        throw new StripeError('confirm card error', result.error);

      return result;
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const useConfirmOneTimePayment = () => {
  const elements = useElements();

  return {
    payment_method: {
      card: elements?.getElement('card'),
    },
  };
};

export const useConfirmWithPaymentInstrument = () => {
  const paymentInstrumentId = useSelector(selectPaymentInstrumentId);
  const paymentInstrumentSelector = useCallback(
    state => selectPaymentInstrumentById(paymentInstrumentId)(state),
    [paymentInstrumentId],
  );
  const paymentInstrument = useSelector(paymentInstrumentSelector);

  return { payment_method: paymentInstrument?.paymentToken };
};

export const useConfirmCardData = () => {
  const paymentOption = useSelector(selectPaymentOption);
  const oneTime = useConfirmOneTimePayment();
  const pi = useConfirmWithPaymentInstrument();

  // eslint-disable-next-line default-case
  switch (paymentOption) {
    case PaymentOptionType.CARD:
      return pi;

    case PaymentOptionType.GUEST_CARD:
      return oneTime;
  }
};

export const useGoToProcessingScreen = () => {
  const orderId = useSelector(selectOrderId);
  const history = useHistory();

  return () => history.push(`/checkout/processing/${orderId}`);
};

export const useIncentivioPaymentsPlaceOrder = () => {
  const goToProcessingScreen = useGoToProcessingScreen();
  const paymentMode = usePaymentMode();
  const paymentOption = useSelector(selectPaymentOption);
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);

  const { loading: cardLoading, trigger: cardTrigger } =
    usePlaceOrderWithCard();
  const { loading: guestCardLoading, trigger: guestCardTrigger } =
    usePlaceOrderWithGuestCard();
  const { loading: placeOrderLoading, trigger: placeOrderTrigger } =
    usePlaceOrder();
  const { trigger: lookupGiftCardTrigger } = usePaymentGiftCardAsAGuest();

  const loading = useMemo(
    () => cardLoading || guestCardLoading || placeOrderLoading,
    [cardLoading, guestCardLoading, placeOrderLoading],
  );

  const trigger = async () => {
    try {
      if (paymentMode === 'NO_PAYMENT') {
        await addPromise(placeOrderTrigger());
        goToProcessingScreen();
      } else {
        // eslint-disable-next-line default-case
        switch (paymentOption) {
          case PaymentOptionType.CARD:
            await addPromise(cardTrigger());
            goToProcessingScreen();
            break;

          case PaymentOptionType.GUEST_CARD:
            await addPromise(guestCardTrigger());
            goToProcessingScreen();
            break;

          case PaymentOptionType.PAY_LATER:
            await addPromise(placeOrderTrigger());
            goToProcessingScreen();
            break;

          case PaymentOptionType.GIFT_CARD:
            await addPromise(placeOrderTrigger());
            goToProcessingScreen();
            break;

          case PaymentOptionType.GUEST_GIFT_CARD:
            const { lookupToken, giftCardNumber } = await addPromise(
              lookupGiftCardTrigger(),
            );
            if (!!lookupToken) {
              await addPromise(
                placeOrderTrigger({ paymentToken: giftCardNumber }),
              );
              goToProcessingScreen();
            }
            break;
        }
      }
    } catch (e) {
      // handle errors in caller
      throw e;
    }
  };

  return { loading, trigger };
};

export const usePlaceOrderWithCard = () => usePreparePayment();

export const usePlaceOrderWithGuestCard = () => {
  const elements = useElements();
  const return_url = useReturnUrl();
  const { loading: preparePaymentLoading, trigger: triggerPreparePayment } =
    usePrepareGuestPayment();
  const { loading: confirmCardLoading, trigger: triggerConfirmCardPayment } =
    useConfirmCardPayment();

  const loading = useMemo(
    () => preparePaymentLoading || confirmCardLoading,
    [confirmCardLoading, preparePaymentLoading],
  );

  const trigger = async overrides => {
    const preparePaymentResponse = await triggerPreparePayment(overrides);
    await triggerConfirmCardPayment(
      preparePaymentResponse.data.attributes.clientSecret,
      {
        payment_method: {
          card: elements?.getElement('card'),
        },
        return_url,
      },
    );
  };

  return { loading, trigger };
};

export const usePlaceOrder = () => useMakePayment(usePreparePaymentBody());

export const getExceptionMessage = e =>
  e.incentivioMessage ?? e.incentivioCode ?? e.cause?.message ?? e.message;
