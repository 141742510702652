import AddCardErrorBoundary from 'components/add-card-error-boundary/add-card-error-boundary.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPurchaseNewGiftCard } from 'redux/config/config.selectors';
import CardBox from '../card-box/card-box.component';
import PurchaseGiftCard from '../purchase-gift-card/purchase-gift-card.component';

const PurchaseGiftCardCard = () => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useCustomDialog();
  const purchaseNewGiftCard = useSelector(selectPurchaseNewGiftCard);

  if (!purchaseNewGiftCard) return null;

  return (
    <AddCardErrorBoundary name="my-cards-screen-purchase-gift-card">
      <CardBox
        onClick={handleOpen}
        text={t('addPurchaseGiftCard.purchaseCardAction')}
        testId="CardBox.IncButtonDark.PurchaseCard"
      />
      <PurchaseGiftCard open={open} handleClose={handleClose} />
    </AddCardErrorBoundary>
  );
};

export default PurchaseGiftCardCard;
