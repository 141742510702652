import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddCreditCard from 'components/add-credit-card/add-credit-card.component';
import AddExistingGiftCardCard from 'components/add-purchase-gift-card/add-existing-gift-card-card/add-existing-gift-card-card.component';
import PurchaseGiftCardCard from 'components/add-purchase-gift-card/purchase-gift-card-card/purchase-gift-card-card.component';
import PaymentErrorBoundary from 'components/payment/payment-error-boundary/payment-error-boundary.component';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AccountContainer from '../../components/account-container/account-container.component';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs.component';
import {
  selectGiftCardTitle,
  selectIsActiveGiftCard,
  selectIsEmailGiftcardEnabled,
} from '../../redux/config/config.selectors';
import { fetchPaymentInstrumentsStartAsync } from '../../redux/user/user.actions';
import {
  selectPaymentInstrumentsCards,
  selectPaymentInstrumentsGiftCards,
} from '../../redux/user/user.selectors';
import {
  SectionTitle,
  EmailLink,
} from '../account-settings/account-settings-form/account-settings-form.styles';
import AccountMyCardsDisplay from './account-my-cards-display/account-my-cards-display.component';
import { useAddCreditCardThroughMyCards } from 'components/payment/payment.new.hooks';
import Payment from 'components/payment/payment.component';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const AccountMyCardsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cards = useSelector(selectPaymentInstrumentsCards);
  const isActiveGiftCard = useSelector(selectIsActiveGiftCard);
  const giftCards = useSelector(selectPaymentInstrumentsGiftCards);
  const giftCardTitle = useSelector(selectGiftCardTitle);
  const isEmailGiftCard = useSelector(selectIsEmailGiftcardEnabled);
  const paymentContext = useAddCreditCardThroughMyCards();
  const title = usePageTitle(t('head.title.cards'));

  useEffect(() => {
    dispatch(fetchPaymentInstrumentsStartAsync());
  }, [dispatch]);

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs isdark="true" maxWidth="md">
        <Link to="/" component={RouterLink}>
          {t('breadcrumbs.home')}
        </Link>
        <Typography
          aria-label={`Currently located at ${t('breadcrumbs.myCards')}`}
        >
          {t('breadcrumbs.myCards')}
        </Typography>
      </Breadcrumbs>
      <AccountContainer
        testId="AccountMyCardsPage.AccountContainer"
        showLoading={paymentContext.loading}
      >
        <SectionTitle>{t('payment.myCards')}</SectionTitle>
        <PaymentErrorBoundary name="payment-mycards">
          {cards?.length === 0 && (
            <Box mb={2}>
              <Typography>
                {t('accountSettings.emptyMessageCreditCards')}
              </Typography>
            </Box>
          )}
          <Grid
            container
            spacing={1}
            data-testid="AccountMyCardsPage.Grid.Cards"
          >
            <AccountMyCardsDisplay
              myCards={cards}
              testId={'AccountMyCardsPage.CardItem.Card'}
            />
            {!!paymentContext.paymentGatewayType && (
              <Payment value={paymentContext}>
                <AddCreditCard />
              </Payment>
            )}
          </Grid>

          {isActiveGiftCard && (
            <>
              <SectionTitle data-testid="AccountMyCardsPage.SectionTitle.GiftCardTitle">
                {giftCardTitle}
                {isEmailGiftCard && (
                  <EmailLink
                    component="button"
                    onClick={() => history.push('giftcard/purchase')}
                    data-testid="AccountMyCardsPage.SectionTitle.EmailLink"
                  >
                    {t('eGiftCard.emailLink')}
                  </EmailLink>
                )}
              </SectionTitle>

              {giftCards.length === 0 && (
                <Box mb={2}>
                  <Typography>
                    {t('accountSettings.emptyMessageGiftCards')}
                  </Typography>
                </Box>
              )}

              <Grid
                container
                spacing={1}
                data-testid="AccountMyCardsPage.Grid.GiftCards"
              >
                <AccountMyCardsDisplay
                  myCards={giftCards}
                  testId={'AccountMyCardsPage.CardItem.GiftCard'}
                />
                {!!paymentContext.paymentGatewayType && (
                  <PurchaseGiftCardCard />
                )}
                <AddExistingGiftCardCard />
              </Grid>
            </>
          )}
        </PaymentErrorBoundary>
      </AccountContainer>
    </>
  );
};

export default AccountMyCardsPage;
