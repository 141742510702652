import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import * as S from './ar-image.styles';

const ARImage = ({
  src,
  onClick,
  paddingPercentage = 56.25,
  testId = 'ARImage',
  objectFit = 'cover',
  locationName,
}) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <S.Container
      paddingPercentage={paddingPercentage}
      onClick={onClick}
      objectFit={objectFit}
    >
      <img
        style={isLoading ? { display: 'none' } : null}
        src={src}
        alt={`${locationName}`}
        onLoad={() => setLoading(false)}
        data-testid={testId}
      />
      {isLoading && <Skeleton variant="rect" />}
    </S.Container>
  );
};

export default ARImage;
