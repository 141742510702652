import CommonTypes from '../common.types';
import LocationsActionTypes from './locations.types';

export const LOCATIONS_INITIAL_STATE = {
  stores: null,
  cateringStores: null,
  menuBrowsingStores: null,
  cateringMenuBrowsingStores: null,
  paging: null,
  isStoresFetching: false,
  isCateringStoresFetching: false,
  isMenuBrowsingStoresFetching: false,
  isCateringMenuBrowsingStoresFetching: false,
  isStoreFetching: false,
};

const locationsReducer = (state = LOCATIONS_INITIAL_STATE, action) => {
  switch (action.type) {
    case LocationsActionTypes.FETCH_LOCATIONS_START:
      return {
        ...state,
        isStoresFetching: true,
      };

    case LocationsActionTypes.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        stores: action.payload.stores,
        paging: action.payload.paging,
        isStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        isStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_CATERING_LOCATIONS_START:
      return {
        ...state,
        isCateringStoresFetching: true,
      };

    case LocationsActionTypes.FETCH_SINGLE_LOCATION_START:
      return {
        ...state,
        isStoreFetching: true,
      };

    case LocationsActionTypes.FETCH_SINGLE_LOCATION_SUCCESS:
      let newStores = {};

      if (action.payload.isCateringStore) {
        newStores = {
          cateringStores: getNewStores(
            state.cateringStores,
            action.payload.store,
          ),
        };
      } else {
        newStores = {
          stores: getNewStores(state.stores, action.payload.store),
        };
      }

      return {
        ...state,
        isStoreFetching: false,
        ...newStores,
      };

    case LocationsActionTypes.FETCH_SINGLE_LOCATION_FAILURE:
      return {
        ...state,
        isStoreFetching: false,
      };

    case LocationsActionTypes.FETCH_CATERING_LOCATIONS_SUCCESS:
      return {
        ...state,
        cateringStores: action.payload.stores,
        cateringPaging: action.payload.paging,
        isCateringStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_CATERING_LOCATIONS_FAILURE:
      return {
        ...state,
        isCateringStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_START:
      return {
        ...state,
        isMenuBrowsingStoresFetching: true,
      };

    case LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_SUCCESS:
      return {
        ...state,
        menuBrowsingStores: action.payload.stores,
        menuBrowsingPaging: action.payload.paging,
        isMenuBrowsingStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_MENU_BROWSING_LOCATIONS_FAILURE:
      return {
        ...state,
        isMenuBrowsingStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_START:
      return {
        ...state,
        isCateringMenuBrowsingStoresFetching: true,
      };

    case LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_SUCCESS:
      return {
        ...state,
        cateringMenuBrowsingStores: action.payload.stores,
        cateringMenuBrowsingPaging: action.payload.paging,
        isCateringMenuBrowsingStoresFetching: false,
      };

    case LocationsActionTypes.FETCH_CATERING_MENU_BROWSING_LOCATIONS_FAILURE:
      return {
        ...state,
        isCateringMenuBrowsingStoresFetching: false,
      };

    case CommonTypes.ERROR_HARD_RESET:
      return LOCATIONS_INITIAL_STATE;

    default:
      return state;
  }
};

export default locationsReducer;

const getNewStores = (stores, newStore) => {
  return stores.map(store => {
    if (store.storeId === newStore.storeId) return newStore;

    return store;
  });
};
