import { Tab } from '@material-ui/core';
import { TabList } from '@material-ui/lab';
import styled from 'styled-components';

export const minTabWidth = 58;

export const DayTabList = styled(TabList)`
  border-radius: 4px;
  margin: 5px 0 15px 0;
  background-color: ${({ theme }) => theme.palette.bgColor};

  .MuiTabs-indicator {
    display: none;
  }
`;

export const DayTab = styled(Tab)`
  &.MuiTab-root {
    min-width: 1px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 1px 0;

    flex: 1 0 ${({ flexbasis }) => flexbasis ?? 0};

    color: ${({ theme }) => theme.palette.text.primary};
    opacity: 1;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }
`;
