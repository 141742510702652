import { Provider } from 'components/flow/flow.context';
import { useFlow } from 'components/flow/flow.hooks';
import StepBody from 'components/flow/step-body/step-body.component';
import StepHeader from 'components/flow/step-header/step-header.component';
import Step from 'components/flow/step/step.component';
import Payment from 'components/payment/payment.component';
import { useEmailGiftCardPayment } from 'components/payment/payment.new.hooks';
import CreateAccountStep from 'pages/checkout/create-account-step/create-account-step.component';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCustomerInfo } from 'redux/cart/cart.selectors';
import { selectClientName } from 'redux/config/config.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import GiftcardPaymentStep from '../giftcard-payment-step/giftcard-payment-step.component';
import GiftcardPreview from '../giftcard-preview/giftcard-preview.component';
import { EmailGiftcardPurchaseProvider } from '../giftcard-purchase.context';
import * as S from '../giftcard-purchase.styles';
import GiftcardStep from '../giftcard-step/giftcard-step.component';

const GiftcardPurchaseSteps = () => {
  const { t } = useTranslation();
  const flowMethods = useFlow();
  const { activeStep, setActiveStep } = flowMethods;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const customerInfo = useSelector(selectCustomerInfo);
  const [giftcardData, setGiftcardData] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (activeStep !== 'auth' && !isLoggedIn && !customerInfo) {
      setActiveStep('auth');
    }
  }, [activeStep, customerInfo, isLoggedIn, setActiveStep]);

  const onSubmitGiftcard = data => setGiftcardData(data);

  const amount = useMemo(
    () => (giftcardData.amount ?? 0) * 1000,
    [giftcardData.amount],
  );
  const clientName = useSelector(selectClientName);
  const walletLabel = useMemo(
    () => t('payment.giftCardWalletLabel', { clientName }),
    [clientName, t],
  );
  const paymentContext = useEmailGiftCardPayment(amount, walletLabel);

  useEffect(() => {
    if (paymentContext?.disabled) {
      // We're not really supporting this anymore, but keeping it around in case
      // we need it. I don't feel like spending 1+ hours to figure out how to test
      // this.
      /* istanbul ignore next */
      history.push('/giftcard/notenabled');
    }
  }, [history, paymentContext]);

  return (
    <EmailGiftcardPurchaseProvider value={giftcardData}>
      <Payment value={paymentContext}>
        <Provider value={flowMethods}>
          <Step
            name="auth"
            initial
            editable={false}
            testId={'GiftcardPurchasePage.Step.Auth'}
          >
            <S.CustomStepHeader label={`1. ${t('eGiftCard.steps.signUp')}`} />
            <StepBody>
              <CreateAccountStep />
            </StepBody>
          </Step>
          <Step name="giftcard" testId={'GiftcardPurchasePage.Step.Giftcard'}>
            <StepHeader label={`2. ${t('eGiftCard.steps.giftCard')}`} />
            <StepBody>
              <GiftcardStep data={giftcardData} onSubmit={onSubmitGiftcard} />
            </StepBody>
          </Step>
          <GiftcardPreview giftcardData={giftcardData} />
          <Step name="payment" testId={'GiftcardPurchasePage.Step.Payment'}>
            <StepHeader label={`3. ${t('eGiftCard.steps.payment')}`} />
            <StepBody>
              <GiftcardPaymentStep />
            </StepBody>
          </Step>
        </Provider>
      </Payment>
    </EmailGiftcardPurchaseProvider>
  );
};

export default GiftcardPurchaseSteps;
