import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
} from '../giftcard.styles';

const GiftcardThankYouPage = ({
  location: { state: { recipientEmail, amount, cardIdentifier } = {} },
}) => {
  const { t } = useTranslation();

  return !recipientEmail ? (
    <Redirect to="/giftcard" />
  ) : (
    <ContentContainer
      elevation={2}
      data-testid={'GiftcardThankYouPage.ContentContainer'}
    >
      <ContentHeader component="h1" variant="h5">
        {t('eGiftCard.thankYou.header')}
      </ContentHeader>
      <ContentBody>
        <Typography variant="h6" component="p" gutterBottom={true}>
          {t('eGiftCard.thankYou.body1', {
            amount: amount?.toFixed(2),
            recipientEmail,
            cardIdentifier,
          })}
        </Typography>
        <Typography variant="h6" component="p">
          {t('eGiftCard.thankYou.body2')}
        </Typography>
      </ContentBody>
    </ContentContainer>
  );
};

export default GiftcardThankYouPage;
