import { dialogDescription1 } from 'components/custom-dialog/custom-dialog.constants';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import * as S from './message-short-description.styles';

const MessageShortDescription = ({ description }) => {
  return (
    <S.ShortDescription id={dialogDescription1}>
      <LinkifyParse>{description}</LinkifyParse>
    </S.ShortDescription>
  );
};

export default MessageShortDescription;
