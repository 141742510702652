import { Box } from '@material-ui/core';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import React, { memo } from 'react';
import DelayedMessage from '../delayed-message/delayed-message.component';
import DayTabs from '../day-tabs/day-tabs.component';
import TimeSelector from '../time-selector/time-selector.component';
import DayTimeTabWrapper from './day-time-tab-wrapper/day-time-tab-wrapper.component';
import TimeZoneText from '../time-zone-text/time-zone-text.component';

const DayTimeSelector = ({
  defaultDay,
  defaultTime,
  onTimeClick,
  availableTimes,
  availableDays,
  day,
  delayedMessage,
  store,
  timeZoneName,
}) => {
  const { fieldNames } = useFieldNames();

  return (
    <>
      <DayTabs
        availableDays={availableDays}
        name={fieldNames.day}
        defaultValue={defaultDay}
      >
        <DelayedMessage delayedMessage={delayedMessage} />

        <DayTimeTabWrapper value={day}>
          <TimeZoneText timeZoneName={timeZoneName} />
          <Box overflow="auto" maxHeight="250px" id="time-container">
            <TimeSelector
              times={availableTimes}
              name={fieldNames.time}
              defaultValue={defaultTime}
              onTimeClick={onTimeClick}
              storeId={store.storeId}
              containerId="time-container"
            />
          </Box>
        </DayTimeTabWrapper>
      </DayTabs>
    </>
  );
};

export default memo(DayTimeSelector);
