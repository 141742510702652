/**
 * We are loading the segment snippet asynchronously depending upon webAppConfig
 * settings. This means we could potentially try and use Segment before it is
 * loaded. I have seen this with the 'identify' call since we trigger that on
 * app load if the user is logged in. See fetchUserDataStartAsync in
 * user.actions.
 *
 * The code below wraps window.segment with a promise that will wait up to
 * config.maxTime for window.segment to be defined. It will check at a defined
 * interval to see if window.segment is defined. If it becomes defined, we will
 * execute all queued calls to segment. If it does not become defined after
 * config.maxTime, we return a 'fake' segment implementation.
 */

let analyticsPromise;

const fakeSegment = {
  identify: () => {},
  track: () => {},
  page: () => {},
  group: () => {},
  alias: () => {},
};

const getSegment = config => {
  if (!analyticsPromise) {
    let intervalId;
    let intervalCount = 0;

    analyticsPromise = new Promise(resolve => {
      intervalId = setInterval(() => {
        ++intervalCount;
        if (!!window.analytics) {
          window.analytics.ready(() => {
            resolve(window.analytics);
          });
          clearInterval(intervalId);
        } else if (intervalCount * config.intervalDuration > config.maxTime) {
          resolve(fakeSegment);
          clearInterval(intervalId);
        }
      }, config.intervalDuration);
    });
  }

  return analyticsPromise;
};

export const segment = {
  config: {
    intervalDuration: 100,
    maxTime: 6000,
  },

  identify: async (...args) =>
    (await getSegment(segment.config)).identify(...args),
  track: async (...args) => (await getSegment(segment.config)).track(...args),
  page: async (...args) => (await getSegment(segment.config)).page(...args),
  group: async (...args) => (await getSegment(segment.config)).group(...args),
  alias: async (...args) => (await getSegment(segment.config)).alias(...args),
  reset: () => {
    analyticsPromise = undefined;
    segment.config = {
      intervalDuration: 100,
      maxTime: 6000,
    };
  },
};
