import INC_BASE_API from 'apis/incentivio-api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const useImHere = () => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const { orderId } = useParams();

  const markAsImHere = async () => {
    setLoading(true);
    try {
      const res = await INC_BASE_API.post(`/orders/${orderId}/markimhere`);
      const { message } = res?.data;
      setDescription(message);

      if (String(res.status)[0] === '2') {
        setStatus('success');
      }

      setLoading(false);
    } catch (err) {
      const { headers, status } = err?.response;
      setLoading(false);
      setDescription(headers['incentivio-message']);
      if (String(status)[0] === '4') {
        setStatus('warning');
      } else if (String(status)[0] === '5') {
        setStatus('error');
      }
    }
  };

  useEffect(() => {
    markAsImHere();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, description, status };
};
