import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Footer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(1)}px 0
    ${({ theme }) => theme.spacing(1)}px 0;
`;

export const Description = styled(Box)`
  font-size: 0.75rem;
  margin: 1em 0;
  color: ${({ theme }) => theme.palette.text.primaryLight};
`;
