export const CHECKOUT_STEP = Object.freeze({
  ACCOUNT: 1,
  REVIEW: 2,
  PAYMENT: 3,
});

export const DEFAULT_TIP_PERCENTAGE = Object.freeze({
  PICKUP: 10,
  DELIVERY: 15,
});

export const SELECTED_DATE_AFTER_MAX_DAYS_OUT =
  'SELECTED_DATE_AFTER_MAX_DAYS_OUT';

export const ORDER_DETAILS_SECTION_ERROR_CODES = Object.freeze([
  'PREP_NOT_ENOUGH_ORDER_PREP_TIME',
  SELECTED_DATE_AFTER_MAX_DAYS_OUT,
]);

export const CHECKOUT_SECTIONS = Object.freeze({
  ORDER_DETAILS: 'Order Details-section',
  PAYMENT: 'Payment-section',
});
