import * as S from './custom-checkbox.styles';
import React, { forwardRef } from 'react';

const CustomCheckbox = forwardRef(
  (
    {
      checked,
      onChange,
      name,
      label,
      title,
      errors,
      defaultChecked,
      value,
      disabled,
      dataTestId = 'customCheckbox',
    },
    ref,
  ) => {
    return (
      <S.OptionWrapper
        data-testid={dataTestId}
        control={
          <S.CustomCheckbox
            inputProps={{ 'aria-label': title }}
            defaultChecked={defaultChecked}
            checked={checked}
            error={errors}
            value={value}
            onChange={onChange}
            name={name}
            inputRef={ref}
            disabled={disabled}
          />
        }
        label={label}
      />
    );
  },
);

export default CustomCheckbox;
