import { Box, Grid, Typography } from '@material-ui/core';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import Spinner from 'components/spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import { usePromptSmsOpt } from './prompt-sms-opt.hooks';

const PromptSmsOpt = () => {
  const { t } = useTranslation();

  const { onSubmit, dontAllow, smsPromptInModalOpen, handleClose, loading } =
    usePromptSmsOpt();

  return (
    <CustomDialog
      open={smsPromptInModalOpen}
      close={handleClose}
      testId={'PromptSMSOptModal.CustomDialog'}
      title={t('smsNotifications.prompt.optInTitle')}
    >
      {loading && <Spinner />}
      <Box mb={2}>
        <Typography aria-label={t('smsNotifications.prompt.optInQuestion')}>
          {t('smsNotifications.prompt.optInQuestion')}
        </Typography>
      </Box>

      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <IncButtonDark
              onClick={dontAllow}
              data-testid="PromptSMSOptModal.IncButtonDark"
              fullWidth
            >
              {t('smsNotifications.prompt.dontAllow')}
            </IncButtonDark>
          </Grid>
          <Grid item xs={6}>
            <IncButton
              type="submit"
              data-testid="PromptSMSOptModal.IncButton"
              fullWidth
            >
              {t('smsNotifications.prompt.allow')}
            </IncButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default PromptSmsOpt;
