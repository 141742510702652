import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRequestedStores } from 'redux/locations/locations.selectors';

export const useLocationsAddressControls = () => {
  const requestedStores = useSelector(selectRequestedStores);

  const hideLocationsAddressControls = useMemo(
    () =>
      requestedStores.length <= 1 &&
      !requestedStores[0]?.deliveryOrdersAccepted,
    [requestedStores],
  );

  return hideLocationsAddressControls;
};
