import React from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import { InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormError from 'components/form-error/form-error.component';
import TrimTextField from './trim-text-field/trim-text-field.component';
import { ErrorMessage } from '@hookform/error-message';
import { useResetDefaultValue } from './useResetDefaultValue';

const FirstName = ({ errors, register, defaultValue, disabled }) => {
  const { t } = useTranslation();

  useResetDefaultValue('firstName', defaultValue);

  return (
    <CustomInput>
      <InputLabel htmlFor="firstName">{t('forms.label.firstName')}*</InputLabel>
      <TrimTextField
        id="firstName"
        fullWidth
        placeholder={t('forms.placeholder.firstName')}
        error={!!errors.firstName}
        inputProps={{ autocomplete: 'given-name', 'aria-required': true }}
        {...register('firstName', {
          required: t('register.errors.requiredField'),
          validate: value => !!value.trim(),
        })}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <ErrorMessage
        errors={errors}
        name="firstName"
        render={({ message }) => (
          <FormError errorMessage={message} data-testid="FirstName.FormError" />
        )}
      />
    </CustomInput>
  );
};

export default FirstName;
