import { useDrawerLoginOptions } from 'components/drawer-login-options/drawer-login-options.hooks';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLoginLink = () => {
  const { setDrawerState, setInitialMachineState } = useDrawerLoginOptions();
  const { state } = useLocation();

  useEffect(
    () => () => setInitialMachineState('initial'),
    [setInitialMachineState, state],
  );

  const handleLoginLinkClick = useCallback(() => {
    setInitialMachineState('login');
    setDrawerState(true);
  }, [setDrawerState, setInitialMachineState]);

  return handleLoginLinkClick;
};
