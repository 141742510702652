import { useSelector } from 'react-redux';
import {
  selectAllergyDisplayInfo,
  selectSignUpPreferencesInfoDisabled,
} from 'redux/config/config.selectors';
import { selectUserAllergyPreferences } from 'redux/user/user.selectors';
import MultiSelect from '../multi-select/multi-select.component';
import SingleSelect from '../single-select/single-select.component';

const Allergy = () => {
  const allergyDisplayInfo = useSelector(selectAllergyDisplayInfo);
  const userAllergyPreferences = useSelector(selectUserAllergyPreferences);
  const signUpPreferencesInfoDisabled = useSelector(
    selectSignUpPreferencesInfoDisabled,
  );

  if (signUpPreferencesInfoDisabled) return null;

  if (allergyDisplayInfo.multiSelect) {
    return (
      <MultiSelect
        info={allergyDisplayInfo}
        userDietaryPreferences={userAllergyPreferences}
        name="ALLERGIES"
        testIdPrefix="Allergy"
      />
    );
  }

  return (
    <SingleSelect
      info={allergyDisplayInfo}
      userDietaryPreferences={userAllergyPreferences}
      name="ALLERGIES"
      testIdPrefix="Allergy"
    />
  );
};

export default Allergy;
