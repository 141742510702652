import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderOffers } from 'redux/cart/cart.actions';
import {
  selectApplicableOffers,
  selectDisplayTotalWithoutGratuityWithTip,
  selectIsAutoDiscountApplied,
  selectOrderAndItemDiscounts,
} from 'redux/cart/cart.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

export const useCheckoutOffersSection = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const applicableOffers = useSelector(selectApplicableOffers);
  const discounts = useSelector(selectOrderAndItemDiscounts);
  const isAutoDiscountApplied = useSelector(selectIsAutoDiscountApplied);
  const displayTotalWithTip = useSelector(
    selectDisplayTotalWithoutGratuityWithTip,
  );

  const show = useMemo(() => {
    if (isAutoDiscountApplied) return false;
    if (displayTotalWithTip === '0.00') return false;
    if (!isLoggedIn) return true;
    return applicableOffers?.length > 0 && discounts.length < 1;
  }, [
    applicableOffers?.length,
    discounts.length,
    displayTotalWithTip,
    isAutoDiscountApplied,
    isLoggedIn,
  ]);

  useEffect(() => {
    if (isLoggedIn && !isAutoDiscountApplied) {
      dispatch(getOrderOffers());
    }
  }, [dispatch, isAutoDiscountApplied, isLoggedIn, displayTotalWithTip]);

  return { show };
};
