import { Box } from '@material-ui/core';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import { useSelector } from 'react-redux';
import {
  selectIsLoyaltyCard,
  selectLoyaltyCardButtonName,
  selectLoyaltyText,
} from 'redux/config/config.selectors';
import * as S from './loyalty-footer.styles';

const LoyaltyFooter = ({ handleShowLoyaltyCard }) => {
  const loyaltyCardButtonName = useSelector(selectLoyaltyCardButtonName);
  const loyaltyText = useSelector(selectLoyaltyText);
  const isLoyaltyCard = useSelector(selectIsLoyaltyCard);

  return (
    <S.Footer>
      <S.Description>
        <LinkifyParse>{loyaltyText}</LinkifyParse>
      </S.Description>

      {isLoyaltyCard && (
        <Box>
          <IncButtonDark
            onClick={handleShowLoyaltyCard}
            data-testid="AccountLoyalty.Button.LoyaltyCard"
            fullWidth
          >
            {loyaltyCardButtonName}
          </IncButtonDark>
        </Box>
      )}
    </S.Footer>
  );
};

export default LoyaltyFooter;
