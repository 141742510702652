import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeDefaultOrder, setLocationData } from 'redux/cart/cart.actions';
import {
  makeSelectIsItemOutOfStock,
  selectCatalogsIsFetching,
  selectMenuType,
} from 'redux/catalogs/catalogs.selectors';
import { selectOrderId, selectSlug } from '../../redux/cart/cart.selectors';
import { fetchCatalogsStartAsync } from '../../redux/catalogs/catalogs.actions';
import {
  makeSelectStoreBySlug,
  makeSelectIsMenuBrowsingStore,
  selectRequestedStore,
} from '../../redux/locations/locations.selectors';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { menuItemKeyParam } from 'hooks/dialog/menu-dialog.constants';

export const useMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(selectRequestedStore);
  const orderId = useSelector(selectOrderId);
  const urlStoreSlug = useParams().storeTitle;
  const cartStoreSlug = useSelector(selectSlug);
  const menuType = useSelector(selectMenuType);
  const isLoading = useSelector(selectCatalogsIsFetching);
  const urlSearchParams = useURLSearchParams();
  const [isInitializing, setIsInitializing] = useState(false);
  const combinedIsLoading = useMemo(
    () => isLoading || isInitializing,
    [isInitializing, isLoading],
  );
  const urlStore = useSelector(makeSelectStoreBySlug(urlStoreSlug));
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(urlStore?.storeId),
  );

  useEffect(() => {
    if (isMenuBrowsingStore) {
      dispatch(
        setLocationData({ slug: urlStoreSlug, locationId: urlStore?.storeId }),
      );
      dispatch(fetchCatalogsStartAsync());
    }
  }, [dispatch, isMenuBrowsingStore, urlStore?.storeId, urlStoreSlug]);

  useEffect(() => {
    if (isMenuBrowsingStore) return;
    const execute = async () => {
      setIsInitializing(true);

      if (urlStoreSlug !== cartStoreSlug || !orderId) {
        try {
          await dispatch(
            makeDefaultOrder(urlSearchParams.get('orderType'), urlStoreSlug),
          );
        } catch (e) {
          history.push('.', {
            mik: urlSearchParams.get(menuItemKeyParam),
            orderType: urlSearchParams.get('orderType'),
          });
        }
      }
      await dispatch(fetchCatalogsStartAsync());
      setIsInitializing(false);
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    menuType,
    store,
    isLoading: combinedIsLoading,
  };
};

export const useIsItemOutOfStock = itemId => {
  const selectIsItemOutOfStock = useCallback(
    state => makeSelectIsItemOutOfStock(itemId)(state),
    [itemId],
  );

  return useSelector(selectIsItemOutOfStock);
};
